import axios from "axios";
import { v4 as uuid } from "uuid";
import { apiConfig } from "./apiConfig";

import { fakeWith } from "utils/fakeQuery";
import { fake_signIn } from "fakeData/fake_signIn";
import { fake_send_activation } from "fakeData/fake_send_activation";
import { fake_register } from "fakeData/fake_register";
import { fake_forgotPassword } from "fakeData/fake_forgotPassword";
import { fake_resetPassword } from "fakeData/fake_resetPassword";
import { fake_get_user_attributes } from "fakeData/fake_get_user_attributes";
import { fake_check_token } from "fakeData/fake_check_token";
import { fake_activate_email } from "fakeData/fake_activate_email";

const auth = function (api) {
  async function signIn(input) {
    const { email, password } = input;
    return axios.post(
      `${apiConfig.hostUrl}/signin`,
      {
        username: email,
        password,
      },
      { timeout: 3000 }
    );
  }

  async function register({
    email,
    password,
    name,
    last_name,
    country,
    phone,
  }) {
    const input = {
      id: uuid(),
      email,
      password,
      name,
      last_name,
      country,
      phone,
      is_owner: true,
    };

    //TODO do it in a proper place
    window.localStorage.removeItem("VerdantipsToken");
    window.localStorage.removeItem("VerdantipsEmail");

    const query = `mutation ($input: Any) { result: users__create (input: $input) {ok payload errors}}`;
    const data = await api.gql(query, { input });
    return data.data.result;
  }

  async function forgotPassword({ email }) {
    const query = `mutation user ($input: Any) { result: users__forgot_password (input: $input) {ok payload errors}}`;
    const variables = { input: { email } };
    const data = await api.gql(query, variables);
    return data;
  }

  async function resetPassword({ token, password }) {
    const query = `mutation user ($input: Any) { result: users__reset_password (input: $input) {ok payload errors}}`;
    const variables = { input: { token, password } };
    const data = await api.gql(query, variables);
    return data;
  }

  async function checkToken({ token }) {
    return axios.get(`${apiConfig.hostUrl}/check_token/${token}`, {
      timeout: 3000,
    });
  }

  async function getUserAttributes() {
    const query = `query { result: users__me {ok errors payload {
      id
      email
      name
      last_name
      country
      phone_number
      type
    }}}`;
    const data = await api.gql(query);
    return data;
  }
  // TODO by Backend
  async function sendActivation(input) {
    const query = `mutation user ($input: Any) { result: users__resend_email_validation_message(input: $input) {ok payload errors}}`;
    const variables = { input };
    const data = await api.gql(query, variables);
    return data;
  }

  async function activateEmail(input) {
    const query = `mutation user ($input: Any) { result: users__validate_email_by_token(input: $input) {ok payload errors}}`;
    const variables = { input };
    const data = await api.gql(query, variables);
    return data;
  }

  // SIMULATIONS: Turn to false to deactivate
  const simulate = false;
  const _signIn = fakeWith(fake_signIn, signIn, simulate);
  const _sendActivation = fakeWith(
    fake_send_activation,
    sendActivation,
    simulate
  );
  const _register = fakeWith(fake_register, register, simulate);
  const _forgotPassword = fakeWith(
    fake_forgotPassword,
    forgotPassword,
    simulate
  );
  const _resetPassword = fakeWith(fake_resetPassword, resetPassword, simulate);
  const _getUserAttributes = fakeWith(
    fake_get_user_attributes,
    getUserAttributes,
    simulate
  );
  const _checkToken = fakeWith(fake_check_token, checkToken, simulate);
  const _activateEmail = fakeWith(fake_activate_email, activateEmail, simulate);

  return {
    signIn: _signIn || signIn,
    register: _register || register,
    forgotPassword: _forgotPassword || forgotPassword,
    resetPassword: _resetPassword || resetPassword,
    getUserAttributes: _getUserAttributes || getUserAttributes,
    sendActivation: _sendActivation || sendActivation,
    activateEmail: _activateEmail || activateEmail,
    checkToken: _checkToken || checkToken,
  };
};

export default auth;
