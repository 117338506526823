import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useLocation } from "react-router-dom";
import { LockClosedIcon } from "@heroicons/react/solid";
import { useAuth } from "context/AuthProvider";
import { LinkText } from "components/LinkText";
import Button from "components/Button";
import { ErrorBlock } from "components/ErrorBlock";

export const Login = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const { signIn } = useAuth();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errorList, setErrorList] = useState([]);
  const [isLoading, setIsloading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();

    setIsloading(true);
    setErrorList([]);
    const errors = [];

    try {
      const response = await signIn({ email, password });
      if (response?.ok === true) {
        let from = location.state?.from?.pathname || "/dashboard"; // If the visitor is redirected to login when access other url, this will redirect to that after sign in
        navigate(from, { replace: true });
        setIsloading(false);
        return;
      }
    } catch (error) {
      if (error.message === "EMAIL-NOT-VALIDATED")
        navigate("/activate-account", { state: email });
      errors.push(error.message);
    }
    setErrorList(errors);
    setIsloading(false);
  };

  return (
    <>
      <div className="flex min-h-full items-center justify-center px-4 py-12 sm:px-6 lg:px-8">
        <div className="w-full max-w-md space-y-8">
          <img
            className="mx-auto h-20 w-auto"
            src={process.env.PUBLIC_URL + "/logo.png"}
            alt="Verdantips"
          />
          <h1 className="m-8 text-center text-4xl font-extrabold text-vdark sm:text-6xl">
            {t("login.title")}
          </h1>
          <form onSubmit={handleSubmit} className="mt-8 space-y-6">
            <div className="-space-y-px rounded-md shadow-sm">
              <div>
                <label htmlFor="email-address" className="sr-only">
                  {t("login.emailLabel")}
                </label>
                <input
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  name="email"
                  type="email"
                  autoComplete="email"
                  required
                  className="relative block w-full appearance-none rounded-none rounded-t-md border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-green-500 focus:outline-none focus:ring-green-500 sm:text-sm"
                  placeholder={t("login.emailLabel")}
                />
              </div>
              <div>
                <label htmlFor="password" className="sr-only">
                  {t("login.passwordLabel")}
                </label>
                <input
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  type="password"
                  autoComplete="current-password"
                  required
                  className="relative block w-full appearance-none rounded-none rounded-b-md border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-green-500 focus:outline-none focus:ring-green-500 sm:text-sm"
                  placeholder={t("login.passwordLabel")}
                />
              </div>
            </div>

            <div className="flex justify-end">
              <LinkText to="/forgot-password" className="text-right">
                {t("login.forgot")}
              </LinkText>
            </div>

            <div>
              <Button
                type="submit"
                value="Submit"
                className="relative flex w-full justify-center px-4 py-2 text-sm font-medium "
                icon={
                  <LockClosedIcon
                    className="h-5 w-5 text-vdark"
                    aria-hidden="true"
                  />
                }
                isLoading={isLoading}
              >
                {t("login.signIn")}
              </Button>
            </div>
          </form>
          <div>
            <span>{t("login.noAccount")}</span>
            <LinkText to="/create-restaurant-account">
              {t("login.create")}
            </LinkText>
            {errorList.length > 0 && (
              <ErrorBlock
                title="login.errorTitle"
                errorList={errorList}
                className="mt-4"
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
