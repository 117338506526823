import React, { Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";

export function PhotoModal({ open, setOpen, photo }) {
  let exitButtonRef = React.useRef(null);
  if (photo === undefined) return null;
  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 z-30 py-4 overflow-y-auto"
        onClose={setOpen}
        initialFocus={exitButtonRef}
      >
        <div className="flex items-center justify-center h-full px-4 pt-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 transition-opacity bg-gray-800 bg-opacity-75" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden sm:inline-block sm:h-screen sm:align-middle"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="relative inline-block max-w-3xl overflow-hidden text-left align-middle transition-all transform rounded-lg shadow-xl max-h-min ">
              <button
                className="absolute rounded-full right-3 top-3 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2 "
                ref={exitButtonRef}
                onClick={() => setOpen(false)}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 20 21"
                  className="w-10 h-10"
                >
                  <path
                    fill="#D9EF59"
                    d="M19 10.5a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                  />
                  <path
                    stroke="#354D5A"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="m8 12.5 2-2m0 0 2-2m-2 2-2-2m2 2 2 2m7-2a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                  />
                </svg>
              </button>
              <img
                src={photo}
                alt=""
                className="w-full h-full max-h-screen rounded"
              />
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
