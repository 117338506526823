// TODO: A lot of duplicated code. We should create a component for both cases
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useUpdateRestaurant } from "hooks/restaurantHooks";
import { TextInput } from "components/FormElements";
import { StepSubmitButtons } from "./StepSubmitButtons";
import isEmail from "validator/lib/isEmail";
import isURL from "validator/lib/isURL";

export function EditRestaurantContactInformation({
  restaurantInfo,
  isUpdateDisabled,
  setIsUpdateDisabled,
}) {
  const {
    id,
    name: savedName,
    address: savedAddress,
    zipcode: savedZipcode,
    city: savedCity,
    phone_number: savedPhone,
    email: savedEmail,
    urls: savedUrls,
  } = restaurantInfo;
  const { t } = useTranslation();
  const { mutate: updateRestaurant, isLoading: isLoadingUpdateRestaurant } =
    useUpdateRestaurant();
  const [error, setError] = useState([]);
  const [success, setSuccess] = useState([]);
  const [restaurantName, setRestaurantName] = useState(savedName);
  const [address, setAddress] = useState(savedAddress);
  const [zipcode, setZipcode] = useState(savedZipcode);
  const [city, setCity] = useState(savedCity);
  const [phone, setPhone] = useState(savedPhone);
  const [email, setEmail] = useState(savedEmail);
  const [website, setWebsite] = useState(savedUrls.website);
  const [googlemaps, setGooglemaps] = useState(savedUrls.google_page);
  const [instagram, setInstagram] = useState(savedUrls.instagram);
  const [facebook, setFacebook] = useState(savedUrls.facebook);

  useEffect(() => {
    if (
      restaurantName.length === 0 ||
      address.length === 0 ||
      zipcode.length === 0 ||
      city.length === 0 ||
      phone.length === 0 ||
      email.length === 0 ||
      website.length === 0 ||
      googlemaps.length === 0
    )
      return setIsUpdateDisabled(true);
    if (
      restaurantName !== savedName ||
      address !== savedAddress ||
      zipcode !== savedZipcode ||
      city !== savedCity ||
      phone !== savedPhone ||
      email !== savedEmail ||
      website !== savedUrls.website ||
      googlemaps !== savedUrls.google_page ||
      instagram !== savedUrls.instagram ||
      facebook !== savedUrls.facebook
    ) {
      return setIsUpdateDisabled(false);
    } else return setIsUpdateDisabled(true);
  }, [
    restaurantName,
    address,
    zipcode,
    city,
    phone,
    email,
    website,
    googlemaps,
    instagram,
    facebook,
    savedName,
    savedAddress,
    savedZipcode,
    savedCity,
    savedPhone,
    savedEmail,
    savedUrls,
    setIsUpdateDisabled,
  ]);

  function handleSubmit() {
    setError([]);
    setSuccess([]);
    const errorsList = [];
    if (isEmail(email) === false) errorsList.push("invalidEmailError");
    if (isURL(website) === false) errorsList.push("websiteError");
    if (isURL(googlemaps) === false) errorsList.push("googlemapsError");
    if (instagram.length > 0 && isURL(instagram) === false)
      errorsList.push("instagramError");
    if (facebook.length > 0 && isURL(facebook) === false)
      errorsList.push("facebookError");
    if (errorsList.length > 0) {
      return setError(errorsList);
    }
    // TODO: Check with backend if we can only send the changed data and not everything
    const input = {
      id,
      name: restaurantName,
      address,
      zipcode,
      city,
      phone_number: phone,
      email,
      urls: {
        website,
        google_page: googlemaps,
        instagram,
        facebook,
      },
    };
    updateRestaurant(input, {
      onError: (error) => {
        console.log(error);
        setError(["unknownError"]);
      },
      onSuccess: (data) => {
        if (data.data.result.ok === false) return setError(["deniedError"]);
        if (data.data.result.ok === true)
          return setSuccess(["informationUpdated"]);
      },
    });
  }

  return (
    <>
      <form
        className="mx-auto max-w-3xl space-y-8 divide-y divide-gray-200 px-4 sm:px-8"
        onSubmit={handleSubmit}
      >
        <div>
          <div className="space-y-6 pt-2 sm:space-y-5 sm:pt-6">
            <div className="space-y-6 sm:space-y-5">
              <TextInput
                attributeName="restaurantName"
                label={t("restaurantContact.nameLabel")}
                value={restaurantName}
                setValue={setRestaurantName}
                required={true}
                borderTop={false}
                placeholder={t("restaurantContact.namePlaceholder")}
              />
              <TextInput
                attributeName="address"
                label={t("restaurantContact.addressLabel")}
                value={address}
                setValue={setAddress}
                required={true}
                placeholder={t("restaurantContact.addressPlaceholder")}
              />
              <TextInput
                attributeName="zipcode"
                label={t("restaurantContact.zipcodeLabel")}
                value={zipcode}
                setValue={setZipcode}
                required={true}
                placeholder="12345"
              />
              <TextInput
                attributeName="city"
                label={t("restaurantContact.cityLabel")}
                value={city}
                setValue={setCity}
                required={true}
                placeholder={t("restaurantContact.cityPlaceholder")}
              />
              <TextInput
                attributeName="phone"
                label={t("restaurantContact.phoneLabel")}
                value={phone}
                setValue={setPhone}
                required={true}
                placeholder={t("restaurantContact.phonePlaceholder")}
              />
              <TextInput
                attributeName="email"
                label={t("restaurantContact.emailLabel")}
                value={email}
                setValue={setEmail}
                required={true}
                placeholder={t("restaurantContact.emailPlaceholder")}
                type="email"
                autoComplete="email"
                icon="mail"
              />
              <TextInput
                attributeName="website"
                label={t("restaurantContact.websiteLabel")}
                value={website}
                setValue={setWebsite}
                required={true}
                placeholder={t("restaurantContact.websitePlaceholder")}
              />
              <TextInput
                attributeName="googlemaps"
                label={t("restaurantContact.googlemapsLabel")}
                value={googlemaps}
                setValue={setGooglemaps}
                required={true}
                placeholder="www.google.com/maps/place/..."
              />
              <TextInput
                attributeName="instagram"
                label={t("restaurantContact.instagramLabel")}
                value={instagram}
                setValue={setInstagram}
                required={false}
                placeholder="www.instagram.com/..."
              />
              <TextInput
                attributeName="facebook"
                label={t("restaurantContact.facebookLabel")}
                value={facebook}
                setValue={setFacebook}
                required={false}
                placeholder="www.facebook.com/..."
              />
            </div>
          </div>
        </div>
        <div>
          <StepSubmitButtons
            isDisabled={isUpdateDisabled}
            onNext={handleSubmit}
            error={error}
            success={success}
            isLoading={isLoadingUpdateRestaurant}
            next={t("editRestaurant.updateContact")}
            skip={false}
            className="mb-7 sm:mb-14"
          />
        </div>
      </form>
    </>
  );
}
