// The icons used in badges are not well aligned for rounded icons, so we use these instead
import React from "react";
import eco from "./img/eco.svg";
import plantFriendly from "./img/plant-friendly.svg";
import security from "./img/security.svg";
import meat from "./img/meat.svg";
import energy from "./img/energy.svg";
import plant from "./img/plant.svg";
import plastic from "./img/plastic.svg";
import plasticConscious from "./img/plastic-conscious.svg";
import vegetarian from "./img/vegan.svg";
import vegetarianFriendly from "./img/vege-friendly.svg";
import produce from "./img/produce.svg";
import waste from "./img/waste.svg";
import renewable from "./img/renewable.svg";
import seafood from "./img/seafood.svg";
import water from "./img/water.svg";

export function BadgeIcon({ keyword }) {
  // In the current database we have bad badge assignments. This should be simplified in the future when we reset it
  let src;
  if (keyword === "ECOCERTI") src = eco;
  if (keyword === "PLANFRIE" || keyword === "PLANTFRIENDLY")
    src = plantFriendly;
  if (keyword === "PLANTBASED") src = plant;
  if (keyword === "COVICONS") src = security;
  if (keyword === "SUSTMEAT") src = meat;
  if (keyword === "ENEGEFFI") src = energy;
  if (keyword === "PLANFULL") src = plant;
  if (keyword === "PLASCONS" || keyword === "PLASTICCONSCIOUS")
    src = plasticConscious;
  if (keyword === "PLASTICFREE") src = plastic;
  if (keyword === "VEGEFRIE" || keyword === "VEGETARIANFRIENDLY")
    src = vegetarianFriendly;
  if (keyword === "VEGEFULL" || keyword === "VEGETARIAN") src = vegetarian;
  if (keyword === "SUSTPROD") src = produce;
  if (keyword === "WASTCONS") src = waste;
  if (keyword === "RENWENEG") src = renewable;
  if (keyword === "SUSTSEAF") src = seafood;
  if (keyword === "WATREFFI") src = water;

  return (
    <img
      src={src}
      alt=""
      className=" h-10 w-10 rounded-full border border-vdark"
    />
  );
}
