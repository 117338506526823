import { FakeResponseCollection } from "utils/fakeResponseCollection";

export const fake_get_restaurant_by_restaurant_id =
  new FakeResponseCollection();

fake_get_restaurant_by_restaurant_id.set({
  input: {
    id: "restaurant-1",
  },
  response: {
    data: {
      result: {
        ok: true,
        payload: {
          id: "restaurant-1",
          owner_id: "kk@kk.com",
          name: "La Viña",
          address: "Lehendakari Aguirre, 4",
          zipcode: 12345,
          city: "Bilbao",
          phone_number: "666555444",
          email: "restaurant-1@kk.com",
          urls: {
            website: "www.restaurant.com",
            google_page: "google.com/maps/place/restaurant.com",
            instagram: "",
            facebook: "",
          },
          location: {
            longitude: 1.897,
            latitude: -8.2351,
          },
          status: "UNPUBLISHED",
          open_hours: {
            monday: [],
            tuesday: [],
            wednesday: [{ start: ["10", "00"], end: ["16", "00"] }],
            thursday: [
              { start: ["10", "00"], end: ["16", "00"] },
              { start: ["20", "00"], end: ["23", "00"] },
            ],
            friday: [
              { start: ["10", "00"], end: ["16", "00"] },
              { start: ["20", "00"], end: ["23", "00"] },
            ],
            saturday: [
              { start: ["10", "00"], end: ["16", "00"] },
              { start: ["20", "00"], end: ["23", "00"] },
            ],
            sunday: [{ start: ["10", "00"], end: ["16", "00"] }],
          },
          closed_days: ["2022-04-01"],
          description: "Cocina de autor en un entorno incomparable",
          cuisine: ["AMERICAN", "ASIAN"],
          dietary: ["KOSHER"],
          services: ["AIR_CON"],
          average_meal_price: 20,
          reservation_required: false,
          reservation_channel: [],
          photos: {
            cover: "https://source.unsplash.com/640x425",
            menu: [],
            food: [],
            ambience: [],
            covid: [],
          },
          sustainability: [],
          certifications: {
            COVICONS: {
              covid_measures: [],
              other_covid_measures: "",
            },
            ECOCERTI: {
              eco_certification: [],
              extra_eco_certification: "",
            },
            ENEGEFFI: {
              energy_practices: [],
              energy_comments: "",
            },
            PLANFRIE: {
              entire_plant_based: false,
              two_main_dishes: false,
              plant_menu_includes: [],
            },
            PLASCONS: {
              has_plastic_free_certification: false,
              plastic_free_certification_name: "",
              plastic_free_certification_year: null,
              plastic_free_certification_duration: null,
              plastic_use: [],
              plastic_free_badge: false,
              plastic_conscious_badge: false,
              plastic_comments: "",
            },
            RENWENEG: {
              renewable_has_own: false,
              renewable_installation: [],
              renewable_consumption_percentage: null,
              renewable_start: null,
              renewable_has_electricity: false,
              renewable_electricity_provider: "",
              renewable_has_gas: false,
              renewable_gas_provider: "",
              renewable_comments: "",
            },
            SUSTMEAT: {
              meat_has_certification: false,
              meat_certification_type: [],
              meat_certification_name: "",
              meat_type: "",
              meat_is_local: false,
              meat_menu: [],
              meat_comments: "",
            },
            SUSTPROD: {
              produce_has_grow: false,
              produce_grow_percentage: "",
              produce_grow_type: [],
              produce_grow_other_type: "",
              produce_is_eu_certified: false,
              produce_organic_percentage: "",
              produce_is_local: false,
              produce_local_percentage: "",
              produce_comments: "",
            },
            SUSTSEAF: {
              has_seafood_certification: false,
              seafood_certification_type: [],
              seafood_certification_name: "",
              seafood_type: "",
              seafood_is_local: false,
              seafood_comments: "",
            },
            VEGEFRIE: {
              entirely_vegetarian: false,
              two_minimum_vegetarian_dishes: false,
            },
            WASTCONS: {
              waste_sell_surplus: false,
              waste_surplus_description: "",
              waste_compost: false,
              waste_compost_explanation: [],
              waste_inventory: false,
              waste_recycle: [],
              waste_train: false,
              waste_customer_containers: false,
              waste_comments: "",
            },
            WATREFFI: {
              water_practices: [],
              water_comments: "",
            },
          },
          signed_consent: true,
        },
      },
    },
  },
});
