import { fakeWith } from "utils/fakeQuery";
import { fake_get_restaurants_by_current_user } from "fakeData/fake_get_restaurants_by_current_user";
import { fake_get_restaurants_by_user } from "fakeData/fake_get_restaurants_by_user";
import { fake_create_restaurant } from "fakeData/fake_create_restaurant";
import { fake_get_keywords } from "fakeData/fake_get_keywords";
import { fake_update_restaurant } from "fakeData/fake_update_restaurant";
import { fake_remove_restaurant } from "fakeData/fake_remove_restaurant";
import { fake_get_restaurant_by_restaurant_id } from "fakeData/fake_get_restaurant_by_restaurant_id";

const restaurants = function (api) {
  async function mine(input) {
    const query = `query { result: restaurants__mine {ok payload {
        id
        name
        photos
        location {latitude longitude}
        address
        zipcode
        city
        phone_number
        email
        urls
        open_hours
        cuisine
        dietary
        services
        sustainability
        description
        closed_days
        reservation_required
        reservation_channel
        average_meal_price
        signed_consent
        status
      } errors}}`;
    const data = await api.gql(query);
    return data;
  }

  async function all() {
    const query = `query { result: restaurants__all 
      {ok payload {
        id
        name
        photos
        location {latitude longitude}
        address
        zipcode
        city
        phone_number
        email
        urls
        is_favourite: is_favourite_for_logged_user
        open_hours
        cuisine
        dietary
        services
        sustainability
        description
        closed_days
        reservation_required
        reservation_channel
        average_meal_price
        signed_consent
        status
        certifications
      } errors}}`;
    const options = { withToken: false };
    const data = await api.gql(query, null, options);
    return data;
  }

  async function byId(id) {
    const query = `query ($id: String) { result: restaurants__by_id(id: $id)
      {ok payload {
        id
        name
        photos
        location {latitude longitude}
        address
        zipcode
        city
        phone_number
        email
        urls
        open_hours
        cuisine
        dietary
        services
        sustainability
        description
        closed_days
        reservation_required
        reservation_channel
        average_meal_price
        signed_consent
        status
      } errors}}`;
    const data = await api.gql(query, { id });
    return data;
  }

  async function create(input) {
    const query = `mutation ($input: Any) { result: restaurants__create(input: $input)
      {ok payload errors}}`;
    const data = await api.gql(query, { input });
    return data;
  }

  async function getKeywords(input) {
    const query = `query { result: restaurants__keywords
    {ok payload errors}}`;
    const data = await api.gql(query);
    return data;
  }

  async function update(input) {
    const query = `mutation ($input: Any) { result: restaurants__update(input: $input) {ok payload errors}}`;
    const data = await api.gql(query, { input });
    return data;
  }

  async function remove({ id }) {
    const query = `mutation ($input: Any) { result: restaurants__delete(input: $input)
      {ok payload errors}}`;
    const data = await api.gql(query, { input: { id } });
    return data;
  }

  async function getInfo(input) {
    const query = `query ($id: String) { result: restaurants__by_id(id: $id)
      {ok payload {
        id
        name
        photos
        location {latitude longitude}
        address
        zipcode
        city
        phone_number
        email
        urls
        open_hours
        cuisine
        dietary
        services
        sustainability
        certifications
        description
        closed_days
        reservation_required
        reservation_channel
        average_meal_price
        signed_consent
        status
      } errors}}`;
    const data = await api.gql(query, { id: input.id });
    return data;
  }

  // SIMULATIONS: Turn to false to deactivate
  const simulate = false;
  const _mine = fakeWith(fake_get_restaurants_by_current_user, mine, simulate);
  const _byId = fakeWith(fake_get_restaurants_by_user, byId, simulate);
  const _create = fakeWith(fake_create_restaurant, create, simulate);
  const _getKeywords = fakeWith(fake_get_keywords, getKeywords, simulate);
  const _update = fakeWith(fake_update_restaurant, update, simulate);
  const _remove = fakeWith(fake_remove_restaurant, remove, simulate);
  const _getInfo = fakeWith(
    fake_get_restaurant_by_restaurant_id,
    getInfo,
    simulate
  );

  return {
    mine: _mine || mine,
    all,
    byId: _byId || byId,
    create: _create || create,
    getKeywords: _getKeywords || getKeywords,
    update: _update || update,
    remove: _remove || remove,
    getInfo: _getInfo || getInfo,
  };
};

export default restaurants;
