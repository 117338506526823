import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import heroImg from "./img/hero.jpg";
import { MarketIcon } from "components/MarketIcons/MarketIcon";
import { AddPills } from "components/FormElements";
import { badgeOptions } from "./badgeOptions";
import { useGetAllRestaurants } from "hooks/restaurantHooks";
import { FullPageLoader } from "components/Loading";
import { RestaurantCard } from "components/RestaurantCard";
import { fixSustainabilityList } from "utils/misc";
import { t } from "i18next";

export function Restaurants() {
  const { t } = useTranslation();
  const { data: restaurantsData, isLoading: isLoadingRestaurantsData } =
    useGetAllRestaurants();
  const [open, setOpen] = useState(false);
  const [filterList, setFilterList] = useState([]);

  if (isLoadingRestaurantsData) return <FullPageLoader />;

  return (
    <main>
      <HeroImage />
      <div className="mx-auto flex max-w-7xl flex-col gap-8 px-4 sm:gap-12 sm:px-8 md:gap-16">
        <div className="mx-auto flex max-w-4xl flex-col gap-4">
          <p>{t("restaurants.intro")}</p>
          <div className="my-4 flex flex-col items-center justify-around gap-6 sm:my-6 sm:flex-row">
            <MarketIcon market="apple" className="w-60 sm:h-20" />
            <MarketIcon market="google" className="w-60 sm:h-20" />
          </div>
          <AddPills
            title={t("restaurants.filter")}
            open={open}
            setOpen={setOpen}
            list={filterList}
            setList={setFilterList}
            optionsList={badgeOptions}
            filter={false}
            blockStyle="sm:max-h-[34vh]"
          />
        </div>
      </div>
      <FilteredRestaurants
        restaurantsList={restaurantsData?.data.result.payload}
        filterList={filterList}
      />
    </main>
  );
}

function FilteredRestaurants({ restaurantsList = [], filterList = [] }) {
  const filteredList =
    filterList.length > 0
      ? restaurantsList.filter(({ sustainability }) => {
          const checkList = [];
          for (const item of filterList) {
            if (sustainability.includes(item)) checkList.push(true);
            else checkList.push(false);
          }
          if (checkList.includes(false)) return false;
          return true;
        })
      : restaurantsList;
  return (
    <div className="mt-8 flex flex-wrap justify-center gap-8">
      {filteredList.length === 0 && (
        <div>
          <p className="p-4 ">{t("restaurants.empty")}</p>
        </div>
      )}
      {filteredList.map(
        ({
          name,
          description,
          cuisine,
          sustainability,
          address,
          photos,
          certifications,
          id,
        }) => {
          fixSustainabilityList(sustainability, certifications);
          return (
            <RestaurantCard
              key={id}
              id={id}
              name={name}
              cover={photos?.cover}
              description={description}
              cuisine={cuisine}
              sustainability={sustainability}
              address={address}
            />
          );
        }
      )}
    </div>
  );
}

function HeroImage() {
  const { t } = useTranslation();
  return (
    <div className="relative isolate mb-8 h-64 sm:mb-12 sm:h-[500px] md:mb-16">
      <img src={heroImg} alt="" className="absolute h-full object-cover" />
      <div className="absolute h-full w-full bg-vdarkgreen opacity-60 " />
      <div className="absolute flex h-full w-full flex-col items-center justify-center gap-2 p-2 sm:gap-6 lg:gap-8">
        <p
          style={{ fontFamily: "DIN" }}
          className="max-w-sm text-center text-5xl text-white drop-shadow sm:max-w-xl sm:text-8xl lg:max-w-5xl lg:text-8xl"
        >
          {t("restaurants.heroTitle")}
        </p>
      </div>
    </div>
  );
}
