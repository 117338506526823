export async function stall(stallTime = 5000) {
  await new Promise((resolve) => setTimeout(resolve, stallTime));
}

export async function fakeQuery(fakeData) {
  /* BACKEND NOTE: this is to make tests run faster.
   * (if running in tests, there is no timeout)
   *
   * Should'n affect performance out of tests.
   */

  const timeout = process.env.JEST_WORKER_ID === undefined ? 2000 : 0;
  await stall(timeout);
  console.log("fakeQuery", { fakeData });
  return fakeData;
}

export function fakeWith(fakeResponse, unfaked, activate) {
  if (activate === false) return null;
  const wrapped = function (input) {
    console.log("fakeQuery", { input });
    return fakeQuery(fakeResponse.get(input));
  };
  wrapped.unfaked = unfaked;
  return wrapped;
}
