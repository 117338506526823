import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { XCircleIcon, CheckCircleIcon } from "@heroicons/react/solid";
import { ModalConfirm } from "components/ModalConfirm";
import {
  useUpdateRestaurant,
  useRemoveRestaurant,
} from "hooks/restaurantHooks";

const MINIMUM_OPEN_DAYS = 1;

export function RestaurantSummaryCard({ restaurant }) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { mutate: update, isLoading: isLoadingUpdate } = useUpdateRestaurant();
  const { mutate: remove, isLoading: isLoadingRemove } = useRemoveRestaurant();
  const [error, setError] = React.useState([]);
  const [openConfirm, setOpenConfirm] = React.useState(false);
  const [confirmTitle, setConfirmTitle] = React.useState("_");
  const [confirmText, setConfirmText] = React.useState("_");
  const [actionText, setActionText] = React.useState("_");
  const [confirmFunction, setConfirmFunction] = React.useState();
  const isLoading = isLoadingUpdate || isLoadingRemove;
  const {
    id,
    name,
    photos,
    address,
    zipcode,
    city,
    phone_number,
    email,
    urls,
    open_hours,
    cuisine,
    dietary,
    services,
    sustainability,
    average_meal_price,
    signed_consent,
    status,
  } = restaurant;

  const isContact = checkContactInformation({
    name,
    urls,
    address,
    city,
    phone_number,
    email,
    zipcode,
  });
  const isOpenHours = checkOpenHours({ open_hours });
  const isDetails = checkRestaurantDetails({
    cuisine,
    dietary,
    services,
    average_meal_price,
  });
  const isSustainability = checkSustainability({ sustainability });
  const isPhotos = checkPhotos({ photos });
  const isConsent = checkConsent({ signed_consent });
  const isAllTrue =
    isContact &&
    isOpenHours &&
    isDetails &&
    isSustainability &&
    isPhotos &&
    isConsent;

  function handleDelete() {
    setError([]);
    function deleteRestaurant() {
      return remove(
        { id },
        {
          onError: () => {
            return setError(["unknownError"]);
          },
          onSuccess: (data) => {
            if (data.data.result.ok === false) {
              return setError(["deniedError"]);
            }
            if (data.data.result.ok === true) {
              setOpenConfirm(false);
              return window.location.reload();
            }
          },
        }
      );
    }
    setConfirmTitle("dashboard.deleteRestaurantTitle");
    setConfirmText("dashboard.deleteRestaurantText");
    setActionText("dashboard.deleteRestaurantAction");
    setConfirmFunction(() => deleteRestaurant);
    setOpenConfirm(true);
    return;
  }

  function handleUnpublish() {
    setError([]);
    function unPublishRestaurant() {
      return update(
        { id, status: "UNPUBLISHED" },
        {
          onError: () => {
            return setError(["unknownError"]);
          },
          onSuccess: (data) => {
            if (data.data.result.ok === false) {
              return setError(["deniedError"]);
            }
            if (data.data.result.ok === true) {
              setOpenConfirm(false);
              return window.location.reload();
            }
          },
        }
      );
    }
    setConfirmTitle("dashboard.unpublishRestaurantTitle");
    setConfirmText("dashboard.unpublishRestaurantText");
    setActionText("dashboard.unpublishRestaurantAction");
    setConfirmFunction(() => unPublishRestaurant);
    setOpenConfirm(true);
    return;
  }

  function handlePublish() {
    setError([]);
    function publishRestaurant() {
      return update(
        { id, status: "PUBLISHED" },
        {
          onError: () => {
            return setError(["unknownError"]);
          },
          onSuccess: (data) => {
            if (data.data.result.ok === false) {
              return setError(["deniedError"]);
            }
            if (data.data.result.ok === true) {
              setOpenConfirm(false);
              return window.location.reload();
            }
          },
        }
      );
    }
    setConfirmTitle("dashboard.publishRestaurantTitle");
    setConfirmText("dashboard.publishRestaurantText");
    setActionText("dashboard.publishRestaurantAction");
    setConfirmFunction(() => publishRestaurant);
    setOpenConfirm(true);
  }
  function handleEdit() {
    navigate(`/dashboard/restaurant/${id}`);
    return;
  }

  return (
    <>
      <ModalConfirm
        open={openConfirm}
        setOpen={setOpenConfirm}
        title={confirmTitle}
        confirmText={confirmText}
        actionText={actionText}
        confirmFunction={confirmFunction}
        error={error}
        isLoading={isLoading}
      />
      <div className="m-4 flex w-80 flex-col overflow-hidden rounded-lg border bg-white shadow">
        <h2 className="truncate p-4 pb-2 text-center text-4xl">{name}</h2>
        {status === "PUBLISHED" ? (
          <p className="w-full border-t-2 bg-green-500 p-4 text-center text-xl font-semibold text-white">
            {t("dashboard.published")}
          </p>
        ) : (
          <p className="w-full border-t-2 bg-red-500 p-4 text-center text-xl font-semibold text-white">
            {t("dashboard.notPublished")}
          </p>
        )}
        <div className="gap-0 border border-b-2 bg-gray-100">
          <Requirement title={t("dashboard.contactInfo")} checked={isContact} />
          <Requirement title={t("dashboard.openHours")} checked={isOpenHours} />
          <Requirement
            title={t("dashboard.restaurantDetails")}
            checked={isDetails}
          />
          <Requirement title={t("dashboard.photos")} checked={isPhotos} />
          <Requirement
            title={t("dashboard.sustainabilityBadges")}
            checked={isSustainability}
          />
          <Requirement
            title={t("dashboard.signedConsent")}
            checked={isConsent}
          />
        </div>
        <div className="flex h-20 justify-between divide-x divide-gray-200">
          <button
            onClick={handleDelete}
            className="flex-1 p-4 font-bold text-red-500 hover:bg-red-50"
          >
            {t("dashboard.delete")}
          </button>
          <button
            onClick={handleEdit}
            className="flex-1 p-4 font-bold text-vdark hover:bg-gray-50"
          >
            {t("dashboard.edit")}
          </button>
          {status === "PUBLISHED" && (
            <button
              onClick={handleUnpublish}
              className="flex-1 cursor-pointer p-4 font-bold text-vdark hover:bg-gray-50"
            >
              {t("dashboard.unpublish")}
            </button>
          )}
          {status === "UNPUBLISHED" && isAllTrue === true && (
            <button
              onClick={handlePublish}
              className="flex-1 cursor-pointer p-4 font-bold text-vdark hover:bg-gray-50"
            >
              {t("dashboard.publish")}
            </button>
          )}
          {status === "UNPUBLISHED" && isAllTrue === false && (
            <button className="flex-1 bg-gray-200 p-4 text-center font-semibold text-gray-400">
              {t("dashboard.publish")}
            </button>
          )}
        </div>
      </div>
    </>
  );
}

function Requirement({ title, checked }) {
  return (
    <div className="flex justify-between p-4">
      <p className="mr-8">{title}</p>
      {checked === true && (
        <CheckCircleIcon className="h-6 w-6 text-green-500" />
      )}
      {checked === false && <XCircleIcon className="h-6 w-6 text-red-500" />}
    </div>
  );
}

function checkContactInformation({
  name,
  urls,
  address,
  zipcode,
  city,
  phone_number,
  email,
}) {
  if (
    name?.length > 0 &&
    address?.length > 0 &&
    zipcode !== null &&
    city?.length > 0 &&
    phone_number?.length > 0 &&
    email?.length > 0 &&
    urls?.website?.length > 0 &&
    urls?.google_page?.length > 0
  ) {
    return true;
  } else {
    return false;
  }
}

function checkOpenHours({ open_hours }) {
  let openDays = 0;
  let closedDays = 0;
  for (let day in open_hours) {
    if (open_hours[day].length > 0) {
      openDays = openDays + 1;
    } else {
      closedDays = closedDays + 1;
    }
  }
  if (openDays < MINIMUM_OPEN_DAYS) {
    return false;
  } else {
    return true;
  }
}

function checkRestaurantDetails({
  cuisine,
  dietary,
  services,
  average_meal_price,
}) {
  if (
    cuisine.length > 0 &&
    services.length > 0 &&
    average_meal_price !== null
  ) {
    return true;
  } else {
    return false;
  }
}

function checkPhotos({ photos }) {
  if (!photos) return false;
  if (!photos.cover) return false;
  if (photos.cover.length > 0) {
    return true;
  } else {
    return false;
  }
}

function checkSustainability({ sustainability }) {
  if (sustainability.length > 0) {
    return true;
  } else {
    return false;
  }
}

function checkConsent({ signed_consent }) {
  if (signed_consent === true) {
    return true;
  } else {
    return false;
  }
}
