import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import jwtDecode from "jwt-decode";
import { useParams } from "react-router-dom";
import { ReactComponent as Success } from "./success.svg";
import { ReactComponent as Error } from "./error.svg";
import { ReactComponent as Expired } from "./expired.svg";
import { useActivateEmail, useSendActivation } from "hooks/authenticationHooks";
import { SuccessBlock } from "components/successBlock";
import { ErrorBlock } from "components/ErrorBlock";
import LinkButton from "components/LinkButton";
import { FullPageLoader } from "components/Loading";
import Button from "components/Button";

const LIMIT_SEND = 5; // After this number we don't allow the user to send more

export function ValidateEmail() {
  const { t } = useTranslation();
  const { token } = useParams();
  const { mutate: sendActivation, isLoading: isLoadingSendActivation } =
    useSendActivation();
  const { mutate: activateEmail, isLoading: isLoadingActivateEmail } =
    useActivateEmail();
  const [tokenResult, setTokenResult] = useState("");
  const [email, setEmail] = useState("");
  const [sendNumber, setSendNumber] = React.useState(0);
  const [errorList, setErrorList] = useState([]);
  const [successList, setSuccessList] = useState([]);

  useEffect(() => {
    try {
      const decoded = jwtDecode(token);
      const { email } = decoded;
      setEmail(email);
    } catch (e) {
      setTokenResult("error");
    }
  }, [token]);

  useEffect(() => {
    activateEmail(
      { token },
      {
        onError: (e) => {
          console.log({ e });
          return setTokenResult("error");
        },
        onSuccess: (data) => {
          if (data.ok === false) {
            if (data.errors.token.error_code === "TOKEN-EXPIRED")
              return setTokenResult("expired");
            return setTokenResult("error");
          }
          if (data.ok === true) return setTokenResult("success");
        },
      }
    );
  }, [activateEmail, token]);

  async function send(email) {
    const errors = [];
    setErrorList([]);
    setSuccessList([]);
    setSendNumber((prev) => prev + 1);
    try {
      sendActivation(
        { email },
        {
          onSuccess: (data) => {
            if (data.ok === false) {
              return setErrorList(["sendActivationError"]);
            }
            if (data.ok === true)
              return setSuccessList(["sendActivationSuccess"]);
          },
        }
      );
    } catch (e) {
      errors.push("sendActivationError");
      setErrorList(errors);
    }
  }

  if (isLoadingActivateEmail) return <FullPageLoader />;
  return (
    <div className="max-w-2xl min-h-full gap-2 mx-auto sm:mt-10 sm:gap-4">
      <h1 className="m-4 text-4xl font-extrabold text-center sm:m-8 sm:text-6xl">
        {tokenResult === "success" && t("validateEmail.titleSuccess")}
        {tokenResult === "error" && t("validateEmail.titleFail")}
        {tokenResult === "expired" && t("validateEmail.titleExpired")}
      </h1>
      {tokenResult === "success" && (
        <Success className="max-w-xs p-4 mx-auto" />
      )}
      {tokenResult === "error" && <Error className="max-w-xs p-4 mx-auto" />}
      {tokenResult === "expired" && (
        <Expired className="max-w-xs p-4 mx-auto" />
      )}
      <p className="p-4 mx-auto sm:text-lg">
        {tokenResult === "success" && t("validateEmail.textSuccess")}
        {tokenResult === "error" && t("validateEmail.textFail")}
        {tokenResult === "expired" && t("validateEmail.textExpired")}
      </p>
      <div className="flex justify-center mt-2 sm:mt-4">
        {tokenResult === "success" && (
          <LinkButton
            className="p-4 text-xl max-w-max sm:p-6 sm:text-2xl md:text-3xl "
            to="/login"
          >
            {t("validateEmail.linkSuccess")}
          </LinkButton>
        )}
        {tokenResult === "expired" && (
          <Button
            isLoading={isLoadingSendActivation}
            className="m-2 text-xl max-w-max sm:p-6 sm:text-2xl md:text-3xl "
            onClick={() => {
              if (sendNumber < LIMIT_SEND) send(email);
              if (sendNumber >= LIMIT_SEND) {
                setSuccessList([]);
                setErrorList(["noMoreActivationSendError"]);
              }
            }}
          >
            {t("activate.send")}
          </Button>
        )}
      </div>
      {errorList.length > 0 && (
        <ErrorBlock className="mt-3" errorList={errorList} />
      )}
      {successList.length > 0 && (
        <SuccessBlock className="mt-3" successList={successList} />
      )}
    </div>
  );
}
