import {
  startOfMonth,
  startOfWeek,
  endOfMonth,
  endOfWeek,
  startOfDay,
  addDays,
  format,
  getMonth,
  isSameMonth,
  getYear,
  isToday,
} from "date-fns";

export function yearIsValid(year) {
  if (parseInt(year) > 1900 && parseInt(year) < 2100) return true;
  else return false;
}

export function generateWeek(start = new Date()) {
  let date = startOfWeek(startOfDay(start), { weekStartsOn: 1 });

  return function () {
    const week = [...Array(7)].map((_, i) => addDays(date, i));
    date = addDays(week[6], 1);
    return week;
  };
}

export function generateMonth(start = new Date()) {
  let month = [];
  let date = start;

  function lastDayOfRange(range) {
    return range[range.length - 1][6];
  }

  return function () {
    const weekGen = generateWeek(startOfMonth(date));
    const endDate = startOfDay(
      endOfWeek(endOfMonth(date), { weekStartsOn: 1 })
    );
    month.push(weekGen());

    while (lastDayOfRange(month) < endDate) {
      month.push(weekGen());
    }

    const range = month;
    month = [];
    date = addDays(lastDayOfRange(range), 1);

    return range;
  };
}

export function getMonthCalendar(start = new Date()) {
  const month = generateMonth(start)(start);
  let result = {
    name: getMonth(start),
    year: getYear(start),
    days: [],
  };

  for (const week of month) {
    for (const day of week) {
      result.days.push({
        date: format(day, "yyyy-MM-dd"),
        isCurrentMonth: isSameMonth(day, start),
        isToday: isToday(day),
      });
    }
  }
  return result;
}
