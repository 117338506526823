import React from "react";
import { CheckIcon } from "@heroicons/react/solid";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export function Step({ className, step, totalSteps }) {
  return (
    <nav aria-label="Progress" className={className}>
      <ol className="flex items-center justify-center">
        {[...Array(totalSteps)].map((_, stepIdx) => (
          <li
            key={stepIdx}
            className={classNames(
              stepIdx !== totalSteps - 1 ? " pr-4  sm:pr-14 " : "",
              "relative"
            )}
          >
            {step > stepIdx ? (
              <>
                <div
                  className="absolute inset-0 flex items-center"
                  aria-hidden="true"
                >
                  <div className="h-0.5 w-full bg-green-600" />
                </div>
                <div className="relative flex items-center justify-center w-8 h-8 bg-green-600 rounded-full ">
                  <CheckIcon
                    className="w-5 h-5 text-white"
                    aria-hidden="true"
                  />
                  {/* <span className="sr-only">{step.name}</span> */}
                </div>
              </>
            ) : step === stepIdx ? (
              <>
                <div
                  className="absolute inset-0 flex items-center"
                  aria-hidden="true"
                >
                  <div className="h-0.5 w-full bg-gray-200" />
                </div>
                <div
                  className="relative flex items-center justify-center w-8 h-8 bg-white border-2 border-green-600 rounded-full"
                  aria-current="step"
                >
                  <span
                    className="h-2.5 w-2.5 rounded-full bg-green-600"
                    aria-hidden="true"
                  />
                </div>
              </>
            ) : (
              <>
                <div
                  className="absolute inset-0 flex items-center"
                  aria-hidden="true"
                >
                  <div className="h-0.5 w-full bg-gray-200" />
                </div>
                <div className="relative flex items-center justify-center w-8 h-8 bg-white border-2 border-gray-300 rounded-full group hover:border-gray-400">
                  <span
                    className="h-2.5 w-2.5 rounded-full bg-transparent group-hover:bg-gray-300"
                    aria-hidden="true"
                  />
                </div>
              </>
            )}
          </li>
        ))}
      </ol>
    </nav>
  );
}
