import React, { useState } from "react";
import { v4 as uuid } from "uuid";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useCreateRestaurant } from "hooks/restaurantHooks";
import { MultipleChoices } from "components/FormElements";
import { LinkText } from "components/LinkText";
import { StepSubmitButtons } from "./StepSubmitButtons";
import { getPhotosUrls } from "utils/photos";

const consentList = ["CONSENT1", "CONSENT2", "CONSENT3"];

export function Consent({ setStep, restaurantInformation }) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { mutate: createRestaurant, isLoading: isLoadingCreateRestaurant } =
    useCreateRestaurant();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState([]);
  const [consent, setConsent] = useState([]);
  const [isDisabled, setIsDisabled] = useState(true);

  React.useEffect(() => {
    if (consent.length === 3) {
      return setIsDisabled(false);
    } else {
      return setIsDisabled(true);
    }
  }, [consent]);

  async function handleNext() {
    setError([]);
    if (consent.length === 3) {
      try {
        setIsLoading(true);
        restaurantInformation.photos = await getPhotosUrls(
          restaurantInformation
        );
      } catch (e) {
        console.log(e);
        setIsLoading(false);
        return setError(["unknownError"]);
      }
      const input = {
        ...restaurantInformation,
        id: uuid(),
        signed_consent: true,
        status: "PUBLISHED",
      };
      createRestaurant(input, {
        onError: (error) => {
          setError([error.message]);
        },
        onSuccess: (data) => {
          if (!data) return setError(["unknownError"]);
          if (data.data.result.ok === false) return setError(["deniedError"]);
          if (data.data.result.ok === true)
            return navigate("/dashboard/success/restaurantCreated");
        },
      });
      setIsLoading(false);
    }
  }

  return (
    <div className="max-w-2xl px-4 mx-auto">
      <h2 className="m-4 text-3xl text-center sm:m-4 sm:text-5xl">
        {t("consent.title")}
      </h2>
      <p className="mt-1 mb-6 text-sm text-gray-500 sm:text-base">
        {t("consent.intro")}
      </p>
      <div className="flex flex-col items-center gap-2 sm:gap-4">
        <MultipleChoices
          intro=""
          optionsList={consentList}
          setSelection={setConsent}
          selection={consent}
        />
        <div className="flex flex-col items-center">
          <LinkText to={t("links.webTermsConditions")} target="_blank">
            {t("consent.conditions")}
          </LinkText>
          <LinkText to={t("links.webTermsUse")} target="_blank">
            {t("consent.use")}
          </LinkText>
          <LinkText to={t("links.privacy")} target="_blank">
            {t("consent.privacy")}
          </LinkText>
        </div>
      </div>
      <StepSubmitButtons
        isLoading={isLoadingCreateRestaurant || isLoading}
        back={t("general.back")}
        next={t("consent.accept")}
        onNext={handleNext}
        error={error}
        isDisabled={isDisabled}
        onBack={() => setStep((prev) => prev - 1)}
      />
    </div>
  );
}
