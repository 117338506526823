import { useMutation } from "react-query";
import { useAuth } from "context/AuthProvider";

export function useSendActivation() {
  const { sendActivation } = useAuth();
  const { mutate, data, isLoading, isError, error } = useMutation((input) =>
    sendActivation(input)
  );

  return {
    mutate,
    data,
    isLoading,
    isError,
    error,
  };
}

export function useActivateEmail() {
  const { activateEmail } = useAuth();
  const { mutate, data, isLoading, isError, error } = useMutation((input) =>
    activateEmail(input)
  );

  return {
    mutate,
    data,
    isLoading,
    isError,
    error,
  };
}

export function useCreateRestaurantAccount() {
  const { register } = useAuth();
  const { mutate, isLoading, isError, error, isSuccess } = useMutation(
    (input) => {
      return register(input);
    }
  );

  return { mutate, isLoading, isError, error, isSuccess };
}

export function useForgotPassword() {
  const { forgotPassword } = useAuth();
  const { mutate, isLoading, isError, error, isSuccess } = useMutation(
    (input) => {
      return forgotPassword(input);
    }
  );
  return { mutate, isLoading, isError, error, isSuccess };
}

export function useResetPassword() {
  const { resetPassword } = useAuth();
  const { mutate, isLoading, isError, error, isSuccess } = useMutation(
    (input) => {
      return resetPassword(input);
    }
  );
  return { mutate, isLoading, isError, error, isSuccess };
}
