export const restaurantKeywords = {
  cuisine: [
    "AMERICAN",
    "ASIAN",
    "BAR",
    "BEER_PUB",
    "BRITISH",
    "CAFE",
    "CATALAN",
    "CHINESE",
    "COFFEE_SHOP",
    "DELI",
    "EUROPEAN",
    "FAST_FOOD",
    "FUSION",
    "HAWAIIAN",
    "HEALTHY",
    "INDIAN",
    "INTERNATIONAL",
    "ISRAELI",
    "ITALIAN",
    "JAPANESE",
    "LATIN",
    "MEDITERRANEAN",
    "MEXICAN",
    "MIDDLE_EASTERN",
    "MODERN",
    "PIZZA",
    "SINGAPOREAN",
    "SOUPS",
    "SPANISH",
    "STEAKHOUSE",
    "STREET_FOOD",
    "SUSHI",
    "TAPAS",
    "THAI",
    "VENEZUELAN",
    "WINE_BAR",
    "WOK",
  ],
  dietary: ["GLUTEN_FREE", "HALAL", "KOSHER"],
  services: [
    "AIR_CON",
    "BOTTOMLESS",
    "BRUNCH",
    "BUFFET",
    "CASUAL_DINING",
    "COCKTAILS",
    "DAILY_SPECIAL",
    "DOGS_ALLOWED",
    "FINE_DINING",
    "FREE_WIFI",
    "HANDICAP_ACCESSIBLE",
    "KIDS_MENU",
    "LIVE_MUSIC",
    "NO_RESERVATION_REQUIRED",
    "OUTDOOR_DINING",
    "OWN_DELIVERY",
    "PARTY_VIBE",
    "QUIET",
    "RESERVATION_REQUIRED",
    "ROMANTIC",
    "SCENIC_VIEW",
    "SHOW",
    "STAY",
  ],
  sustainability: [
    "COVICONS",
    "ECOCERTI",
    "ENEGEFFI",
    "PLANFRIE",
    "PLANFULL",
    "PLASCONS",
    "RENWENEG",
    "SUSTMEAT",
    "SUSTPROD",
    "SUSTSEAF",
    "VEGEFRIE",
    "VEGEFULL",
    "WASTCONS",
    "WATREFFI",
  ],
  //-- TODO by Backend
  reservation_channel: ["PHONE", "WHATSAPP", "ONLINE"],
};
