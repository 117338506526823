import React from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import hero from "./hero.png";

export function CheckEmail() {
  const { t } = useTranslation();
  const params = useParams();

  return (
    <div className="max-w-2xl mx-auto sm:mt-10">
      <h1 className="m-8 text-4xl font-extrabold text-center sm:text-6xl">
        {t(`mail.${params.action}Title`)}
      </h1>
      <img src={hero} alt="" className="w-2/3 max-w-xs mx-auto" />
      <p className="p-10 mx-auto mb-20 text-center sm:text-lg">
        {t(`mail.${params.action}Text`)}
      </p>
    </div>
  );
}
