import React from "react";
import { useGetIssueById } from "hooks/issuesHooks";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { useUpdateIssue } from "hooks/issuesHooks";
import { FullPageLoader } from "components/Loading";
import { ErrorPage } from "components/ErrorPage";
import Button from "components/Button";
import ModalSolveIssue from "components/ModalSolveIssue";
import { ErrorBlock } from "components/ErrorBlock";
import { PhotoModal } from "components/ModalPhoto";
import { LinkText } from "components/LinkText";
import { ArrowLeftIcon } from "@heroicons/react/outline";

export function IssueDetail() {
  const { t } = useTranslation();
  const { mutate: updateIssue, isLoading: isLoadingReopen } = useUpdateIssue();
  const { id } = useParams();
  const [error, setError] = React.useState([]);
  const [open, setOpen] = React.useState(false);
  const [photo, setPhoto] = React.useState();
  const [openDialog, setOpenDialog] = React.useState(false);
  const {
    data: response,
    isLoading,
    isError,
  } = useGetIssueById({ issue_id: id });

  function handleReopen() {
    updateIssue(
      {
        issue_id: id,
        completed_date: null,
        restaurant_comment: null,
      },
      {
        onError: (error) => {
          console.log({ error });
          setError(["unknownError"]);
        },
        onSuccess: (data) => {
          if (data.data.result.ok === false) {
            return setError(["deniedError"]);
          }
          if (data.data.result.ok === true) {
            window.location.reload(); // TODO: Make sure that the cache is refreshed
            return;
          }
        },
      }
    );
  }

  if (isLoading) return <FullPageLoader />;
  if (isError) return <ErrorPage />;

  const {
    user_id,
    restaurant,
    description,
    keyword,
    date,
    completed_date,
    photos,
    restaurant_comment,
  } = { ...response.data.result.payload };

  return (
    <div className="mx-auto mt-8 min-h-full max-w-4xl px-4">
      <ModalSolveIssue open={openDialog} setOpen={setOpenDialog} issueId={id} />
      <PhotoModal open={open} setOpen={setOpen} photo={photo} />
      <div className="mx-auto my-2 flex max-w-4xl items-center text-vaccent">
        <LinkText className="flex items-center" to="/dashboard">
          <ArrowLeftIcon className="mr-2 h-4 w-4 " />
          {t("general.backDashboard")}
        </LinkText>
      </div>
      <div>
        <h1 className="mx-4 mb-4 text-center text-4xl font-extrabold sm:mx-8 sm:mb-8 sm:text-6xl">
          {t("issue.title").toUpperCase()}
        </h1>
        <p className="text-2xl font-medium leading-6">{t("issue.subTitle")}</p>
      </div>
      <div className="mt-5 border-t border-gray-200">
        <dl className="divide-y divide-gray-200">
          <Field title={t("issue.titleId")} value={id} />
          <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5">
            <dt className="text-sm font-medium text-gray-500">
              {t("issue.status")}
            </dt>
            <dd className="mt-1 flex text-sm text-gray-900 sm:col-span-2 sm:mt-0">
              {!completed_date ? (
                <span className="inline-flex rounded-full bg-orange-100 px-3 py-1 text-sm font-semibold leading-5 text-orange-800">
                  {t("issues.pending")}
                </span>
              ) : (
                <span className="inline-flex rounded-full bg-green-100 px-3 py-1 text-sm font-semibold leading-5 text-green-800">
                  {t("issues.solved")}
                </span>
              )}
            </dd>
          </div>
          {restaurant_comment && (
            <Field title={t("issue.titleComment")} value={restaurant_comment} />
          )}
          <Field title={t("issue.titleDate")} value={date} />
          <Field title={t("issue.titleRestaurant")} value={restaurant.name} />
          <Field
            title={t("issue.titleKeyword")}
            value={t(`keywords.${keyword}`)}
          />
          <Field title={t("issue.titleDescription")} value={description} />
          <Field title={t("issue.titleUser")} value={user_id} />
          <PhotoContainer
            photosList={photos}
            setOpen={setOpen}
            setPhoto={setPhoto}
          />
          <div className="flex w-full justify-end py-4 sm:py-5">
            {!completed_date && (
              <Button onClick={() => setOpenDialog(true)}>
                {t("issue.markSolved")}
              </Button>
            )}
            {completed_date && (
              <Button
                isLoading={isLoadingReopen}
                onClick={handleReopen}
                variant="warning"
              >
                {t("issue.reopen")}
              </Button>
            )}
          </div>
        </dl>
        <div className="flex w-full justify-end">
          {error.length > 0 && (
            <ErrorBlock className="max-w-max" errorList={error} />
          )}
        </div>
      </div>
    </div>
  );
}

function Field({ title, value }) {
  return (
    <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5">
      <dt className="text-sm font-medium text-gray-500">{title}</dt>
      <dd className="mt-1 flex text-sm text-gray-900 sm:col-span-2 sm:mt-0">
        <span className="flex-grow">{value}</span>
      </dd>
    </div>
  );
}

function PhotoContainer({ photosList, setOpen, setPhoto }) {
  const { t } = useTranslation();
  function handleClick(photo) {
    setPhoto(photo);
    setOpen(true);
  }
  return (
    <div className="py-4 sm:py-5">
      <dt className="text-sm font-medium text-gray-500">
        {t("issue.titlePhotos")}
      </dt>
      <ul className="mt-2 flex flex-wrap gap-8">
        {photosList.map((photo, index) => (
          <li key={index} className="relative">
            <div
              className="block overflow-hidden rounded-lg bg-gray-100 "
              onClick={() => handleClick(photo)}
            >
              <img
                src={photo}
                alt=""
                className="h-48 w-48 cursor-pointer object-cover hover:opacity-75"
              />
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
}
