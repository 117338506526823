import React from "react";
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/solid";
import { useTranslation } from "react-i18next";
import { addMonths, getDay, parseISO } from "date-fns";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

function checkIsRegularClosed(date, regularClosedDays) {
  return regularClosedDays.includes(getDay(parseISO(date)));
}

function checkIsExtraClosed(date, extraClosedDays) {
  return extraClosedDays.includes(date);
}

export default function Calendar({
  month,
  setReferenceDay,
  regularClosedDays,
  extraClosedDays,
  setExtraClosedDays,
}) {
  const { t } = useTranslation();

  function handlePrevious() {
    setReferenceDay((prev) => addMonths(prev, -1));
  }

  function handleNext() {
    setReferenceDay((prev) => addMonths(prev, 1));
  }

  function handleClick(date) {
    if (checkIsExtraClosed(date, extraClosedDays) === true) {
      setExtraClosedDays((prev) => {
        return prev.filter((day) => date !== day);
      });
    } else {
      if (checkIsRegularClosed(date, regularClosedDays) === false) {
        // We could insert the days in order, but don't see how this would be more useful now, and adds complexity
        setExtraClosedDays([...extraClosedDays, date]);
      }
    }
  }

  return (
    <div className="w-full">
      <div className="grid max-w-2xl grid-cols-1 px-4 py-4 mx-auto gap-x-8 gap-y-16 ">
        <section key={month.name} className="text-center">
          <div className="flex items-center text-center text-gray-900">
            <button
              type="button"
              onClick={handlePrevious}
              className="-m-1.5 flex flex-none items-center justify-center p-1.5 text-gray-400 hover:text-gray-500"
            >
              <span className="sr-only">Previous month</span>
              <ChevronLeftIcon className="w-5 h-5" aria-hidden="true" />
            </button>
            <div className="flex-auto font-semibold">
              {t(`calendar.${month.name}`)}{" "}
              <span className="ml-1">{month.year}</span>
            </div>
            <button
              type="button"
              onClick={handleNext}
              className="-m-1.5 flex flex-none items-center justify-center p-1.5 text-gray-400 hover:text-gray-500"
            >
              <span className="sr-only">Next month</span>
              <ChevronRightIcon className="w-5 h-5" aria-hidden="true" />
            </button>
          </div>
          <div className="grid grid-cols-7 mt-6 text-xs leading-6 text-gray-500">
            <div>{t("calendar.monday")}</div>
            <div>{t("calendar.tuesday")}</div>
            <div>{t("calendar.wednesday")}</div>
            <div>{t("calendar.thursday")}</div>
            <div>{t("calendar.friday")}</div>
            <div>{t("calendar.saturday")}</div>
            <div>{t("calendar.sunday")}</div>
          </div>
          <div className="grid grid-cols-7 gap-px mt-2 text-sm bg-gray-200 rounded-lg shadow isolate ring-1 ring-gray-200">
            {month.days.map((day, dayIdx) => {
              const regularClosed = checkIsRegularClosed(
                day.date,
                regularClosedDays
              );

              const extraClosed = checkIsExtraClosed(day.date, extraClosedDays);
              return (
                <button
                  key={day.date}
                  type="button"
                  onClick={() => handleClick(day.date)}
                  className={classNames(
                    day.isCurrentMonth &&
                      !regularClosed &&
                      !extraClosed &&
                      "bg-white text-gray-900 hover:bg-gray-100",
                    day.isCurrentMonth &&
                      !regularClosed &&
                      !extraClosed &&
                      "bg-gray-50 text-gray-400 hover:bg-gray-100",
                    regularClosed &&
                      "pointer-events-none bg-gray-600 text-gray-200",
                    extraClosed && "bg-red-400 text-white hover:bg-red-500",
                    dayIdx === 0 && "rounded-tl-lg ",
                    dayIdx === 6 && "rounded-tr-lg",
                    dayIdx === month.days.length - 7 && "rounded-bl-lg",
                    dayIdx === month.days.length - 1 && "rounded-br-lg",
                    "py-1.5 focus:z-10"
                  )}
                >
                  <time
                    dateTime={day.date}
                    className={classNames(
                      day.isToday && "bg-green-600 font-semibold text-white",
                      "mx-auto flex h-7 w-7 items-center justify-center rounded-full"
                    )}
                  >
                    {day.date.split("-").pop().replace(/^0/, "")}
                  </time>
                </button>
              );
            })}
          </div>
          <Legend />
        </section>
      </div>
    </div>
  );
}

function Legend() {
  const { t } = useTranslation();
  return (
    <div className="flex flex-wrap gap-4 mt-8">
      <div className="flex items-center gap-2">
        <div className="w-8 h-8 text-gray-200 bg-red-400 rounded-md"></div>
        <p className="text-sm sm:text-base">{t("calendar.extra")}</p>
      </div>
      <div className="flex items-center gap-2">
        <div className="w-8 h-8 text-gray-200 bg-gray-600 rounded-md"></div>
        <p className="text-sm sm:text-base">{t("calendar.regular")}</p>
      </div>
      <div className="flex items-center gap-2">
        <div className="w-8 h-8 text-gray-200 bg-green-600 rounded-md"></div>
        <p className="text-sm sm:text-base">{t("calendar.today")}</p>
      </div>
    </div>
  );
}
