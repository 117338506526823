import React from "react";
import { useTranslation } from "react-i18next";
import heroImg from "./img/hero.jpg";
import locationImg from "./img/location.jpg";
import sustainabilityImg from "./img/sustainability.jpg";
import totalityImg from "./img/totality.jpg";
import truthImg from "./img/truth.jpg";
import symbolImg from "./img/symbol.jpg";
import { ReactComponent as LinkedInIcon } from "assets/images/icons/linkedInIcon.svg";

export const About = () => {
  const { t, i18n } = useTranslation();
  const cvs = require(`./cvs_${i18n.language.split("-")[0]}.json`);

  return (
    <main>
      <HeroImage />
      <div className="mx-auto flex max-w-7xl flex-col gap-8 px-4 sm:gap-12 sm:px-8 md:gap-16">
        <div className="mx-auto flex max-w-4xl flex-col gap-2 sm:gap-4">
          <h2 className="text-4xl sm:text-6xl">{t("about.ourVisionTitle")}</h2>
          <h2>{t("about.ourVisionSubtitle")}</h2>
          <p>{t("about.ourVisionText")}</p>
        </div>
        <div className="mx-auto flex max-w-4xl flex-col gap-2 sm:gap-4">
          <h2>{t("about.ourValuesTitle")}</h2>
          <p>{t("about.ourValuesText")}</p>
          <div className="mt-2 flex flex-wrap justify-center gap-4 sm:mt-4">
            <Box
              title={t("about.empowering")}
              text={t("about.empoweringText")}
            />
            <Box
              title={t("about.compassionate")}
              text={t("about.compassionateText")}
            />
            <Box
              title={t("about.courageous")}
              text={t("about.corageousText")}
            />
            <Box title={t("about.resilient")} text={t("about.resilientText")} />
            <Box title={t("about.truthful")} text={t("about.truthfulText")} />
          </div>
        </div>
        <div className="flex flex-col items-center gap-2 sm:gap-4">
          <h2>{t("about.ourLogo")}</h2>
          <p>{t("about.ourLogoText")}</p>
          <div className="flex flex-col items-center gap-4 sm:mt-4 sm:flex-row sm:gap-4 md:gap-16">
            <div className="flex flex-col gap-4">
              <LogoText logo={locationImg} text={t("about.location")} />
              <LogoText logo={truthImg} text={t("about.symbolOfTruth")} />
            </div>
            <div className="w-56 sm:max-w-xs">
              <img src={symbolImg} className="w-full object-contain" alt="" />
            </div>
            <div className="flex flex-col gap-4">
              <LogoText
                logo={sustainabilityImg}
                text={t("about.sustainability")}
              />
              <LogoText logo={totalityImg} text={t("about.wholeness")} />
            </div>
          </div>
        </div>
        <div className="mx-auto flex max-w-4xl flex-col items-center gap-2 sm:gap-4">
          <h2>{t("about.ourStory")}</h2>
          <div className="flex flex-col gap-2 sm:gap-4">
            <p>{t("about.ourStoryText1")}</p>
            <p>{t("about.ourStoryText2")}</p>
            <p>{t("about.ourStoryText3")}</p>
            <p>{t("about.ourStoryText4")}</p>
          </div>
        </div>
        <div className="flex flex-col items-center gap-2 sm:gap-4">
          <h2>{t("about.ourTeam")}</h2>
          <p>{t("about.ourTeamText")}</p>
          <div className="mt-4 flex flex-wrap items-start justify-center">
            {cvs.map((item) => {
              return (
                <ProfileCard
                  key={item.name}
                  name={item.name}
                  image={item.image}
                  linkedIn={item.linkedIn}
                  position={item.position}
                  location={item.location}
                  description={item.description}
                />
              );
            })}
          </div>
        </div>
      </div>
    </main>
  );
};

function HeroImage() {
  const { t } = useTranslation();
  return (
    <div className="relative isolate mb-8 h-64 sm:mb-12 sm:h-[500px] md:mb-16">
      <img src={heroImg} alt="" className="absolute h-full object-cover" />
      <div className="absolute h-full w-full bg-vdarkgreen opacity-60 " />
      <div className="absolute flex h-full w-full flex-col items-center justify-center gap-2 p-2 sm:gap-6 lg:gap-8">
        <p
          style={{ fontFamily: "DIN" }}
          className="max-w-sm text-center text-3xl text-white drop-shadow sm:max-w-xl sm:text-6xl md:text-7xl lg:max-w-5xl lg:text-8xl"
        >
          {t("about.title")}
        </p>
      </div>
    </div>
  );
}

function Box({ title, text }) {
  return (
    <div className="flex basis-96 flex-col items-center gap-4 bg-vgreen p-4 sm:p-8">
      <h4
        style={{ fontFamily: "DIN" }}
        className="text-2xl text-vdark sm:text-3xl"
      >
        {title}
      </h4>
      <p>{text}</p>
    </div>
  );
}

function LogoText({ logo, text }) {
  return (
    <div className="mt-2 flex flex-col items-center gap-4 sm:mt-4">
      <div className="h-16">
        <img src={logo} className="h-full object-contain" alt="" />
      </div>
      <p>{text}</p>
    </div>
  );
}

function ProfileCard({
  name,
  image,
  linkedIn,
  position,
  location,
  description,
}) {
  return (
    <div className="m-2 my-4 flex max-w-xs flex-col items-center gap-2 sm:max-w-sm ">
      <div className="w-56 ">
        <img
          src={process.env.PUBLIC_URL + `/img/team/${image}`}
          className="w-full rounded-md object-contain"
          alt=""
        />
      </div>
      <a
        href={linkedIn}
        className="w-fit rounded-full bg-vgreen p-4"
        target="_blank"
        rel="noopener noreferrer"
      >
        <LinkedInIcon className="w-6" />
      </a>
      <div className="flex flex-col items-center gap-0">
        <p style={{ fontFamily: "DIN" }} className="text-4xl text-vdarkgreen">
          {name}
        </p>
        <p
          style={{ fontFamily: "Poppins" }}
          className="text-sm italic text-vdark"
        >
          {position}
        </p>
        <p style={{ fontFamily: "Poppins" }} className="text-sm text-vdark">
          {location}
        </p>
      </div>
      <p style={{ fontFamily: "Poppins" }} className="p-1 text-sm text-vdark">
        {description}
      </p>
    </div>
  );
}
