import { FakeResponseCollection } from "utils/fakeResponseCollection";

export const fake_update_issue_status = new FakeResponseCollection();

fake_update_issue_status.set({
  input: {
    issue_id: "issue-1",
    completed_date: "2022-03-02",
    restaurant_comment: "Comment OK",
  },
  response: {
    data: {
      result: {
        ok: true,
      },
    },
  },
});

fake_update_issue_status.set({
  input: {
    issue_id: "issue-1",
    completed_date: "2022-03-02",
    restaurant_comment: "Comment FAIL",
  },
  response: {
    data: {
      result: {
        ok: false,
      },
    },
  },
});

// Reopen issue
fake_update_issue_status.set({
  input: {
    issue_id: "issue-3",
    completed_date: null,
    restaurant_comment: null,
  },
  response: {
    data: {
      result: {
        ok: true,
      },
    },
  },
});
