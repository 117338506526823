import api from "API";

import { useQuery, useMutation, useQueryClient } from "react-query";

export function useGetUserIssues() {
  const {
    data: response,
    isLoading,
    error,
    isError,
    isSuccess,
  } = useQuery("userIssues", () => {
    const testInput = { test: "RESPONSE" }; // Only for testing, in production it should be ignored because the function doesn't need arguments
    return api.issues.mine(testInput);
  });

  let filteredData = {};
  let pendingList = [];
  let solvedList = [];

  if (isSuccess === true) {
    if (!response) return { isError: true, error: "unknownError" };
    if (response.data.result.ok === false) {
      return { isError: true, error: "deniedError" };
    }
    if (response.data.result.ok === true) {
      for (let item of response.data.result.payload) {
        if (item.completed_date === null) pendingList.push(item);
        if (item.completed_date !== null) solvedList.push(item);
      }
      filteredData["pending"] = pendingList;
      filteredData["solved"] = solvedList;
    }
  }

  return {
    data: isSuccess === false ? [] : filteredData,
    isLoading,
    isError,
    error,
    isSuccess,
  };
}

export function useGetIssueById(input) {
  const { issue_id } = input;
  const { data, isLoading, isError, error, isSuccess } = useQuery(
    [`issue-${issue_id}`, issue_id],
    () => api.issues.byId({ issue_id })
  );

  if (isSuccess === true) {
    if (!data) {
      return {
        isError: true,
        error: new Error("unknownError"),
      };
    }
    if (data.data.result.ok === false) {
      return {
        isError: true,
        error: new Error("deniedError"),
      };
    }
  }

  return { data, isLoading, isError, error, isSuccess };
}

export function useUpdateIssue() {
  const queryClient = useQueryClient();
  const { mutate, isLoading, isError, error, isSuccess } = useMutation(
    (input) => {
      return api.issues.update(input);
    },
    {
      onSuccess: (data, variables, context) => {
        queryClient.invalidateQueries(`issue-${variables.issue_id}`);
      },
    }
  );
  return { mutate, isLoading, isError, error, isSuccess };
}
