import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import Button from "components/Button";
import { ErrorBlock } from "components/ErrorBlock";
import { TextInput } from "components/FormElements";
import { useResetPassword } from "hooks/authenticationHooks";

export function ResetPassword() {
  const { t } = useTranslation();
  const { mutate: resetPassword, isLoading } = useResetPassword();
  const { token } = useParams();
  const navigate = useNavigate();
  const [password, setPassword] = React.useState("");
  const [confirmPassword, setConfirmPassword] = React.useState("");
  const [errorList, setErrorList] = React.useState([]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    setErrorList([]);
    const errors = [];

    if (password !== confirmPassword) errors.push("repeatPasswordError");

    if (password.length < 8 || confirmPassword.length < 8)
      errors.push("shortPasswordError");

    if (errors.length > 0) {
      return setErrorList(errors);
    }

    resetPassword(
      { token, password },
      {
        onError: (error) => {
          return setErrorList([error.message]);
        },
        onSuccess: (data) => {
          if (data.ok === true) {
            return navigate("/success/passwordUpdated");
          }
          if (data.ok === false) {
            return setErrorList(["resetPasswordError"]);
          }
        },
      }
    );
  };

  return (
    <div className="mx-auto min-h-full max-w-3xl">
      <h2 className="mt-8 mb-4 text-center text-4xl font-extrabold sm:text-6xl">
        {t("resetPassword.title")}
      </h2>
      <p className="p-4 text-sm text-gray-500 sm:text-base">
        {t("resetPassword.text")}
      </p>
      <form
        className="space-y-8 divide-y divide-gray-200 px-3"
        onSubmit={handleSubmit}
      >
        <div className="space-y-6 pt-2 sm:space-y-5 sm:pt-4 ">
          <TextInput
            attributeName="password"
            label={t("resetPassword.passwordLabel")}
            type="password"
            value={password}
            setValue={setPassword}
            required={true}
            icon="lock"
            borderTop={false}
          />
          <TextInput
            attributeName="confirmPassword"
            label={t("resetPassword.confirmLabel")}
            type="password"
            value={confirmPassword}
            setValue={setConfirmPassword}
            required={true}
            icon="lock"
          />
        </div>
        <div className="flex justify-end">
          <Button
            type="submit"
            value="submit"
            isLoading={isLoading}
            className="mt-4"
          >
            {t("resetPassword.button")}
          </Button>
        </div>
      </form>
      {errorList.length > 0 && (
        <ErrorBlock
          message={t("resetPassword.errorTitle")}
          errorList={errorList}
          className="mt-4"
        />
      )}
    </div>
  );
}
