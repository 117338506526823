import React from "react";
import { useTranslation } from "react-i18next";
import { RoutesContainer } from "./routes";
import { Header } from "./components/Header/Header";
import { Footer } from "components/Footer";
import { useAuth } from "context/AuthProvider";
import CookieConsent from "react-cookie-consent";
import "./assets/styles/tailwind.css";
import "./assets/styles/index.css";

function App() {
  const { checkUser } = useAuth();
  const { t } = useTranslation();

  React.useEffect(() => {
    checkUser();
  }, [checkUser]);

  return (
    <div className="App">
      <Header />
      <RoutesContainer />
      <Footer />
      <CookieConsent buttonText={t("general.cookieConsent")}>
        {t("general.cookieMessage")}
      </CookieConsent>
    </div>
  );
}

export default App;
