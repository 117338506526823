import { FakeResponseCollection } from "utils/fakeResponseCollection";

export const fake_add_user_to_waitlist = new FakeResponseCollection();

fake_add_user_to_waitlist.set({
  input: {
    name: "user",
    email: "kk@kk.com",
    city: "city",
  },
  response: {
    ok: true,
  },
});

fake_add_user_to_waitlist.set({
  input: {
    name: "user",
    email: "kk@kk.com",
    city: "fail",
  },
  response: {
    ok: false,
  },
});
