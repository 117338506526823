import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { countriesList } from "assets/countriesList";
import Button from "components/Button";
import { LinkText } from "components/LinkText";
import { ErrorBlock } from "components/ErrorBlock";
import { TextInput } from "components/FormElements";
import { useCreateRestaurantAccount } from "hooks/authenticationHooks";
import isEmail from "validator/lib/isEmail";

export const CreateRestaurantAccount = () => {
  const { t, i18n } = useTranslation();
  const { mutate: register, isLoading } = useCreateRestaurantAccount();
  const navigate = useNavigate();
  const [name, setName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [country, setCountry] = useState(
    i18n.language === "en" ? "United States of America" : "Spain"
  );
  const [phone, setPhone] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [errorList, setErrorList] = useState([]);
  const [isDisabled, setIsDisabled] = useState(true);

  useEffect(() => {
    if (
      name.length > 0 &&
      lastName.length > 0 &&
      email.length > 0 &&
      phone.length > 0 &&
      password.length > 0 &&
      confirmPassword.length > 0
    )
      return setIsDisabled(false);
    return setIsDisabled(true);
  }, [name, lastName, email, phone, password, confirmPassword]);

  const handleSubmit = (e) => {
    e.preventDefault();

    setErrorList([]);
    const errors = [];

    if (password !== confirmPassword) {
      errors.push("repeatPasswordError");
    }

    if (password.length < 8 || confirmPassword.length < 8) {
      errors.push("shortPasswordError");
    }

    if (isEmail(email) === false) {
      errors.push("invalidEmailError");
    }

    if (errors.length > 0) {
      return setErrorList(errors);
    }

    register(
      {
        email,
        password,
        name,
        last_name: lastName,
        country,
        phone: phone.replace(/ /g, ""),
      },
      {
        onSuccess: (data) => {
          if (data.ok === false) {
            errors.push("registerError");
            return setErrorList(errors);
          }
          if (data.ok === true) {
            navigate("/check-email/accountCreated");
          }
        },
        onError: (error) => {
          errors.push(error.message);
          return setErrorList(errors);
        },
      }
    );
  };

  return (
    <div className="max-w-3xl mx-auto">
      <h2 className="m-8 text-4xl font-extrabold text-center sm:text-6xl">
        {t("createRestaurantAccount.title")}
      </h2>
      <p className="max-w-2xl p-4 mt-1 ">
        {t("createRestaurantAccount.intro")}
      </p>
      <form
        className="px-3 space-y-8 divide-y divide-gray-200"
        onSubmit={handleSubmit}
      >
        <div>
          <div className="pt-8 space-y-6 sm:space-y-5 sm:pt-10">
            <div className="space-y-6 sm:space-y-5">
              <TextInput
                name="email"
                type="email"
                attributeName="email"
                label={t("createRestaurantAccount.emailLabel")}
                value={email}
                setValue={setEmail}
                autoComplete="email"
                required={true}
                icon="mail"
                labelClassName="sm:w-1/3"
              />

              <TextInput
                attributeName="name"
                label={t("createRestaurantAccount.nameLabel")}
                value={name}
                setValue={setName}
                autoComplete="given-name"
                required={true}
                icon="user"
                labelClassName="sm:w-1/3"
              />

              <TextInput
                attributeName="lastName"
                label={t("createRestaurantAccount.lastNameLabel")}
                value={lastName}
                setValue={setLastName}
                autoComplete="family-name"
                required={true}
                icon="user"
                labelClassName="sm:w-1/3"
              />

              <SelectInput
                attributeName="country"
                label={t("createRestaurantAccount.countryLabel")}
                value={country}
                setValue={setCountry}
                optionList={countriesList}
                labelClassName="sm:w-1/3"
              />

              <TextInput
                attributeName="phone"
                label={t("createRestaurantAccount.phoneLabel")}
                value={phone}
                setValue={setPhone}
                type="number"
                required={true}
                icon="phone"
                placeholder={t("createRestaurantAccount.phonePlaceholder")}
                labelClassName="sm:w-1/3"
              />

              <TextInput
                attributeName="password"
                label={t("createRestaurantAccount.passwordLabel")}
                value={password}
                setValue={setPassword}
                required={true}
                type="password"
                icon="lock"
                labelClassName="sm:w-1/3"
              />

              <TextInput
                attributeName="confirmPassword"
                label={t("createRestaurantAccount.repeatLabel")}
                value={confirmPassword}
                setValue={setConfirmPassword}
                required={true}
                type="password"
                icon="lock"
                labelClassName="sm:w-1/3"
              />
            </div>
          </div>
        </div>

        <div className="pt-5">
          <div className="flex justify-end">
            <div className="flex flex-col items-end gap-y-5">
              <Button
                className="w-52"
                isLoading={isLoading}
                isDisabled={isDisabled}
              >
                {t("createRestaurantAccount.create")}
              </Button>
              <div>
                <span>{t("createRestaurantAccount.already")}</span>
                <LinkText to="/login">
                  {t("createRestaurantAccount.signIn")}
                </LinkText>
              </div>
            </div>
          </div>
        </div>
      </form>
      {errorList.length > 0 && (
        <ErrorBlock
          message={t("createRestaurantAccount.errorMessage")}
          errorList={errorList}
          className="mt-4"
        />
      )}
    </div>
  );
};

function SelectInput({
  attributeName,
  label,
  value,
  setValue,
  autoComplete,
  required = false,
  optionList,
  labelClassName,
}) {
  const { i18n } = useTranslation();
  return (
    <div className="sm:flex sm:items-center sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
      <label
        htmlFor={attributeName}
        className={`block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2 ${labelClassName}`}
      >
        {`${label} ${required === true ? "*" : ""}`}
      </label>
      <div className="mt-1 sm:col-span-2 sm:mt-0">
        <select
          id={attributeName}
          value={value}
          onChange={(e) => setValue(e.target.value)}
          name={attributeName}
          autoComplete={autoComplete}
          className="block w-full max-w-lg border-gray-300 rounded-md shadow-sm text-vdark focus:border-green-500 focus:ring-green-500 sm:max-w-xs sm:text-sm"
          required={required}
        >
          {optionList.map((item) => {
            return (
              <option key={item.name} value={item.name}>
                {i18n.language === "en" ? item.name : item.nombre}
              </option>
            );
          })}
        </select>
      </div>
    </div>
  );
}
