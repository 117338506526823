import { FakeResponseCollection } from "utils/fakeResponseCollection";

export const fake_register = new FakeResponseCollection();

fake_register.set({
  input: {
    email: "kk@kk.com",
    password: "Password123",
    name: "name-1",
    last_name: "last-name-1",
    country: "Spain",
    phone: "666555444",
  },
  response: {
    data: {
      result: {
        ok: true,
      },
    },
  },
});

fake_register.set({
  input: {
    email: "kk@kk.com",
    password: "Password000",
    name: "name-1",
    last_name: "last-name-1",
    country: "Spain",
    phone: "666555444",
  },
  response: {
    data: {
      result: {
        ok: false,
        errors: {
          email: { error_code: "BAD-FORMAT" },
        },
      },
    },
  },
});
