export const es = {
  title: "Título",
  _: "", // We use it to initialize states and avoid "missing key errors"

  links: {
    webTermsConditions: "/web-terminos-y-condiciones",
    webTermsUse: "/web-terminos-uso",
    appTerms: "/app-terminos-condiciones",
    privacy: "/politica-privacidad",
    restaurants: "/negocios-sostenibles",
  },

  errors: {
    undefined: "Error inesperado.",
    announceError: "Se ha producido un error.",
    accessError: "No se ha podido acceder con el mail y contraseña provistos.",
    registerError: "No se pudo guardar tu information en el servidor.",
    forgotPasswordError:
      "No se pudo iniciar el proceso de restauración de contraseña en el servidor.",
    resetPasswordError:
      "No se pudo iniciar el proceso de cambio de contraseña en el servidor.",
    connectionError: "No se ha recibido respuesta del servidor.",
    unknownError: "No se ha podido conectar con el servidor.",
    repeatPasswordError: "Las contraseñas no coinciden.",
    invalidEmailError: "El formato de la dirección de correo no es válido.",
    shortPasswordError: "La contraseña debería tener al menos 8 caracteres.",
    sendActivationError:
      "Ha ocurrido un error y no se ha podido mandar el mail de activación. Por favor inténtalo más tarde.",
    deniedError: "La operación fue rechazada por el servidor.",
    noMoreActivationSendError:
      "Se ha superado el número de intentos máximos de envío de mail de activación. Por favor inténtelo más tarde",
    rangeConflictError:
      "El rango seleccionado está dentro de al menos un rango ya introducido anteriormente",
    selectMinimumDaysError: "Selecciona al menos un día",
    allFieldsError: "Rellena todos los campos horarios",
    bigImageError: "El tamaño máximo de imagen permitido son 10mb",
    tooManyImagesError:
      "Algunas imágenes han sido rechazadas ya que exceden el límite máximo",
    websiteError:
      "La dirección proporcionada en la web del restaurante no es válida",
    googlemapsError: "La dirección de Google Maps no es válida",
    instagramError: "La dirección de Instagram no es válida",
    facebookError: "La dirección de Facebook no es válida",
    requiredError:
      "Por favor rellena todos los campos marcados como obligatorios con (*)",
    loadIssuesError:
      "No se ha podido obtener del servidor información de tus incidencias",
    loadRestaurantsError:
      "No se ha podido obtener del servidor información de tus restaurantes",
    plantAnimalIncompatible:
      "Las medallas de proteína vegetal totalmente vegana o totalmente vegetariano no son compatibles con carne o pescados y mariscos sostenibles",
    notShortenedLinksError:
      "Los links cortos de Google no son válidos. Para obtener el link correcto puedes visitar el que has provisto y copiar el que aparezca en la barra de navegación con la forma https://www.google...",
    notGoogleLink:
      "Debes aportar un link válido de Google Maps con la forma https://www.google....",
    emptyIntervalError:
      "La hora de inicio no pude ser la misma que la hora final",
  },

  success: {
    announceSuccess: "Operación realizada con éxito",
    accountConfirmedTitle: "Cuenta creada",
    accountConfirmedText: "Tu cuenta de usuario ha sido creada correctamente.",
    passwordUpdatedTitle: "Contraseña actualizada",
    passwordUpdatedText: "Tu contaseña ha sido cambiada correctamente.",
    signInLink: "Entrar",
    sendActivationSuccess:
      "Se ha enviado un mail con instrucciones a la dirección provista.",
    dashboardLink: "Ir al Panel de Control",
    restaurantCreatedTitle: "Tu restaurante ha sido publicado correctamente",
    restaurantCreatedText:
      "Tu restaurante se ha publicado en nuestra plataforma. Puedes modificar o añadir más información desde el panel de control.",
    informationUpdated: "La información ha sido actualizada con éxito",
    contactEmailSent: "El mensaje ha sido enviado",
    waitlistSent: "Tu contacto ha sido añadido a nuestra lista de espera :)",
  },

  validateEmail: {
    titleSuccess: "Cuenta activada",
    titleFail: "Error en activación de cuenta",
    titleExpired: "El link de activación ha expirado",
    textSuccess:
      "Tu cuenta ha sido activada con éxito. Ya puedes acceder a tu zona privada para usuarios registrados.",
    textFail:
      "Ha habido un problema al activar tu cuenta. Vuelve a intentarlo más tarde.",
    textExpired:
      "El link que te enviamos ya no es válido. Utiliza por favor el que te acabamos de enviar de nuevo. Si no lo recibes, utiliza el botón para pedir otro.",
    linkSuccess: "Entra en tu cuenta",
  },

  keywords: {
    COVICONS: "Gestión de la Seguridad",
    ECOCERTI: "Eco-certificado",
    ENEGEFFI: "Eficiencia energética",
    PLANFRIE: "Proteína vegetal",
    PLANTFRIENDLY: "Opciones de proteína vegetal",
    PLANFULL: "Proteína vegetal",
    PLANTBASED: "Proteína vegetal",
    PLASCONS: "Gestión sostenible de plásticos",
    PLASTICCONSCIOUS: "Gestión sostenible de plásticos",
    PLASTICFREE: "Libre de plásticos",
    RENWENEG: "Energía renovable",
    SUSTMEAT: "Carne sostenible",
    SUSTPROD: "Frutas y verduras sostenibles",
    SUSTSEAF: "Pescados y mariscos sostenibles",
    VEGEFRIE: "Vegetariano",
    VEGEFULL: "Vegetariano",
    VEGETARIAN: "Vegetariano",
    VEGETARIANFRIENDLY: "Opciones vegetarianas",
    WASTCONS: "Gestión sostenible de residuos",
    WATREFFI: "Gestión sostenible del agua",
    AIR_CON: "Aire acondicionado",
    BOTTOMLESS: "Buffet libre",
    BRUNCH: "Brunch",
    BUFFET: "Buffet",
    CASUAL_DINING: "Cena casual",
    COCKTAILS: "Cocktails",
    DAILY_SPECIAL: "Especial del día",
    DOGS_ALLOWED: "Permitidos perros",
    FINE_DINING: "Alta cocina",
    FREE_WIFI: "Wifi gratis",
    HANDICAP_ACCESSIBLE: "Accessible personas movilidad reducida",
    KIDS_MENU: "Menú para niños",
    LIVE_MUSIC: "Música en directo",
    NO_RESERVATION_REQUIRED: "Sin reserva",
    OUTDOOR_DINING: "Al aire libre",
    OWN_DELIVERY: "Envío propio",
    PARTY_VIBE: "Ambiente de fiesta",
    QUIET: "Tranquilo",
    RESERVATION_REQUIRED: "Con reserva",
    ROMANTIC: "Romántico",
    SCENIC_VIEW: "Vista panorámica",
    SHOW: "Comida con espectáculo",
    STAY: "Posibilidad de quedarte a trabajar",
    AFGHANI: "Afgana",
    AFRICAN: "Africana",
    ARGELIAN: "Argelina",
    AMERICAN: "Americana",
    ARABIC: "Árabe",
    ARGENTINEAN: "Argentina",
    ASIAN: "Asiática",
    ASSYRIAN: "Asiria",
    AUSTRALIAN: "Australiana",
    BAR: "Bar",
    BARBECUE: "Barbacóa",
    BASQUE: "Vasca",
    BEER_PUB: "Cervecería",
    BELGIAN: "Belga",
    BRAZILIAN: "Brasileña",
    BRITISH: "Británica",
    CAFE: "Cafetería",
    CAJUN: "Cajún & Criolla",
    CARIBBEAN: "Caribeña",
    CATALAN: "Catalana",
    CENTRAL_AMERICAN: "América Central",
    CENTRAL_ASIAN: "Centro Asiática",
    CENTRAL_EUROPEAN: "Centro Européa",
    CHINESE: "China",
    COFFEE_SHOP: "Coffee Shop",
    COLOMBIAN: "Colombian",
    CONTEMPORARY: "Contemporánea",
    COSTA_RICAN: "Costa Rica",
    CROATIAN: "Croata",
    CUBAN: "Cubana",
    DANISH: "Danesa",
    DELI: "Deli",
    DINER: "Diner Americano",
    DUTCH: "Holandesa",
    EASTERN_EUROPEAN: "Europa del Este",
    ECUADOREAN: "Ecuatoriana",
    EGYPTIAN: "Egipcia",
    ETHIOPIAN: "Etíope",
    EUROPEAN: "Europea",
    FAST_FOOD: "Fast Food",
    FILIPINO: "Filipina",
    FRENCH: "Francesa",
    FUSION: "Fusión",
    GASTROPUB: "Gastropub",
    GEORGIAN: "Georgiana",
    GERMAN: "Alemana",
    GREEK: "Griega",
    GRILL: "Parrilla",
    HAWAIIAN: "Hawaiana",
    HEALTHY: "Saludable",
    HUNGARIAN: "Húngara",
    INDIAN: "India",
    INDONESIAN: "Indonesia",
    INTERNATIONAL: "Internacional",
    IRISH: "Irlandesa",
    ISRAELI: "Israelí",
    ITALIAN: "Italiana",
    JAMAICAN: "Jamaicana",
    JAPANESE: "Japonesa",
    KOREAN: "Koreana",
    LATIN: "Latina",
    LEBANESE: "Libanesa",
    MALAYSIAN: "Malaya",
    MEDITERRANEAN: "Mediterránea",
    MEXICAN: "Mexicana",
    MIDDLE_EASTERN: "Oriente medio",
    MOROCCAN: "Marroquí",
    MODERN: "Moderna",
    NEPALI: "Nepalí",
    NEW_ZEALAND: "Neocelandesa",
    NORWEGIAN: "Noruega",
    PAKISTANI: "Pakistaní",
    PERSIAN: "Persa",
    PERUVIAN: "Peruana",
    PIZZA: "Pizza",
    POLISH: "Polaca",
    POLYNESIAN: "Polinesia",
    PORTUGUESE: "Portuguesa",
    PUERTO_RICAN: "Puertorriqueña",
    RUSSIAN: "Rusa",
    SCANDINAVIAN: "Escandinava",
    SCOTTISH: "Escocesa",
    SEAFOOD: "Marisquería",
    SINGAPOREAN: "Singapur",
    SOUPS: "Sopas",
    SOUTH_AMERICAN: "Sudamericana",
    SPANISH: "Española",
    STEAKHOUSE: "Asador",
    STREET_FOOD: "Comida callejera",
    SUSHI: "Sushi",
    SWEDISH: "Sueca",
    SWISS: "Suiza",
    SZECHUAN: "Sichuan",
    TAIWANESE: "Taiwanesa",
    TAPAS: "Tapas",
    THAI: "Tailandesa",
    TIBETAN: "Tibetana",
    TUNISIAN: "Tunecina",
    TURKISH: "Turca",
    VENEZUELAN: "Venezolana",
    VIETNAMESE: "Vietnamita",
    WINE_BAR: "Vinoteca",
    WOK: "Wok",
    GLUTEN_FREE: "Sin gluten",
    HALAL: "Halal",
    KOSHER: "Kosher",
    PHONE: "Teléfono",
    WHATSAPP: "Whatsapp",
    ONLINE: "Online",
    PLANTAPPETIZERS: "Entrantes veganos",
    PLANTDESSERTS: "Postres veganos",
    VEGANBEER: "Cerveza vegana",
    VEGANWINE: "Vino vegano",
    VEGANLIQUOR: "Licores vegano",
    MICHELIN: "MICHELIN Green Star",
    SLOWFOOD: "Slow Food",
    INTERECO: "InterEco",
    BIOSPHERE: "Biosphere",
    ECOCOOK: "EcoCook",
    QUALITAT: "Garantía de Qualitat Ambiental",
    BREEAM: "BREEAM",
    LEED: "LEED",
    OTHERECOCERTIFICATION: "Otra",
    COVIDLOCAL:
      "Sigue todas las medidas de seguridad exigidas por la administración local",
    OPENAIR: "Ofrece comedor/mesas al aire libre",
    EMPLOYEETEMPERATURES:
      "Medición diaria de la temperatura de los empleados antes del trabajo",
    HIGHERMASKS:
      "El personal lleva máscaras de grado quirúrgico o superior en todo momento",
    SANITIZER:
      "Se proporciona desinfectante de manos para los clientes en un lugar visible",
    TOUCHLESS:
      "Entrada al restaurante y acceso a baños sin contacto (sin pomos con sensores de luz...)",
    SIGNS:
      "Señalización visible para que los clientes respeten el distanciamiento social",
    COMPOSTOWN: "Sí, tenemos nuestro propio sistema de compostaje in situ",
    COMPOSTCITY:
      "Sí, deposito los residuos orgánicos en los contenedores de la ciudad",
    COMPOSTTRASH: "No, los restos se depositan en la basura normal",
    RECYCLEGLASS: "Vidrio",
    RECYCLEPAPER: "Papel",
    RECYCLEMETAL: "Metal",
    PLASTICTABLEWARE: "Cubiertos y vajilla",
    PLASTICCUPS: "Vasos tapas y agitadores",
    PLASTICSTRAWS: "Pajitas",
    PLASTICONTAINERS: "Envases para llevar y bolsas",
    PLASTICBOTTLES:
      "Botellas de agua y refrescos (por ejemplo si no sirves agua filtrada)",
    WILDGAME: "De caza",
    GRASSFED: "Alimentada con pastos",
    HORMONEFREE: "Libre de hormonas",
    FREERANGECHICKEN: "Criada en libertad",
    MEATCERTIFICATIONEU: "Certificación Orgánica de la EU",
    MEATCERTIFICATIONOTHER: "Otra",
    SEAFOODCERTIFICATIONMSC: "Marine Stewardship Council (MSC)",
    SEAFOODCERTIFICATIONOTHER: "Otra",
    "0-25": "Menos de 25%",
    "25-50": "25% - 50%",
    "50-75": "50% - 75%",
    "75-100": "75% - 100%",
    GROWONSITE: "En el mismo restaurante",
    GROWBACKYARD: "En un jardín/terraza del restaurante",
    GROWROOFTOP: "En el tejado",
    GROWOFFSITE: "En un terreno no próximo al restaurante",
    GROWVERTICAL: "Con cultivo vertical",
    GROWAQUAPONICS: "Con tecnologías de acuaponía",
    GROWHYDROPONICS: "Con agricultura hidropónica",
    GROWOTHER: "Otra",
    GEOTHERMAL: "Geotérmica",
    WIND: "Eólica",
    SOLARPV: "Solar Fotovoltaica",
    SOLARTHERMAL: "Solar Térmica",
    HYDRO: "Hidráulica",
    HYDROFUEL: "Pila de Hidrógeno",
    NOOVEN:
      "Sin horno / horno combi/de convección u olla a presión en lugar de horno tradicional",
    DISHWASHER:
      "Lavavajillas con calificación energética A++ o A+++ (escala antigua) o A (nueva escala)",
    REFRIGERATOR:
      "Frigorífico con calificación energética A++ o A+++ (escala antigua) o A (nueva escala)",
    LED: "Toda la iluminación con LED (sin luces incandescentes)",
    CONTROLLED: "Luces controladas por sensores de ocupación/movimiento",
    PRERINSE:
      "Válvulas de prelavado eficientes (menos de 6L de agua por minuto)",
    EFFICIENTDISHWASHER:
      "Lavavajillas de bajo consumo de agua (menos de 2L de agua por cesto)",
    TABLECLOTHFREE: "Sin manteles de tela",
    DUALURINALS: "Urinarios/Inodoros duales de bajo consumo",
    SINKSENSOR:
      "Lavabos con sensor o temporizador (activados con botón por ejemplo)",
    ECOPRODUCTS: "Uso de productos de limpieza ecológicos",
    CONSENT1:
      "Por la presente confirmo que la información proporcionada es precisa, correcta y completa. Entiendo que si se determina que mis declaraciones son falsas, mi restaurante corre el riesgo de perder el ícono de sostenibilidad correspondiente y potencialmente ser eliminado de la aplicación Verdantips. *",
    CONSENT2:
      "Me comprometo a informar a Verdantips por escrito de cualquier cambio en la información ya facilitada y a proporcionar información actualizada cuando así se me solicite. *",
    CONSENT3:
      "Acepto los términos y condiciones, los términos de uso y la política de privacidad de Verdantips para usar los datos enviados en este formulario.",
  },

  general: {
    accept: "Aceptar",
    cancel: "Cancelar",
    upload: "Subir imagen",
    imageFormat: "PNG, JPG, GIF menos de 10MB",
    add: "Añadir",
    update: "Actualizar",
    start: "Crear",
    next: "Siguiente",
    back: "Anterior",
    filter: "Filtro",
    backDashboard: "Volver a Panel de Control",
    cookieMessage: "Mensaje de Cookie",
    cookieConsent: "Acepto",
  },

  nav: {
    forRestaurants: "/para-restaurantes",
    forUsers: "/para-usuarios",
    about: "/sobre-verdantips",
    contact: "/contacto",
    sustainability: "/sostenibilidad",
    restaurants: "/negocios-sostenibles",
    barcelonaPilot: "/unete-a-nuestro-piloto-en-barcelona",
  },

  header: {
    findRestaurants: "Encuentra restaurantes",
    dashboard: "Panel de control",
    login: "Entrar",
    signOut: "Salir",
  },

  footer: {
    siteMap: "Mapa del Sitio",
    legal: "Legal",
    followUs: "Síguenos",
  },

  restaurantWaitlistForm: {
    title: "Únete",
    intro:
      "Verdantips llegará pronto a ciudades de toda Europa! Suscríbete ahora a nuestra lista de espera para ser notificado cuando se lance Verdantips en tu ciudad y convertirse en un restaurante Verdant.",
    restaurant: "Nombre del Restaurante",
    restaurantPlaceholder: "Mi restaurante",
    city: "Ciudad",
    email: "Email",
    yourName: "Nombre",
    yourNamePlaceholder: "Tu nombre",
    yourPosition: "Cargo",
    yourPositionPlaceholder: "Dueña",
    submit: "Enviar",
  },

  waitlistForm: {
    title: "Únete",
    intro:
      "Verdantips llegará pronto a ciudades de toda Europa! Suscríbete ahora a nuestra lista de espera para ser notificado cuando se lance Verdantips en tu ciudad y cómo convertirse en un usuario Verdant.",
    city: "Ciudad",
    cityPlaceholder: "Tu ciudad",
    email: "Email",
    emailPlaceholder: "Tu email",
    name: "Nombre",
    namePlaceholder: "Tu nombre",
    restaurantName: "Nombre del restaurante",
    restaurantNamePlaceholder: "Tu restaurante",
    position: "Cargo",
    positionPlaceholder: "Dueño",
    submit: "Enviar",
  },

  modal: {
    title: "Elige tu ubicación",
    barcelona: "Barcelona y alrededores",
    other: "Otro",
    link: "/negocios-sostenibles",
    cancel: "Cancelar",
    confirm: "Confirmar",
    confirmTitle: "Confirmación Requerida",
  },

  home: {
    heroTitle: "Restaurantes sostenibles",
    heroSubTitle: "a tu alcance",
    headerRestaurants:
      "Ayudamos a restaurantes a reducir su impacto medioambiental y a ganar nuevos clientes concienciados",
    moreAboutRestaurants: "Más sobre los restaurantes Verdant",
    headerDiners:
      "Ayudamos a todos a encontrar restaurantes cercanos que se ajusten a sus valores",
    moreAboutDiners: "Más sobre los usuarios Verdant",
    headerSustainability: "Sostenibilidad",
    textSustainability01:
      "Tenemos un enfoque holístico de la sostenibilidad, lo que significa que incluimos todas las formas en que comer fuera puede tener un impacto en el medio ambiente.",
    textSustainability02:
      "Cuando un restaurante confirma que cumple nuestros estándares para un determinado criterio, rellenando un sencillo cuestionario de sostenibilidad, recibe una medalla por ello.",
    moreAboutSustainability: "Más sobre sostenibilidad",
    friendsAndPartners: "Amigos y colaboradores",
    followUs: "¡Síguenos!",
  },

  forUsers: {
    title: "¿Buscando el restaurante perfecto?",
    findRestaurants: "Encontrar restaurantes",
    header01:
      "Juntos podemos marcar la diferencia, con cada elección. Únete a la lista de espera para participar en nuestro lanzamiento beta.",
    header02: "¿Tardas mucho en encontrar un restaurante que te guste?",
    text02:
      "Deja que Verdantips te ayude a identificar qué restaurantes de tu zona trabajan para ser sostenibles y seguros para sus clientes.",
    header03: "¿Tienes amigos quisquillosos?",
    text03:
      "Verdantips encuentra la mejor combinación para todas tus preferencias, desde platos aptos para veganos, hasta comida para llevar sin plástico o electrodomésticos de bajo consumo.",
    header04: "¿Preocupado por la seguridad?",
    text04:
      "Con Verdantips puedes sentirte seguro al salir, conociendo de antemano todas las medidas de seguridad que aplican tus restaurantes favoritos.",
    header05: "¿Buscando gente como tú?",
    text05:
      "Únete a nuestra comunidad Verdant y encuentra nuevos amigos en tu ciudad que compartan tus mismos valores.",
    socialMediaClaim: "¡No olvides seguirnos en las redes sociales!",
    header06: "Pero, ¿qué entendemos por sostenible?",
    text06:
      "Adoptamos un enfoque holístico de la sostenibilidad, lo que significa que examinamos todas las formas en que comer fuera puede tener un impacto en el medio ambiente.",
    text06_bis:
      "Cuando un restaurante cumple nuestros estándares para un determinado criterio de sostenibilidad, recibe una medalla por ello.",
    button06: "Más sobre sostenibilidad",
    link: "/sostenibilidad",
  },

  forRestaurants: {
    title: "Únete al movimiento y ¡conviértete en un restaurante Verdant!",
    joinUs: "Únete",
    header01: "Cómo puede Verdantips ayudar a tu restaurante",
    text01:
      "Publicita las prácticas sostenibles de tu restaurante en la app y aprende a reducir aún más tu impacto ambiental con la plataforma web de soporte de restaurantes de Verdantips.",
    header02:
      "Muestra tus prácticas sostenibles y medidas de seguridad a una clientela concienciada",
    header03:
      "Estate al día sobre las tendencias locales y recibe analíticas personalizados para saber como conseguir más clientes",
    header04:
      "Aprende a reducir tu huella de carbono y tus costes, todo en un solo lugar",
  },

  sustainability: {
    heroTitle: "¿A qué nos referimos con sostenibilidad?",
    intro: "Sostenibilidad en el sector de la restauración",
    introText:
      "Los usuarios pueden elegir entre 12 preferencias de sostenibilidad en la app de Verdantips. Sigue leyendo para saber qué medidas debe implementar un restaurante para recibir cada medalla.",
    ecoCertifiedTitle: "Eco-Certificado",
    ecoCertifiedText:
      "Un restaurante recibe la medalla de Eco-Certificado si ha recibido una certificación, designación o premio por sus esfuerzos hacia la sostenibilidad.",
    covidConsciousTitle: "Gestión de la Seguridad",
    covidConsciousText:
      "Un restaurante recibe la medalla de Gestión de la Seguridad si sigue protocolos más estrictos que los exigidos por las autoridades locales para garantizar la seguridad de sus comensales y personal",
    plantBasedTitle: "Proteína de Origen Vegetal",
    plantBasedFriendly: "Opciones de Proteína de Origen Vegetal",
    plantBased: "Proteína de Origen Vegetal",
    plantBasedText:
      "Un restaurante recibe la medalla de Proteína de Origen Vegetal si todo su menú es vegano, de origen vegetal. Recibe la medalla de Opciones de Proteína de Origen Vegetal si ofrece al menos dos platos principales que no contienen productos animales",
    vegetarianTitle: "Vegetariano",
    vegetarianFriendly: "Opciones Vegetarianas",
    vegetarian: "Vegetariano",
    vegetarianText:
      "Un restaurante recibe la medalla Vegetariano si todo su menú excluye carnes, aves, pescados y mariscos. Recibe la medalla de Opciones Vegetarianas si ofrece al menos dos platos principales que sean vegetarianos.",
    sustainableMeatTitle: "Carne Sostenible",
    sustainableMeatText:
      "Un restaurante recibe la medalla de Carne Sostenible si ofrece continuamente en su menú carne o aves de corral con certificación orgánica u otra certificación ecológica, y/o local de la región y al menos una de las siguientes: carne de caza, alimentada con pasto, sin hormonas y/o criada en libertad.",
    sustainableSeafoodTitle: "Pescados y Mariscos Sostenibles",
    sustainableSeafoodText:
      "Un restaurante recibe la medalla de Pescado y Marisco Sostenible si proporciona continuamente productos del mar que proceden de proveedores con una certificación ecológica y/o que se capturan o producen localmente y se ajustan a la oferta diaria.",
    sustainableProduceTitle: "Frutas y Verduras Sostenibles",
    sustainableProduceText:
      "Un restaurante recibe la medalla de Frutas y Verduras Sostenibles si cultiva sus propios alimentos y/o sirve continuamente productos certificados como ecológicos o cultivados localmente en la región.",
    wasteConsciousTitle: "Gestión Sostenible de los Residuos",
    wasteConsciousText:
      "Un restaurante recibe la medalla de Gestión Sostenible de los Residuos si reduce, reutiliza y recicla eficazmente sus residuos de alimentos y materiales, por ejemplo, mediante el compostaje de residuos orgánicos, la gestión adecuada del inventario y el reciclaje constante de productos de papel, vidrio y aluminio.",
    plasticConsciousTitle: "Libre de Plástico",
    plasticConscious: "Gestión Sostenible de Plásticos",
    plasticFree: "Libre de Plástico",
    plasticConsciousText:
      "Un restaurante recibe la medalla Libre de Plástico si ha recibido una certificación. Recibe la medalla de Gestión Sostenible de Plásticos si minimiza el uso de plásticos de un solo uso.",
    renewableEnergyTitle: "Consumo de Energías Renovables",
    renewableEnergyText:
      "Un restaurante recibe la medalla de Consumo de Energía Renovable si produce su propia energía renovable o si compra electricidad y/o gas renovable a través de su proveedor.",
    energyEfficientTitle: "Prácticas de Eficiencia Energética",
    energyEfficientText:
      "Un restaurante recibe la medalla de Prácticas de Eficiencia Energética si aplica una serie de medidas para reducir su consumo de energía, como el uso de electrodomésticos eficientes, LED e iluminación activada por movimiento.",
    waterEfficientTitle: "Gestión Sostenible del Agua",
    waterEfficientText:
      "Un restaurante recibe la medalla de Gestión Sostenible del Agua si aplica una serie de medidas para reducir su consumo de agua, como el uso de lavavajillas de bajo consumo, fregaderos de bajo flujo e inodoros de baja descarga.",
  },

  about: {
    title: "Sobre Verdantips",
    ourVisionTitle: "Nuestra Vision",
    ourVisionSubtitle: "Potenciar una sociedad sostenible",
    ourVisionText:
      "Queremos que Verdantips sea la plataforma a la que acudan personas y empresas para tomar decisiones sostenibles en todo lo que hacen.",
    ourValuesTitle: "Nuestros Valores",
    ourValuesText:
      "En Verdantips, nos esforzamos por vivir de acuerdo con nuestros valores y tomamos cada decisión con ellos en mente.",
    empowering: "Empoderamiento",
    empoweringText:
      "Aportamos a las personas herramientas y conocimiento en una plataforma accesible para ayudarles a tomar decisiones más alineadas con el futuro que quieren para el planeta",
    compassionate: "Compasión",
    compassionateText:
      "Amamos y respetamos a nuestras compañeras y nuestro ecosistema, siempre cuidando a nuestros empleados, clientes, usuarios, personas y el planeta.",
    courageous: "Valentía",
    corageousText:
      "Nos atrevemos a imaginar un futuro audaz, decir las duras verdades necesarias para llegar allí y luchar por un futuro sostenible sin importar lo difícil que sea.",
    resilient: "Resiliencia",
    resilientText:
      "Superamos los desafíos de construir un futuro sostenible, los enfrentamos como aprendizajes y emerger como mejores versiones de nosotros mismos.",
    truthful: "Veracidad",
    truthfulText:
      "Nos comprometemos a aportar transparencia a través de nuestra plataforma, a tratar la información con respeto y a vivir de acuerdo con nuestros valores en todo lo que hacemos.",
    ourLogo: "Nuestro Logo",
    ourLogoText:
      "Nuestro logo es el emblema de nuestra misión, visión y valores.",
    location: "Ubicación",
    symbolOfTruth: "Símbolo de la Verdad",
    sustainability: "Sostenibilidad",
    wholeness: "Totalidad y Plenitud",
    ourStory: "Nuestra Historia",
    ourStoryText1:
      "La visión de Verdantips surgió cuando compartimos nuestra lucha sobre cómo vivir de forma sostenible. Mackenzie es vegana y evita comprar plástico, Natalia es estrictamente vegetariana y toma medidas para reducir sus residuos, y Mahima es flexitariana pero se preocupa por su consumo de electricidad y agua. Dada la diversidad de valores de cada una de nosotras, nos dimos cuenta de que era un reto ponernos de acuerdo sobre la sostenibilidad en su conjunto. Esto se hacía aún más conflictivo cuando salíamos a cenar fuera, ya que nos costaba encontrar restaurantes que siguieran prácticas ecológicas más allá de servir platos veganos y vegetarianos. Y los que lo hacían, no satisfacían todas nuestras necesidades.",
    ourStoryText2:
      "Pronto nos dimos cuenta de que este problema está presente en muchos sectores del estilo de vida, como la alimentación, la ropa, el cuidado de la piel y el maquillaje, entre otros. Uno se pasa horas revisando páginas web y artículos para luego sentirse abrumado y perder la motivación.",
    ourStoryText3:
      "Con este problema entre manos, participamos en el Entrepreneurship Journey de EIT Innoenergy en septiembre de 2019, donde validamos nuestro concepto, desarrollamos un modelo de negocio y una estrategia de salida al mercado. Más tarde, en 2020, fuimos aceptados en el programa ALTS Accelerator y presentamos en sus Demo Days. También participamos en el reto MALL New Normal y ganamos en la categoría “Prácticas sostenibles”. Con ello, nos aseguramos una colaboración con AMS Smart City y llevamos nuestro producto BETA para lanzarlo pronto.",
    ourStoryText4:
      "Nuestro objetivo es aportar transparencia y facilidad para que todo el mundo se adapte a un estilo de vida sostenible, y creemos en dar un paso tras otro. De ahí que empecemos por el sector de la alimentación para ayudar a la gente a tomar mejores decisiones alimentarias y, más adelante, a entrar en otros sectores.",
    ourTeam: "Nuestro Equipo",
    ourTeamText:
      "Somos tres ingenieras de tres continentes con pasión por ayudar a las personas y al planeta",
  },

  contact: {
    title: "Contáctanos",
    intro:
      "Si quieres saber más sobre Verdantips o estás interesado en asociarte con nosotras, déjanos tus datos en el siguiente formulario y nos pondremos en contacto contigo.",
    sendMail:
      "También puedes enviarnos un correo electrónico a: contact@verdantips.com.",
    nameLabel: "Nombre",
    namePlaceholder: "Tu nombre",
    emailLabel: "Email",
    emailPlaceholder: "Tu email",
    message: "Mensaje",
    submit: "Enviar",
  },

  barcelonaPilot: {
    heroTitle: "¡Únete a nuestro piloto en Barcelona!",
    heroText:
      "Eres un pionero al hacer esfuerzos para que tu restaurante sea más sostenible, y nosotras queremos que se sepa realmente quién está haciendo las cosas bien.",
    gettingStartedTitle: "¡Empezamos!",
    gettingStartedText:
      "¡Estamos listos para lanzar nuestro piloto en Barcelona pronto! Crea tu perfil con nosotras ahora para convertirte en uno de los primeros restaurantes Verdant en la app, completamente gratis.",
    howToJoinTitle: "Cómo unirse",
    howToJoinText:
      "Simplemente tienes que contestar nuestro sencillo cuestionario de 20 minutos (o menos) preguntando sobre las prácticas de tu restaurante. Te haremos saber las medallas que has recibido y cuando tu perfil sea visible en la aplicación.",
    whatToHavePrepared: "Qué hay que tener preparado",
    basicInfoBulletpoint:
      "Información básica sobre el restaurante (dirección, página web, horario de apertura, tipo de cocina, etc.)",
    photosBulletpoint:
      "Fotos para mostrar en la aplicación del menú, los platos, el interior/ambiente y la aplicación de las medidas de seguridad en formato png o jpeg",
    detailsBulletpoint:
      "Detalles sobre las prácticas relativas a nuestras 12 medallas",
    learn: "lee más sobre nuestras medallas",
    link: "/sostenibilidad",
    minutesBulletpoint:
      "10 a 20 minutos de tiempo, preferiblemente con ordenador",
    startNow: "Empezar ahora",
  },

  login: {
    title: "Acceso para Restaurantes",
    emailLabel: "Email",
    passwordLabel: "Contraseña",
    forgot: "Recordar contraseña",
    signIn: "Acceder",
    noAccount: "¿No tienes una cuenta?",
    create: "Crear cuenta",
    errorTitle: "Ha habido errores al acceder a tu cuenta:",
  },

  createRestaurantAccount: {
    title: "Crea tu cuenta de Restaurante",
    intro:
      "Crea una cuenta para trabajar con nosotros. Más tarde podrás cambiar los datos excepto el mail, que quedará asociado a tu cuenta.",
    emailLabel: "Email",
    nameLabel: "Nombre",
    lastNameLabel: "Apellidos",
    countryLabel: "País",
    defaultCountry: "Espana",
    phoneLabel: "Teléfono",
    phonePlaceholder: "666 123 456",
    roleLabel: "Cargo",
    rolePlaceholder: "Dueño",
    passwordLabel: "Contraseña",
    repeatLabel: "Repetir contraseña",
    create: "Crear cuenta",
    already: "¿Tienes ya una cuenta?",
    signIn: "Entra",
    errorMessage: "La operación no se ha podido realizar:",
  },

  mail: {
    accountCreatedTitle: "Revisa tu Correo",
    accountCreatedText:
      "Por favor sigue las instrucciones enviadas a tu correo para activar tu cuenta.",
    forgotPasswordTitle: "Revisa tu Correo",
    forgotPasswordText:
      "Por favor sigue las instrucciones enviadas a tu correo para reiniciar tu contraseña.",
  },

  forgotPassword: {
    title: "¿Olvidaste tu contraseña?",
    text: "Introduce tu dirección de correo electrónico. Te enviaremos instrucciones para reiniciar tu contraseña.",
    emailLabel: "Email",
    reset: "Enviar instrucciones",
  },

  resetPassword: {
    title: "Reinicia tu contraseña",
    text: "Introduce la contraseña que vas a querer utilizar:",
    passwordLabel: "Nueva contraseña",
    confirmLabel: "Confirma nueva contraseña",
    button: "Reinicia contraseña",
    errorTitle: "No se pudo reiniciar tu contraseña",
  },

  activate: {
    title: "Tu cuenta no ha sido activada todavía",
    text: "Tu cuenta ha sido creada, pero no activada. Te hemos enviado un mail con instructiones sobre coomo hacerlo a tu dirección de correo electrónico.",
    send: "Reenviar correo de activación",
  },

  dashboard: {
    title: "Panel de Control",
    pendingIssues: "Incidencias pendientes",
    date: "Fecha",
    restaurant: "Restaurante",
    category: "Categoría",
    description: "Descripción",
    seeAll: "Ver todo",
    tableExplanation:
      "Pulsa sobre cualquier fila para obtener más información. Pulsa en los encabezados para ordenar la tabla en función del campo seleccionado.",
    noPending: "No hay ninguna incidencia.",
    registeredRestaurants: "Restaurantes Registrados",
    published: "Publicado",
    notPublished: "No Publicado",
    contactInfo: "Información de Contacto",
    openHours: "Horario de Apertura",
    restaurantDetails: "Detalles del Restaurante",
    photos: "Foto de Portada",
    sustainabilityBadges: "Medallas de Sostenibilidad",
    signedConsent: "Consentimiento Firmado",
    delete: "Borrar",
    edit: "Editar",
    unpublish: "Retirar Publicación",
    publish: "Publicar",
    deleteRestaurantTitle: "Confirmar Borrado de Restaurante",
    deleteRestaurantText:
      "Si continúas el restaurante será borrado de nuestra base de datos",
    deleteRestaurantAction: "Borrar",
    unpublishRestaurantTitle: "Confirmar Retirada de Restaurante",
    unpublishRestaurantText:
      "Si continúas el restaurante ya no estará disponible para nuestros usuarios.",
    unpublishRestaurantAction: "Retirar",
    publishRestaurantTitle: "Confirmar Publicación de Restaurante",
    publishRestaurantText:
      "Si continúas el restaurante estará disponible para nuestros usuarios.",
    publishRestaurantAction: "Publicar",
    createRestaurant: "Crear nuevo restaurante",
  },

  issues: {
    title: "Gestor de Incidencias",
    status: "Situación",
    pending: "Pendiente",
    solved: "Resuelta",
    noIssues: "Felicidades!! No tienes ninguna incidencia :)",
  },

  issue: {
    title: "Informe de Incidencia",
    subTitle: "Información y detalles de la incidencia",
    status: "Situación",
    titleId: "Incidencia Nº",
    titleUser: "Creada por el usuario",
    titleRestaurant: "Nombre del Restaurante",
    titleDescription: "Descripción",
    titleKeyword: "Categoría",
    titleDate: "Fecha",
    titlePhotos: "Fotos",
    markSolved: "Marcar la incidencia como resuelta",
    solve: "Resolver",
    cancel: "Cancelar",
    solveMessage:
      "Para marcar la incidencia como resuelta comenta por favor la razón o las acciones realizadas para considerarla terminada.",
    reopen: "Reabrir incidencia",
  },

  restaurantContact: {
    title: "Información de Contacto",
    intro: "Introduce la información de contacto de tu restaurante:",
    nameLabel: "Nombre del restaurante",
    namePlaceholder: "Añadir nombre del restaurante",
    addressLabel: "Dirección",
    addressPlaceholder: "Calle, Número...",
    zipcodeLabel: "Código Postal",
    cityLabel: "Ciudad",
    cityPlaceholder: "Añadir nombre de ciudad",
    phoneLabel: "Teléfono",
    phonePlaceholder: "666 555 444",
    emailLabel: "Email",
    emailPlaceholder: "nombre@micorreo.com",
    websiteLabel: "Web del restaurante",
    websitePlaceholder: "www.mirestaurante.com",
    googlemapsLabel: "Link a página de Google Maps",
    instagramLabel: "Link a página de Instagram",
    facebookLabel: "Link a página de Facebook",
  },

  restaurantOpen: {
    title: "Horario de Apertura del Restaurante",
    intro:
      "Rellena las horas que tu restaurante suele estar abierto durante la semana",
    monday: "Lunes",
    tuesday: "Martes",
    wednesday: "Miércoles",
    thursday: "Jueves",
    friday: "Viernes",
    saturday: "Sábado",
    sunday: "Domingo",
    addTime: "Añadir intervalo de tiempo",
    addSlot: "Añadir horas de apertura",
    addTimeIntro: "Introduce el intervalo de tiempo que quieres asignar:",
    cancel: "Cancelar",
    add: "Añadir",
    from: "Desde",
    to: "a",
    following: "En los siguientes días:",
    schedule: "Horario de apertura",
  },

  restaurantDetails: {
    title: "Detalles del Restaurante",
    intro: "Rellena información particular de tu restaurante:",
    descriptionTitle: "Define tu restaurante en una frase",
    services: "Servicios y Ambiente",
    cuisine: "Cocina",
    dietary: "¿Ofrece tu restaurante las siguientes opciones?",
    price: "Cuál es el precio medio de menú por persona en €?",
    reservation: "¿Pides reserva?",
    channel: "Por favor incluye el canal que ofreces para reservar:",
  },

  restaurantPhotos: {
    title: "Fotos del Restaurante",
    intro:
      "Aporta fotos de diferentes categorías. Como mínimo debes añadir una imagen portada para continuar.",
    coverTitle: "Portada",
    menuTitle: "Menú",
    foodTitle: "Comida",
    ambienceTitle: "Ambiente",
    covidTitle: "Medidas de Seguridad",
    maximum: "Máximo",
    photo: "imagen",
    photos: "imágenes",
  },

  restaurantBadges: {
    title: "Medallas de Sostenibilidad",
    intro: "Solicita nuestras diferentes medallas de sostenibilidad",
    apply: "Solicitar",
    edit: "Editar información",
    remove: "Quitar",
    formIntro:
      "Por favor responde las siguientes preguntas. Si satisfacen nuestros criterios podrás añadir la medalla.",
    plant: {
      entirePlantBased:
        "¿Es tu restaurante totalmente vegano (la carta está totalmente basada en proteínas de origen vegetal)?",
      twoMainDishes:
        "¿Ofreces en el menú al menos dos platos principales totalmente veganos?",
      menuIncludes: "¿Incluye tu menú...?",
    },
    eco: {
      certificationIntro:
        "Selecciona la certificación, designación o premio que tenga tu restaurante (escoge todas las relevantes)",
      extraEco: `Ingrese el nombre de la "otra" certificación `,
    },
    vegetarian: {
      entirely: "¿Es tu restaurante totalmente vegetariano?",
      twoMainDishes:
        "¿Ofreces en el menú al menos dos platos principales totalmente vegetarianos?",
    },
    covid: {
      measures:
        "Selecciona cada una de las medidas de seguridad que se aplican a su restaurante",
      otherMeasures:
        "¿Hay algo más que te gustaría que tus clientes sepan sobre las medidas que estás tomando para proteger la seguridad de tus empleados y clientes?",
    },
    waste: {
      surplus: "¿Vendes o donas los excedentes de alimentos de su restaurante?",
      surplusDescription:
        "Describe cómo vendes o donas los excedentes de alimentos (por ejemplo, a través de plataformas como Too Good To Go, o a través de una organización)",
      compost:
        "¿Compostas los residuos orgánicos generados por el restaurante?",
      compostExplanation: "Por favor, danos más detalles",
      inventory: `¿Utilizas un sistema de gestión de inventario para minimizar el desperdicio de alimentos, como por ejemplo, el "FIFO" (First In First Out)?`,
      recycle: "Selecciona los tipos de residuos que reciclas continuamente",
      train:
        "¿Formas a tu personal en prácticas de gestión sostenible de residuos?",
      containers:
        "¿Permites que los comensales lleven sus propios envases para llevar?",
      comments:
        "¿Hay algo más que te gustaría que tus clientes sepan sobre tu gestión de residuos en tu restaurante?",
    },
    plastic: {
      hasCertification:
        "¿Tiene tu restaurante una certificación por ser Libre de Plásticos?",
      certificationName: "¿Cuál es el nombre de la certificación?",
      certificationYear: "¿Qué año recibiste la certificación?",
      certificationDuration: "¿Cuál es el plazo de validez?",
      plasticUse:
        "¿Qué productos de plástico utilizas? (escoge todos los relevantes)",
      comments:
        "¿Hay algo más que te gustaría que tus clientes sepan sobre tu gestión de plásticos en tu restaurante?",
    },
    meat: {
      hasCertification:
        "¿Ofreces continuamente en el menú carne que ha recibido una certificación ecológica?",
      certificationType: "¿Cuál es el nombre de la certificación?",
      certificationName: "Ingrese el nombre de la certificación:",
      meatType:
        "Danos más información sobre tu carne certificada, como el tipo de carne, etc:",
      local:
        "¿Ofreces continuamente en el menú carne de animales criados o cazados en una región a menos de 100 Km de distancia?",
      menu: "¿Ofreces continuamente carne que es...?",
      comments:
        "¿Hay algo más que te gustaría que tus clientes sepan sobre sobre la carne que sirves en tu restaurante?",
    },
    seafood: {
      hasCertification:
        "¿Ofreces continuamente en el menú productos del mar que hayan recibido una certificación ecológica?",
      certificationType: "¿Cuál es el nombre de la certificación?",
      certificationName: "Ingrese el nombre de la certificación:",
      seafoodType:
        "Danos más información sobre tus productos del mar certificados, como el tipo, origen etc",
      local:
        "¿Ofreces continuamente pescado o marisco capturado o producido en aguas a menos de 100 Km de distancia y ajustado a la oferta diaria?",
      comments:
        "¿Hay algo más que te gustaría que tus clientes sepan sobre sobre el pescado o marisco que sirves en tu restaurante?",
    },
    produce: {
      grow: "¿Cultivas tus propios alimentos?",
      growPercentage:
        "Aproximadamente, ¿qué porcentaje de los productos que sirves cultivas tu mismo?",
      growType: "¿Cómo cultivas esos productos? (escoge todos los relevantes)",
      otherGrowType: `Por favor especifica tu "otra" opción:`,
      certified:
        "¿Ofreces continuamente en el menú productos (frutas y verduras) con certificación ecológica de la UE?",
      organicPercentage:
        "Aproximadamente, ¿qué porcentaje de los productos que sirves son ecológicos certificados por la UE?",
      local:
        "¿Ofreces continuamente en el menú productos (frutas y verduras) cultivados en un área a menos de 100 Km de distancia?",
      localPercentage:
        "Aproximadamente, ¿qué porcentaje de los productos que sirves se cultivan en un área a menos de 100 Km de distancia?",
      comments:
        "¿Hay algo más que te gustaría que tus clientes sepan sobre sobre las frutas y verduras que sirves en tu restaurante?",
    },
    renewable: {
      hasOwn:
        "¿Produces y consumes tu propia energía renovable, por ejemplo, mediante paneles solares en el tejado?",
      installation:
        "¿Qué tipo de instalación utiliza para producir la energía?",
      consumptionPercentage:
        "¿Qué porcentaje aproximado de tu consumo energético está cubierto por la instalación?",
      start: "¿En qué año entró en funcionamiento la instalación?",
      hasElectricity:
        "¿Compras electricidad de origen renovable a tu proveedor de electricidad?",
      electricityProvider:
        "¿Cuál es el nombre de su proveedor de electricidad?",
      hasGas: "¿Compras gas renovable a tu proveedor de gas?",
      gasProvider: "¿Cuál es el nombre de tu proveedor de gas?",
      comments:
        "¿Hay algo más que te gustaría que tus clientes sepan sobre la energía consumida en tu restaurante?",
    },
    energy: {
      practices:
        "Escoge todas las prácticas de eficiencia energética que sigue tu restaurante",
      comments:
        "¿Hay algo más que te gustaría que tus clientes sepan sobre la eficiencia energética en tu restaurante?",
    },
    water: {
      practices:
        "Escoge todas las prácticas de gestión sostenible del agua que sigue tu restaurante:",
      comments:
        "¿Hay algo más que te gustaría que tus clientes sepan sobre la gestión del agua en tu restaurante?",
    },
  },

  consent: {
    title: "Consentimiento y autorización",
    intro:
      "Por favor acepta los siguientes términos para crear tu cuenta de restaurante:",
    conditions: "Términos y Condiciones",
    use: "Términos de Uso",
    privacy: "Política de Privacidad",
    accept: "Acepto",
  },

  closed: {
    title: "Días de Cierre del Restaurante",
    intro:
      "Marca los días que el restaurante estará cerrado durante el año además de los días regulares.",
    explanation:
      "Pulsa en los días que quieres marcar como cerrado. Pulsando en los días marcados los anularás.",
    explanation2:
      "Si en ciertos días no has rellenado el horario de apertura, aparecerán como cerrados regularmente.",
  },

  calendar: {
    monday: "L",
    tuesday: "M",
    wednesday: "M",
    thursday: "J",
    friday: "V",
    saturday: "S",
    sunday: "D",
    0: "Enero",
    1: "Febrero",
    2: "Marzo",
    3: "Abril",
    4: "Mayo",
    5: "Junio",
    6: "Julio",
    7: "Agosto",
    8: "Septiembre",
    9: "Octubre",
    10: "Noviembre",
    11: "Diciembre",
    regular: "Días cerrados regularmente",
    extra: "Días cerrados extra",
    today: "Hoy",
  },
  register: {
    title: "Registrar Restaurante:",
    skipMessage: "Quieres hacerlo más tarde?",
    skip: "Pasar",
    skipTitle: "Pasar Registro de Restaurante",
    skipWithCreation:
      "El restaurante será creado con la información que has proporcionado hasta el momento, pero deberás completar toda la información restante en un futuro para que el restaurante sea publicado en nuestra plataforma.",
    skipWithoutCreation:
      "Más adelante podrás registrar restaurantes desde el Panel de Control",
  },
  editRestaurant: {
    title: "Detalles del Restaurante",
    updateContact: "Actualizar información de contacto",
    updateOpenHours: "Actualizar horario",
    updateClosed: "Actualizar días de cierre",
    updateDetails: "Actualizar detalles",
    updatePhotos: "Actualizar fotos",
    updateBadges: "Actualizar medallas",
    acceptConsent: "Aceptar consentimiento",
    rejectConsent: "Rechazar consentimiento",
    contactTab: "Contacto",
    openTab: "Horario",
    closedTab: "Cierre",
    detailsTab: "Detalles",
    photosTab: "Fotos",
    badgesTab: "Medallas",
    consentTab: "Consentimiento",
    confirmMessage:
      "Los cambios realizados se perderán si no los salvas. ¿Deseas continuar?",
  },

  careers: {
    heroTitle: "Carrera",
    whyVerdantips: "¿Por qué Verdantips?",
    reason:
      "Trabaja directamente con nuestro equipo fundador para ayudar a potenciar una sociedad sostenible.",
    impact: "Carreras con impacto",
    jobAvailable:
      "Actualmente tenemos las siguientes posiciones abiertas. ¿No ves uno que se ajuste a tus habilidades pero quieres contribuir? Envíanos tu CV y una carta de presentación explicando el puesto que te gustaría a",
    jobNotAvailable:
      "Actualmente no tenemos ninguna oferta abierta. ¿Crees que estaríamos interesadas en tus habilidades? Envíanos tu CV con una carta de presentación explicando el puesto que te gustaría a",
    learnMore: "Más información",
  },
  restaurants: {
    heroTitle: "Restaurantes sostenibles en Barcelona",
    intro:
      "Aquí tienes una lista de todos nuestros restaurantes sostenibles en Barcelona. Para más información y funcionalidades como mapas o filtros a medida descarga nuestra app:",
    filter: "Filtra por medalla de sostenibilidad:",
    empty:
      "Actualmente no hay ningún restaurante que posea las medallas seleccionadas",
  },
  restaurantInfo: {
    toRestaurants: "Lista de restaurantes sostenibles",
    restaurantDetails: "Detalles del Restaurante",
    cuisine: "Cocina",
    services: "Servicios",
    dietary: "Opciones de dieta",
    badges: "Medallas de sostenibilidad",
    more: "¿Quieres más información?",
    download:
      "Descarga nuestra App y accede a mucha más información sobre este restaurante como:",
    map: "Localización en el mapa.",
    price: "Precio medio por persona.",
    reservation: "Requerimientos de reserva.",
    opening: "Horario de apertura.",
    photos: "Fotos del menú, comida, ambiente y medidas de seguridad.",
    contact: "Información completa de contacto.",
    future: "¡¡Y muchas más cosas en el futuro!!",
  },
};
