import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { TextInput } from "components/FormElements";
import { AddPills } from "components/FormElements";
import { useGetKeywords } from "hooks/restaurantHooks";
import { MultipleChoices } from "components/FormElements";
import { FullPageLoader } from "components/Loading";
import { ErrorPage } from "components/ErrorPage";
import { InputToggle } from "components/FormElements";
import { StepSubmitButtons } from "./StepSubmitButtons";
import { Conditional } from "components/FormElements";

export function RestaurantDetails({
  setStep,
  restaurantInformation,
  setRestaurantInformation,
}) {
  const {
    description: _description,
    cuisine: _cuisine,
    dietary: _dietary,
    services: _services,
    average_meal_price: _average_meal_price,
    reservation_required: _reservation_required,
    reservation_channel: _reservation_channel,
  } = restaurantInformation;
  const { t } = useTranslation();
  const {
    data: keywords,
    isLoading: isLoadingKeywords,
    isError: isErrorKeywords,
  } = useGetKeywords();
  const [isNextDisabled, setIsNextDisabled] = useState(true);
  const [description, setDescription] = useState(_description);
  const [services, setServices] = useState(_services);
  const [dietary, setDietary] = useState(_dietary);
  const [cuisine, setCuisine] = useState(_cuisine);
  const [average_meal_price, setPrice] = useState(_average_meal_price || "");
  const [reservation_required, setReservation] = useState(
    _reservation_required
  );
  const [reservation_channel, setReservationChannel] =
    useState(_reservation_channel);
  const [openServices, setOpenServices] = useState(false);
  const [openCuisine, setOpenCuisine] = useState(false);

  useEffect(() => {
    if (
      description.length === 0 ||
      services.length === 0 ||
      cuisine.length === 0 ||
      average_meal_price.length === 0 ||
      average_meal_price === 0 ||
      (reservation_required === true && reservation_channel.length === 0)
    ) {
      return setIsNextDisabled(true);
    } else return setIsNextDisabled(false);
  }, [
    description,
    services,
    cuisine,
    reservation_required,
    average_meal_price,
    reservation_channel,
  ]);

  useEffect(() => {
    if (parseInt(average_meal_price) < 0) setPrice("0");
  }, [average_meal_price]);

  function handleReservation() {
    setReservation(!reservation_required);
    setReservationChannel([]);
  }

  function handleNext() {
    setRestaurantInformation({
      description,
      cuisine,
      dietary,
      services,
      average_meal_price,
      reservation_required,
      reservation_channel,
    });
    setStep((prev) => prev + 1);
  }

  if (isLoadingKeywords) return <FullPageLoader />;
  if (isErrorKeywords) return <ErrorPage />;
  return (
    <div className="mx-auto max-w-3xl px-4">
      <h2 className="m-4 text-center text-3xl sm:m-4 sm:text-5xl">
        {t("restaurantDetails.title")}
      </h2>
      <p className="my-2 sm:my-4">{t("restaurantDetails.intro")}</p>
      <ul className="flex list-disc flex-col gap-4 p-2 pl-4">
        <li>
          <TextInput
            attributeName="description"
            label={t("restaurantDetails.descriptionTitle")}
            value={description}
            setValue={setDescription}
            borderTop={false}
            autoCorrect="on"
          />
        </li>
        <li>
          <AddPills
            title={t("restaurantDetails.services")}
            open={openServices}
            setOpen={setOpenServices}
            optionsList={keywords.data.result.payload.services}
            setList={setServices}
            list={services}
            required={true}
          />
        </li>
        <li>
          <AddPills
            title={t("restaurantDetails.cuisine")}
            open={openCuisine}
            setOpen={setOpenCuisine}
            optionsList={keywords.data.result.payload.cuisine}
            setList={setCuisine}
            list={cuisine}
            required={true}
          />
        </li>
        <li>
          <MultipleChoices
            optionsList={keywords.data.result.payload.dietary}
            setSelection={setDietary}
            selection={dietary}
            intro={t("restaurantDetails.dietary")}
            blockStyle="max-w-fit"
          />
        </li>
        <li>
          <TextInput
            attributeName="average_meal_price"
            type="number"
            label={t("restaurantDetails.price")}
            value={average_meal_price}
            setValue={setPrice}
            borderTop={false}
            autoCorrect="on"
            className="w-20"
            required={true}
          />
        </li>
        <li>
          <InputToggle
            enabled={reservation_required}
            setEnabled={handleReservation}
            text={t("restaurantDetails.reservation")}
          />
          {reservation_required === true && (
            <Conditional className="rounded-md bg-gray-100 ">
              <MultipleChoices
                optionsList={["PHONE", "WHATSAPP", "ONLINE"]}
                setSelection={setReservationChannel}
                selection={reservation_channel}
                intro={t("restaurantDetails.channel")}
                required={true}
                blockStyle="max-w-fit"
              />
            </Conditional>
          )}
        </li>
      </ul>
      <StepSubmitButtons
        isDisabled={isNextDisabled}
        back={t("general.back")}
        next={t("general.next")}
        onNext={handleNext}
        onBack={() => setStep((prev) => prev - 1)}
      />
    </div>
  );
}
