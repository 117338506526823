import React from "react";
import { useTranslation } from "react-i18next";
import Button from "components/Button";
import forRestaurantsHero from "./img/for-resturants-hero.jpg";
import mobileImg from "./img/mobile.jpg";
import tablet1 from "./img/tablet1.jpg";
import tablet2 from "./img/tablet2.jpg";
import { WaitlistForm } from "components/WaitlistForm";
import FindRestaurantsModal from "components/FindRestaurantsModal";

export const ForRestaurants = () => {
  const { t } = useTranslation();
  return (
    <main>
      <HeroImage />
      <div className="flex flex-col gap-8 px-4 mx-auto max-w-7xl sm:gap-12 sm:px-8 md:gap-16">
        <div className="flex flex-col max-w-4xl gap-4 mx-auto">
          <h2>{t("forRestaurants.header01")}</h2>
          <p>{t("forRestaurants.text01")}</p>
        </div>
        <div className="flex flex-col items-center gap-4 sm:flex-row ">
          <h2 className="flex-1">{t("forRestaurants.header02")}</h2>
          <div className="h-72 sm:flex-1">
            <img src={mobileImg} className="object-contain h-full" alt="" />
          </div>
        </div>
        <div className="flex flex-col items-center gap-4 sm:flex-row">
          <h2 className="flex-1 sm:order-2 ">{t("forRestaurants.header03")}</h2>
          <div className="sm:flex-1">
            <img src={tablet1} className="object-contain w-full " alt="" />
          </div>
        </div>
        <div className="flex flex-col items-center gap-4 sm:flex-row ">
          <h2 className="flex-1">{t("forRestaurants.header04")}</h2>
          <div className="sm:flex-1 ">
            <img src={tablet2} className="object-contain h-full" alt="" />
          </div>
        </div>
        <WaitlistForm target="restaurant" />
      </div>
    </main>
  );
};

function HeroImage() {
  const { t } = useTranslation();
  const [modalIsOpen, setModalIsOpen] = React.useState(false);
  return (
    <div className="relative isolate mb-8 h-64 sm:mb-12 sm:h-[500px] md:mb-16">
      <img
        src={forRestaurantsHero}
        alt=""
        className="absolute object-cover h-full"
      />
      <div className="absolute w-full h-full bg-vdarkgreen opacity-60 " />
      <div className="absolute flex flex-col items-center justify-center w-full h-full gap-2 p-2 sm:gap-6 lg:gap-8">
        <p
          style={{ fontFamily: "DIN" }}
          className="max-w-sm p-4 text-4xl text-center text-white drop-shadow sm:max-w-xl sm:text-6xl md:text-7xl lg:max-w-5xl lg:text-8xl"
        >
          {t("forRestaurants.title")}
        </p>
        <Button
          onClick={() => setModalIsOpen(true)}
          className="text-3xl sm:text-4xl md:text-5xl"
        >
          {t("forRestaurants.joinUs")}
        </Button>
      </div>
      <FindRestaurantsModal
        open={modalIsOpen}
        setOpen={setModalIsOpen}
        target="restaurant"
      />
    </div>
  );
}
