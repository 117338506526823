import React from "react";
import { useTranslation } from "react-i18next";
import { useGetUserRestaurants } from "hooks/restaurantHooks";
import { useGetUserIssues } from "hooks/issuesHooks";
import { FullPageLoader } from "components/Loading";
import { LinkText } from "components/LinkText";
import { Table } from "components/Table";
import { RestaurantSummaryCard } from "components/RestaurantSummaryCard";
import { useNavigate } from "react-router-dom";

export const Dashboard = () => {
  const { t } = useTranslation();
  const {
    data: userRestaurants,
    isLoading: isLoadingUserRestaurants,
    isError: isErrorUserRestaurants,
  } = useGetUserRestaurants();

  const {
    data: userIssues,
    isLoading: isLoadingUserIssues,
    isError: isErrorUserIssues,
  } = useGetUserIssues();

  const columns = React.useMemo(
    () => [
      {
        Header: t("dashboard.date"),
        accessor: "date", // accessor is the "key" in the data
      },
      {
        Header: t("dashboard.restaurant"),
        accessor: "restaurant.name",
      },
      {
        Header: t("dashboard.category"),
        accessor: "keyword",
        Cell: ({ row }) => {
          return <p>{t(`keywords.${row.values.keyword}`)}</p>;
        },
      },
      {
        Header: t("dashboard.description"),
        accessor: "description",
        disableSortBy: true,
      },
    ],
    [t]
  );

  if (isLoadingUserIssues || isLoadingUserRestaurants)
    return <FullPageLoader />;
  else {
    return (
      <>
        <div className="mx-auto mt-4 min-h-full max-w-7xl">
          <h1 className="mx-8 text-center text-4xl font-extrabold sm:text-6xl">
            {t("dashboard.title").toUpperCase()}
          </h1>
          <div className="my-6 flex items-baseline justify-between">
            <h2 className="ml-4 text-2xl font-extrabold sm:ml-8 sm:text-5xl">
              {t("dashboard.pendingIssues").toUpperCase()}
            </h2>
            {userIssues?.solved.length > 0 && (
              <LinkText
                to="/dashboard/issues"
                className="mr-4 text-lg sm:mr-8 sm:text-3xl md:text-3xl"
              >
                {t("dashboard.seeAll")}
              </LinkText>
            )}
          </div>
          {isErrorUserIssues && (
            <p className="mx-4 max-w-fit rounded-md bg-red-50 p-4 text-red-800 sm:mx-8">
              {t("errors.loadIssuesError")}
            </p>
          )}
          {userIssues?.pending.length > 0 && (
            <>
              <p className="mx-4 mb-4 text-sm sm:mx-8 sm:mb-6 sm:text-base">
                {t("dashboard.tableExplanation")}
              </p>
              <Table data={userIssues.pending} columns={columns} />
            </>
          )}
          {userIssues?.pending.length === 0 && (
            <p className="mx-4 mb-4 text-sm sm:mx-8 sm:mb-6 sm:text-base">
              {t("dashboard.noPending")}
            </p>
          )}
          <h2 className="my-6 ml-4 text-left text-2xl font-extrabold sm:ml-8 sm:text-5xl">
            {t("dashboard.registeredRestaurants").toUpperCase()}
          </h2>
          {isErrorUserRestaurants && (
            <p className="mx-4 sm:mx-8">{t("errors.loadRestaurantsError")}</p>
          )}
          <div className="ml-8 flex flex-wrap">
            {userRestaurants?.data.result.payload.length > 0 &&
              userRestaurants?.data.result.payload.map((restaurant) => {
                return (
                  <RestaurantSummaryCard
                    key={restaurant.id}
                    restaurant={restaurant}
                  />
                );
              })}
            <CreateRestaurantButton />
          </div>
        </div>
      </>
    );
  }
};

function CreateRestaurantButton() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  return (
    <button
      type="button"
      onClick={() => navigate("/dashboard/create-restaurant")}
      className="relative m-4 block w-80 rounded-lg border-2 border-dashed border-gray-300 p-12 text-center hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2"
    >
      <svg
        className="mx-auto h-12 w-12 text-gray-400"
        xmlns="http://www.w3.org/2000/svg"
        stroke="none"
        fill="currentColor"
        viewBox="0 0 544 544"
        aria-hidden="true"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
          d="M516.25 229.86a141.247 141.247 0 0 0 10.789-149.132 149.804 149.804 0 0 0-265.066 0 143.99 143.99 0 0 0-16.109 60.023H9.504a8.75 8.75 0 0 0-8.75 8.75v61.25a52.34 52.34 0 0 0 17.5 39.023v240.98h-8.75a8.75 8.75 0 0 0-8.75 8.75v35c0 2.32.922 4.547 2.563 6.188a8.754 8.754 0 0 0 6.187 2.562h437.5c2.32 0 4.547-.922 6.188-2.562a8.756 8.756 0 0 0 2.562-6.188v-35c0-2.32-.922-4.547-2.562-6.188a8.756 8.756 0 0 0-6.188-2.562h-8.75v-157.31L516.25 229.86ZM277.52 88.74a132.352 132.352 0 0 1 70.188-61.934 132.343 132.343 0 0 1 93.602 0 132.356 132.356 0 0 1 70.188 61.934 124.003 124.003 0 0 1-9.363 130.762l-107.62 142.96-35-46.48v-.227h-.176l-72.316-96.039a124.063 124.063 0 0 1-23.492-64.129 124.107 124.107 0 0 1 13.996-66.844l-.007-.003Zm108.24 291.18v58.328h-157.5v-105h122.35l35.15 46.672ZM262.94 213.99a34.907 34.907 0 0 1-19.207 28.035 34.919 34.919 0 0 1-33.945-1.578 34.908 34.908 0 0 1-16.523-29.695v-52.5h52.93a141.889 141.889 0 0 0 16.746 55.738h-.001Zm-157.18-55.738h70v52.5a35.005 35.005 0 0 1-17.5 30.312 35.01 35.01 0 0 1-35 0 35.008 35.008 0 0 1-17.5-30.312v-52.5Zm-87.5 0h70v52.5a35.005 35.005 0 0 1-17.5 30.312 35.01 35.01 0 0 1-35 0 35.008 35.008 0 0 1-17.5-30.312v-52.5Zm420 367.5h-420v-17.5h420v17.5Zm-262.5-35h-105v-122.5h105v122.5Zm245 0h-227.5v-131.25a8.752 8.752 0 0 0-8.75-8.75H62.01a8.75 8.75 0 0 0-8.75 8.75v131.25h-17.5v-230.56a52.203 52.203 0 0 0 34.172.367 52.194 52.194 0 0 0 27.078-20.844 52.432 52.432 0 0 0 43.75 23.539 52.432 52.432 0 0 0 43.75-23.539 52.411 52.411 0 0 0 29.07 21.453 52.407 52.407 0 0 0 61.93-27.664l61.922 82.25h-117.92a8.75 8.75 0 0 0-8.75 8.75v122.5a8.751 8.751 0 0 0 8.75 8.75h175c2.32 0 4.547-.922 6.188-2.562a8.755 8.755 0 0 0 2.562-6.188v-67.078l17.5-23.238-.002 134.064Z"
        />
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
          d="M184.5 280.75H62a8.75 8.75 0 0 0-8.75 8.75v35c0 2.32.922 4.547 2.563 6.188A8.754 8.754 0 0 0 62 333.25h122.5c2.32 0 4.547-.922 6.188-2.562a8.756 8.756 0 0 0 2.562-6.188v-35c0-2.32-.922-4.547-2.562-6.188a8.756 8.756 0 0 0-6.188-2.562Zm-8.75 35h-105v-17.5h105v17.5ZM394.5 263.25A113.752 113.752 0 0 0 508.25 149.5 113.752 113.752 0 0 0 394.5 35.75 113.752 113.752 0 0 0 280.75 149.5 113.881 113.881 0 0 0 394.5 263.25Zm26.25-122.5c0-10.684 5.18-17.5 8.75-17.5 3.57 0 8.75 6.816 8.75 17.5 0 10.684-5.18 17.5-8.75 17.5-3.57 0-8.75-6.816-8.75-17.5Zm-26.25-87.5a96.178 96.178 0 0 1 74.773 35.621 96.19 96.19 0 0 1-31.024 146.257v-61.453a35.222 35.222 0 0 0 17.5-32.926c0-19.625-11.523-35-26.25-35s-26.25 15.375-26.25 35a35.219 35.219 0 0 0 17.5 32.926v68.348a94.312 94.312 0 0 1-52.5 0V175.75a17.5 17.5 0 0 0 17.5-17.5v-52.5h-17.5v52.5h-17.5v-52.5h-17.5v52.5c0 4.641 1.844 9.094 5.125 12.375a17.507 17.507 0 0 0 12.375 5.125v59.379a96.2 96.2 0 0 1-50.395-65.73 96.177 96.177 0 0 1 19.371-80.527 96.176 96.176 0 0 1 74.773-35.621l.002-.001Z"
        />
      </svg>
      <span className="mt-4 block text-sm font-medium text-vaccent">
        {t("dashboard.createRestaurant")}
      </span>
    </button>
  );
}
