import React from "react";
import { Link } from "react-router-dom";

function LinkText({ children, className, to, target }) {
  return (
    <Link
      className={`${className}  rounded-sm p-2 text-xs font-bold text-vaccent sm:p-3 sm:text-sm md:text-base`}
      style={{ fontFamily: "Poppins" }}
      to={to}
      target={target}
    >
      {children}
    </Link>
  );
}

export { LinkText };
