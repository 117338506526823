import React from "react";
import { useLocation, Navigate } from "react-router-dom";
import api from "API";

const AuthContext = React.createContext();

//#region Helper methods
function handleServerError(error) {
  if (error.response) {
    // The request was made and the server responded with an status code that falls out of the range of 2xx
    console.log("Response error!!!", error.response);
    if (
      error.response.status === 401 &&
      error.response.data.code === "BAD-USER-PASSWORD"
    ) {
      throw new Error("accessError");
    }
    if (
      error.response.status === 401 &&
      error.response.data.code === "EMAIL-NOT-VALIDATED"
    ) {
      throw new Error("EMAIL-NOT-VALIDATED");
    }
    if (
      error.response.status === 401 &&
      error.response.data.msg === "Token has expired"
    )
      AuthProvider.signOut();
    if (error.response.status === 500) throw new Error("serverError");
    throw new Error("accessError");
  } else if (error.request) {
    // The request was made but no response was received
    console.log("No response received", error.request);
    throw new Error("connectionError");
  } else {
    // Something happened in setting up the request that triggered an Error
    console.log("Request error!!!", error);
    if (error?.message) throw new Error(error.message);
    else throw new Error("unknownError");
  }
}

function saveUserInStorage({ access_token, email }) {
  window.localStorage.setItem("VerdantipsToken", access_token);
  window.localStorage.setItem("VerdantipsEmail", email);
}

function removeUserInStorage() {
  window.localStorage.removeItem("VerdantipsToken");
  window.localStorage.removeItem("VerdantipsEmail");
}

function getUserInStorage() {
  return {
    token: window.localStorage.getItem("VerdantipsToken"),
    email: window.localStorage.getItem("VerdantipsEmail"),
  };
}
//#endregion

function AuthProvider(props) {
  let [user, setUser] = React.useState({});

  async function checkToken(input) {
    try {
      const response = await api.auth.checkToken(input);
      if (response?.data.is_valid === true) {
        return { ok: true };
      }
      if (response?.data.is_valid === false) {
        return { ok: false };
      }
    } catch (error) {
      handleServerError(error);
    }
  }

  // We memoize the function so it's not called on every render
  const checkUser = React.useCallback(async () => {
    try {
      if (window.localStorage.VerdantipsToken) {
        const token = window.localStorage.getItem("VerdantipsToken");
        const response = await checkToken({ token });

        if (response.ok === false) {
          removeUserInStorage();
          return setUser(null);
        }

        if (response.ok === true) {
          return setUser(getUserInStorage());
        }
      } else {
        return setUser(null);
      }
    } catch (error) {
      setUser(null);
    }
  }, []);

  async function signIn(input) {
    try {
      const response = await api.auth.signIn(input);
      if (response.status === 401) {
        if (response.data.code === "BAD-USER-PASSWORD")
          throw new Error("accessError");
        if (response.data.code === "EMAIL-NOT-VALIDATED")
          throw new Error("EMAIL-NOT-VALIDATED");
        throw new Error("deniedError");
      }
      if (response.status !== 200) throw new Error("connectionError");
      const { email, access_token } = response.data;
      saveUserInStorage({ access_token, email });
      setUser({
        email,
        access_token,
      });

      return {
        ok: true,
        access_token,
      };
    } catch (error) {
      handleServerError(error);
    }
  }

  async function signOut() {
    removeUserInStorage();
    return setUser(null);
  }

  async function register(input) {
    try {
      const response = await api.auth.register(input);
      if (response?.ok === true) {
        // The backend will send an email with instructions
        return {
          ok: true,
        };
      } else {
        return {
          ok: false,
        };
      }
    } catch (error) {
      handleServerError(error);
    }
  }

  async function forgotPassword(input) {
    try {
      const response = await api.auth.forgotPassword(input);
      if (response?.data.result.ok === true) {
        return {
          ok: true,
        };
      }
      if (response?.data.result.ok === false) {
        return {
          ok: false,
        };
      }
    } catch (error) {
      handleServerError(error);
    }
  }

  async function resetPassword(input) {
    try {
      const response = await api.auth.resetPassword(input);

      if (response?.data.result.ok === true) {
        // The backend will send an email with instructions
        return {
          ok: true,
        };
      }

      if (response?.data.result.ok === false) {
        return {
          ok: false,
        };
      }
    } catch (error) {
      handleServerError(error);
    }
  }

  async function sendActivation(input) {
    try {
      const response = await api.auth.sendActivation(input);
      if (response?.data.result.ok === true) {
        return {
          ok: true,
        };
      }
      if (response?.data.result.ok === false) {
        return {
          ok: false,
        };
      }
      throw new Error("sendActivationError");
    } catch (e) {
      handleServerError(e);
    }
  }

  async function activateEmail(input) {
    try {
      const response = await api.auth.activateEmail(input);
      if (response?.data.result.ok === true) {
        return {
          ok: true,
        };
      }
      if (response?.data.result.ok === false) {
        return {
          ok: false,
          errors: response.data.result.errors,
        };
      }
      throw new Error("activateEmailError");
    } catch (e) {
      handleServerError(e);
    }
  }

  const value = {
    user,
    setUser,
    signIn,
    signOut,
    register,
    forgotPassword,
    resetPassword,
    checkUser,
    sendActivation,
    activateEmail,
  };

  return <AuthContext.Provider value={value} {...props} />;
}

function useAuth() {
  const context = React.useContext(AuthContext);
  if (context === undefined) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
}

function RequireAuth({ children }) {
  let location = useLocation();
  let { user } = useAuth();

  if (!user) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }
  return children;
}

export { AuthProvider, useAuth, RequireAuth };
