export function getLatLngFromGoogleUrl(url) {
  const firstCut = url.split("@")[1];
  if (!firstCut) return { latitude: null, longitude: null };
  const latLngString = firstCut.split("z")[0];
  if (!latLngString) return { latitude: null, longitude: null };
  const latLng = {
    latitude: latLngString.split(",")[0],
    longitude: latLngString.split(",")[1],
  };

  return latLng;
}
