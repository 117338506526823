import React, { useState, useEffect, useReducer } from "react";
import { useTranslation } from "react-i18next";
import { v4 as uuid } from "uuid";
import { useNavigate } from "react-router-dom";
import { ModalConfirm } from "components/ModalConfirm";
import { useCreateRestaurant } from "hooks/restaurantHooks";
import { RestaurantContactInformation } from "components/Forms/RestaurantContactInformation";
import { RestaurantOpenHours } from "components/Forms/RestaurantOpenHours";
import { RestaurantDetails } from "components/Forms/RestaurantDetails";
import { RestaurantPhotos } from "components/Forms/RestaurantPhotos";
import { RestaurantBadges } from "components/Forms/RestaurantBadges";
import { Consent } from "components/Forms/Consent";
import { RestaurantClosedDays } from "components/Forms/RestaurantClosedDays";
import { Step } from "components/Step";
import { getPhotosUrls } from "utils/photos";

const initialValues = {
  name: "",
  address: "",
  zipcode: "",
  city: "",
  phone_number: "",
  email: "",
  urls: {
    website: "",
    google_page: "",
    instagram: "",
    facebook: "",
  },
  location: {
    longitude: null,
    latitude: null,
  },
  open_hours: {
    monday: [],
    tuesday: [],
    wednesday: [],
    thursday: [],
    friday: [],
    saturday: [],
    sunday: [],
  },
  closed_days: [],
  description: "",
  cuisine: [],
  dietary: [],
  services: [],
  average_meal_price: null,
  reservation_required: false,
  reservation_channel: [],
  photos: {
    cover: [],
    menu: [],
    food: [],
    ambience: [],
    covid: [],
  },
  sustainability: [],
  certifications: {
    COVICONS: {
      covid_measures: [],
      other_covid_measures: "",
    },
    ECOCERTI: {
      eco_certification: [],
      extra_eco_certification: "",
    },
    ENEGEFFI: {
      energy_practices: [],
      energy_comments: "",
    },
    PLANFRIE: {
      entire_plant_based: false,
      two_main_dishes: false,
      plant_menu_includes: [],
      badge: "",
    },
    PLASCONS: {
      has_plastic_free_certification: false,
      plastic_free_certification_name: "",
      plastic_free_certification_year: null,
      plastic_free_certification_duration: null,
      plastic_use: [],
      badge: "",
      plastic_comments: "",
    },
    RENWENEG: {
      renewable_has_own: false,
      renewable_installation: [],
      renewable_consumption_percentage: null,
      renewable_start: null,
      renewable_has_electricity: false,
      renewable_electricity_provider: "",
      renewable_has_gas: false,
      renewable_gas_provider: "",
      renewable_comments: "",
    },
    SUSTMEAT: {
      meat_has_certification: false,
      meat_certification_type: [],
      meat_certification_name: "",
      meat_type: "",
      meat_is_local: false,
      meat_menu: [],
      meat_comments: "",
    },
    SUSTPROD: {
      produce_has_grow: false,
      produce_grow_percentage: "",
      produce_grow_type: [],
      produce_grow_other_type: "",
      produce_is_eu_certified: false,
      produce_organic_percentage: "",
      produce_is_local: false,
      produce_local_percentage: "",
      produce_comments: "",
    },
    SUSTSEAF: {
      has_seafood_certification: false,
      seafood_certification_type: [],
      seafood_certification_name: "",
      seafood_type: "",
      seafood_is_local: false,
      seafood_comments: "",
    },
    VEGEFRIE: {
      entirely_vegetarian: false,
      two_minimum_vegetarian_dishes: false,
      badge: "",
    },
    WASTCONS: {
      waste_sell_surplus: false,
      waste_surplus_description: "",
      waste_compost: false,
      waste_compost_explanation: [],
      waste_inventory: false,
      waste_recycle: [],
      waste_train: false,
      waste_customer_containers: false,
      waste_comments: "",
    },
    WATREFFI: {
      water_practices: [],
      water_comments: "",
    },
  },
  signed_consent: false,
};

export function RestaurantCreate() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { mutate: createRestaurant, isLoading: isLoadingCreateRestaurant } =
    useCreateRestaurant();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState([]);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [step, setStep] = useState(0);
  const [isContactInfoValid, setIsContactInfoValid] = useState(false);
  const [restaurantInformation, setRestaurantInformation] = useReducer(
    (currentValues, newValues) => ({ ...currentValues, ...newValues }),
    initialValues
  );

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [step]);

  async function handleRestaurantCreation() {
    try {
      setIsLoading(true);
      restaurantInformation.photos = await getPhotosUrls(restaurantInformation);
    } catch (e) {
      console.log(e);
      setIsLoading(false);
      return setError(["unknownError"]);
    }

    const input = {
      ...restaurantInformation,
      id: uuid(),
      signed_consent: false,
    };
    createRestaurant(input, {
      onError: (error) => {
        console.log({ error });
        setError([error.message]);
      },
      onSuccess: (data) => {
        if (!data) return setError(["unknownError"]);
        if (data.data.result.ok === false) return setError(["deniedError"]);
        if (data.data.result.ok === true) return navigate("/dashboard");
      },
    });
    setIsLoading(false);
  }

  return (
    <>
      <ModalConfirm
        open={openConfirm}
        setOpen={setOpenConfirm}
        title="register.skipTitle"
        confirmText={
          isContactInfoValid
            ? "register.skipWithCreation"
            : "register.skipWithoutCreation"
        }
        confirmFunction={
          isContactInfoValid
            ? handleRestaurantCreation
            : () => navigate("/dashboard")
        }
        isLoading={isLoadingCreateRestaurant || isLoading}
        error={error}
      />
      <div className="mb-10 sm:mb-20">
        <Step className="mt-8 sm:mt-12" totalSteps={7} step={step} />
        <h1 className="mt-6 text-center text-4xl sm:mt-10 sm:text-6xl">
          {t("register.title")}
        </h1>
        {step === 0 && (
          <RestaurantContactInformation
            setStep={setStep}
            restaurantInformation={restaurantInformation}
            setRestaurantInformation={setRestaurantInformation}
            setIsContactInfoValid={setIsContactInfoValid}
          />
        )}
        {step === 1 && (
          <RestaurantOpenHours
            setStep={setStep}
            restaurantInformation={restaurantInformation}
            setRestaurantInformation={setRestaurantInformation}
          />
        )}
        {step === 2 && (
          <RestaurantClosedDays
            setStep={setStep}
            restaurantInformation={restaurantInformation}
            setRestaurantInformation={setRestaurantInformation}
          />
        )}
        {step === 3 && (
          <RestaurantDetails
            setStep={setStep}
            restaurantInformation={restaurantInformation}
            setRestaurantInformation={setRestaurantInformation}
          />
        )}
        {step === 4 && (
          <RestaurantPhotos
            setStep={setStep}
            restaurantInformation={restaurantInformation}
            setRestaurantInformation={setRestaurantInformation}
          />
        )}
        {step === 5 && (
          <RestaurantBadges
            setStep={setStep}
            restaurantInformation={restaurantInformation}
            setRestaurantInformation={setRestaurantInformation}
          />
        )}
        {step === 6 && (
          <Consent
            setStep={setStep}
            restaurantInformation={restaurantInformation}
            setRestaurantInformation={setRestaurantInformation}
          />
        )}
        {step > 0 && (
          <div className="mx-auto mt-4 max-w-3xl px-4 text-xs sm:mt-8 sm:text-sm md:text-base">
            <p>
              {t("register.skipMessage")}
              <button
                className="p-2 text-xs font-bold text-vaccent sm:p-3 sm:text-sm md:text-base"
                onClick={() => setOpenConfirm(true)}
              >
                {t("register.skip")}
              </button>
            </p>
          </div>
        )}
      </div>
    </>
  );
}
