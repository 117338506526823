import { FakeResponseCollection } from "utils/fakeResponseCollection";

export const fake_remove_restaurant = new FakeResponseCollection();

fake_remove_restaurant.set({
  input: {
    id: "restaurant-1",
  },
  response: {
    data: {
      result: {
        ok: true,
      },
    },
  },
});

fake_remove_restaurant.set({
  input: {
    id: "restaurant-2",
  },
  response: {
    data: {
      result: {
        ok: true,
      },
    },
  },
});

fake_remove_restaurant.set({
  input: {
    id: "restaurant-3",
  },
  response: {
    data: {
      result: {
        ok: true,
      },
    },
  },
});
