import React from "react";
import { useTranslation } from "react-i18next";
import covidImg from "./img/covid.jpg";
import ecoCertifiedImg from "./img/ecoCertified.jpg";
import energyImg from "./img/energy.jpg";
import heroImg from "./img/hero.jpg";
import meatImg from "./img/meat.jpg";
import plantsImg from "./img/plants.jpg";
import plasticImg from "./img/plastic.jpg";
import produceImg from "./img/produce.jpg";
import renewableImg from "./img/renewable.jpg";
import seafoodImg from "./img/seafood.jpg";
import vegetarianImg from "./img/vegetarian.jpg";
import wasteImg from "./img/waste.jpg";
import waterImg from "./img/water.jpg";

export const Sustainability = () => {
  const { t } = useTranslation();
  return (
    <main>
      <HeroImage />
      <div className="flex flex-col gap-8 px-4 mx-auto max-w-7xl sm:gap-12 sm:px-8 md:gap-16">
        <div className="flex flex-col max-w-4xl gap-4 mx-auto">
          <h2 className="text-3xl sm:text-5xl">{t("sustainability.intro")}</h2>
          <p>{t("sustainability.introText")}</p>
        </div>
        <Block
          img={ecoCertifiedImg}
          header={t("sustainability.ecoCertifiedTitle")}
          text={t("sustainability.ecoCertifiedText")}
        />
        <Block
          img={covidImg}
          header={t("sustainability.covidConsciousTitle")}
          text={t("sustainability.covidConsciousText")}
          reverse={true}
        />
        <Block
          img={plantsImg}
          header={t("sustainability.plantBasedTitle")}
          text={t("sustainability.plantBasedText")}
        />
        <Block
          img={vegetarianImg}
          header={t("sustainability.vegetarianTitle")}
          text={t("sustainability.vegetarianText")}
          reverse={true}
        />
        <Block
          img={meatImg}
          header={t("sustainability.sustainableMeatTitle")}
          text={t("sustainability.sustainableMeatText")}
        />
        <Block
          img={seafoodImg}
          header={t("sustainability.sustainableSeafoodTitle")}
          text={t("sustainability.sustainableSeafoodText")}
          reverse={true}
        />
        <Block
          img={produceImg}
          header={t("sustainability.sustainableProduceTitle")}
          text={t("sustainability.sustainableProduceText")}
        />
        <Block
          img={wasteImg}
          header={t("sustainability.wasteConsciousTitle")}
          text={t("sustainability.wasteConsciousText")}
          reverse={true}
        />
        <Block
          img={plasticImg}
          header={t("sustainability.plasticConsciousTitle")}
          text={t("sustainability.plasticConsciousText")}
        />
        <Block
          img={renewableImg}
          header={t("sustainability.energyEfficientTitle")}
          text={t("sustainability.energyEfficientText")}
          reverse={true}
        />
        <Block
          img={energyImg}
          header={t("sustainability.renewableEnergyTitle")}
          text={t("sustainability.renewableEnergyText")}
        />
        <Block
          img={waterImg}
          header={t("sustainability.energyEfficientTitle")}
          text={t("sustainability.energyEfficientText")}
          reverse={true}
        />
      </div>
    </main>
  );
};

function HeroImage() {
  const { t } = useTranslation();
  return (
    <div className="relative isolate mb-8 h-64 sm:mb-12 sm:h-[500px] md:mb-16">
      <img src={heroImg} alt="" className="absolute object-cover h-full" />
      <div className="absolute w-full h-full bg-vdarkgreen opacity-60 " />
      <div className="absolute flex flex-col items-center justify-center w-full h-full gap-2 p-2 sm:gap-6 lg:gap-8">
        <p
          style={{ fontFamily: "DIN" }}
          className="max-w-sm text-4xl text-center text-white drop-shadow sm:max-w-xl sm:text-6xl md:text-7xl lg:max-w-5xl lg:text-8xl"
        >
          {t("sustainability.heroTitle")}
        </p>
      </div>
    </div>
  );
}

function Block({ reverse = false, img, header, text }) {
  return (
    <div
      className={`${
        reverse === true ? "sm:flex-row-reverse" : "sm:flex-row"
      } flex flex-col items-center justify-center sm:flex-row sm:gap-4 md:gap-8`}
    >
      <div className="flex-1 max-w-xs">
        <img src={img} className="object-contain w-full" alt="" />
      </div>
      <div className="flex flex-col flex-1 max-w-lg gap-2 sm:gap-4">
        <h2>{header}</h2>
        <p>{text}</p>
      </div>
    </div>
  );
}
