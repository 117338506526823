import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import ecoIcon from "assets/images/badges/eco.svg";
import covidIcon from "assets/images/badges/covid.svg";
import energyIcon from "assets/images/badges/energy.svg";
import meatIcon from "assets/images/badges/meat.svg";
import plantIcon from "assets/images/badges/plant.svg";
import plasticIcon from "assets/images/badges/plastic.svg";
import produceIcon from "assets/images/badges/produce.svg";
import renewableIcon from "assets/images/badges/renewable.svg";
import seafoodIcon from "assets/images/badges/seafood.svg";
import vegetarianIcon from "assets/images/badges/vegetarian.svg";
import wasteIcon from "assets/images/badges/waste.svg";
import waterIcon from "assets/images/badges/water.svg";
import plantBasedFriendlyIcon from "assets/images/badges/plantBasedFriendly.svg";
import plasticConsciousIcon from "assets/images/badges/plasticConscious.svg";
import vegetarianFriendlyIcon from "assets/images/badges/vegetarianFriendly.svg";
// import badgeIcon from "assets/images/badges/badge.svg";
import { Modal } from "components/Modal";
import {
  InputToggle,
  MultipleChoices,
  TextInput,
  TextInputSimple,
  SingleChoice,
} from "components/FormElements";
import Button from "components/Button";
import { yearIsValid } from "utils/dates";
import { Conditional } from "components/FormElements";
import { StepSubmitButtons } from "./StepSubmitButtons";

// #region option lists
const plantMenuIncludesOptionList = [
  "PLANTAPPETIZERS",
  "PLANTDESSERTS",
  "VEGANBEER",
  "VEGANWINE",
  "VEGANLIQUOR",
];
const ecoCertificationList = [
  "MICHELIN",
  "SLOWFOOD",
  "INTERECO",
  "BIOSPHERE",
  "ECOCOOK",
  "QUALITAT",
  "BREEAM",
  "LEED",
  "OTHERECOCERTIFICATION",
];
const covidOptionsList = [
  "COVIDLOCAL",
  "OPENAIR",
  "EMPLOYEETEMPERATURES",
  "HIGHERMASKS",
  "SANITIZER",
  "TOUCHLESS",
  "SIGNS",
];
const plasticUseOptionsList = [
  "PLASTICTABLEWARE",
  "PLASTICCUPS",
  "PLASTICSTRAWS",
  "PLASTICONTAINERS",
  "PLASTICBOTTLES",
];
const meatCertificationsList = [
  "MEATCERTIFICATIONEU",
  "MEATCERTIFICATIONOTHER",
];
const meatOptionsList = [
  "WILDGAME",
  "GRASSFED",
  "HORMONEFREE",
  "FREERANGECHICKEN",
];
const seafoodCertificationsOptionsList = [
  "SEAFOODCERTIFICATIONMSC",
  "SEAFOODCERTIFICATIONOTHER",
];
const growOptionsList = [
  "GROWONSITE",
  "GROWBACKYARD",
  "GROWROOFTOP",
  "GROWOFFSITE",
  "GROWVERTICAL",
  "GROWAQUAPONICS",
  "GROWHYDROPONICS",
  "GROWOTHER",
];
const energyOptions = [
  "GEOTHERMAL",
  "WIND",
  "SOLARPV",
  "SOLARTHERMAL",
  "HYDRO",
  "HYDROFUEL",
];
const efficiencyOptions = [
  "NOOVEN",
  "DISHWASHER",
  "REFRIGERATOR",
  "LED",
  "CONTROLLED",
];
const waterOptions = [
  "PRERINSE",
  "EFFICIENTDISHWASHER",
  "TABLECLOTHFREE",
  "DUALURINALS",
  "SINKSENSOR",
  "ECOPRODUCTS",
];
const percentagesOptionsList = ["0-25", "25-50", "50-75", "75-100"];
const recycleOptionsList = ["RECYCLEGLASS", "RECYCLEPAPER", "RECYCLEMETAL"];
const compostOptionsList = ["COMPOSTOWN", "COMPOSTCITY", "COMPOSTTRASH"];
// #endregion

export function RestaurantBadges({
  setStep,
  restaurantInformation,
  setRestaurantInformation,
}) {
  const { t } = useTranslation();
  const { sustainability, certifications } = restaurantInformation;
  const {
    COVICONS,
    ECOCERTI,
    ENEGEFFI,
    PLANFRIE,
    PLASCONS,
    RENWENEG,
    SUSTMEAT,
    SUSTPROD,
    SUSTSEAF,
    VEGEFRIE,
    WASTCONS,
    WATREFFI,
  } = certifications;

  const [isNextDisabled, setIsNextDisabled] = useState(true);
  const [open, setOpen] = useState(false);
  const [error, setError] = useState([]);
  const [currentBadge, setCurrentBadge] = useState();
  const [plantBased, setPlantBased] = useState(
    sustainability.includes("PLANFRIE")
      ? { ...PLANFRIE, sustainability: "PLANFRIE" }
      : null
  );
  const [eco, setEco] = useState(
    sustainability.includes("ECOCERTI")
      ? { ...ECOCERTI, sustainability: "ECOCERTI" }
      : null
  );
  const [vegetarian, setVegetarian] = useState(
    sustainability.includes("VEGEFRIE")
      ? { ...VEGEFRIE, sustainability: "VEGEFRIE" }
      : null
  );
  const [covid, setCovid] = useState(
    sustainability.includes("COVICONS")
      ? { ...COVICONS, sustainability: "COVICONS" }
      : null
  );
  const [waste, setWaste] = useState(
    sustainability.includes("WASTCONS")
      ? { ...WASTCONS, sustainability: "WASTCONS" }
      : null
  );
  const [plastic, setPlastic] = useState(
    sustainability.includes("PLASCONS")
      ? { ...PLASCONS, sustainability: "PLASCONS" }
      : null
  );
  const [meat, setMeat] = useState(
    sustainability.includes("SUSTMEAT")
      ? { ...SUSTMEAT, sustainability: "SUSTMEAT" }
      : null
  );
  const [seafood, setSeafood] = useState(
    sustainability.includes("SUSTSEAF")
      ? { ...SUSTSEAF, sustainability: "SUSTSEAF" }
      : null
  );
  const [produce, setProduce] = useState(
    sustainability.includes("SUSTPROD")
      ? { ...SUSTPROD, sustainability: "SUSTPROD" }
      : null
  );
  const [renewable, setRenewable] = useState(
    sustainability.includes("RENWENEG")
      ? { ...RENWENEG, sustainability: "RENWENEG" }
      : null
  );
  const [energy, setEnergy] = useState(
    sustainability.includes("ENEGEFFI")
      ? { ...ENEGEFFI, sustainability: "ENEGEFFI" }
      : null
  );
  const [water, setWater] = useState(
    sustainability.includes("WATREFFI")
      ? { ...WATREFFI, sustainability: "WATREFFI" }
      : null
  );

  useEffect(() => {
    if (
      plantBased === null &&
      eco === null &&
      vegetarian === null &&
      covid === null &&
      waste === null &&
      plastic === null &&
      meat === null &&
      seafood === null &&
      produce === null &&
      renewable === null &&
      energy === null &&
      water === null
    )
      return setIsNextDisabled(true);
    else return setIsNextDisabled(false);
  }, [
    plantBased,
    eco,
    vegetarian,
    covid,
    waste,
    plastic,
    meat,
    seafood,
    produce,
    renewable,
    energy,
    water,
  ]);

  function handleNext() {
    setError([]);
    if (
      (plantBased?.badge === "PLANTBASED" ||
        vegetarian?.badge === "VEGETARIAN") &&
      (meat !== null || seafood !== null)
    )
      return setError(["plantAnimalIncompatible"]);
    const badgesResults = [
      plantBased,
      eco,
      vegetarian,
      covid,
      waste,
      plastic,
      meat,
      seafood,
      produce,
      renewable,
      energy,
      water,
    ];
    let sustainabilityList = [];
    let updatedCertifications = {};
    for (const result of badgesResults) {
      if (result !== null) {
        const badge = result["sustainability"];
        updatedCertifications[badge] = {};
        for (const field in result) {
          if (field === "sustainability") {
            sustainabilityList.push(result[field]);
          } else {
            updatedCertifications[badge][field] = result[field];
          }
        }
      }
    }
    setRestaurantInformation({
      sustainability: sustainabilityList,
      certifications: updatedCertifications,
    });
    setStep((prev) => prev + 1);
  }

  return (
    <>
      <Modal open={open} setOpen={setOpen}>
        {currentBadge === "ECO" && (
          <EcoForm setOpen={setOpen} setEco={setEco} eco={eco} />
        )}
        {currentBadge === "PLANT" && (
          <PlantForm
            setOpen={setOpen}
            setPlantBased={setPlantBased}
            plantBased={plantBased}
          />
        )}
        {currentBadge === "VEGETARIAN" && (
          <VegetarianForm
            setOpen={setOpen}
            setVegetarian={setVegetarian}
            vegetarian={vegetarian}
          />
        )}
        {currentBadge === "COVID" && (
          <CovidForm setOpen={setOpen} setCovid={setCovid} covid={covid} />
        )}
        {currentBadge === "WASTE" && (
          <WasteForm setOpen={setOpen} setWaste={setWaste} waste={waste} />
        )}
        {currentBadge === "PLASTIC" && (
          <PlasticForm
            setOpen={setOpen}
            setPlastic={setPlastic}
            plastic={plastic}
          />
        )}
        {currentBadge === "MEAT" && (
          <MeatForm setOpen={setOpen} setMeat={setMeat} meat={meat} />
        )}
        {currentBadge === "SEAFOOD" && (
          <SeafoodForm
            setOpen={setOpen}
            setSeafood={setSeafood}
            seafood={seafood}
          />
        )}
        {currentBadge === "PRODUCE" && (
          <ProduceForm
            setOpen={setOpen}
            setProduce={setProduce}
            produce={produce}
          />
        )}
        {currentBadge === "RENEWABLE" && (
          <RenewableForm
            setOpen={setOpen}
            setRenewable={setRenewable}
            renewable={renewable}
          />
        )}
        {currentBadge === "ENERGY" && (
          <EnergyForm setOpen={setOpen} setEnergy={setEnergy} energy={energy} />
        )}
        {currentBadge === "WATER" && (
          <WaterForm setOpen={setOpen} setWater={setWater} water={water} />
        )}
      </Modal>
      <div className="px-4">
        <h2 className="m-4 text-center text-3xl sm:m-4 sm:text-5xl">
          {t("restaurantBadges.title")}
        </h2>
        <p className="mx-auto mt-1 mb-4 max-w-3xl text-sm text-gray-500 sm:mb-6 sm:text-base">
          {t("restaurantBadges.intro")}
        </p>
        <div className="flex flex-wrap justify-center gap-4 sm:gap-6">
          <BadgeBlock
            title={t("sustainability.ecoCertifiedTitle")}
            text={t("sustainability.ecoCertifiedText")}
            icon={ecoIcon}
            active={eco !== null}
            onApply={() => {
              setCurrentBadge("ECO");
              setOpen(true);
            }}
            onRemove={() => {
              setEco(null);
              setOpen(false);
            }}
          />
          <BadgeBlock
            title={
              plantBased?.badge === "PLANTFRIENDLY"
                ? t("sustainability.plantBasedFriendly")
                : t("sustainability.plantBased")
            }
            text={t("sustainability.plantBasedText")}
            icon={
              plantBased?.badge === "PLANTFRIENDLY"
                ? plantBasedFriendlyIcon
                : plantIcon
            }
            active={plantBased !== null}
            onApply={() => {
              setCurrentBadge("PLANT");
              setOpen(true);
            }}
            onRemove={() => {
              setPlantBased(null);
              setOpen(false);
            }}
          />
          <BadgeBlock
            title={
              vegetarian?.badge === "VEGETARIANFRIENDLY"
                ? t("sustainability.vegetarianFriendly")
                : t("sustainability.vegetarian")
            }
            text={t("sustainability.vegetarianText")}
            icon={
              vegetarian?.badge === "VEGETARIANFRIENDLY"
                ? vegetarianFriendlyIcon
                : vegetarianIcon
            }
            active={vegetarian !== null}
            onApply={() => {
              setCurrentBadge("VEGETARIAN");
              setOpen(true);
            }}
            onRemove={() => {
              setVegetarian(null);
              setOpen(false);
            }}
          />
          <BadgeBlock
            title={t("sustainability.covidConsciousTitle")}
            text={t("sustainability.covidConsciousText")}
            icon={covidIcon}
            active={covid !== null}
            onApply={() => {
              setCurrentBadge("COVID");
              setOpen(true);
            }}
            onRemove={() => {
              setCovid(null);
              setOpen(false);
            }}
          />
          <BadgeBlock
            title={t("sustainability.wasteConsciousTitle")}
            text={t("sustainability.wasteConsciousText")}
            icon={wasteIcon}
            active={waste !== null}
            onApply={() => {
              setCurrentBadge("WASTE");
              setOpen(true);
            }}
            onRemove={() => {
              setWaste(null);
              setOpen(false);
            }}
          />
          <BadgeBlock
            title={
              plastic?.badge === "plasticFree"
                ? t("sustainability.plasticFree")
                : t("sustainability.plasticConscious")
            }
            text={t("sustainability.plasticConsciousText")}
            icon={
              plastic?.badge === "plasticFree"
                ? plasticIcon
                : plasticConsciousIcon
            }
            active={plastic !== null}
            onApply={() => {
              setCurrentBadge("PLASTIC");
              setOpen(true);
            }}
            onRemove={() => {
              setPlastic(null);
              setOpen(false);
            }}
          />
          <BadgeBlock
            title={t("sustainability.sustainableMeatTitle")}
            text={t("sustainability.sustainableMeatText")}
            icon={meatIcon}
            active={meat !== null}
            onApply={() => {
              setCurrentBadge("MEAT");
              setOpen(true);
            }}
            onRemove={() => {
              setMeat(null);
              setOpen(false);
            }}
          />
          <BadgeBlock
            title={t("sustainability.sustainableSeafoodTitle")}
            text={t("sustainability.sustainableSeafoodText")}
            icon={seafoodIcon}
            active={seafood !== null}
            onApply={() => {
              setCurrentBadge("SEAFOOD");
              setOpen(true);
            }}
            onRemove={() => {
              setSeafood(null);
              setOpen(false);
            }}
          />
          <BadgeBlock
            title={t("sustainability.sustainableProduceTitle")}
            text={t("sustainability.sustainableProduceText")}
            icon={produceIcon}
            active={produce !== null}
            onApply={() => {
              setCurrentBadge("PRODUCE");
              setOpen(true);
            }}
            onRemove={() => {
              setProduce(null);
              setOpen(false);
            }}
          />
          <BadgeBlock
            title={t("sustainability.renewableEnergyTitle")}
            text={t("sustainability.renewableEnergyText")}
            icon={renewableIcon}
            active={renewable !== null}
            onApply={() => {
              setCurrentBadge("RENEWABLE");
              setOpen(true);
            }}
            onRemove={() => {
              setRenewable(null);
              setOpen(false);
            }}
          />
          <BadgeBlock
            title={t("sustainability.energyEfficientTitle")}
            text={t("sustainability.energyEfficientText")}
            icon={energyIcon}
            active={energy !== null}
            onApply={() => {
              setCurrentBadge("ENERGY");
              setOpen(true);
            }}
            onRemove={() => {
              setEnergy(null);
              setOpen(false);
            }}
          />
          <BadgeBlock
            title={t("sustainability.waterEfficientTitle")}
            text={t("sustainability.waterEfficientText")}
            icon={waterIcon}
            active={water !== null}
            onApply={() => {
              setCurrentBadge("WATER");
              setOpen(true);
            }}
            onRemove={() => {
              setWater(null);
              setOpen(false);
            }}
          />
        </div>
        <StepSubmitButtons
          isDisabled={isNextDisabled}
          // isLoading={isLoadingUpdateRestaurant}
          back={t("general.back")}
          next={t("general.next")}
          onNext={handleNext}
          error={error}
          onBack={() => setStep((prev) => prev - 1)}
        />
      </div>
    </>
  );
}

export function BadgeBlock({ title, text, icon, onApply, onRemove, active }) {
  const { t } = useTranslation();
  return (
    <div
      className={` ${
        active === true
          ? "border-green-500 bg-vgreen bg-opacity-40"
          : "bg-gray-100"
      } relative flex w-80 flex-col justify-between rounded-md border-2  p-2`}
    >
      {/* {active === true && (
        <img
          src={badgeIcon}
          className="absolute -top-2 -right-2 h-14 w-14 "
          alt=""
        />
      )} */}
      <div>
        <div className="flex items-center">
          <img src={icon} alt="" className="h-20 w-20" />
          <p className="text-lg font-semibold sm:text-lg">{title}</p>
        </div>
        <p className="mb-4 text-xs sm:text-sm">{text}</p>
      </div>
      {active === true ? (
        <div className="flex justify-between">
          <button
            onClick={onApply}
            className="text-sm font-semibold text-vaccent"
          >
            {t("restaurantBadges.edit")}
          </button>
          <button
            onClick={onRemove}
            className="text-sm font-semibold text-vaccent"
          >
            {t("restaurantBadges.remove")}
          </button>
        </div>
      ) : (
        <div className="flex w-full flex-row-reverse">
          <button
            className="text-sm font-semibold text-vaccent"
            onClick={onApply}
          >
            {t("restaurantBadges.apply")}
          </button>
        </div>
      )}
    </div>
  );
}

export function EcoForm({ setOpen, setEco, eco }) {
  const { t } = useTranslation();
  const isUpdating = eco !== null || false;
  const [ecoCertification, setEcoCertification] = useState(
    eco?.eco_certification || []
  );
  const [extraEcoCertification, setExtraEcoCertification] = useState(
    eco?.extra_eco_certification || ""
  );
  const [isValid, setIsValid] = useState(false);

  function handleAdd() {
    setEco({
      sustainability: "ECOCERTI",
      eco_certification: ecoCertification,
      extra_eco_certification: extraEcoCertification,
    });
    return setOpen(false);
  }

  React.useEffect(() => {
    // Clean useless answers
    if (ecoCertification.includes("OTHERECOCERTIFICATION") === false)
      setExtraEcoCertification("");
    // Validate
    if (
      ecoCertification.includes("OTHERECOCERTIFICATION") === true &&
      extraEcoCertification.length <= 1
    )
      return setIsValid(false);
    if (ecoCertification.length === 0) return setIsValid(false);
    return setIsValid(true);
  }, [setIsValid, extraEcoCertification, ecoCertification]);

  return (
    <div className="flex flex-col gap-2 sm:gap-4">
      <h2 className="w-full text-center text-4xl font-bold">
        {t("sustainability.ecoCertifiedTitle")}
      </h2>
      <p className="text-sm sm:text-base">{t("restaurantBadges.formIntro")}</p>
      <ul className="flex list-disc flex-col gap-2 pl-4">
        <li>
          <MultipleChoices
            intro={t("restaurantBadges.eco.certificationIntro")}
            optionsList={ecoCertificationList}
            selection={ecoCertification}
            setSelection={setEcoCertification}
            required={true}
            blockStyle="max-w-fit"
          />
        </li>
        {ecoCertification.indexOf("OTHERECOCERTIFICATION") > -1 && (
          <Conditional>
            <TextInput
              attributeName={extraEcoCertification}
              label={t("restaurantBadges.eco.extraEco")}
              value={extraEcoCertification}
              setValue={setExtraEcoCertification}
              borderTop={false}
              required={true}
            />
          </Conditional>
        )}
      </ul>
      <div className="mx-auto mt-5 flex flex-row justify-center gap-4 sm:mt-4 sm:gap-8">
        <Button variant="secondary" onClick={() => setOpen(false)}>
          {t("general.cancel")}
        </Button>
        <Button isDisabled={!isValid} onClick={handleAdd}>
          {isUpdating === true ? t("general.update") : t("general.add")}
        </Button>
      </div>
    </div>
  );
}

export function PlantForm({ setOpen, setPlantBased, plantBased }) {
  const { t } = useTranslation();
  const isUpdating = plantBased !== null || false;
  const [entirePlantBased, setEntirePlantBased] = useState(
    plantBased?.entire_plant_based || false
  );
  const [twoMainDishes, setTwoMainDishes] = useState(
    plantBased?.two_main_dishes || false
  );
  const [menuIncludes, setMenuIncludes] = useState(
    plantBased?.plant_menu_includes || []
  );
  const [isValid, setIsValid] = useState(true);

  function handleAdd() {
    let badge;
    if (twoMainDishes) {
      badge = "PLANTFRIENDLY";
    }
    if (entirePlantBased) badge = "PLANTBASED";
    setPlantBased({
      sustainability: "PLANFRIE",
      entire_plant_based: entirePlantBased,
      two_main_dishes: twoMainDishes,
      plant_menu_includes: menuIncludes,
      badge,
    });
    return setOpen(false);
  }

  React.useEffect(() => {
    if (entirePlantBased === true) return setIsValid(true);
    if (twoMainDishes === true) return setIsValid(true);
    else return setIsValid(false);
  }, [entirePlantBased, twoMainDishes, setIsValid]);

  return (
    <div className="flex flex-col gap-2 sm:gap-4">
      <h2 className="w-full text-center text-4xl font-bold">
        {t("sustainability.plantBasedTitle")}
      </h2>
      <p className="text-sm sm:text-base">{t("restaurantBadges.formIntro")}</p>
      <ul className="mt-2 flex list-disc flex-col gap-2 pl-4">
        <li>
          <InputToggle
            enabled={entirePlantBased}
            setEnabled={setEntirePlantBased}
            text={t("restaurantBadges.plant.entirePlantBased")}
          />
        </li>
        <li>
          <InputToggle
            enabled={twoMainDishes}
            setEnabled={setTwoMainDishes}
            text={t("restaurantBadges.plant.twoMainDishes")}
          />
        </li>
        <li>
          <MultipleChoices
            optionsList={plantMenuIncludesOptionList}
            selection={menuIncludes}
            setSelection={setMenuIncludes}
            intro={t("restaurantBadges.plant.menuIncludes")}
            blockStyle=" max-w-fit"
          />
        </li>
      </ul>
      <div className="mx-auto mt-5 flex flex-row justify-center gap-4 sm:mt-4 sm:gap-8">
        <Button
          variant="secondary"
          onClick={() => {
            setOpen(false);
          }}
        >
          {t("general.cancel")}
        </Button>
        <Button isDisabled={!isValid} onClick={handleAdd}>
          {isUpdating === true ? t("general.update") : t("general.add")}
        </Button>
      </div>
    </div>
  );
}

export function VegetarianForm({ setOpen, setVegetarian, vegetarian }) {
  const { t } = useTranslation();
  const isUpdating = vegetarian !== null || false;
  const [entirelyVegetarian, setEntirelyVegetarian] = useState(
    vegetarian?.entirely_vegetarian || false
  );
  const [twoMinimumVegetarianDishes, setTwoMinimumVegetarianDishes] = useState(
    vegetarian?.two_minimum_vegetarian_dishes || false
  );
  const [isValid, setIsValid] = useState(false);

  function handleAdd() {
    let badge;
    if (twoMinimumVegetarianDishes) badge = "VEGETARIANFRIENDLY";
    if (entirelyVegetarian) badge = "VEGETARIAN";
    setVegetarian({
      sustainability: "VEGEFRIE",
      entirely_vegetarian: entirelyVegetarian,
      two_minimum_vegetarian_dishes: twoMinimumVegetarianDishes,
      badge,
    });
    return setOpen(false);
  }

  React.useEffect(() => {
    if (entirelyVegetarian === true || twoMinimumVegetarianDishes === true)
      return setIsValid(true);
    return setIsValid(false);
  }, [entirelyVegetarian, twoMinimumVegetarianDishes, setIsValid]);

  return (
    <div className="flex flex-col gap-2 sm:gap-4">
      <h2 className="w-full text-center text-4xl font-bold">
        {t("sustainability.vegetarianTitle")}
      </h2>
      <p className="text-sm sm:text-base">{t("restaurantBadges.formIntro")}</p>
      <ul className="mt-2 flex list-disc flex-col gap-2 pl-4">
        <li>
          <InputToggle
            enabled={entirelyVegetarian}
            setEnabled={setEntirelyVegetarian}
            text={t("restaurantBadges.vegetarian.entirely")}
          />
        </li>
        <li>
          <InputToggle
            enabled={twoMinimumVegetarianDishes}
            setEnabled={setTwoMinimumVegetarianDishes}
            text={t("restaurantBadges.vegetarian.twoMainDishes")}
          />
        </li>
      </ul>
      <div className="mx-auto mt-5 flex flex-row justify-center gap-4 sm:mt-4 sm:gap-8">
        <Button
          variant="secondary"
          onClick={() => {
            setOpen(false);
          }}
        >
          {t("general.cancel")}
        </Button>
        <Button isDisabled={!isValid} onClick={handleAdd}>
          {isUpdating === true ? t("general.update") : t("general.add")}
        </Button>
      </div>
    </div>
  );
}

export function CovidForm({ setOpen, setCovid, covid }) {
  const { t } = useTranslation();
  const isUpdating = covid !== null || false;
  const [covidMeasures, setCovidMeasures] = useState(
    covid?.covid_measures || []
  );
  const [otherCovidMeasures, setOtherCovidMeasures] = useState(
    covid?.other_covid_measures || ""
  );
  const [isValid, setIsValid] = useState(false);

  function handleAdd() {
    setCovid({
      sustainability: "COVICONS",
      covid_measures: covidMeasures,
      other_covid_measures: otherCovidMeasures,
    });
    return setOpen(false);
  }

  React.useEffect(() => {
    if (covidMeasures.includes("COVIDLOCAL") && covidMeasures.length > 2)
      return setIsValid(true);
    else return setIsValid(false);
  }, [covidMeasures]);

  return (
    <div className="flex flex-col gap-2 sm:gap-4">
      <h2 className="w-full text-center text-4xl font-bold">
        {t("sustainability.covidConsciousTitle")}
      </h2>
      <p className="text-sm sm:text-base">{t("restaurantBadges.formIntro")}</p>
      <ul className="flex list-disc flex-col gap-2 pl-4 sm:gap-4">
        <li>
          <MultipleChoices
            intro={t("restaurantBadges.covid.measures")}
            optionsList={covidOptionsList}
            selection={covidMeasures}
            setSelection={setCovidMeasures}
            required={true}
          />
        </li>
        <li>
          <TextInputSimple
            label={t("restaurantBadges.covid.otherMeasures")}
            attributeName={otherCovidMeasures}
            value={otherCovidMeasures}
            setValue={setOtherCovidMeasures}
          />
        </li>
      </ul>
      <div className="mx-auto mt-5 flex flex-row justify-center gap-4 sm:mt-4 sm:gap-8">
        <Button
          variant="secondary"
          onClick={() => {
            setOpen(false);
          }}
        >
          {t("general.cancel")}
        </Button>
        <Button isDisabled={!isValid} onClick={handleAdd}>
          {isUpdating === true ? t("general.update") : t("general.add")}
        </Button>
      </div>
    </div>
  );
}

export function WasteForm({ setOpen, setWaste, waste }) {
  const { t } = useTranslation();
  const isUpdating = waste !== null || false;
  const [surplus, setSurplus] = useState(waste?.waste_sell_surplus || false);
  const [surplusDescription, setSurplusDescription] = useState(
    waste?.waste_surplus_description || ""
  );
  const [compost, setCompost] = useState(waste?.waste_compost || false);
  const [compostExplanation, setCompostExplanation] = useState(
    waste?.waste_compost_explanation || []
  );
  const [inventory, setInventory] = useState(waste?.waste_inventory || false);
  const [recycle, setRecycle] = useState(waste?.waste_recycle || []);
  const [train, setTrain] = useState(waste?.waste_train || false);
  const [containers, setContainers] = useState(
    waste?.waste_customer_containers || false
  );
  const [comments, setComments] = useState(waste?.waste_comments || "");
  const [isValid, setIsValid] = useState(false);

  function handleAdd() {
    setWaste({
      sustainability: "WASTCONS",
      waste_sell_surplus: surplus,
      waste_surplus_description: surplusDescription,
      waste_compost: compost,
      waste_compost_explanation: compostExplanation,
      waste_inventory: inventory,
      waste_recycle: recycle,
      waste_train: train,
      waste_customer_containers: containers,
      waste_comments: comments,
    });
    return setOpen(false);
  }

  React.useEffect(() => {
    // Clean useless values
    if (surplus === false) setSurplusDescription("");
    if (compost === false) setCompostExplanation("");
    // Validate badge
    let valuation = 0;
    if (surplus === true) {
      if (surplusDescription.length !== 0) valuation = valuation + 1;
    }
    if (compost === true) {
      if (
        compostExplanation.includes("COMPOSTTRASH") === false &&
        compostExplanation.length > 0
      )
        valuation = valuation + 1;
    }
    if (inventory === true) valuation = valuation + 1;
    if (recycle.length === 3) valuation = valuation + 1;
    if (train === true) valuation = valuation + 1;
    if (containers === true) valuation = valuation + 1;
    if (surplus === true && surplusDescription.length === 0)
      return setIsValid(false);
    if (compost === true && compostExplanation.length === 0)
      return setIsValid(false);
    if (valuation >= 2) return setIsValid(true);
    return setIsValid(false);
  }, [
    surplus,
    compost,
    inventory,
    recycle,
    train,
    containers,
    surplusDescription,
    compostExplanation,
  ]);

  return (
    <div className="flex flex-col gap-2 sm:gap-4">
      <h2 className="w-full text-center text-4xl font-bold">
        {t("sustainability.wasteConsciousTitle")}
      </h2>
      <p className="text-sm sm:text-base">{t("restaurantBadges.formIntro")}</p>
      <ul className="flex list-disc flex-col gap-2 pl-4 sm:gap-4">
        <li>
          <InputToggle
            enabled={surplus}
            setEnabled={setSurplus}
            text={t("restaurantBadges.waste.surplus")}
          />
          {surplus === true && (
            <Conditional>
              <TextInputSimple
                label={t("restaurantBadges.waste.surplusDescription")}
                value={surplusDescription}
                setValue={setSurplusDescription}
                required={true}
              />
            </Conditional>
          )}
        </li>
        <li>
          <InputToggle
            enabled={compost}
            setEnabled={setCompost}
            text={t("restaurantBadges.waste.compost")}
          />
          {compost === true && (
            <Conditional>
              <MultipleChoices
                intro={t("restaurantBadges.waste.compostExplanation")}
                optionsList={compostOptionsList}
                selection={compostExplanation}
                setSelection={setCompostExplanation}
                required={true}
              />
            </Conditional>
          )}
        </li>
        <li>
          <InputToggle
            enabled={inventory}
            setEnabled={setInventory}
            text={t("restaurantBadges.waste.inventory")}
          />
        </li>
        <li>
          <MultipleChoices
            intro={t("restaurantBadges.waste.recycle")}
            optionsList={recycleOptionsList}
            selection={recycle}
            setSelection={setRecycle}
            blockStyle="max-w-fit"
          />
        </li>
        <li>
          <InputToggle
            enabled={train}
            setEnabled={setTrain}
            text={t("restaurantBadges.waste.train")}
          />
        </li>
        <li>
          <InputToggle
            enabled={containers}
            setEnabled={setContainers}
            text={t("restaurantBadges.waste.containers")}
          />
        </li>
        <li>
          <TextInputSimple
            label={t("restaurantBadges.waste.comments")}
            value={comments}
            setValue={setComments}
          />
        </li>
      </ul>
      <div className="mx-auto mt-5 flex flex-row justify-center gap-4 sm:mt-4 sm:gap-8">
        <Button
          variant="secondary"
          onClick={() => {
            setOpen(false);
          }}
        >
          {t("general.cancel")}
        </Button>
        <Button isDisabled={!isValid} onClick={handleAdd}>
          {isUpdating === true ? t("general.update") : t("general.add")}
        </Button>
      </div>
    </div>
  );
}

export function PlasticForm({ setOpen, setPlastic, plastic }) {
  const { t } = useTranslation();
  const isUpdating = plastic !== null || false;
  const currentYear = new Date().getFullYear();
  const [plasticFree, setPlasticFree] = useState(false);
  const [plasticConscious, setPlasticConscious] = useState(false);
  const [hasCertification, setHasCertification] = useState(
    plastic?.has_plastic_free_certification || false
  );
  const [certificationName, setCertificationName] = useState(
    plastic?.plastic_free_certification_name || ""
  );
  const [certificationYear, setCertificationYear] = useState(
    plastic?.plastic_free_certification_year || ""
  );
  const [certificationDuration, setCertificationDuration] = useState(
    plastic?.plastic_free_certification_duration || ""
  );
  const [plasticUse, setPlasticUse] = useState(plastic?.plastic_use || []);
  const [comments, setComments] = useState(plastic?.plastic_comments || "");
  const [isValid, setIsValid] = useState(false);

  function handleAdd() {
    let badge;
    if (plasticConscious) badge = "PLASTICCONSCIOUS";
    if (plasticFree) badge = "PLASTICFREE";
    setPlastic({
      sustainability: "PLASCONS",
      has_plastic_free_certification: hasCertification,
      plastic_free_certification_name: certificationName,
      plastic_free_certification_year: parseInt(certificationYear) || null,
      plastic_free_certification_duration:
        parseInt(certificationDuration) || null,
      plastic_use: plasticUse,
      badge,
      plastic_comments: comments,
    });
    setOpen(false);
  }

  React.useEffect(() => {
    // Clean useless values
    if (hasCertification === false) {
      setCertificationName("");
      setCertificationYear("");
      setCertificationDuration("");
    }
    // Badge validation. The dates checking is quite trashy for now
    if (hasCertification === true) {
      if (
        certificationName.length > 0 &&
        yearIsValid(certificationYear) === true &&
        yearIsValid(certificationDuration) === true &&
        parseInt(certificationYear) <= currentYear &&
        parseInt(certificationDuration) >= currentYear
      ) {
        setPlasticFree(true);
      } else {
        setPlasticFree(false);
        return setIsValid(false);
      }
    } else setPlasticFree(false);

    if (plasticUse.length <= 1) {
      setPlasticConscious(true);
    } else {
      setPlasticConscious(false);
    }

    if (plasticFree === true || plasticConscious === true) {
      return setIsValid(true);
    } else {
      return setIsValid(false);
    }
  }, [
    hasCertification,
    certificationName,
    certificationYear,
    certificationDuration,
    plasticUse,
    currentYear,
    plasticFree,
    plasticConscious,
  ]);
  return (
    <div className="flex flex-col gap-2 sm:gap-4">
      <h2 className="w-full text-center text-4xl font-bold">
        {t("sustainability.plasticConsciousTitle")}
      </h2>
      <p className="text-sm sm:text-base">{t("restaurantBadges.formIntro")}</p>
      <ul className="flex list-disc flex-col gap-2 pl-4 sm:gap-4">
        <li>
          <InputToggle
            enabled={hasCertification}
            setEnabled={setHasCertification}
            text={t("restaurantBadges.plastic.hasCertification")}
          />
        </li>
        {hasCertification === true && (
          <Conditional>
            <TextInputSimple
              value={certificationName}
              setValue={setCertificationName}
              label={t("restaurantBadges.plastic.certificationName")}
              required={true}
              borderTop={false}
            />
            <TextInputSimple
              value={certificationYear}
              setValue={setCertificationYear}
              label={t("restaurantBadges.plastic.certificationYear")}
              required={true}
              borderTop={false}
              type="number"
              placeholder="2020"
            />
            <TextInputSimple
              value={certificationDuration}
              setValue={setCertificationDuration}
              label={t("restaurantBadges.plastic.certificationDuration")}
              required={true}
              borderTop={false}
              type="number"
              placeholder="2024"
            />
          </Conditional>
        )}
        <li>
          <MultipleChoices
            intro={t("restaurantBadges.plastic.plasticUse")}
            optionsList={plasticUseOptionsList}
            selection={plasticUse}
            setSelection={setPlasticUse}
          />
        </li>
        <li>
          <TextInputSimple
            label={t("restaurantBadges.plastic.comments")}
            value={comments}
            setValue={setComments}
          />
        </li>
      </ul>
      <div className="mx-auto mt-5 flex flex-row justify-center gap-4 sm:mt-4 sm:gap-8">
        <Button
          variant="secondary"
          onClick={() => {
            setOpen(false);
          }}
        >
          {t("general.cancel")}
        </Button>
        <Button isDisabled={!isValid} onClick={handleAdd}>
          {isUpdating === true ? t("general.update") : t("general.add")}
        </Button>
      </div>
    </div>
  );
}

export function MeatForm({ setOpen, setMeat, meat }) {
  const { t } = useTranslation();
  const isUpdating = meat !== null || false;
  const [hasCertification, setHasCertification] = useState(
    meat?.meat_has_certification || false
  );
  const [certificationType, setCertificationType] = useState(
    meat?.meat_certification_type || []
  );
  const [certificationName, setCertificationName] = useState(
    meat?.meat_certification_name || ""
  );
  const [meatType, setMeatType] = useState(meat?.meat_type || "");
  const [local, setLocal] = useState(meat?.meat_is_local || false);
  const [menu, setMenu] = useState(meat?.meat_menu || []);
  const [comments, setComments] = useState(meat?.meat_comments || "");
  const [isValid, setIsValid] = useState(false);

  function handleAdd() {
    setMeat({
      sustainability: "SUSTMEAT",
      meat_has_certification: hasCertification,
      meat_certification_type: certificationType,
      meat_certification_name: certificationName,
      meat_type: meatType,
      meat_is_local: local,
      meat_menu: menu,
      meat_comments: comments,
    });
    setOpen(false);
  }

  React.useEffect(() => {
    // Clean useless values
    if (hasCertification === false && certificationType.length > 0) {
      setCertificationType([]);
      setCertificationName("");
      setMeatType("");
    }
    // Badge validation
    if (
      certificationType.includes("MEATCERTIFICATIONOTHER") &&
      certificationName.length === 0
    )
      return setIsValid(false);
    if (
      hasCertification === true &&
      certificationType.length > 0 &&
      meatType.length > 0
    )
      return setIsValid(true);
    if (certificationType.length > 0 && meatType.length === 0)
      return setIsValid(false);
    if (local === true && menu.length > 0) return setIsValid(true);
    return setIsValid(false);
  }, [
    hasCertification,
    certificationType,
    certificationName,
    meatType,
    local,
    menu,
  ]);

  return (
    <div className="flex flex-col gap-2 sm:gap-4">
      <h2 className="w-full text-center text-4xl font-bold">
        {t("sustainability.sustainableMeatTitle")}
      </h2>
      <p className="text-sm sm:text-base">{t("restaurantBadges.formIntro")}</p>
      <ul className="flex list-disc flex-col gap-2 pl-4 sm:gap-4">
        <li>
          <InputToggle
            enabled={hasCertification}
            setEnabled={setHasCertification}
            text={t("restaurantBadges.meat.hasCertification")}
          />
        </li>
        {hasCertification === true && (
          <Conditional>
            <MultipleChoices
              intro={t("restaurantBadges.meat.certificationType")}
              optionsList={meatCertificationsList}
              selection={certificationType}
              setSelection={setCertificationType}
              blockStyle="max-w-fit"
            />
            {certificationType.includes("MEATCERTIFICATIONOTHER") === true && (
              <>
                <TextInputSimple
                  value={certificationName}
                  setValue={setCertificationName}
                  label={t("restaurantBadges.meat.certificationName")}
                  required={true}
                />
              </>
            )}
            <TextInputSimple
              value={meatType}
              setValue={setMeatType}
              label={t("restaurantBadges.meat.meatType")}
              required={true}
            />
          </Conditional>
        )}
        <li>
          <InputToggle
            enabled={local}
            setEnabled={setLocal}
            text={t("restaurantBadges.meat.local")}
          />
        </li>
        <li>
          <MultipleChoices
            intro={t("restaurantBadges.meat.menu")}
            optionsList={meatOptionsList}
            selection={menu}
            setSelection={setMenu}
            blockStyle="max-w-fit"
          />
        </li>
        <li>
          <TextInputSimple
            label={t("restaurantBadges.meat.comments")}
            value={comments}
            setValue={setComments}
          />
        </li>
      </ul>
      <div className="mx-auto mt-5 flex flex-row justify-center gap-4 sm:mt-4 sm:gap-8">
        <Button
          variant="secondary"
          onClick={() => {
            setOpen(false);
          }}
        >
          {t("general.cancel")}
        </Button>
        <Button isDisabled={!isValid} onClick={handleAdd}>
          {isUpdating === true ? t("general.update") : t("general.add")}
        </Button>
      </div>
    </div>
  );
}

export function SeafoodForm({ setOpen, setSeafood, seafood }) {
  const { t } = useTranslation();
  const isUpdating = seafood !== null || false;
  const [hasCertification, setHasCertification] = useState(
    seafood?.has_seafood_certification || false
  );
  const [certificationType, setCertificationType] = useState(
    seafood?.seafood_certification_type || []
  );
  const [certificationName, setCertificationName] = useState(
    seafood?.seafood_certification_name || ""
  );
  const [seafoodType, setSeafoodType] = useState(seafood?.seafood_type || "");
  const [local, setLocal] = useState(seafood?.seafood_is_local || false);
  const [comments, setComments] = useState(seafood?.seafood_comments || "");
  const [isValid, setIsValid] = useState(false);

  function handleAdd() {
    setSeafood({
      sustainability: "SUSTSEAF",
      has_seafood_certification: hasCertification,
      seafood_certification_type: certificationType,
      seafood_certification_name: certificationName,
      seafood_type: seafoodType,
      seafood_is_local: local,
      seafood_comments: comments,
    });
    return setOpen(false);
  }

  React.useEffect(() => {
    // Clean useless values
    if (hasCertification === false && certificationType.length > 0) {
      setCertificationType([]);
      setCertificationName("");
      setSeafoodType("");
    }
    // Badge validation
    if (
      certificationType.includes("SEAFOODCERTIFICATIONOTHER") &&
      certificationName.length === 0
    )
      return setIsValid(false);
    if (
      hasCertification === true &&
      certificationType.length > 0 &&
      seafoodType.length > 0
    )
      return setIsValid(true);
    if (certificationType.length > 0 && seafoodType.length === 0)
      return setIsValid(false);
    if (local === true) return setIsValid(true);
    return setIsValid(false);
  }, [
    hasCertification,
    certificationType,
    certificationName,
    seafoodType,
    local,
  ]);

  return (
    <div className="flex flex-col gap-2 sm:gap-4">
      <h2 className="w-full text-center text-4xl font-bold">
        {t("sustainability.sustainableSeafoodTitle")}
      </h2>
      <p className="text-sm sm:text-base">{t("restaurantBadges.formIntro")}</p>
      <ul className="flex list-disc flex-col gap-2 pl-4 sm:gap-4">
        <li>
          <InputToggle
            enabled={hasCertification}
            setEnabled={setHasCertification}
            text={t("restaurantBadges.seafood.hasCertification")}
          />
          {hasCertification === true && (
            <Conditional>
              <MultipleChoices
                intro={t("restaurantBadges.seafood.certificationType")}
                optionsList={seafoodCertificationsOptionsList}
                selection={certificationType}
                setSelection={setCertificationType}
                required={true}
              />
              {certificationType.includes("SEAFOODCERTIFICATIONOTHER") ===
                true && (
                <Conditional>
                  <TextInputSimple
                    label={t("restaurantBadges.seafood.certificationName")}
                    value={certificationName}
                    setValue={setCertificationName}
                    required={true}
                    borderTop={false}
                  />
                </Conditional>
              )}
              <TextInputSimple
                label={t("restaurantBadges.seafood.seafoodType")}
                value={seafoodType}
                setValue={setSeafoodType}
                required={true}
                borderTop={false}
              />
            </Conditional>
          )}
        </li>
        <li>
          <InputToggle
            enabled={local}
            setEnabled={setLocal}
            text={t("restaurantBadges.seafood.local")}
          />
        </li>
        <li>
          <TextInputSimple
            label={t("restaurantBadges.seafood.comments")}
            value={comments}
            setValue={setComments}
          />
        </li>
      </ul>
      <div className="mx-auto mt-5 flex flex-row justify-center gap-4 sm:mt-4 sm:gap-8">
        <Button
          variant="secondary"
          onClick={() => {
            setOpen(false);
          }}
        >
          {t("general.cancel")}
        </Button>
        <Button isDisabled={!isValid} onClick={handleAdd}>
          {isUpdating === true ? t("general.update") : t("general.add")}
        </Button>
      </div>
    </div>
  );
}

export function ProduceForm({ setOpen, setProduce, produce }) {
  const { t } = useTranslation();
  const isUpdating = produce !== null || false;
  const [isValid, setIsValid] = useState(false);
  const [grow, setGrow] = useState(produce?.produce_has_grow || false);
  const [growPercentage, setGrowPercentage] = useState(
    produce?.produce_grow_percentage || []
  );
  const [growType, setGrowType] = useState(produce?.produce_grow_type || []);
  const [otherGrowType, setOtherGrowType] = useState(
    produce?.produce_grow_other_type || ""
  );
  const [certified, setCertified] = useState(
    produce?.produce_is_eu_certified || false
  );
  const [organicPercentage, setOrganicPercentage] = useState(
    produce?.produce_organic_percentage || []
  );
  const [local, setLocal] = useState(produce?.produce_is_local || false);
  const [localPercentage, setLocalPercentage] = useState(
    produce?.produce_local_percentage || []
  );
  const [comments, setComments] = useState(produce?.produce_comments || "");

  function handleAdd() {
    setProduce({
      sustainability: "SUSTPROD",
      produce_has_grow: grow,
      produce_grow_percentage: growPercentage[0] || "",
      produce_grow_type: growType,
      produce_grow_other_type: otherGrowType,
      produce_is_eu_certified: certified,
      produce_organic_percentage: organicPercentage[0] || "",
      produce_is_local: local,
      produce_local_percentage: localPercentage[0] || "",
      produce_comments: comments,
    });
    return setOpen(false);
  }

  React.useEffect(() => {
    // Clean useless values
    if (growType.includes("GROWOTHER") === false && otherGrowType.length > 0)
      setOtherGrowType("");
    if (grow === false && (growPercentage.length > 0 || growType.length > 0)) {
      setGrowPercentage([]);
      setGrowType([]);
      setOtherGrowType("");
    }
    if (certified === false && organicPercentage.length > 0)
      setOrganicPercentage([]);
    if (local === false && localPercentage.length > 0) setLocalPercentage([]);
    // Badge validation
    let growValid = false;
    let certifiedValid = false;
    let localValid = false;
    if (grow === true)
      if (
        growPercentage.includes("0-25") === false &&
        growPercentage.length > 0 &&
        growType.length > 0
      ) {
        if (growType.includes("GROWOTHER") === true && otherGrowType.length > 0)
          growValid = true;
        if (growType.includes("GROWOTHER") === false) growValid = true;
      } else return setIsValid(false);

    if (certified === true) {
      if (
        organicPercentage.includes("50-75") === true ||
        organicPercentage.includes("75-100") === true
      ) {
        certifiedValid = true;
      } else return setIsValid(false);
    }

    if (local === true) {
      if (localPercentage.includes("75-100") === true) {
        localValid = true;
      } else return setIsValid(false);
    }

    if (growValid || certifiedValid || localValid) {
      return setIsValid(true);
    } else {
      return setIsValid(false);
    }
  }, [
    grow,
    growPercentage,
    growType,
    otherGrowType,
    certified,
    organicPercentage,
    local,
    localPercentage,
  ]);

  return (
    <div className="flex flex-col gap-2 sm:gap-4">
      <h2 className="w-full text-center text-4xl font-bold">
        {t("sustainability.sustainableProduceTitle")}
      </h2>
      <p className="text-sm sm:text-base">{t("restaurantBadges.formIntro")}</p>
      <ul className="flex list-disc flex-col gap-2 pl-4 sm:gap-4">
        <li>
          <InputToggle
            enabled={grow}
            setEnabled={setGrow}
            text={t("restaurantBadges.produce.grow")}
          />
          {grow === true && (
            <Conditional>
              <SingleChoice
                intro={t("restaurantBadges.produce.growPercentage")}
                optionsList={percentagesOptionsList}
                selection={growPercentage}
                setSelection={setGrowPercentage}
                required={true}
                blockStyle="max-w-fit"
              />
              <MultipleChoices
                intro={t("restaurantBadges.produce.growType")}
                optionsList={growOptionsList}
                selection={growType}
                setSelection={setGrowType}
                required={true}
                blockStyle="max-w-fit"
              />
              {growType.includes("GROWOTHER") === true && (
                <Conditional>
                  <TextInputSimple
                    label={t("restaurantBadges.produce.otherGrowType")}
                    value={otherGrowType}
                    setValue={setOtherGrowType}
                    required={true}
                  />
                </Conditional>
              )}
            </Conditional>
          )}
        </li>
        <li>
          <InputToggle
            enabled={certified}
            setEnabled={setCertified}
            text={t("restaurantBadges.produce.certified")}
          />
          {certified === true && (
            <Conditional>
              <SingleChoice
                intro={t("restaurantBadges.produce.organicPercentage")}
                optionsList={percentagesOptionsList}
                selection={organicPercentage}
                setSelection={setOrganicPercentage}
                required={true}
                blockStyle="max-w-fit"
              />
            </Conditional>
          )}
        </li>
        <li>
          <InputToggle
            enabled={local}
            setEnabled={setLocal}
            text={t("restaurantBadges.produce.local")}
          />
          {local === true && (
            <Conditional>
              <SingleChoice
                intro={t("restaurantBadges.produce.localPercentage")}
                optionsList={percentagesOptionsList}
                selection={localPercentage}
                setSelection={setLocalPercentage}
                required={true}
                blockStyle="max-w-fit"
              />
            </Conditional>
          )}
        </li>
        <li>
          <TextInputSimple
            label={t("restaurantBadges.produce.comments")}
            value={comments}
            setValue={setComments}
          />
        </li>
      </ul>
      <div className="mx-auto mt-5 flex flex-row justify-center gap-4 sm:mt-4 sm:gap-8">
        <Button
          variant="secondary"
          onClick={() => {
            setOpen(false);
          }}
        >
          {t("general.cancel")}
        </Button>
        <Button isDisabled={!isValid} onClick={handleAdd}>
          {isUpdating === true ? t("general.update") : t("general.add")}
        </Button>
      </div>
    </div>
  );
}

export function RenewableForm({ setOpen, setRenewable, renewable }) {
  const { t } = useTranslation();
  const currentYear = new Date().getFullYear();
  const isUpdating = renewable !== null || false;
  const [isValid, setIsValid] = useState(false);
  const [hasOwn, setHasOwn] = useState(renewable?.renewable_has_own || false);
  const [installation, setInstallation] = useState(
    renewable?.renewable_installation || []
  );
  const [consumptionPercentage, setConsumptionPercentage] = useState(
    renewable?.renewable_consumption_percentage || ""
  );
  const [start, setStart] = useState(renewable?.renewable_start || "");
  const [hasElectricity, setHasElectricity] = useState(
    renewable?.renewable_has_electricity || false
  );
  const [electricityProvider, setElectricityProvider] = useState(
    renewable?.renewable_electricity_provider || ""
  );
  const [hasGas, setHasGas] = useState(renewable?.renewable_has_gas || false);
  const [gasProvider, setGasProvider] = useState(
    renewable?.renewable_gas_provider || ""
  );
  const [comments, setComments] = useState(renewable?.renewable_comments || "");

  function handleAdd() {
    setRenewable({
      sustainability: "RENWENEG",
      renewable_has_own: hasOwn,
      renewable_installation: installation,
      renewable_consumption_percentage: parseInt(consumptionPercentage) || null,
      renewable_start: parseInt(start) || null,
      renewable_has_electricity: hasElectricity,
      renewable_electricity_provider: electricityProvider,
      renewable_has_gas: hasGas,
      renewable_gas_provider: gasProvider,
      renewable_comments: comments,
    });
    setOpen(false);
  }

  React.useEffect(() => {
    // Clean useless values
    if (
      hasOwn === false &&
      (installation.length > 0 ||
        consumptionPercentage.length > 0 ||
        start.length > 0)
    ) {
      setInstallation([]);
      setConsumptionPercentage("");
      setStart("");
    }
    if (hasElectricity === false && electricityProvider.length > 0) {
      setElectricityProvider("");
    }
    if (hasGas === false && gasProvider.length > 0) {
      setGasProvider("");
    }
    // Badge validation
    let ownValid = false;
    let electricityValid = false;
    let gasValid = false;

    if (hasOwn === true) {
      if (
        installation.length > 0 &&
        consumptionPercentage.length > 0 &&
        yearIsValid(start) === true &&
        parseInt(start) <= currentYear
      ) {
        ownValid = true;
      } else return setIsValid(false);
    }

    if (hasElectricity === true) {
      if (electricityProvider.length > 0) {
        electricityValid = true;
      } else return setIsValid(false);
    }

    if (hasGas === true) {
      if (gasProvider.length > 0) {
        gasValid = true;
      } else return setIsValid(false);
    }

    if (ownValid || electricityValid || gasValid) {
      return setIsValid(true);
    } else {
      return setIsValid(false);
    }
  }, [
    hasOwn,
    installation,
    consumptionPercentage,
    start,
    hasElectricity,
    electricityProvider,
    hasGas,
    gasProvider,
    currentYear,
  ]);

  return (
    <div className="flex flex-col gap-2 sm:gap-4">
      <h2 className="w-full text-center text-4xl font-bold">
        {t("sustainability.renewableEnergyTitle")}
      </h2>
      <p className="text-sm sm:text-base">{t("restaurantBadges.formIntro")}</p>
      <ul className="flex list-disc flex-col gap-2 pl-4 sm:gap-4">
        <li>
          <InputToggle
            enabled={hasOwn}
            setEnabled={setHasOwn}
            text={t("restaurantBadges.renewable.hasOwn")}
          />
          {hasOwn === true && (
            <Conditional>
              <MultipleChoices
                intro={t("restaurantBadges.renewable.installation")}
                optionsList={energyOptions}
                selection={installation}
                setSelection={setInstallation}
                required={true}
                blockStyle="max-w-fit"
              />
              <TextInputSimple
                label={t("restaurantBadges.renewable.consumptionPercentage")}
                value={consumptionPercentage}
                setValue={setConsumptionPercentage}
                required={true}
                placeholder="50 %"
                type="number"
                min={1}
                max={100}
              />
              <TextInputSimple
                label={t("restaurantBadges.renewable.start")}
                value={start}
                setValue={setStart}
                required={true}
                placeholder="2020"
                type="number"
              />
            </Conditional>
          )}
        </li>
        <li>
          <InputToggle
            enabled={hasElectricity}
            setEnabled={setHasElectricity}
            text={t("restaurantBadges.renewable.hasElectricity")}
          />
          {hasElectricity === true && (
            <Conditional>
              <TextInputSimple
                label={t("restaurantBadges.renewable.electricityProvider")}
                value={electricityProvider}
                setValue={setElectricityProvider}
                required={true}
              />
            </Conditional>
          )}
        </li>
        <li>
          <InputToggle
            enabled={hasGas}
            setEnabled={setHasGas}
            text={t("restaurantBadges.renewable.hasGas")}
          />
          {hasGas === true && (
            <Conditional>
              <TextInputSimple
                label={t("restaurantBadges.renewable.gasProvider")}
                value={gasProvider}
                setValue={setGasProvider}
                required={true}
              />
            </Conditional>
          )}
        </li>
        <li>
          <TextInputSimple
            label={t("restaurantBadges.renewable.comments")}
            value={comments}
            setValue={setComments}
          />
        </li>
      </ul>
      <div className="mx-auto mt-5 flex flex-row justify-center gap-4 sm:mt-4 sm:gap-8">
        <Button
          variant="secondary"
          onClick={() => {
            setOpen(false);
          }}
        >
          {t("general.cancel")}
        </Button>
        <Button isDisabled={!isValid} onClick={handleAdd}>
          {isUpdating === true ? t("general.update") : t("general.add")}
        </Button>
      </div>
    </div>
  );
}

export function EnergyForm({ setOpen, setEnergy, energy }) {
  const { t } = useTranslation();
  const isUpdating = energy !== null || false;
  const [isValid, setIsValid] = useState(false);
  const [practices, setPractices] = useState(energy?.energy_practices || []);
  const [comments, setComments] = useState(energy?.energy_comments || "");

  function handleAdd() {
    setEnergy({
      sustainability: "ENEGEFFI",
      energy_practices: practices,
      energy_comments: comments,
    });
    setOpen(false);
  }

  React.useEffect(() => {
    let validation = 0;
    if (practices.includes("NOOVEN")) validation = validation + 2;
    if (practices.includes("DISHWASHER")) validation = validation + 2;
    if (practices.includes("REFRIGERATOR")) validation = validation + 2;
    if (practices.includes("LED")) validation = validation + 1;
    if (practices.includes("CONTROLLED")) validation = validation + 1;
    if (validation >= 3) return setIsValid(true);
    else return setIsValid(false);
  }, [practices]);

  return (
    <div className="flex flex-col gap-2 sm:gap-4">
      <h2 className="w-full text-center text-4xl font-bold">
        {t("sustainability.energyEfficientTitle")}
      </h2>
      <p className="text-sm sm:text-base">{t("restaurantBadges.formIntro")}</p>
      <ul className="flex list-disc flex-col gap-2 pl-4 sm:gap-4">
        <li>
          <MultipleChoices
            intro={t("restaurantBadges.energy.practices")}
            optionsList={efficiencyOptions}
            selection={practices}
            setSelection={setPractices}
          />
        </li>
        <li>
          <TextInputSimple
            label={t("restaurantBadges.energy.comments")}
            value={comments}
            setValue={setComments}
          />
        </li>
      </ul>
      <div className="mx-auto mt-5 flex flex-row justify-center gap-4 sm:mt-4 sm:gap-8">
        <Button
          variant="secondary"
          onClick={() => {
            setOpen(false);
          }}
        >
          {t("general.cancel")}
        </Button>
        <Button isDisabled={!isValid} onClick={handleAdd}>
          {isUpdating === true ? t("general.update") : t("general.add")}
        </Button>
      </div>
    </div>
  );
}

export function WaterForm({ setOpen, setWater, water }) {
  const { t } = useTranslation();
  const isUpdating = water !== null || false;
  const [isValid, setIsValid] = useState(false);
  const [practices, setPractices] = useState(water?.water_practices || []);
  const [comments, setComments] = useState(water?.water_comments || "");

  function handleAdd() {
    setWater({
      sustainability: "WATREFFI",
      water_practices: practices,
      water_comments: comments,
    });
    setOpen(false);
  }

  React.useEffect(() => {
    let valoration = 0;
    if (practices.includes("PRERINSE")) valoration = valoration + 2;
    if (practices.includes("EFFICIENTDISHWASHER")) valoration = valoration + 2;
    if (practices.includes("TABLECLOTHFREE")) valoration = valoration + 1;
    if (practices.includes("DUALURINALS")) valoration = valoration + 1;
    if (practices.includes("SINKSENSOR")) valoration = valoration + 1;
    if (practices.includes("ECOPRODUCTS")) valoration = valoration + 1;
    if (valoration >= 3) return setIsValid(true);
    else return setIsValid(false);
  }, [practices]);

  return (
    <div className="flex flex-col gap-2 sm:gap-4">
      <h2 className="w-full text-center text-4xl font-bold">
        {t("sustainability.waterEfficientTitle")}
      </h2>
      <p className="text-sm sm:text-base">{t("restaurantBadges.formIntro")}</p>
      <ul className="flex list-disc flex-col gap-2 pl-4 sm:gap-4">
        <li>
          <MultipleChoices
            intro={t("restaurantBadges.water.practices")}
            optionsList={waterOptions}
            selection={practices}
            setSelection={setPractices}
            required={true}
          />
        </li>
        <li>
          <TextInputSimple
            label={t("restaurantBadges.water.comments")}
            value={comments}
            setValue={setComments}
          />
        </li>
      </ul>
      <div className="mx-auto mt-5 flex flex-row justify-center gap-4 sm:mt-4 sm:gap-8">
        <Button
          variant="secondary"
          onClick={() => {
            setOpen(false);
          }}
        >
          {t("general.cancel")}
        </Button>
        <Button isDisabled={!isValid} onClick={handleAdd}>
          {isUpdating === true ? t("general.update") : t("general.add")}
        </Button>
      </div>
    </div>
  );
}
