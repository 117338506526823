import { FakeResponseCollection } from "utils/fakeResponseCollection";
import { restaurantKeywords } from "data/restaurantKeywords";

export const fake_get_keywords = new FakeResponseCollection();

fake_get_keywords.set({
  input: {
    test: "RESPONSE",
  },
  response: {
    data: {
      result: {
        ok: true,
        payload: restaurantKeywords,
      },
    },
  },
});

fake_get_keywords.set({
  input: {
    test: "DENIED",
  },
  response: {
    data: {
      result: {
        ok: false,
      },
    },
  },
});
