// I use a different file from the one used in Auth because maybe we will need to make it more complex in the future
import React from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { ReactComponent as Hero } from "./hero.svg";
import LinkButton from "components/LinkButton";

export function SuccessDashboard() {
  const { t } = useTranslation();
  const params = useParams();

  return (
    <div className="max-w-2xl min-h-full mx-auto sm:mt-10">
      <h1 className="m-8 text-4xl font-extrabold text-center sm:text-6xl">
        {t(`success.${params.action}Title`)}
      </h1>
      <Hero className="m-10" />
      <p className="p-8 mx-auto text-center sm:text-lg">
        {t(`success.${params.action}Text`)}
      </p>
      <div className="flex justify-center">
        <LinkButton
          className="p-4 text-xl max-w-max sm:p-6 sm:text-2xl md:text-3xl "
          to="/dashboard"
        >
          {t("success.dashboardLink")}
        </LinkButton>
      </div>
    </div>
  );
}
