import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { UploadPhoto } from "components/FormElements";
import { StepSubmitButtons } from "./StepSubmitButtons";

export function RestaurantPhotos({
  setStep,
  restaurantInformation,
  setRestaurantInformation,
}) {
  const { t } = useTranslation();
  const { photos } = restaurantInformation;
  const [isNextDisabled, setIsNextDisabled] = useState(true);
  const [coverPhoto, setCoverPhoto] = useState(photos.cover);
  const [menuPhotos, setMenuPhotos] = useState(photos.menu);
  const [foodPhotos, setFoodPhotos] = useState(photos.food);
  const [ambiencePhotos, setAmbiencePhotos] = useState(photos.ambience);
  const [covidPhotos, setCovidPhotos] = useState(photos.covid);

  useEffect(() => {
    if (coverPhoto.length === 0) return setIsNextDisabled(true);
    else return setIsNextDisabled(false);
  }, [coverPhoto]);

  async function handleNext() {
    const value = {
      cover: coverPhoto,
      menu: menuPhotos,
      food: foodPhotos,
      ambience: ambiencePhotos,
      covid: covidPhotos,
    };
    setRestaurantInformation({ photos: value });
    setStep((prev) => prev + 1);
  }

  return (
    <div className="max-w-3xl px-4 mx-auto">
      <h2 className="m-4 text-3xl text-center sm:m-4 sm:text-5xl">
        {t("restaurantPhotos.title")}
      </h2>
      <p className="mt-1 mb-4 ">{t("restaurantPhotos.intro")}</p>
      <div className="flex flex-col gap-4">
        <UploadPhoto
          title={t("restaurantPhotos.coverTitle")}
          imageList={coverPhoto}
          setImageList={setCoverPhoto}
          max={1}
          required={true}
        />
        <UploadPhoto
          title={t("restaurantPhotos.menuTitle")}
          imageList={menuPhotos}
          setImageList={setMenuPhotos}
          max={10}
        />
        <UploadPhoto
          title={t("restaurantPhotos.foodTitle")}
          imageList={foodPhotos}
          setImageList={setFoodPhotos}
          max={10}
        />
        <UploadPhoto
          title={t("restaurantPhotos.ambienceTitle")}
          imageList={ambiencePhotos}
          setImageList={setAmbiencePhotos}
          max={10}
        />
        <UploadPhoto
          title={t("restaurantPhotos.covidTitle")}
          imageList={covidPhotos}
          setImageList={setCovidPhotos}
          max={10}
        />
      </div>
      <StepSubmitButtons
        isDisabled={isNextDisabled}
        back={t("general.back")}
        next={t("general.next")}
        onNext={handleNext}
        onBack={() => setStep((prev) => prev - 1)}
      />
    </div>
  );
}
