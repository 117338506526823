export function areEqual(item1, item2) {
  // console.log("Item to compare:", item1, item2);
  if (typeof item1 !== typeof item2) {
    // console.log({ item1 }, { item2 }, false);
    return false;
  }

  if (Array.isArray(item1) && Array.isArray(item2)) {
    if (arraysAreEqual(item1, item2) === false) {
      // console.log({ item1 }, { item2 }, arraysAreEqual(item1, item2));
      return false;
    }
  }

  if (
    typeof item1 === "string" ||
    typeof item1 === "number" ||
    typeof item1 === "boolean"
  )
    if (item1 !== item2) {
      // console.log({ item1, item2 });
      return false;
    }

  if (
    typeof item1 === "object" &&
    typeof item2 === "object" &&
    Array.isArray(item1) === false &&
    Array.isArray(item2) === false
  ) {
    for (const item in item2) {
      if (areEqual(item1[item], item2[item]) === false) {
        // console.log({ item1 }, { item2 });
        return false;
      }
    }
    for (const item in item1) {
      if (areEqual(item1[item], item2[item]) === false) {
        // console.log({ item1 }, { item2 });
        return false;
      }
    }
  }
  // console.log({ item1 }, { item2 }, "ARE IDENTICAL");
  return true;
}

//Checks if two arrays are equal even if the order is different
// TODO: I don't know if it works well with object elements
export function arraysAreEqual(array1, array2) {
  if (array1.length === array2.length) {
    return array1.every((element) => {
      if (array2.includes(element)) {
        return true;
      }

      return false;
    });
  }

  return false;
}
