export class FakeResponseCollection {
  constructor() {
    this.items = new Map()
  }

  set({ input, response }) {
    this.items.set(this.hash(input), response)
  }
  input
  get(input) {
    return this.items.get(this.hash(input))
  }

  hash(obj) {
    const sortedKeys = Object.keys(obj).sort()
    const sortedValues = sortedKeys.map((x) => obj[x])
    return sortedValues.join('~')
  }
}
