import React from "react";
import { Fragment, useEffect } from "react";
import { Dialog, Transition } from "@headlessui/react";
import LinkButton from "components/LinkButton";
import Button from "components/Button";
import { WaitlistForm } from "components/WaitlistForm";
import { useTranslation } from "react-i18next";
import { ReactComponent as Logo } from "./logo.svg";

export default function FindRestaurantsModal({ open, setOpen, target }) {
  const { t } = useTranslation();
  const [isContactVisible, setIsContactVisible] = React.useState(false);
  useEffect(() => {
    if (open === false) {
      setIsContactVisible(false);
    }
  }, [open]);
  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 z-30 overflow-y-auto"
        onClose={setOpen}
      >
        <div className="flex items-center justify-center min-h-screen px-4 pt-4 pb-20 text-center sm:block sm:items-end sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 transition-opacity bg-gray-300 bg-opacity-95 " />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden sm:inline-block sm:h-screen sm:align-middle"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div
              className={`${
                isContactVisible ? "translate-y-6 sm:max-w-4xl" : "sm:max-w-sm"
              }  inline-block transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left align-bottom shadow-xl transition-all sm:my-8 sm:w-full  sm:p-6 sm:align-middle`}
            >
              <div>
                <div className="flex items-center justify-center w-12 h-12 mx-auto rounded-full">
                  <Logo className="w-12 h-12" aria-hidden="true" />
                </div>
                {isContactVisible ? (
                  <WaitlistForm className="mt-6" target={target} />
                ) : (
                  <div className="mt-3 text-center sm:mt-5">
                    <Dialog.Title
                      as="h3"
                      className="text-4xl font-medium text-vdarkgreen"
                      style={{ fontFamily: "DIN" }}
                    >
                      {t("modal.title")}
                    </Dialog.Title>
                    <div className="flex mt-4 justify-evenly">
                      <LinkButton
                        to={
                          target === "restaurant"
                            ? t("nav.barcelonaPilot")
                            : t("nav.restaurants")
                        }
                        className="flex-1 m-2 sm:m-4"
                        onClick={() => setOpen(false)}
                      >
                        {t("modal.barcelona")}
                      </LinkButton>
                      <Button
                        className="flex-1 m-2 sm:m-4"
                        onClick={() => setIsContactVisible(true)}
                      >
                        {t("modal.other")}
                      </Button>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
