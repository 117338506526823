import api from "API";
import { v4 as uuid } from "uuid";

export async function uploadPhotos(photos) {
  const urls = Promise.all(
    photos.map(async (photo) => {
      if (!photo.raw) return photo.preview;
      const res = await api.images.getUploadUrl("uploads", uuid());
      if (!res.ok) {
        return null;
      }

      const presignedPost = res.payload;
      const formData = new FormData();
      Object.entries(presignedPost.fields).forEach(([k, v]) => {
        formData.append(k, v);
      });
      formData.append("file", photo.raw);
      try {
        await api.images.uploadFile(formData, presignedPost.url);
        return presignedPost.file_url;
      } catch (e) {
        throw new Error(e);
      }
    })
  );
  return urls;
}

export async function getPhotosUrls(restaurantInformation) {
  const { cover, menu, food, ambience, covid } = restaurantInformation.photos;
  const coverUrls = await uploadPhotos(cover);
  const menuUrls = await uploadPhotos(menu);
  const foodUrls = await uploadPhotos(food);
  const ambienceUrls = await uploadPhotos(ambience);
  const covidUrls = await uploadPhotos(covid);
  const photosInput = {
    cover: coverUrls[0],
    menu: menuUrls,
    food: foodUrls,
    ambience: ambienceUrls,
    covid: covidUrls,
  };
  return photosInput;
}
