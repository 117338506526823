import { FakeResponseCollection } from "utils/fakeResponseCollection";

export const fake_resetPassword = new FakeResponseCollection();

fake_resetPassword.set({
  input: {
    token: "TOKEN",
    password: "Password123",
  },
  response: {
    data: {
      result: {
        ok: true,
      },
    },
  },
});

fake_resetPassword.set({
  input: {
    token: "TOKEN-FAIL",
    password: "Password123",
  },
  response: {
    data: {
      result: {
        ok: false,
      },
    },
  },
});
