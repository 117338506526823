import api from "API";
import { useMutation } from "react-query";

export function useSendContactEmail() {
  const { mutate, isLoading, error, isError, isSuccess } = useMutation(
    (input) => {
      return api.contact.sendContactEmail(input);
    }
  );
  return { mutate, isLoading, error, isError, isSuccess };
}

export function useAddUserToWaitlist() {
  const { mutate, isLoading, error, isError, isSuccess } = useMutation(
    (input) => {
      return api.contact.addToUserWaitlist(input);
    }
  );
  return { mutate, isLoading, error, isError, isSuccess };
}

export function useAddRestaurantToWaitlist() {
  const { mutate, isLoading, error, isError, isSuccess } = useMutation(
    (input) => {
      return api.contact.addToRestaurantWaitlist(input);
    }
  );
  return { mutate, isLoading, error, isError, isSuccess };
}
