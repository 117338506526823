import React, { useState, useEffect } from "react";
import isEmail from "validator/lib/isEmail";
import { useTranslation } from "react-i18next";
import { useSendContactEmail } from "hooks/contactHooks";
import { SuccessBlock } from "components/successBlock";
import { ErrorBlock } from "components/ErrorBlock";
import Button from "components/Button";

export const ContactUs = () => {
  const { t } = useTranslation();
  const { mutate: sendContactEmail, isLoading } = useSendContactEmail();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [isDisabled, setIsDisabled] = useState(true);
  const [errors, setErrors] = useState([]);
  const [success, setSuccess] = useState([]);

  useEffect(() => {
    if (name.length > 0 && email.length > 0 && message.length > 0)
      setIsDisabled(false);
    else setIsDisabled(true);
  }, [name, email, message]);

  function handleSendEmail() {
    setSuccess([]);
    setErrors([]);
    if (!isEmail(email)) return setErrors(["invalidEmailError"]);
    sendContactEmail(
      { name, email, message },
      {
        onError: () => {
          return setErrors(["unknownError"]);
        },
        onSuccess: (data) => {
          if (data.ok === false) return setErrors(["unknownError"]);
          if (data.ok === true) return setSuccess(["contactEmailSent"]);
        },
      }
    );
  }

  return (
    <main className="flex flex-col max-w-4xl gap-4 px-4 mx-auto mt-4 px- sm:gap-8 sm:p-8 sm:px-8">
      <h1
        className="text-4xl text-center sm:text-5xl"
        style={{ fontFamily: "DIN" }}
      >
        {t("contact.title")}
      </h1>
      <p>{t("contact.intro")}</p>
      <p>{t("contact.sendMail")}</p>
      <div className="flex flex-col self-center w-full max-w-3xl gap-4 mt-4 sm:mt-8 sm:gap-8">
        <LabelInput
          labelText={t("contact.nameLabel")}
          placeholder={t("contact.namePlaceholder")}
          value={name}
          onChange={(e) => setName(e.target.value)}
          name="name"
          required={true}
        />
        <LabelInput
          labelText={t("contact.emailLabel")}
          placeholder={t("contact.emailPlaceholder")}
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          name="email"
          type="email"
          required={true}
        />
        <LabelInput
          labelText={t("contact.message")}
          name="message"
          type="textarea"
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          required={true}
          className="h-60"
        />
        <Button
          isLoading={isLoading}
          isDisabled={isDisabled}
          className="self-center max-w-fit"
          onClick={handleSendEmail}
        >
          {t("contact.submit")}
        </Button>
        {errors.length > 0 && <ErrorBlock errorList={errors} />}
        {success.length > 0 && <SuccessBlock successList={success} />}
      </div>
    </main>
  );
};

function LabelInput({
  labelText,
  placeholder = "",
  type = "text",
  name,
  required = false,
  className,
  value,
  onChange,
}) {
  return (
    <div className="flex-1">
      <label
        htmlFor={name}
        className="block text-3xl font-medium text-vdarkgreen"
        style={{ fontFamily: "DIN" }}
      >
        {`${labelText} ${required === true ? "*" : ""}`}
      </label>
      <div className="mt-1">
        {type === "textarea" ? (
          <textarea
            type={type}
            name={name}
            id={name}
            className={`${className} block w-full rounded-md border-gray-300 shadow-sm sm:text-sm`}
            placeholder={placeholder}
            value={value}
            onChange={onChange}
            required={required}
            style={{ fontFamily: "Poppins" }}
          />
        ) : (
          <input
            type={type}
            name={name}
            id={name}
            value={value}
            onChange={onChange}
            className={`${className} block w-full rounded-md border-gray-300 shadow-sm sm:text-sm`}
            placeholder={placeholder}
            required={required}
            style={{ fontFamily: "Poppins" }}
          />
        )}
      </div>
    </div>
  );
}
