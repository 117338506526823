import React from "react";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import { Audio, TailSpin } from "react-loader-spinner";

export function FullPageLoader() {
  return (
    <div className="flex items-center justify-center min-h-screen ">
      <TailSpin color="#354D5A" />
    </div>
  );
}
export function AudioLoader() {
  return <Audio height="100" width="100" color="grey" ariaLabel="loading" />;
}
