import axios from "axios";
import { apiConfig } from "./apiConfig";
import { fakeWith } from "utils/fakeQuery";
import { fake_send_contact_email } from "fakeData/fake_send_contact_email";
import { fake_add_user_to_waitlist } from "fakeData/fake_add_user_to_waitlist";
import { fake_add_restaurant_to_waitlist } from "fakeData/fake_add_restauranto_to_waitlist";

const contact = function(api) {
  async function sendContactEmail({ name, email, message }) {
    return axios
      .post(`${apiConfig.hostUrl}/contact`, { name, email, message })
      .then((response) => {
        console.log({ response });
        return { ok: true };
      })
      .catch((error) => {
        console.log({ error });
        return { ok: false };
      });
  }

  async function addToUserWaitlist({ name, city, email }) {
    const input = { name, city, email }
    const query = `mutation ($input: Any) { result: users__add_to_wait_list(input: $input)
      {ok payload errors}}`;
    const data = await api.gql(query, { input });
    return data;
  }

  async function addToRestaurantWaitlist({
    restaurant_name,
    city,
    email,
    contact_name,
    contact_position,
  }) {
    const input = { restaurant_name, city, email, contact_name, contact_position }
    const query = `mutation ($input: Any) { result: restaurants__add_to_wait_list(input: $input)
      {ok payload errors}}`;
    const data = await api.gql(query, { input });
    return data;
  }

  // SIMULATION
  const simulate = false;
  const _sendContactEmail = fakeWith(
    fake_send_contact_email,
    sendContactEmail,
    simulate
  );
  // const _addToUserWaitlist = fakeWith(
  //   fake_add_user_to_waitlist,
  //   addToUserWaitlist,
  //   simulate
  // );
  // const _addToRestaurantWaitlist = fakeWith(
  //   fake_add_restaurant_to_waitlist,
  //   addToRestaurantWaitlist,
  //   simulate
  // );

  return {
    sendContactEmail: _sendContactEmail || sendContactEmail,
    addToUserWaitlist, // _addToUserWaitlist || addToUserWaitlist,
    addToRestaurantWaitlist, // _addToRestaurantWaitlist || addToRestaurantWaitlist,
  };
};

export default contact;
