import { FakeResponseCollection } from "utils/fakeResponseCollection";
import { fake_token } from "./fake_token";

export const fake_activate_email = new FakeResponseCollection();

fake_activate_email.set({
  input: {
    token: "VALID-TOKEN",
  },
  response: {
    data: {
      result: {
        ok: true,
      },
    },
  },
});

fake_activate_email.set({
  input: {
    token: "BAD-TOKEN",
  },
  response: {
    data: {
      result: {
        ok: false,
      },
    },
  },
});

// TODO by Backend: See if this response is right
fake_activate_email.set({
  input: {
    token: fake_token,
  },
  response: {
    data: {
      result: {
        ok: false,
        errors: {
          token: { error_code: "TOKEN-EXPIRED" },
        },
      },
    },
  },
});
