import React from "react";
import { MarkdownPage } from "components/MarkdownPage";
import { useTranslation } from "react-i18next";
import en from "./markdown/en.md";
import es from "./markdown/es.md";

export default function PrivacyPolicy() {
  const { i18n } = useTranslation();
  if (i18n.language === "es") return <MarkdownPage url={es} />;
  else return <MarkdownPage url={en} />;
}
