import React from "react";
import { useTranslation } from "react-i18next";
import { Link, useLocation } from "react-router-dom";
import { useAuth } from "context/AuthProvider";
import { navigation } from "routes";
import verdantipsLogo from "./img/verdantipsLogo.svg";
import verdantipsMiniLogo from "./img/verdantipsMiniLogo.svg";
import Button from "components/Button";
import { Fragment } from "react";
import { Disclosure, Menu, Transition } from "@headlessui/react";
import { MenuIcon, XIcon, GlobeIcon, UserIcon } from "@heroicons/react/outline";
import FindRestaurantsModal from "components/FindRestaurantsModal";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export const Header = () => {
  const { t } = useTranslation();
  const [modalIsOpen, setModalIsOpen] = React.useState(false);
  const location = useLocation();
  return (
    <Disclosure as="nav" className="relative isolate z-20 bg-white">
      {({ open }) => (
        <>
          <div className="mx-auto max-w-7xl px-2 sm:px-6 lg:px-8 xs:px-4">
            <div className="flex h-16 flex-1 justify-between">
              <div className="z-0 flex">
                <Logo />
                <Navigation location={location} />
              </div>
              <div className="flex items-center justify-end gap-2 sm:gap-4">
                <Button
                  className="max-w-min px-1 sm:max-w-full sm:px-2"
                  onClick={() => setModalIsOpen(true)}
                >
                  {t("header.findRestaurants")}
                </Button>
                <ProfileDropdDown />
                <MobileMenuButton open={open} />
                <LanguageMenu />
              </div>
              <FindRestaurantsModal
                open={modalIsOpen}
                setOpen={setModalIsOpen}
                target="user"
              />
            </div>
          </div>
          <MobileMenu location={location} />
        </>
      )}
    </Disclosure>
  );
};

function Logo() {
  return (
    <div className="flex flex-shrink-0 items-center">
      <Link to="/">
        <img
          className="hidden h-12 w-auto xs:block"
          src={verdantipsLogo}
          alt="Verdantips"
        />
        <img
          className="h-12 w-auto xs:hidden"
          src={verdantipsMiniLogo}
          alt="Verdantips"
        />
      </Link>
    </div>
  );
}

function Navigation({ location }) {
  const { i18n } = useTranslation();
  const language = i18n.resolvedLanguage;
  return (
    <div className="hidden sm:ml-6 sm:space-x-4 lg:flex">
      {navigation[language].map((item) => {
        if (!item.dropdown) {
          return (
            <Link
              key={item.name}
              to={item.to}
              className={classNames(
                item.to === location.pathname
                  ? "border-green-500 font-medium text-green-600"
                  : "border-transparent text-vdarkgreen hover:border-gray-300",
                "inline-flex items-center border-b-4 px-1 pt-1 text-sm font-normal "
              )}
              style={{ fontFamily: "Poppins" }}
              aria-current={item.current ? "page" : undefined}
            >
              {item.name}
            </Link>
          );
        }
        if (item.dropdown) {
          return (
            <LinkDropdown
              item={item}
              key={item.name}
              location={location}
              dropdownItems={item.dropdown}
            />
          );
        } else return undefined;
      })}
    </div>
  );
}

function ProfileDropdDown() {
  const { t } = useTranslation();
  const { user, signOut } = useAuth();
  return (
    <div className="hidden text-vdarkgreen sm:items-center lg:flex">
      {user !== null ? (
        <Menu as="div" className="relative ml-3">
          <Menu.Button className="flex bg-white text-sm ">
            <span className="sr-only">Open user menu</span>
            <UserIcon className="h-8 w-8 stroke-current" />
          </Menu.Button>
          <Transition
            as={Fragment}
            enter="transition ease-out duration-200"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items className="absolute right-0 mt-3 w-40 origin-top-right rounded-md border bg-white py-1 shadow-lg">
              <Menu.Item>
                {({ active }) => (
                  <Link
                    to="/dashboard"
                    className={classNames(
                      active ? "bg-gray-100" : "",
                      "block px-4 py-2 text-sm text-vdarkgreen"
                    )}
                    style={{ fontFamily: "Poppins" }}
                  >
                    {t("header.dashboard")}
                  </Link>
                )}
              </Menu.Item>
              <Menu.Item>
                <button
                  onClick={() => signOut()}
                  className="block px-4 py-2 text-sm text-vdarkgreen"
                  style={{ fontFamily: "Poppins" }}
                >
                  {t("header.signOut")}
                </button>
              </Menu.Item>
            </Menu.Items>
          </Transition>
        </Menu>
      ) : (
        <Link
          to="login"
          className="text-base font-medium text-vdarkgreen"
          style={{ fontFamily: "Poppins" }}
        >
          {t("header.login")}
        </Link>
      )}
    </div>
  );
}

function LanguageMenu() {
  const { i18n } = useTranslation();
  return (
    <div className="flex items-center">
      <Menu as="div" className="relative">
        <div>
          <Menu.Button className="flex items-center text-vdarkgreen">
            <GlobeIcon className="h-5 w-5 stroke-current" aria-hidden="true" />
            <p
              style={{ fontFamily: "Poppins" }}
              className="font-regular text-sm text-vdarkgreen"
            >
              {i18n.resolvedLanguage.toUpperCase()}
            </p>
          </Menu.Button>
        </div>
        <Transition
          as={Fragment}
          enter="transition ease-out duration-200"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="absolute right-0 mt-4 w-10 origin-top-right rounded-md border bg-white py-1 text-center shadow-lg">
            <Menu.Item>
              <button
                style={{ fontFamily: "Poppins" }}
                className="font-regular p-1 text-sm text-vdarkgreen"
                onClick={() =>
                  i18n.changeLanguage(
                    i18n.resolvedLanguage === "es" ? "en" : "es"
                  )
                }
              >
                {i18n.resolvedLanguage.toUpperCase() === "ES" ? "EN" : "ES"}
              </button>
            </Menu.Item>
          </Menu.Items>
        </Transition>
      </Menu>
    </div>
  );
}

function MobileMenuButton({ open }) {
  return (
    <div className="-mr-2 flex items-center lg:hidden">
      <Disclosure.Button className="inline-flex items-center justify-center p-2 text-vdarkgreen">
        <span className="sr-only">Open main menu</span>
        {open ? (
          <XIcon className="block h-8 w-8" aria-hidden="true" />
        ) : (
          <MenuIcon className="block h-8 w-8" aria-hidden="true" />
        )}
      </Disclosure.Button>
    </div>
  );
}

function MobileMenu({ location }) {
  const { i18n } = useTranslation();
  const { user, signOut } = useAuth();
  const language = i18n.resolvedLanguage;
  const mobileLinks = [];
  // eslint-disable-next-line
  for (const [key, item] of Object.entries(navigation[language])) {
    const { name, to, dropdown } = { ...item };
    mobileLinks.push({ name, to });
    if (dropdown) {
      for (const subItem of dropdown) {
        const { name, to } = subItem;
        mobileLinks.push({ name, to });
      }
    }
  }

  return (
    <Disclosure.Panel className="lg:hidden" style={{ fontFamily: "Poppins" }}>
      <div className="space-y-1 pt-2 pb-3">
        {mobileLinks.map((item) => {
          return (
            <Disclosure.Button
              as={Link}
              key={item.name}
              to={item.to}
              className={classNames(
                item.to === location.pathname
                  ? "border-l-4 border-vdarkgreen bg-vgreen font-medium "
                  : "border-transparent text-vdarkgreen hover:border-gray-300",
                "font-regular block border-transparent py-2 pl-3 pr-4 text-base text-vdarkgreen"
              )}
              style={{ fontFamily: "Poppins" }}
              aria-current={item.current ? "page" : undefined}
            >
              {item.name}
            </Disclosure.Button>
          );
        })}
      </div>

      <div className="border-t border-gray-200 pt-4 pb-3">
        <div className="space-y-1">
          {user !== null ? (
            <>
              <Disclosure.Button
                as={Link}
                to="/dashboard"
                className="font-regular block px-4 py-2 text-base text-vdarkgreen"
              >
                {i18n.t("header.dashboard")}
              </Disclosure.Button>
              <button
                onClick={() => signOut()}
                className="font-regular block px-4 py-2 text-base text-vdarkgreen"
              >
                {i18n.t("header.signOut")}
              </button>
            </>
          ) : (
            <Disclosure.Button
              as={Link}
              to="/login"
              className="font-regular block px-4 py-2 text-base text-vdarkgreen"
            >
              {i18n.t("header.login")}
            </Disclosure.Button>
          )}
        </div>
      </div>
    </Disclosure.Panel>
  );
}

function LinkDropdown({ item, dropdownItems, location }) {
  // There will be some problems with interaction in mobile, but if the client wants an on hover dropdown this is hard to fix
  const [visible, setVisible] = React.useState(false);
  return (
    <div
      className={classNames(
        item.to === location.pathname
          ? "border-green-500 font-medium text-green-600"
          : "border-transparent text-vdarkgreen hover:border-gray-300",
        "font-regular hidden items-center border-b-4 px-1 pt-1 text-sm text-vdarkgreen hover:cursor-pointer sm:items-center lg:flex "
      )}
      onMouseEnter={() => setVisible(true)}
      onMouseLeave={() => setVisible(false)}
      onMouseOver={() => setVisible(true)}
    >
      <div className="relative">
        <div className="flex bg-white text-sm ">
          <Link
            key={item.name}
            to={item.to}
            style={{ fontFamily: "Poppins" }}
            aria-current={item.current ? "page" : undefined}
          >
            {item.name}
          </Link>
        </div>
        <Transition
          show={visible}
          enter="transition ease-out duration-200"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <div
            onMouseEnter={() => setVisible(true)}
            onMouseLeave={() => setVisible(false)}
            className="absolute right-0 mt-3 w-40 origin-top-right rounded-md border bg-white py-1 shadow-lg"
          >
            <div>
              {dropdownItems.map((link) => {
                return (
                  <Link
                    key={link.name}
                    to={link.to}
                    className="block px-4 py-2 text-sm font-normal text-vdarkgreen hover:bg-gray-100"
                    style={{ fontFamily: "Poppins" }}
                  >
                    {link.name}
                  </Link>
                );
              })}
            </div>
          </div>
        </Transition>
      </div>
    </div>
  );
}
