import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import heroImg from "./img/hero.jpg";
import mobileImg from "./img/mobile.jpg";
import { CheckIcon } from "@heroicons/react/outline";
import LinkButton from "components/LinkButton";

export const BarcelonaPilot = () => {
  const { t } = useTranslation();
  return (
    <main>
      <HeroImage />
      <div className="flex flex-col gap-8 px-4 mx-auto max-w-7xl sm:gap-12 sm:px-8 md:gap-16">
        <p className="max-w-4xl mx-auto">{t("barcelonaPilot.heroText")}</p>
        <div className="flex flex-col max-w-4xl gap-4 mx-auto">
          <h2>{t("barcelonaPilot.gettingStartedTitle")}</h2>
          <p>{t("barcelonaPilot.gettingStartedText")}</p>
        </div>
        <div className="flex flex-col max-w-4xl gap-4 mx-auto">
          <h2>{t("barcelonaPilot.howToJoinTitle")}</h2>
          <p>{t("barcelonaPilot.howToJoinText")}</p>
        </div>
        <div className="flex flex-col gap-4">
          <h2>{t("barcelonaPilot.whatToHavePrepared")}</h2>
          <div className="flex flex-col gap-4 sm:flex-row md:gap-8">
            <ul
              className="flex flex-col flex-1 max-w-2xl gap-4 text-sm justify-evenly text-vdark sm:gap-8 md:text-xl"
              style={{ fontFamily: "Poppins" }}
            >
              <Item text={t("barcelonaPilot.basicInfoBulletpoint")} />
              <Item text={t("barcelonaPilot.photosBulletpoint")} />
              <Item text={t("barcelonaPilot.detailsBulletpoint")}>
                (
                <Link to={t("barcelonaPilot.link")} className="underline ">
                  {t("barcelonaPilot.learn")}
                </Link>
                )
              </Item>
              <Item text={t("barcelonaPilot.minutesBulletpoint")} />
            </ul>
            <div className="mx-auto w-60">
              <img src={mobileImg} className="object-contain w-full" alt="" />
            </div>
          </div>
          <LinkButton to="/create-restaurant-account" className="mx-auto">
            {t("barcelonaPilot.startNow")}
          </LinkButton>
        </div>
      </div>
    </main>
  );
};

function HeroImage() {
  const { t } = useTranslation();
  return (
    <div className="relative isolate mb-8 h-64 sm:mb-12 sm:h-[500px] md:mb-16">
      <img src={heroImg} alt="" className="absolute object-cover h-full" />
      <div className="absolute w-full h-full bg-vdarkgreen opacity-60 " />
      <div className="absolute flex flex-col items-center justify-center w-full h-full gap-2 p-2 sm:gap-6 lg:gap-8">
        <p
          style={{ fontFamily: "DIN" }}
          className="max-w-sm text-4xl text-center text-white drop-shadow sm:max-w-xl sm:text-6xl md:text-7xl lg:max-w-5xl lg:text-8xl"
        >
          {t("barcelonaPilot.heroTitle")}
        </p>
      </div>
    </div>
  );
}

function Item({ text, children }) {
  return (
    <li className="flex">
      <div className="flex-none mr-2 text-green-500 basis-8 sm:mr-4">
        <CheckIcon className="block" alt="" />
      </div>
      <p>
        {text}
        {children}
      </p>
    </li>
  );
}
