import React from "react";
import { useTable, useSortBy } from "react-table";
import { useNavigate } from "react-router-dom";

export function Table({ data, columns }) {
  const navigate = useNavigate();
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({ columns, data }, useSortBy);

  return (
    <div className="flex flex-col overflow-clip">
      <div className="-my-2 overflow-x-auto ">
        <div className="inline-block min-w-full py-8 align-middle sm:px-6 lg:px-8">
          <div className="overflow-hidden border-t border-b border-l border-r border-gray-300 shadow sm:rounded-lg">
            <table
              {...getTableProps()}
              className="min-w-full divide-y divide-gray-200"
            >
              <thead className="bg-gray-50">
                {
                  // Loop over the header rows
                  headerGroups.map((headerGroup) => (
                    // Apply the header row props
                    <tr {...headerGroup.getHeaderGroupProps()}>
                      {
                        // Loop over the headers in each row
                        headerGroup.headers.map((column) => (
                          // Apply the header cell props
                          <th
                            {...column.getHeaderProps(
                              column.getSortByToggleProps()
                            )}
                            scope="col"
                            className="w-4 px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase"
                          >
                            {
                              // Render the header
                              column.render("Header")
                            }
                            <span>
                              {column.isSorted
                                ? column.isSortedDesc
                                  ? "    🔽"
                                  : "    🔼"
                                : ""}
                            </span>
                          </th>
                        ))
                      }
                    </tr>
                  ))
                }
              </thead>
              <tbody {...getTableBodyProps()}>
                {
                  // Loop over the table rows
                  rows.map((row, i) => {
                    // Prepare the row for display
                    prepareRow(row);
                    return (
                      // Apply the row props
                      <tr
                        {...row.getRowProps()}
                        className={
                          i % 2 === 0
                            ? "cursor-pointer bg-white text-gray-500 hover:bg-vgreen hover:text-vdark"
                            : "cursor-pointer bg-gray-50 text-gray-500 hover:bg-vgreen hover:text-vdark"
                        }
                      >
                        {
                          // Loop over the rows cells
                          row.cells.map((cell) => {
                            // Apply the cell props
                            return (
                              <td
                                {...cell.getCellProps()}
                                className="max-w-xs px-6 py-4 text-sm truncate "
                                onClick={() =>
                                  navigate(
                                    `/dashboard/issues/${row.original.id}`
                                  )
                                }
                              >
                                {
                                  // Render the cell contents
                                  cell.render("Cell")
                                }
                              </td>
                            );
                          })
                        }
                      </tr>
                    );
                  })
                }
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}
