import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { TextInput } from "components/FormElements";
import { StepSubmitButtons } from "./StepSubmitButtons";
import isEmail from "validator/lib/isEmail";
import isURL from "validator/lib/isURL";
import { getLatLngFromGoogleUrl } from "utils/google";

// const APIKEY = "AIzaSyCejQ3cSS0GyPew7pNiuu4tTRc3l5_82K8";

function getLocation(googleMapsUrl) {
  return getLatLngFromGoogleUrl(googleMapsUrl);
}

export function RestaurantContactInformation({
  setStep,
  restaurantInformation,
  setRestaurantInformation,
  setIsContactInfoValid,
}) {
  const {
    name: _name,
    address: _address,
    zipcode: _zipcode,
    city: _city,
    phone_number: _phone_number,
    email: _email,
    urls,
  } = restaurantInformation;
  const {
    website: _website,
    google_page: _google_page,
    instagram: _instagram,
    facebook: _facebook,
  } = urls;
  const { t } = useTranslation();
  const [error, setError] = useState([]);
  const [isDisabled, setIsDisabled] = useState(false);
  const [name, setName] = useState(_name);
  const [address, setAddress] = useState(_address);
  const [zipcode, setZipcode] = useState(_zipcode);
  const [city, setCity] = useState(_city);
  const [phone_number, setPhoneNumber] = useState(_phone_number);
  const [email, setEmail] = useState(_email);
  const [website, setWebsite] = useState(_website);
  const [google_page, setGooglePage] = useState(_google_page);
  const [instagram, setInstagram] = useState(_instagram);
  const [facebook, setFacebook] = useState(_facebook);

  useEffect(() => {
    if (
      name.length === 0 ||
      address.length === 0 ||
      zipcode.length === 0 ||
      city.length === 0 ||
      phone_number.length === 0 ||
      email.length === 0 ||
      website.length === 0 ||
      google_page.length === 0
    )
      return setIsDisabled(true);
    else return setIsDisabled(false);
  }, [name, address, zipcode, city, phone_number, email, website, google_page]);

  async function handleNext(e) {
    // TODO: Need to check the location function better. See if the url is right, handle shortened urls...
    e.preventDefault();
    setError([]);
    const errorsList = [];
    if (isEmail(email) === false) errorsList.push("invalidEmailError");
    if (isURL(website) === false) errorsList.push("websiteError");
    if (isURL(google_page) === false) errorsList.push("googlemapsError");
    if (google_page.includes("goo.gl") || google_page.includes("g.page/"))
      errorsList.push("notShortenedLinksError");
    if (!google_page.includes("goo.gl") && !google_page.includes("google."))
      errorsList.push("notGoogleLink");
    if (instagram.length > 0 && isURL(instagram) === false)
      errorsList.push("instagramError");
    if (facebook.length > 0 && isURL(facebook) === false)
      errorsList.push("facebookError");
    if (errorsList.length > 0) {
      setIsContactInfoValid(false);
      return setError(errorsList);
    }
    setRestaurantInformation({
      name,
      address,
      zipcode,
      city,
      phone_number,
      email,
      urls: {
        website,
        google_page,
        instagram,
        facebook,
      },
      location: getLocation(google_page),
    });
    setIsContactInfoValid(true);
    setStep((prev) => prev + 1);
  }

  return (
    <div className="mx-auto max-w-3xl">
      <h2 className="text-center text-3xl sm:m-4 sm:text-5xl">
        {t("restaurantContact.title")}
      </h2>
      <p className="mt-4 max-w-2xl px-4 text-sm text-gray-500 sm:mt-8 sm:text-base">
        {t("restaurantContact.intro")}
      </p>
      <div className="space-y-8 divide-y divide-gray-200 px-4 sm:px-8">
        <div>
          <div className="space-y-6 pt-2 sm:space-y-5 sm:pt-6">
            <div className="space-y-6 sm:space-y-5">
              <TextInput
                attributeName="restaurantName"
                label={t("restaurantContact.nameLabel")}
                value={name}
                setValue={setName}
                required={true}
                borderTop={false}
                placeholder={t("restaurantContact.namePlaceholder")}
              />
              <TextInput
                attributeName="address"
                label={t("restaurantContact.addressLabel")}
                value={address}
                setValue={setAddress}
                required={true}
                placeholder={t("restaurantContact.addressPlaceholder")}
              />
              <TextInput
                attributeName="zipcode"
                label={t("restaurantContact.zipcodeLabel")}
                value={zipcode}
                setValue={setZipcode}
                required={true}
                placeholder="12345"
                type="number"
              />
              <TextInput
                attributeName="city"
                label={t("restaurantContact.cityLabel")}
                value={city}
                setValue={setCity}
                required={true}
                placeholder={t("restaurantContact.cityPlaceholder")}
              />
              <TextInput
                attributeName="phone"
                label={t("restaurantContact.phoneLabel")}
                value={phone_number}
                setValue={setPhoneNumber}
                required={true}
                placeholder={t("restaurantContact.phonePlaceholder")}
                type="number"
              />
              <TextInput
                attributeName="email"
                label={t("restaurantContact.emailLabel")}
                value={email}
                setValue={setEmail}
                required={true}
                placeholder={t("restaurantContact.emailPlaceholder")}
                type="email"
                autoComplete="email"
                icon="mail"
              />
              <TextInput
                attributeName="website"
                label={t("restaurantContact.websiteLabel")}
                value={website}
                setValue={setWebsite}
                required={true}
                placeholder={t("restaurantContact.websitePlaceholder")}
              />
              <TextInput
                attributeName="googlemaps"
                label={t("restaurantContact.googlemapsLabel")}
                value={google_page}
                setValue={setGooglePage}
                required={true}
                placeholder="www.google.com/maps/place/..."
              />
              <TextInput
                attributeName="instagram"
                label={t("restaurantContact.instagramLabel")}
                value={instagram}
                setValue={setInstagram}
                required={false}
                placeholder="www.instagram.com/..."
              />
              <TextInput
                attributeName="facebook"
                label={t("restaurantContact.facebookLabel")}
                value={facebook}
                setValue={setFacebook}
                required={false}
                placeholder="www.facebook.com/..."
              />
            </div>
          </div>
        </div>
        <div>
          <StepSubmitButtons
            error={error}
            isDisabled={isDisabled}
            next={t("general.next")}
            onNext={handleNext}
          />
        </div>
      </div>
    </div>
  );
}
