import React from "react";
import { ReactComponent as InstagramIcon } from "assets/images/icons/instagramIcon.svg";
import { ReactComponent as LinkedInIcon } from "assets/images/icons/linkedInIcon.svg";
import { ReactComponent as FacebookIcon } from "assets/images/icons/facebookIcon.svg";

function SocialIcons() {
  return (
    <div className="flex justify-center gap-6 sm:gap-12 ">
      <a
        href="https://www.linkedin.com/company/verdantips/"
        className="p-4 rounded-full bg-vgreen sm:p-6"
        target="_blank"
        rel="noopener noreferrer"
      >
        <LinkedInIcon className="w-6 sm:w-8" />
      </a>
      <a
        href="https://www.instagram.com/verdantips.sl/"
        target="_blank"
        rel="noopener noreferrer"
        className="p-4 rounded-full bg-vgreen sm:p-6"
      >
        <InstagramIcon className="w-6 sm:w-8" />
      </a>
      <a
        href="https://www.facebook.com/business.verdantips/"
        target="_blank"
        rel="noopener noreferrer"
        className="p-4 rounded-full bg-vgreen sm:p-6 "
      >
        <FacebookIcon className="w-6 sm:w-8" />
      </a>
    </div>
  );
}

export default SocialIcons;
