import { FakeResponseCollection } from "utils/fakeResponseCollection";

export const fake_send_activation = new FakeResponseCollection();

fake_send_activation.set({
  input: {
    email: "kk@kk.com",
  },
  response: {
    data: {
      result: {
        ok: true,
      },
    },
  },
});

fake_send_activation.set({
  input: {
    email: "fail@kk.com",
  },
  response: {
    data: {
      result: {
        ok: false,
      },
    },
  },
});
