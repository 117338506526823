import React from "react";
import { useTranslation } from "react-i18next";
import LinkButton from "components/LinkButton";
import hero from "./img/hero.jpg";
import heroXs from "./img/hero-xs.jpg";
import { MarketIcon } from "components/MarketIcons/MarketIcon";
import tabletImg from "./img/tablet-1.png";
import mobileImg from "./img/mobile-1.png";
import partnersAlstCapital from "./img/partner-alts-capital.png";
import partnerEttInnoenergy from "./img/partner-ett-innoenergy.png";
import partnerFarmer from "./img/partner-farmer.png";
import partnerMarine from "./img/partner-marineterrein-ams-living-lab.png";
import partnerReusabol from "./img/partner-reusabol.png";
import SocialIcons from "components/SocialIcons";
import { BadgesImage } from "components/BadgesImage";

export const Home = () => {
  const { t } = useTranslation();
  return (
    <main className="">
      <HeroImage t={t} />
      <div className="mx-auto flex max-w-7xl flex-col gap-8 px-4 sm:gap-12 sm:px-8 md:gap-16">
        <div className="flex flex-col items-center gap-4 sm:flex-row sm:gap-8 md:gap-12">
          <div className="h-40 sm:order-1 sm:h-60 sm:flex-1">
            <img src={tabletImg} alt="" className="h-full object-contain" />
          </div>
          <div className="flex flex-col items-center gap-4 sm:flex-1">
            <h2>{t("home.headerRestaurants")}</h2>
            <LinkButton to={t("nav.forRestaurants")}>
              {t("home.moreAboutRestaurants")}
            </LinkButton>
          </div>
        </div>
        <div className="flex flex-col items-center gap-4 sm:flex-row sm:gap-8 md:gap-12">
          <div className="h-40 sm:h-60 sm:flex-1">
            <img src={mobileImg} alt="" className="h-full object-contain" />
          </div>
          <div className="flex flex-col items-center gap-4 sm:flex-1">
            <h2>{t("home.headerDiners")}</h2>
            <LinkButton to={t("nav.forUsers")}>
              {t("home.moreAboutDiners")}
            </LinkButton>
          </div>
        </div>
        <div>
          <h2 className="text-3xl sm:text-5xl">
            {t("home.headerSustainability")}
          </h2>
          <div className="flex flex-col items-center gap-4 lg:flex-row ">
            <div className="w-5/6 flex-1">
              <BadgesImage className="w-full" />
            </div>
            <div className="flex flex-1 flex-col justify-evenly gap-4 self-stretch ">
              <p>{t("home.textSustainability01")}</p>
              <p>{t("home.textSustainability02")}</p>
              <div className="flex justify-center">
                <LinkButton to={t("nav.sustainability")}>
                  {t("home.moreAboutSustainability")}
                </LinkButton>
              </div>
            </div>
          </div>
        </div>
        <div>
          <h2
            className="mb-2 text-3xl sm:text-5xl "
            style={{ fontFamily: "DIN" }}
          >
            {t("home.friendsAndPartners")}
          </h2>
          <div className="flex flex-wrap items-center justify-center">
            <PartnerLogo
              img={partnerReusabol}
              alt="Partner Reusabol"
              url="https://www.reusabol.com/"
            />
            <PartnerLogo
              img={partnersAlstCapital}
              alt="Partner Alst Capital"
              url="https://www.altscapital.com/"
            />
            <PartnerLogo
              img={partnerEttInnoenergy}
              alt="Partner Ett Innoenergy"
              url="https://www.innoenergy.com/"
            />
            <PartnerLogo
              img={partnerFarmer}
              alt="Partner Farmer"
              url="https://www.farmerdirecto.com/sp"
            />
            <PartnerLogo
              img={partnerMarine}
              alt="Partner Marine"
              url="https://www.living-lab.nl/aboutpage"
            />
          </div>
        </div>
        <div>
          <h2 className="mb-2 text-3xl sm:text-5xl">{t("home.followUs")}</h2>
          <SocialIcons />
        </div>
      </div>
    </main>
  );
};

function HeroImage({ t }) {
  return (
    <div className="relative isolate mb-8 h-80 sm:mb-12 sm:h-[500px] md:mb-16">
      <img
        src={heroXs}
        alt=""
        className="pointer-events-none absolute h-full object-cover sm:hidden"
      />
      <img
        src={hero}
        alt=""
        className="pointer-events-none absolute hidden h-full object-cover sm:block"
      />
      <div className="relative flex">
        <div className="sm:basis-1/5 lg:basis-2/5" />
        <div
          className="flex flex-1 flex-col p-4 sm:gap-2 sm:pt-12"
          style={{ fontFamily: "DIN", textShadow: "0 0 10px #193c44" }}
        >
          <p className="text-4xl text-white sm:text-center sm:text-6xl lg:text-7xl">
            {t("home.heroTitle")}
          </p>
          <p className="text-3xl text-white sm:text-center sm:text-5xl lg:text-6xl">
            {t("home.heroSubTitle")}
          </p>
          <div className="mt-4 w-48 cursor-pointer self-start sm:w-64 sm:self-center">
            <MarketIcon market="google" />
          </div>
          <div className="z-10 mt-4 w-48 self-start sm:w-64 sm:self-center">
            <MarketIcon market="apple" />
          </div>
        </div>
      </div>
    </div>
  );
}

function PartnerLogo({ img, alt, url }) {
  return (
    <div className="m-1 h-28 xs:m-4">
      <a href={url} target="_blank" rel="noopener noreferrer">
        <img src={img} alt={alt} className="h-full w-auto object-contain" />
      </a>
    </div>
  );
}
