import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import Calendar from "components/Calendar";
import { getMonthCalendar } from "utils/dates";
import { StepSubmitButtons } from "./StepSubmitButtons";

export function RestaurantClosedDays({
  setStep,
  restaurantInformation,
  setRestaurantInformation,
}) {
  const { open_hours, closed_days: _closed_days } = restaurantInformation;
  const { t } = useTranslation();
  const [referenceDay, setReferenceDay] = useState(new Date());
  const [regularClosedDays, setRegularClosedDays] = useState([]);
  const [extraClosedDays, setExtraClosedDays] = useState(_closed_days);
  const month = getMonthCalendar(referenceDay);

  useEffect(() => {
    let closedDaysList = [];
    for (const day in open_hours) {
      if (open_hours[day].length === 0) {
        if (day === "sunday") closedDaysList.push(0);
        if (day === "monday") closedDaysList.push(1);
        if (day === "tuesday") closedDaysList.push(2);
        if (day === "wednesday") closedDaysList.push(3);
        if (day === "thursday") closedDaysList.push(4);
        if (day === "friday") closedDaysList.push(5);
        if (day === "saturday") closedDaysList.push(6);
      }
    }
    setRegularClosedDays(closedDaysList);
  }, [open_hours]);

  function handleNext() {
    setRestaurantInformation({ closed_days: extraClosedDays });
    setStep((prev) => prev + 1);
  }

  return (
    <div className="mx-auto max-w-3xl px-4">
      <h2 className="m-4 text-center text-3xl sm:m-4 sm:text-5xl">
        {t("closed.title")}
      </h2>
      <div className="mb-4 flex flex-col gap-2 sm:mb-4">
        <p>{t("closed.intro")}</p>
        <p>{t("closed.explanation")}</p>
        <p>{t("closed.explanation2")}</p>
      </div>
      <div className="flex flex-col items-center ">
        <Calendar
          month={month}
          setReferenceDay={setReferenceDay}
          regularClosedDays={regularClosedDays}
          extraClosedDays={extraClosedDays}
          setExtraClosedDays={setExtraClosedDays}
        />
      </div>
      <StepSubmitButtons
        back={t("general.back")}
        next={t("general.next")}
        onNext={handleNext}
        onBack={() => setStep((prev) => prev - 1)}
      />
    </div>
  );
}
