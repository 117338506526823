export function fixSustainabilityList(sustainability, certifications) {
  // When we first designed the list didn't include some badges
  if (sustainability?.includes("PLANFRIE")) {
    // Could be "PLANTFRIENDLY" or "PLANTBASED"
    const index = sustainability.indexOf("PLANFRIE");
    const badge = certifications.PLANFRIE?.badge;
    if (badge) sustainability[index] = badge.toUpperCase(); // In some tests we used camelCase for this field, but should be all caps
  }
  if (sustainability?.includes("PLASCONS")) {
    // Could be "PLASTICCONSCIOUS" or "PLASTICFREE"
    const index = sustainability.indexOf("PLASCONS");
    const badge = certifications.PLASCONS?.badge;
    if (badge) sustainability[index] = badge.toUpperCase();
  }
  if (sustainability?.includes("VEGEFRIE")) {
    // Could be "VEGETARIAN" or "VEGETARIANFRIENDLY"
    const index = sustainability.indexOf("VEGEFRIE");
    const badge = certifications.VEGEFRIE?.badge;
    if (badge) sustainability[index] = badge.toUpperCase();
  }
}
