import React from "react";
import { useTranslation } from "react-i18next";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export function TabHeader({
  className,
  tabs,
  selectedTab,
  setSelectedTab,
  isUpdateDisabled,
  setOpenModalConfirm,
  setTargetTab,
}) {
  const { t } = useTranslation();
  return (
    <div className={className}>
      <div className="sm:hidden">
        <label htmlFor="tabs" className="sr-only">
          Select a tab
        </label>
        <select
          id="tabs"
          name="tabs"
          value={selectedTab}
          onChange={(e) => {
            setTargetTab(e.target.value);
            if (isUpdateDisabled === false) setOpenModalConfirm(true);
            else {
              return setSelectedTab(e.target.value);
            }
          }}
          className="block w-full rounded-md border-gray-300 focus:border-green-500 focus:ring-green-500"
        >
          {tabs.map((tab) => (
            <option key={tab.name} value={tab.name}>
              {t(tab.name)}
            </option>
          ))}
        </select>
      </div>
      <div className="hidden sm:block">
        <nav className="flex justify-around" aria-label="Tabs">
          {tabs.map((tab) => (
            <button
              key={tab.name}
              onClick={() => {
                setTargetTab(tab.name);
                if (isUpdateDisabled === false) setOpenModalConfirm(true);
                else {
                  return setSelectedTab(tab.name);
                }
              }}
              className={classNames(
                tab.name === selectedTab
                  ? "bg-green-100 text-green-700"
                  : "text-gray-500 hover:text-gray-700",
                "rounded-md px-3 py-2 text-sm font-medium"
              )}
            >
              {t(tab.name)}
            </button>
          ))}
        </nav>
      </div>
    </div>
  );
}
