import api from "API";
import { useQuery, useMutation, useQueryClient } from "react-query";
import { uploadPhotos } from "utils/photos";

function checkData(data) {
  if (!data) {
    console.log("error");
    return {
      isError: true,
      error: new Error("unknownError"),
      data,
    };
  }
  if (data.data.result.ok === false) {
    return {
      isError: true,
      error: new Error("deniedError"),
      data,
    };
  }
  return {
    data,
  };
}

export function useGetUserRestaurants() {
  const { data, isLoading, error, isError, isSuccess } = useQuery(
    "userRestaurants",
    () => {
      const input = { test: "RESPONSE" }; // Only for test queries: "RESPONSE", "EMPTY", "FAIL"
      return api.restaurants.mine(input);
    }
  );
  if (isSuccess === true) return checkData(data);
  return {
    data,
    isLoading,
    isError,
    error,
    isSuccess,
  };
}

export function useGetAllRestaurants() {
  const { data, isLoading, error, isError, isSuccess } = useQuery(
    "allRestaurants",
    () => {
      return api.restaurants.all();
    }
  );
  return { data, isLoading, isError, error, isSuccess };
}

export function useGetKeywords() {
  const { data, isLoading, isError, error, isSuccess } = useQuery(
    "keywords",
    () => {
      const input = { test: "RESPONSE" };
      return api.restaurants.getKeywords(input);
    }
  );
  if (isSuccess === true) return checkData(data);
  return {
    data,
    isLoading,
    isError,
    error,
    isSuccess,
  };
}

export function useGetRestaurantInfo(input) {
  const { data, isLoading, isError, error, isSuccess } = useQuery(
    `restaurant-info-${input.id}`,
    () => {
      return api.restaurants.getInfo(input);
    }
  );
  // if (isSuccess === true) return checkData(data);
  return { data, isLoading, isError, error, isSuccess };
}

export function useUpdateRestaurant() {
  const queryClient = useQueryClient();
  const { mutate, isLoading, error, isError, isSuccess } = useMutation(
    (input) => {
      return api.restaurants.update(input);
    },
    {
      onSuccess: (data, variables) => {
        queryClient.invalidateQueries("userRestaurants");
        queryClient.invalidateQueries(`restaurant-info-${variables.id}`); // TODO:Check if the variable is right
      },
    }
  );
  // if (isSuccess === true) return checkData(data);
  return { mutate, isLoading, error, isError, isSuccess };
}

export function useRemoveRestaurant() {
  const { mutate, isLoading, error, isError, isSuccess } = useMutation(
    (input) => {
      return api.restaurants.remove(input);
    },
    {
      onSuccess: () => {
        console.log("TODO: Invalidate affected queries");
      },
    }
  );
  // if (isSuccess === true) return checkData(data);
  return { mutate, isLoading, error, isError, isSuccess };
}

export function useCreateRestaurant() {
  const queryClient = useQueryClient();
  const { mutate, isLoading, error, isError, isSuccess } = useMutation(
    (input) => {
      return api.restaurants.create(input);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("userRestaurants");
      },
    }
  );
  // if (isSuccess === true) {
  //   return checkData(data);
  //   // TODO: We should handle more errors, like the restaurant already exists
  // }
  return { mutate, isLoading, error, isError, isSuccess };
}

export function useUploadPhotos() {
  const { mutate, mutateAsync, isLoading, error, isError, isSuccess } =
    useMutation((input) => {
      return uploadPhotos(input);
    });
  return { mutate, mutateAsync, isLoading, error, isError, isSuccess };
}
