import React, { useState, useEffect } from "react";
import isEmail from "validator/lib/isEmail";
import { useTranslation } from "react-i18next";
import {
  useAddUserToWaitlist,
  useAddRestaurantToWaitlist,
} from "hooks/contactHooks";
import { SuccessBlock } from "./successBlock";
import { ErrorBlock } from "./ErrorBlock";
import Button from "components/Button";

export function WaitlistForm({ className, target }) {
  const { t } = useTranslation();
  const { mutate: addUser, isLoading: isLoadingUser } = useAddUserToWaitlist();
  const { mutate: addRestaurant, isLoading: isLoadingRestaurant } =
    useAddRestaurantToWaitlist();
  const [name, setName] = useState("");
  const [city, setCity] = useState("");
  const [email, setEmail] = useState("");
  const [restaurantName, setRestaurantName] = useState("");
  const [position, setPosition] = useState("");
  const [isDisabled, setIsDisabled] = useState(true);
  const [errors, setErrors] = useState([]);
  const [success, setSuccess] = useState([]);

  useEffect(() => {
    if (name.length === 0 || email.length === 0 || city.length === 0)
      return setIsDisabled(true);
    if (
      target === "restaurant" &&
      (position.length === 0 || restaurantName.length === 0)
    )
      return setIsDisabled(true);
    else setIsDisabled(false);
  }, [name, city, email, restaurantName, position, target]);

  function handleSubmit() {
    setSuccess([]);
    setErrors([]);
    if (!isEmail(email)) return setErrors(["invalidEmailError"]);
    if (target === "restaurant") {
      addRestaurant(
        {
          restaurant_name: restaurantName,
          email,
          city,
          contact_name: name,
          contact_position: position,
        },
        {
          onError: () => {
            return setErrors(["unknownError"]);
          },
          onSuccess: (data) => {
            if (data.ok === false) return setErrors(["unknownError"]);
            if (data.ok === true) return setSuccess(["waitlistSent"]);
          },
        }
      );
    }
    if (target === "user") {
      addUser(
        { name, email, city },
        {
          onError: () => {
            return setErrors(["unknownError"]);
          },
          onSuccess: (data) => {
            if (data.ok === false) return setErrors(["unknownError"]);
            if (data.ok === true) return setSuccess(["waitlistSent"]);
          },
        }
      );
    }
  }

  return (
    <div className={`${className} flex flex-col items-center gap-4`}>
      <h2 className="text-3xl sm:text-5xl">{t("waitlistForm.title")}</h2>
      <p className="mb-2 max-w-4xl ">{t("waitlistForm.intro")}</p>
      <div className="flex w-full max-w-4xl flex-col gap-4">
        <div className="flex flex-1 flex-col gap-4 sm:flex-row">
          <LabelInput
            labelText={t("waitlistForm.name")}
            placeholder={t("waitlistForm.namePlaceholder")}
            name="name"
            value={name}
            setValue={setName}
            required={true}
          />
          <LabelInput
            labelText={t("waitlistForm.city")}
            placeholder={t("waitlistForm.cityPlaceholder")}
            name="city"
            required={true}
            value={city}
            setValue={setCity}
          />
          <LabelInput
            labelText={t("waitlistForm.email")}
            placeholder={t("waitlistForm.emailPlaceholder")}
            name="email"
            type="email"
            required={true}
            value={email}
            setValue={setEmail}
          />
        </div>
        {target === "restaurant" && (
          <div className="flex flex-1 flex-col gap-4 sm:flex-row">
            <LabelInput
              labelText={t("waitlistForm.restaurantName")}
              placeholder={t("waitlistForm.restaurantNamePlaceholder")}
              name="restaurantName"
              value={restaurantName}
              setValue={setRestaurantName}
              required={true}
            />
            <LabelInput
              labelText={t("waitlistForm.position")}
              placeholder={t("waitlistForm.positionPlaceholder")}
              name="position"
              value={position}
              setValue={setPosition}
              required={true}
            />
          </div>
        )}
        <Button
          isDisabled={isDisabled}
          isLoading={isLoadingRestaurant || isLoadingUser}
          onClick={handleSubmit}
          className="mt-2 max-w-fit self-center text-xl sm:mt-4 sm:text-2xl md:text-2xl"
        >
          {t("waitlistForm.submit")}
        </Button>
      </div>
      {errors.length > 0 && <ErrorBlock errorList={errors} />}
      {success.length > 0 && <SuccessBlock successList={success} />}
    </div>
  );
}

function LabelInput({
  labelText,
  placeholder,
  type = "text",
  name,
  required = false,
  className,
  value,
  setValue,
}) {
  return (
    <div className={`${className} flex-1`}>
      <label
        htmlFor={name}
        className="block text-lg font-medium text-vdarkgreen sm:text-2xl"
        style={{ fontFamily: "DIN" }}
      >
        {`${labelText} ${required === true ? "*" : ""}`}
      </label>
      <div className="mt-1">
        <input
          type={type}
          name={name}
          value={value}
          onChange={(e) => setValue(e.target.value)}
          id={name}
          className="block w-full rounded-md border-gray-300 text-sm shadow-sm focus:border-green-500 focus:ring-green-500 sm:text-base"
          placeholder={placeholder}
          required={required}
          style={{ fontFamily: "Poppins" }}
        />
      </div>
    </div>
  );
}
