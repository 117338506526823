import { FakeResponseCollection } from "utils/fakeResponseCollection";

export const fake_get_restaurants_by_user = new FakeResponseCollection();

fake_get_restaurants_by_user.set({
  input: {
    user_id: "user-id-1",
  },
  response: {
    data: {
      result: {
        ok: true,
        payload: [
          {
            id: "restaurant-id-1",
            name: "Vegetalia Born",
            location: {
              latitude: 1.897,
              longitude: -8.2351,
            },
            address: "Plaça del Fossar de les Moreres",
            zipcode: "08003",
            city: "Barcelona",
            urls: {
              website: "https://www.vegetalia.com/",
              google_page: "",
              instagram: "",
              facebook: "",
            },
          },
          {
            id: "restaurant-id-2",
            name: "Garibolo",
            location: {
              latitude: 1.897,
              longitude: -8.2351,
            },
            address: "Plaça del Fossar de les Moreres",
            zipcode: "08003",
            city: "Barcelona",
            urls: {
              website: "https://www.vegetalia.com/",
              google_page: "",
              instagram: "",
              facebook: "",
            },
          },
        ],
      },
    },
  },
});

fake_get_restaurants_by_user.set({
  input: {
    user_id: "EMPTY",
  },
  response: {
    data: {
      ok: true,
      payload: [],
    },
  },
});

fake_get_restaurants_by_user.set({
  input: {
    user_id: "DENIED",
  },
  response: {
    data: {
      ok: false,
    },
  },
});
