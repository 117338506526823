import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useUpdateRestaurant } from "hooks/restaurantHooks";
import { UploadPhoto } from "components/FormElements";
import { StepSubmitButtons } from "./StepSubmitButtons";
import { useUploadPhotos } from "hooks/restaurantHooks";
import { areEqual } from "utils/areEqual";

export function EditRestaurantPhotos({
  restaurantInfo,
  isUpdateDisabled,
  setIsUpdateDisabled,
}) {
  const { id, photos } = restaurantInfo;
  const {
    cover: savedCover,
    menu: savedMenu,
    food: savedFood,
    ambience: savedAmbience,
    covid: savedCovid,
  } = photos;
  const { t } = useTranslation();
  const {
    mutate: updateRestaurant,
    isLoading: isLoadingUpdateRestaurant,
    isError,
  } = useUpdateRestaurant();
  const { mutateAsync: uploadPhotos, isLoading: isLoadingUploadPhotos } =
    useUploadPhotos();
  const [error, setError] = useState([]);
  const [success, setSuccess] = useState([]);
  const [coverPhoto, setCoverPhoto] = useState(
    (savedCover || []).length === 0 ? [] : [{ preview: savedCover }]
  );
  const [menuPhotos, setMenuPhotos] = useState(
    (savedMenu || []).map((item) => {
      return { preview: item };
    })
  );
  const [foodPhotos, setFoodPhotos] = useState(
    (savedFood || []).map((item) => {
      return { preview: item };
    })
  );
  const [ambiencePhotos, setAmbiencePhotos] = useState(
    (savedAmbience || []).map((item) => {
      return { preview: item };
    })
  );
  const [covidPhotos, setCovidPhotos] = useState(
    (savedCovid || []).map((item) => {
      return { preview: item };
    })
  );

  useEffect(() => {
    // The algorithm to check if the photos have changed could be improved A LOT, but for now it seems it works
    if (coverPhoto.length === 0) return setIsUpdateDisabled(true);

    if (
      areEqual(savedCover, getUrls(coverPhoto)[0]) &&
      areEqual(savedMenu, getUrls(menuPhotos)) &&
      areEqual(savedFood, getUrls(foodPhotos)) &&
      areEqual(savedAmbience, getUrls(ambiencePhotos)) &&
      areEqual(savedCovid, getUrls(covidPhotos))
    ) {
      return setIsUpdateDisabled(true);
    }
    return setIsUpdateDisabled(false);
  }, [
    covidPhotos,
    savedCovid,
    coverPhoto,
    savedCover,
    menuPhotos,
    savedMenu,
    foodPhotos,
    savedFood,
    ambiencePhotos,
    savedAmbience,
    restaurantInfo,
    setIsUpdateDisabled,
  ]);

  async function handleNext() {
    setError([]);
    setSuccess([]);
    function handleImageUploadError(e) {
      return setError(["unknownError"]);
    }
    const input = {
      id,
      photos: {
        cover: "",
        menu: [],
        food: [],
        ambience: [],
        covid: [],
      },
    };
    try {
      const coverUrl = await uploadPhotos(coverPhoto, {
        onError: handleImageUploadError,
      });
      input.photos.cover = coverUrl[0];
      if (menuPhotos.length > 0) {
        const menuUrls = await uploadPhotos(menuPhotos, {
          onError: handleImageUploadError,
        });
        input.photos.menu = menuUrls;
      }
      if (foodPhotos.length > 0) {
        const foodUrls = await uploadPhotos(foodPhotos, {
          onError: handleImageUploadError,
        });
        input.photos.food = foodUrls;
      }
      if (ambiencePhotos.length > 0) {
        const ambienceUrls = await uploadPhotos(ambiencePhotos, {
          onError: handleImageUploadError,
        });
        input.photos.ambience = ambienceUrls;
      }
      if (covidPhotos.length > 0) {
        const covidUrls = await uploadPhotos(covidPhotos, {
          onError: handleImageUploadError,
        });
        input.photos.covid = covidUrls;
      }
    } catch (e) {
      return setError(["unknownError"]);
    }

    if (error.length === 0) {
      updateRestaurant(input, {
        onError: (error) => {
          return setError([error.message]);
        },
        onSuccess: (data) => {
          if (!data) return setError(["unknownError"]);
          if (data.data.result.ok === false) return setError(["deniedError"]);
          if (data.data.result.ok === true) {
            setCoverPhoto([{ preview: input.photos.cover }]);
            setMenuPhotos(setUrls(input.photos.menu));
            setFoodPhotos(setUrls(input.photos.food));
            setAmbiencePhotos(setUrls(input.photos.ambience));
            setCovidPhotos(setUrls(input.photos.covid));
            setIsUpdateDisabled(true);
            return setSuccess(["informationUpdated"]);
          }
        },
      });
    }
  }

  if (isError) return <p>Error</p>;
  return (
    <div className="mx-auto mt-4 max-w-3xl px-4 sm:mt-8">
      <div className="flex flex-col gap-4">
        <UploadPhoto
          title={t("restaurantPhotos.coverTitle")}
          imageList={coverPhoto}
          setImageList={setCoverPhoto}
          max={1}
          required={true}
        />
        <UploadPhoto
          title={t("restaurantPhotos.menuTitle")}
          imageList={menuPhotos}
          setImageList={setMenuPhotos}
          max={10}
        />
        <UploadPhoto
          title={t("restaurantPhotos.foodTitle")}
          imageList={foodPhotos}
          setImageList={setFoodPhotos}
          max={10}
        />
        <UploadPhoto
          title={t("restaurantPhotos.ambienceTitle")}
          imageList={ambiencePhotos}
          setImageList={setAmbiencePhotos}
          max={10}
        />
        <UploadPhoto
          title={t("restaurantPhotos.covidTitle")}
          imageList={covidPhotos}
          setImageList={setCovidPhotos}
          max={10}
        />
      </div>
      <StepSubmitButtons
        isDisabled={isUpdateDisabled}
        isLoading={isLoadingUpdateRestaurant || isLoadingUploadPhotos}
        next={t("editRestaurant.updatePhotos")}
        onNext={handleNext}
        error={error}
        success={success}
        skip={false}
        className="mb-7 sm:mb-14"
      />
    </div>
  );
}

function getUrls(list) {
  const result = [];
  for (const item of list) result.push(item.preview);
  return result;
}

function setUrls(list) {
  if (list.length === 0) return [];
  const result = [];
  for (const item of list) result.push({ preview: item });
  return result;
}
