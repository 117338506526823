import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useUpdateRestaurant } from "hooks/restaurantHooks";
import { areEqual } from "utils/areEqual";
import ecoIcon from "assets/images/badges/eco.svg";
import covidIcon from "assets/images/badges/covid.svg";
import energyIcon from "assets/images/badges/energy.svg";
import meatIcon from "assets/images/badges/meat.svg";
import plantIcon from "assets/images/badges/plant.svg";
import plasticIcon from "assets/images/badges/plastic.svg";
import produceIcon from "assets/images/badges/produce.svg";
import renewableIcon from "assets/images/badges/renewable.svg";
import seafoodIcon from "assets/images/badges/seafood.svg";
import vegetarianIcon from "assets/images/badges/vegetarian.svg";
import wasteIcon from "assets/images/badges/waste.svg";
import waterIcon from "assets/images/badges/water.svg";
import plantBasedFriendlyIcon from "assets/images/badges/plantBasedFriendly.svg";
import plasticConsciousIcon from "assets/images/badges/plasticConscious.svg";
import vegetarianFriendlyIcon from "assets/images/badges/vegetarianFriendly.svg";
import { Modal } from "components/Modal";
import { StepSubmitButtons } from "./StepSubmitButtons";
import {
  BadgeBlock,
  EcoForm,
  PlantForm,
  VegetarianForm,
  CovidForm,
  WasteForm,
  PlasticForm,
  MeatForm,
  SeafoodForm,
  ProduceForm,
  RenewableForm,
  EnergyForm,
  WaterForm,
} from "./RestaurantBadges";

export function EditRestaurantBadges({
  restaurantInfo,
  isUpdateDisabled,
  setIsUpdateDisabled,
}) {
  const { id, sustainability } = restaurantInfo;

  const {
    COVICONS,
    ECOCERTI,
    ENEGEFFI,
    PLANFRIE,
    PLASCONS,
    RENWENEG,
    SUSTMEAT,
    SUSTPROD,
    SUSTSEAF,
    VEGEFRIE,
    WASTCONS,
    WATREFFI,
  } = restaurantInfo.certifications;

  const { t } = useTranslation();
  const { mutate: updateRestaurant, isLoading: isLoadingUpdateRestaurant } =
    useUpdateRestaurant();
  const [error, setError] = useState([]);
  const [success, setSuccess] = useState([]);
  const [open, setOpen] = useState(false);
  const [currentBadge, setCurrentBadge] = useState();
  const [plantBased, setPlantBased] = useState(
    sustainability.includes("PLANFRIE")
      ? { ...PLANFRIE, sustainability: "PLANFRIE" }
      : null
  );
  const [eco, setEco] = useState(
    sustainability.includes("ECOCERTI")
      ? { ...ECOCERTI, sustainability: "ECOCERTI" }
      : null
  );
  const [vegetarian, setVegetarian] = useState(
    sustainability.includes("VEGEFRIE")
      ? { ...VEGEFRIE, sustainability: "VEGEFRIE" }
      : null
  );
  const [covid, setCovid] = useState(
    sustainability.includes("COVICONS")
      ? { ...COVICONS, sustainability: "COVICONS" }
      : null
  );
  const [waste, setWaste] = useState(
    sustainability.includes("WASTCONS")
      ? { ...WASTCONS, sustainability: "WASTCONS" }
      : null
  );
  const [plastic, setPlastic] = useState(
    sustainability.includes("PLASCONS")
      ? { ...PLASCONS, sustainability: "PLASCONS" }
      : null
  );
  const [meat, setMeat] = useState(
    sustainability.includes("SUSTMEAT")
      ? { ...SUSTMEAT, sustainability: "SUSTMEAT" }
      : null
  );
  const [seafood, setSeafood] = useState(
    sustainability.includes("SUSTSEAF")
      ? { ...SUSTSEAF, sustainability: "SUSTSEAF" }
      : null
  );
  const [produce, setProduce] = useState(
    sustainability.includes("SUSTPROD")
      ? { ...SUSTPROD, sustainability: "SUSTPROD" }
      : null
  );
  const [renewable, setRenewable] = useState(
    sustainability.includes("RENWENEG")
      ? { ...RENWENEG, sustainability: "RENWENEG" }
      : null
  );
  const [energy, setEnergy] = useState(
    sustainability.includes("ENEGEFFI")
      ? { ...ENEGEFFI, sustainability: "ENEGEFFI" }
      : null
  );
  const [water, setWater] = useState(
    sustainability.includes("WATREFFI")
      ? { ...WATREFFI, sustainability: "WATREFFI" }
      : null
  );

  useEffect(() => {
    const savedCertifications = restaurantInfo.certifications;
    const currentCertifications = {
      PLANFRIE: { ...plantBased },
      ECOCERTI: { ...eco },
      VEGEFRIE: { ...vegetarian },
      COVICONS: { ...covid },
      WASTCONS: { ...waste },
      PLASCONS: { ...plastic },
      SUSTMEAT: { ...meat },
      SUSTSEAF: { ...seafood },
      SUSTPROD: { ...produce },
      RENWENEG: { ...renewable },
      ENEGEFFI: { ...energy },
      WATREFFI: { ...water },
    };
    for (const item in currentCertifications) {
      if (currentCertifications[item].sustainability !== undefined) {
        delete currentCertifications[item].sustainability;
      } else delete currentCertifications[item];
    }
    const hasChanged = !areEqual(savedCertifications, currentCertifications);
    setIsUpdateDisabled(!hasChanged);
  }, [
    restaurantInfo,
    plantBased,
    eco,
    vegetarian,
    covid,
    waste,
    plastic,
    meat,
    seafood,
    produce,
    renewable,
    energy,
    water,
    setIsUpdateDisabled,
  ]);

  useEffect(() => {
    if (
      plantBased === null &&
      eco === null &&
      vegetarian === null &&
      covid === null &&
      waste === null &&
      plastic === null &&
      meat === null &&
      seafood === null &&
      produce === null &&
      renewable === null &&
      energy === null &&
      water === null
    )
      return setIsUpdateDisabled(true);
  }, [
    plantBased,
    eco,
    vegetarian,
    covid,
    waste,
    plastic,
    meat,
    seafood,
    produce,
    renewable,
    energy,
    water,
    setIsUpdateDisabled,
  ]);

  function handleNext() {
    setError([]);
    setSuccess([]);
    if (
      (plantBased?.badge === "PLANTBASED" ||
        vegetarian?.badge === "VEGETARIAN") &&
      (meat !== null || seafood !== null)
    )
      return setError(["plantAnimalIncompatible"]);
    const badgesResults = [
      plantBased,
      eco,
      vegetarian,
      covid,
      waste,
      plastic,
      meat,
      seafood,
      produce,
      renewable,
      energy,
      water,
    ];
    let sustainabilityList = [];
    let updatedCertifications = {};
    for (const result of badgesResults) {
      if (result !== null) {
        const badge = result["sustainability"];
        updatedCertifications[badge] = {};
        for (const field in result) {
          if (field === "sustainability") {
            sustainabilityList.push(result[field]);
          } else {
            updatedCertifications[badge][field] = result[field];
          }
        }
      }
    }

    const input = {
      id,
      sustainability: sustainabilityList,
      certifications: updatedCertifications,
    };

    updateRestaurant(input, {
      onError: (error) => {
        setError([error.message]);
      },
      onSuccess: (data) => {
        if (!data) return setError(["unknownError"]);
        if (data.data.result.ok === false) return setError(["deniedError"]);
        if (data.data.result.ok === true)
          return setSuccess(["informationUpdated"]);
      },
    });
  }

  return (
    <div className="mt-4 sm:mt-8">
      <Modal open={open} setOpen={setOpen}>
        {currentBadge === "ECO" && (
          <EcoForm setOpen={setOpen} setEco={setEco} eco={eco} />
        )}
        {currentBadge === "PLANT" && (
          <PlantForm
            setOpen={setOpen}
            setPlantBased={setPlantBased}
            plantBased={plantBased}
          />
        )}
        {currentBadge === "VEGETARIAN" && (
          <VegetarianForm
            setOpen={setOpen}
            setVegetarian={setVegetarian}
            vegetarian={vegetarian}
          />
        )}
        {currentBadge === "COVID" && (
          <CovidForm setOpen={setOpen} setCovid={setCovid} covid={covid} />
        )}
        {currentBadge === "WASTE" && (
          <WasteForm setOpen={setOpen} setWaste={setWaste} waste={waste} />
        )}
        {currentBadge === "PLASTIC" && (
          <PlasticForm
            setOpen={setOpen}
            setPlastic={setPlastic}
            plastic={plastic}
          />
        )}
        {currentBadge === "MEAT" && (
          <MeatForm setOpen={setOpen} setMeat={setMeat} meat={meat} />
        )}
        {currentBadge === "SEAFOOD" && (
          <SeafoodForm
            setOpen={setOpen}
            setSeafood={setSeafood}
            seafood={seafood}
          />
        )}
        {currentBadge === "PRODUCE" && (
          <ProduceForm
            setOpen={setOpen}
            setProduce={setProduce}
            produce={produce}
          />
        )}
        {currentBadge === "RENEWABLE" && (
          <RenewableForm
            setOpen={setOpen}
            setRenewable={setRenewable}
            renewable={renewable}
          />
        )}
        {currentBadge === "ENERGY" && (
          <EnergyForm setOpen={setOpen} setEnergy={setEnergy} energy={energy} />
        )}
        {currentBadge === "WATER" && (
          <WaterForm setOpen={setOpen} setWater={setWater} water={water} />
        )}
      </Modal>
      <div className="px-4">
        <p className="mx-auto mt-1 mb-4 max-w-3xl sm:mb-6 ">
          {t("restaurantBadges.intro")}
        </p>
        <div className="flex flex-wrap justify-center gap-4 sm:gap-6">
          <BadgeBlock
            title={t("sustainability.ecoCertifiedTitle")}
            text={t("sustainability.ecoCertifiedText")}
            icon={ecoIcon}
            active={eco !== null}
            onApply={() => {
              setCurrentBadge("ECO");
              setOpen(true);
            }}
            onRemove={() => {
              setEco(null);
              setOpen(false);
            }}
          />
          <BadgeBlock
            title={
              plantBased?.badge === "PLANTFRIENDLY"
                ? t("sustainability.plantBasedFriendly")
                : t("sustainability.plantBased")
            }
            text={t("sustainability.plantBasedText")}
            icon={
              plantBased?.badge === "PLANTFRIENDLY"
                ? plantBasedFriendlyIcon
                : plantIcon
            }
            active={plantBased !== null}
            onApply={() => {
              setCurrentBadge("PLANT");
              setOpen(true);
            }}
            onRemove={() => {
              setPlantBased(null);
              setOpen(false);
            }}
          />
          <BadgeBlock
            title={
              vegetarian?.badge === "VEGETARIANFRIENDLY"
                ? t("sustainability.vegetarianFriendly")
                : t("sustainability.vegetarian")
            }
            text={t("sustainability.vegetarianText")}
            icon={
              vegetarian?.badge === "VEGETARIANFRIENDLY"
                ? vegetarianFriendlyIcon
                : vegetarianIcon
            }
            active={vegetarian !== null}
            onApply={() => {
              setCurrentBadge("VEGETARIAN");
              setOpen(true);
            }}
            onRemove={() => {
              setVegetarian(null);
              setOpen(false);
            }}
          />
          <BadgeBlock
            title={t("sustainability.covidConsciousTitle")}
            text={t("sustainability.covidConsciousText")}
            icon={covidIcon}
            active={covid !== null}
            onApply={() => {
              setCurrentBadge("COVID");
              setOpen(true);
            }}
            onRemove={() => {
              setCovid(null);
              setOpen(false);
            }}
          />
          <BadgeBlock
            title={t("sustainability.wasteConsciousTitle")}
            text={t("sustainability.wasteConsciousText")}
            icon={wasteIcon}
            active={waste !== null}
            onApply={() => {
              setCurrentBadge("WASTE");
              setOpen(true);
            }}
            onRemove={() => {
              setWaste(null);
              setOpen(false);
            }}
          />
          <BadgeBlock
            title={
              plastic?.badge === "plasticFree"
                ? t("sustainability.plasticFree")
                : t("sustainability.plasticConscious")
            }
            text={t("sustainability.plasticConsciousText")}
            icon={
              plastic?.badge === "plasticFree"
                ? plasticIcon
                : plasticConsciousIcon
            }
            active={plastic !== null}
            onApply={() => {
              setCurrentBadge("PLASTIC");
              setOpen(true);
            }}
            onRemove={() => {
              setPlastic(null);
              setOpen(false);
            }}
          />
          <BadgeBlock
            title={t("sustainability.sustainableMeatTitle")}
            text={t("sustainability.sustainableMeatText")}
            icon={meatIcon}
            active={meat !== null}
            onApply={() => {
              setCurrentBadge("MEAT");
              setOpen(true);
            }}
            onRemove={() => {
              setMeat(null);
              setOpen(false);
            }}
          />
          <BadgeBlock
            title={t("sustainability.sustainableSeafoodTitle")}
            text={t("sustainability.sustainableSeafoodText")}
            icon={seafoodIcon}
            active={seafood !== null}
            onApply={() => {
              setCurrentBadge("SEAFOOD");
              setOpen(true);
            }}
            onRemove={() => {
              setSeafood(null);
              setOpen(false);
            }}
          />
          <BadgeBlock
            title={t("sustainability.sustainableProduceTitle")}
            text={t("sustainability.sustainableProduceText")}
            icon={produceIcon}
            active={produce !== null}
            onApply={() => {
              setCurrentBadge("PRODUCE");
              setOpen(true);
            }}
            onRemove={() => {
              setProduce(null);
              setOpen(false);
            }}
          />
          <BadgeBlock
            title={t("sustainability.renewableEnergyTitle")}
            text={t("sustainability.renewableEnergyText")}
            icon={renewableIcon}
            active={renewable !== null}
            onApply={() => {
              setCurrentBadge("RENEWABLE");
              setOpen(true);
            }}
            onRemove={() => {
              setRenewable(null);
              setOpen(false);
            }}
          />
          <BadgeBlock
            title={t("sustainability.energyEfficientTitle")}
            text={t("sustainability.energyEfficientText")}
            icon={energyIcon}
            active={energy !== null}
            onApply={() => {
              setCurrentBadge("ENERGY");
              setOpen(true);
            }}
            onRemove={() => {
              setEnergy(null);
              setOpen(false);
            }}
          />
          <BadgeBlock
            title={t("sustainability.waterEfficientTitle")}
            text={t("sustainability.waterEfficientText")}
            icon={waterIcon}
            active={water !== null}
            onApply={() => {
              setCurrentBadge("WATER");
              setOpen(true);
            }}
            onRemove={() => {
              setWater(null);
              setOpen(false);
            }}
          />
        </div>
        <StepSubmitButtons
          isDisabled={isUpdateDisabled}
          isLoading={isLoadingUpdateRestaurant}
          next={t("editRestaurant.updateBadges")}
          onNext={handleNext}
          error={error}
          success={success}
          skip={true}
          className="mb-7 sm:mb-14"
        />
      </div>
    </div>
  );
}
