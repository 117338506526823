import { FakeResponseCollection } from "utils/fakeResponseCollection";

export const fake_send_contact_email = new FakeResponseCollection();

fake_send_contact_email.set({
  input: {
    name: "user",
    email: "kk@kk.com",
    message: "test",
  },
  response: {
    ok: true,
  },
});

fake_send_contact_email.set({
  input: {
    name: "user",
    email: "kk@kk.com",
    message: "fail",
  },
  response: {
    ok: false,
  },
});
