import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { es } from "./es.js";
import { en } from "./en.js";

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    debug: true,
    fallbackLng: "es",
    interpolation: {
      escapeValue: false,
    },
    resources: {
      es: { translation: es },
      en: { translation: en },
    },
    parseMissingKeyHandler: () => {
      return "Unexpected Error"; // How could we provide this message in the two languages?
    },
  });

export default i18n;
