import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useForgotPassword } from "hooks/authenticationHooks";
import { TextInput } from "components/FormElements";
import Button from "components/Button";
import { ReactComponent as Hero } from "./hero.svg";
import { ErrorBlock } from "components/ErrorBlock";

export const ForgotPassword = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { mutate: forgotPassword, isLoading } = useForgotPassword();
  const [email, setEmail] = React.useState("");
  const [errorList, setErrorList] = React.useState([]);

  const handleSubmit = (e) => {
    e.preventDefault();

    setErrorList([]);

    forgotPassword(
      { email },
      {
        onError: (error) => {
          return setErrorList([error.message]);
        },
        onSuccess: (data) => {
          if (data.ok === true) {
            return navigate("/check-email/forgotPassword");
          }
          if (data.ok === false) {
            return setErrorList(["forgotPasswordError"]);
          }
        },
      }
    );
  };

  return (
    <div className="mx-auto min-h-full max-w-xl">
      <h2 className="m-8 text-center text-4xl font-extrabold sm:text-6xl">
        {t("forgotPassword.title")}
      </h2>
      <Hero className="mx-auto max-w-sm p-4" />
      <p className="p-4 text-sm sm:text-base">{t("forgotPassword.text")}</p>
      <form
        className="space-y-8 divide-y divide-gray-200 px-3"
        onSubmit={handleSubmit}
      >
        <TextInput
          attributeName="email"
          label={t("forgotPassword.emailLabel")}
          value={email}
          setValue={setEmail}
          autoComplete="email"
          required={true}
          icon="mail"
          borderTop={false}
        />
        <div className="pt-5">
          <div className="flex justify-end">
            <div className="flex flex-col items-end gap-y-5">
              <Button isLoading={isLoading}>{t("forgotPassword.reset")}</Button>
            </div>
          </div>
        </div>
      </form>
      {errorList.length > 0 && (
        <ErrorBlock
          message={t("createRestaurantAccount.errorMessage")}
          errorList={errorList}
          className="mt-4"
        />
      )}
    </div>
  );
};
