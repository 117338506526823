import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import Button from "components/Button";
import SocialIcons from "components/SocialIcons";
import heroImg from "./img/hero.jpg";
import mobile1Img from "./img/mobile1.jpg";
import mobile2Img from "./img/mobile2.jpg";
import mobile3Img from "./img/mobile3.jpg";
import mobile4Img from "./img/mobile4.jpg";
// import sustainableImg from "./img/sustainable.jpg";
import { BadgesImage } from "components/BadgesImage";
import LinkButton from "components/LinkButton";
import { WaitlistForm } from "components/WaitlistForm";
import FindRestaurantsModal from "components/FindRestaurantsModal";

export const ForUsers = () => {
  const { t } = useTranslation();
  return (
    <main>
      <HeroImage />
      <div className="mx-auto flex max-w-7xl flex-col gap-8 px-4 sm:gap-12 sm:px-8 md:gap-16">
        <div className="flex flex-col gap-4">
          <h2 className="mx-auto max-w-4xl">{t("forUsers.header01")}</h2>
        </div>
        <div className="flex flex-col items-center gap-4 sm:flex-row ">
          <div className=" h-80 sm:flex-1 sm:basis-1/3">
            <img src={mobile1Img} className="h-full object-contain" alt="" />
          </div>
          <div className="flex-1 sm:basis-2/3">
            <h2>{t("forUsers.header02")}</h2>
            <p className="mt-2 p-2 sm:mt-4 sm:p-6 ">{t("forUsers.text02")}</p>
          </div>
        </div>
        <div className="flex flex-col items-center gap-4 sm:flex-row-reverse ">
          <div className="h-80 sm:flex-1 sm:basis-1/3">
            <img src={mobile2Img} className="h-full object-contain" alt="" />
          </div>
          <div className="flex-1 sm:basis-2/3 ">
            <h2>{t("forUsers.header03")}</h2>
            <p
              style={{ fontFamily: "Poppins" }}
              className="mt-2 p-2 sm:mt-4 sm:p-6 "
            >
              {t("forUsers.text03")}
            </p>
          </div>
        </div>
        <div className="flex flex-col items-center gap-4 sm:flex-row ">
          <div className="h-80 sm:flex-1 sm:basis-1/3 ">
            <img src={mobile3Img} className="h-full object-contain" alt="" />
          </div>
          <div className="flex-1 sm:basis-2/3">
            <h2>{t("forUsers.header04")}</h2>
            <p className="mt-2 p-2 sm:mt-4 sm:p-6">{t("forUsers.text04")}</p>
          </div>
        </div>
        <div className="flex flex-col items-center gap-4 sm:flex-row-reverse">
          <div className="h-80 sm:flex-1 sm:basis-1/3">
            <img src={mobile4Img} className="h-full object-contain" alt="" />
          </div>
          <div className="flex-1 sm:basis-2/3">
            <h2>{t("forUsers.header05")}</h2>
            <p className="mt-2 p-2 sm:mt-4 sm:p-6">{t("forUsers.text05")}</p>
            <p className="p-4 text-center text-base sm:text-xl">
              {t("forUsers.socialMediaClaim")}
            </p>
            <SocialIcons />
          </div>
        </div>
        <div className="flex flex-col items-center gap-4 sm:gap-8">
          <BadgesImage className="max-w-3xl" />
          <div className="flex flex-1 flex-col gap-4 sm:basis-2/3 sm:gap-8">
            <h2>{t("forUsers.header06")}</h2>
            <div className="flex max-w-4xl flex-col gap-2">
              <p>{t("forUsers.text06")}</p>
              <p>{t("forUsers.text06_bis")}</p>
            </div>
            <LinkButton className="self-center" to={t("forUsers.link")}>
              {t("forUsers.button06")}
            </LinkButton>
          </div>
        </div>
        <WaitlistForm target="user" />
      </div>
    </main>
  );
};

function HeroImage() {
  const { t } = useTranslation();
  const [modalIsOpen, setModalIsOpen] = useState(false);
  return (
    <>
      <FindRestaurantsModal
        open={modalIsOpen}
        setOpen={setModalIsOpen}
        target="user"
      />
      <div className="relative isolate mb-8 h-64 sm:mb-12 sm:h-[500px] md:mb-16">
        <img src={heroImg} alt="" className="absolute h-full object-cover" />
        <div className="absolute h-full w-full bg-vdarkgreen opacity-60 " />
        <div className="absolute flex h-full w-full flex-col items-center justify-center gap-2 p-2 sm:gap-6 lg:gap-8">
          <p
            style={{ fontFamily: "DIN" }}
            className="max-w-sm text-center text-3xl text-white drop-shadow sm:max-w-xl sm:text-6xl md:text-7xl lg:max-w-5xl lg:text-8xl"
          >
            {t("forUsers.title")}
          </p>
          <Button
            onClick={() => setModalIsOpen(!modalIsOpen)}
            className="text-3xl sm:text-4xl md:text-5xl"
          >
            {t("forUsers.findRestaurants")}
          </Button>
        </div>
      </div>
    </>
  );
}
