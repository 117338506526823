import React from "react";

function Button({
  children,
  className,
  onClick,
  type,
  isLoading = false,
  isDisabled = false,
  icon,
  variant = "normal",
}) {
  const warningStyle = "bg-orange-500 text-white hover:bg-orange-400";
  const dangerStyle = "bg-red-500 text-white hover:bg-red-400";
  const secondaryStyle =
    "bg-white text-gray-700 border border-gray-300 hover:bg-gray-300";
  const normalStyle = "bg-vgreen hover:bg-vlightgreen text-vdarkgreen";
  return (
    <button
      className={`${className} ${isLoading ? "pointer-events-none" : null} ${
        isDisabled ? "pointer-events-none bg-gray-300" : null
      } ${variant === "warning" ? warningStyle : null} ${
        variant === "danger" ? dangerStyle : null
      } ${variant === "secondary" ? secondaryStyle : null} ${
        variant === "normal" ? normalStyle : null
      } relative rounded-sm p-2 px-8 text-center text-xs font-semibold focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2 sm:p-3 sm:px-10 sm:text-sm md:text-base`}
      style={{ fontFamily: "Poppins" }}
      onClick={onClick}
      type={type}
      disabled={isDisabled}
    >
      {children}
      <span className="absolute inset-y-0 left-0 flex items-center pl-3">
        {icon}
      </span>
      {isLoading && (
        <span className="absolute inset-y-0 right-0 flex items-center pr-3">
          <svg fill="none" viewBox="0 0 24 24" className="w-5 h-5 animate-spin">
            <circle
              opacity="0.25"
              cx="12"
              cy="12"
              r="10"
              stroke="currentColor"
              strokeWidth="4"
            ></circle>
            <path
              opacity="0.75"
              fill="currentColor"
              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
            ></path>
          </svg>
        </span>
      )}
    </button>
  );
}

export default Button;
