import { fakeWith } from "utils/fakeQuery";
import { fake_get_issues_by_current_user } from "fakeData/fake_get_issues_by_current_user";
import { fake_get_issue_by_id } from "fakeData/fake_get_issue_by_id";
import { fake_update_issue_status } from "fakeData/fake_update_issue_status";

const issues = function (api) {
  async function mine(input) {
    const query = `query { result: issues__mine_as_owner{ok payload {
        id
        user_id
        restaurant { id name }
        description
        keyword
        date
        completed_date
        photos
        restaurant_comment
      } errors}}`;
    const data = await api.gql(query);
    return data;
  }

  async function byId({ issue_id }) {
    const query = `query ($id: String) { result: issues__by_id(id:$id) {ok payload {
        id
        user_id
        restaurant { id name }
        description
        keyword
        date
        completed_date
        photos
        restaurant_comment
      } errors}}`;
    const data = await api.gql(query, { id: issue_id });
    return data;
  }

  async function update(input) {
    return;
  }

  // SIMULATIONS: Turn to false to deactivate
  const simulate = false;
  const _mine = fakeWith(fake_get_issues_by_current_user, mine, simulate);
  const _byId = fakeWith(fake_get_issue_by_id, byId, simulate);
  const _update = fakeWith(fake_update_issue_status, update, simulate);

  return {
    mine: _mine || mine,
    byId: _byId || byId,
    update: _update || update,
  };
};

export default issues;
