import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { ReactComponent as Logo } from "./img/logo.svg";
import { navigation } from "routes";
import { legalLinks } from "routes";
import { ReactComponent as InstagramIcon } from "assets/images/icons/instagramIcon.svg";
import { ReactComponent as LinkedInIcon } from "assets/images/icons/linkedInIcon.svg";
import { ReactComponent as FacebookIcon } from "assets/images/icons/facebookIcon.svg";

export const Footer = () => {
  const { i18n, t } = useTranslation();
  const language = i18n.resolvedLanguage;
  return (
    <footer
      className="mt-4 bg-vdarkgreen p-6 text-white sm:mt-12"
      style={{ fontFamily: "Poppins" }}
    >
      <div className="mx-auto hidden max-w-7xl items-center justify-evenly sm:flex">
        <Logo className="m-4 w-64" />
        <div className="m-4 flex gap-12">
          <div className="">
            <p className="text-2xl text-white" style={{ fontFamily: "DIN" }}>
              {t("footer.siteMap")}
            </p>
            {navigation[language].map((item) => {
              return (
                <Link
                  to={item.to}
                  className="block py-1 text-sm lg:text-base"
                  key={item.name}
                >
                  {item.name}
                </Link>
              );
            })}
          </div>
          <div className="">
            <p style={{ fontFamily: "DIN" }} className="text-2xl text-white">
              {t("footer.legal")}
            </p>
            {legalLinks[language].map((item) => {
              return (
                <Link
                  to={item.to}
                  className="block py-1 text-sm lg:text-base"
                  key={item.name}
                >
                  {item.name}
                </Link>
              );
            })}
          </div>
          <div className="ml-4 hidden items-center justify-start gap-6 lg:flex lg:flex-col">
            <p style={{ fontFamily: "DIN" }} className="text-2xl text-white">
              {t("footer.followUs")}
            </p>
            <SocialIcons />
            <p className="text-white">contact@verdantips.com</p>
          </div>
        </div>
      </div>
      <div className="hidden flex-col items-center gap-4 sm:flex lg:hidden">
        <p style={{ fontFamily: "DIN" }} className="text-2xl text-white">
          {t("footer.followUs")}
        </p>
        <SocialIcons />
        <p>contact@verdantips.com</p>
      </div>
      <p className="text-center text-white sm:mt-4">© Verdantips 2022</p>
    </footer>
  );
};

function SocialIcons() {
  return (
    <div className="flex justify-center gap-4 ">
      <a
        href="https://www.linkedin.com/company/verdantips/"
        className="rounded-full bg-vgreen p-4 "
        target="_blank"
        rel="noopener noreferrer"
      >
        <LinkedInIcon className="w-6" />
      </a>
      <a
        href="https://www.instagram.com/verdantips.sl/"
        target="_blank"
        rel="noopener noreferrer"
        className="rounded-full bg-vgreen p-4 "
      >
        <InstagramIcon className="w-6" />
      </a>
      <a
        href="https://www.facebook.com/business.verdantips/"
        target="_blank"
        rel="noopener noreferrer"
        className="rounded-full bg-vgreen p-4 "
      >
        <FacebookIcon className="w-6" />
      </a>
    </div>
  );
}
