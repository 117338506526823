import React from "react";
import { Fragment, useRef } from "react";
import { useTranslation } from "react-i18next";
import { Dialog, Transition } from "@headlessui/react";
import { CheckIcon } from "@heroicons/react/outline";
import { format } from "date-fns";
import Button from "components/Button";
import { useUpdateIssue } from "hooks/issuesHooks";
import { ErrorBlock } from "components/ErrorBlock";

const MINIMUM_MESSAGE_LENGTH = 5;

export default function ModalSolveIssue({ open, setOpen, issueId }) {
  const { t } = useTranslation();
  const { mutate: updateIssue, isLoading } = useUpdateIssue();
  const focusRef = useRef(null);
  const [message, setMessage] = React.useState("");
  const [error, setError] = React.useState([]);

  function handleSolve() {
    const today = format(new Date(), "yyyy-MM-dd");
    setError([]);
    setMessage("");
    updateIssue(
      {
        issue_id: issueId,
        completed_date: today,
        restaurant_comment: message,
      },
      {
        onError: () => setError(["unknownError"]),
        onSuccess: (data) => {
          if (data.data.result.ok === true) {
            return setOpen(false);
          }
          if (data.data.result.ok === false) {
            return setError(["deniedError"]);
          }
        },
      }
    );
  }

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 z-10 overflow-y-auto"
        initialFocus={focusRef}
        onClose={setOpen}
      >
        <div className="flex min-h-screen items-center justify-center px-4 pt-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden sm:inline-block sm:h-screen sm:align-middle"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="relative inline-block transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left align-bottom shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6 sm:align-middle">
              <div>
                <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-green-100">
                  <CheckIcon
                    className="h-6 w-6 text-green-600"
                    aria-hidden="true"
                  />
                </div>
                <div className="mt-3 text-center sm:mt-5">
                  <Dialog.Title
                    as="h2"
                    className="text-2xl font-medium leading-6 text-gray-900"
                  >
                    {t("issue.markSolved")}
                  </Dialog.Title>
                  <div className="mt-2">
                    <p className="text-left text-sm text-gray-500">
                      {t("issue.solveMessage")}
                    </p>
                  </div>
                  <TextComment
                    message={message}
                    setMessage={setMessage}
                    focusRef={focusRef}
                  />
                </div>
              </div>
              <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
                <Button variant="secondary" onClick={() => setOpen(false)}>
                  {t("issue.cancel")}
                </Button>
                <Button
                  isLoading={isLoading}
                  isDisabled={
                    message.length > MINIMUM_MESSAGE_LENGTH ? false : true
                  }
                  onClick={handleSolve}
                >
                  {t("issue.solve")}
                </Button>
              </div>
              {error.length > 0 && (
                <ErrorBlock className="mt-4" errorList={error} />
              )}
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

function TextComment({ message, setMessage, focusRef }) {
  return (
    <div>
      <div className="mt-4">
        <textarea
          rows={4}
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          name="comment"
          id="comment"
          className="block w-full rounded-md border-gray-300 shadow-sm focus:border-green-500 focus:ring-green-500 sm:text-sm"
          ref={focusRef}
        />
      </div>
    </div>
  );
}
