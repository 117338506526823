import { FakeResponseCollection } from "utils/fakeResponseCollection";

export const fake_add_restaurant_to_waitlist = new FakeResponseCollection();

fake_add_restaurant_to_waitlist.set({
  input: {
    restaurant_name: "restaurant",
    email: "kk@kk.com",
    city: "city",
    contact_name: "contact",
    contact_position: "owner",
  },
  response: {
    ok: true,
  },
});

fake_add_restaurant_to_waitlist.set({
  input: {
    restaurant_name: "restaurant",
    email: "kk@kk.com",
    city: "city",
    contact_name: "contact",
    contact_position: "fail",
  },
  response: {
    ok: false,
  },
});
