import React from "react";
import { useTranslation } from "react-i18next";
import { MarketIcon } from "components/MarketIcons/MarketIcon";
import { BadgeIcon } from "components/BadgeIcon";
import { useGetRestaurantInfo } from "hooks/restaurantHooks";
import { useParams } from "react-router-dom";
import { FullPageLoader } from "components/Loading";
import { fixSustainabilityList } from "utils/misc";
import { t } from "i18next";
import { LinkText } from "components/LinkText";
import { ArrowLeftIcon } from "@heroicons/react/outline";

export function RestaurantInfo() {
  const { t } = useTranslation();
  const params = useParams();
  const restaurantId = params.id;
  const { data: restaurantInfo, isLoading: isLoadingRestaurantInfo } =
    useGetRestaurantInfo({ id: restaurantId });
  const {
    photos,
    name,
    description,
    cuisine = [],
    services = [],
    dietary = [],
    sustainability,
    certifications,
  } = { ...restaurantInfo?.data.result.payload };

  const cuisineText = cuisine.map((item, index) => {
    return (
      <span key={item}>{`${t(`keywords.${item}`)}${
        index === cuisine.length - 1 ? "" : ","
      } `}</span>
    );
  });

  const servicesText = services.map((item, index) => {
    return (
      <span key={item}>{`${t(`keywords.${item}`)}${
        index === services.length - 1 ? "" : ","
      } `}</span>
    );
  });

  const dietaryText = dietary.map((item, index) => {
    return (
      <span key={item}>{`${t(`keywords.${item}`)}${
        index === dietary.length - 1 ? "" : ","
      } `}</span>
    );
  });

  if (sustainability) fixSustainabilityList(sustainability, certifications);

  if (isLoadingRestaurantInfo) return <FullPageLoader />;

  return (
    <main>
      <div className="mx-auto flex max-w-4xl items-center text-vaccent">
        <LinkText className="flex items-center" to={t("links.restaurants")}>
          <ArrowLeftIcon className="mr-2 h-4 w-4 " />
          {t("restaurantInfo.toRestaurants")}
        </LinkText>
      </div>
      <div className="mx-auto flex max-w-7xl flex-col gap-8 px-4 sm:gap-12 sm:px-8 md:gap-16">
        <div className="h-96">
          <img
            src={photos?.cover}
            className="mt-4 h-full self-center overflow-hidden rounded-lg object-contain"
            alt=""
          />
        </div>
        <div className="mx-auto flex max-w-3xl flex-col gap-4 sm:gap-8">
          <div className="flex flex-col gap-2">
            <h1 className="self-center text-5xl">{name}</h1>
            <p>{description}</p>
          </div>
          <div className="flex flex-col gap-2">
            <h2 className="self-center text-4xl">
              {t("restaurantInfo.restaurantDetails")}
            </h2>
            <ul className=" ml-8 flex list-disc flex-col gap-2">
              <li>
                <span className="font-semibold">{`${t(
                  "restaurantInfo.cuisine"
                )}: `}</span>
                <span>{cuisineText}</span>
              </li>
              <li>
                <span className="font-semibold">{`${t(
                  "restaurantInfo.services"
                )}: `}</span>
                <span>{servicesText}</span>
              </li>
              {dietary.length > 0 && (
                <li>
                  <span className="font-semibold">{`${t(
                    "restaurantInfo.dietary"
                  )}: `}</span>
                  <span>{dietaryText}</span>
                </li>
              )}
              <li>
                <div className="flex flex-col gap-2">
                  <p className="font-semibold">{`${t(
                    "restaurantInfo.badges"
                  )}: `}</p>
                  <div className="flex flex-wrap gap-4">
                    {sustainability?.map((item) => {
                      return <BadgeAndText key={item} keyword={item} />;
                    })}
                  </div>
                </div>
              </li>
            </ul>
          </div>
          <div className="flex flex-col gap-2">
            <h2 className="self-center text-4xl">{t("restaurantInfo.more")}</h2>
            <p>{t("restaurantInfo.download")}</p>
            <ul className=" ml-8 flex list-disc flex-col gap-2">
              <li>{t("restaurantInfo.map")}</li>
              <li>{t("restaurantInfo.price")}</li>
              <li>{t("restaurantInfo.reservation")}</li>
              <li>{t("restaurantInfo.opening")}</li>
              <li>{t("restaurantInfo.photos")}</li>
              <li>{t("restaurantInfo.contact")}</li>
              <li>{t("restaurantInfo.future")}</li>
            </ul>
          </div>
          <div className="mb-4 flex flex-col items-center justify-around gap-6 sm:flex-row">
            <MarketIcon market="apple" className="w-60 sm:h-20" />
            <MarketIcon market="google" className="w-60 sm:h-20" />
          </div>
        </div>
      </div>
    </main>
  );
}

function BadgeAndText({ keyword }) {
  return (
    <div className="flex items-center gap-1">
      <BadgeIcon keyword={keyword} />
      <p>{t(`keywords.${keyword}`)}</p>
    </div>
  );
}
