export const jobOffers = {
  es: [
    {
      title: "Co-Fundador Tecnológico",
      description:
        "Buscamos un desarrollador full-stack con experiencia en liderazgo para unirse a nosotros como miembro fundador del equipo y mantener nuestro producto.",
      link: "https://www.google.com",
    },
    {
      title: "Becario de Desarrollo de Negocio",
      description:
        "Buscando un estudiante de negocios carismático para ayudarnos a generar clientes potenciales, atraer socios y clientes y vender nuestro producto.",
      link: "https://www.google.com",
    },
  ],
  en: [
    {
      title: "Tech Co-Founder",
      description:
        "Seeking a full-stack developer with leadership experience to join us as a founding team member and maintain our product.",
      link: "https://www.google.com",
    },
    {
      title: "Business Development Intern",
      description:
        "Seeking a charismatic business studen to help us generate leads, engage partners and customers and sell our product.",
      link: "https://www.google.com",
    },
  ],
};
