import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useGetRestaurantInfo } from "hooks/restaurantHooks";
import { FullPageLoader } from "components/Loading";
import { ErrorPage } from "components/ErrorPage";
import { TabHeader } from "components/TabHeader";
import { LinkText } from "components/LinkText";
import { EditRestaurantContactInformation } from "components/Forms/EditRestaurantContactInformation";
import { EditRestaurantOpenHours } from "components/Forms/EditRestaurantOpenHours";
import { EditRestaurantClosedDays } from "components/Forms/EditRestaurantClosedDays";
import { EditRestaurantDetails } from "components/Forms/EditRestaurantDetails";
import { EditRestaurantPhotos } from "components/Forms/EditRestaurantPhotos";
import { EditRestaurantBadges } from "components/Forms/EditRestaurantBadges";
import { EditConsent } from "components/Forms/EditConsent";
import { ArrowLeftIcon } from "@heroicons/react/outline";
import { ModalConfirm } from "components/ModalConfirm";

const tabs = [
  { name: "editRestaurant.contactTab" },
  { name: "editRestaurant.openTab" },
  { name: "editRestaurant.closedTab" },
  { name: "editRestaurant.detailsTab" },
  { name: "editRestaurant.photosTab" },
  { name: "editRestaurant.badgesTab" },
  { name: "editRestaurant.consentTab" },
];
const tabsNoConsent = [
  { name: "editRestaurant.contactTab" },
  { name: "editRestaurant.openTab" },
  { name: "editRestaurant.closedTab" },
  { name: "editRestaurant.detailsTab" },
  { name: "editRestaurant.photosTab" },
  { name: "editRestaurant.badgesTab" },
];
export const RestaurantDetail = () => {
  const { t } = useTranslation();
  const params = useParams();
  const restaurantId = params.id;
  const [selectedTab, setSelectedTab] = useState("editRestaurant.contactTab");
  const [targetTab, setTargetTab] = useState();
  const {
    data: restaurantInfo,
    isLoading: isLoadingRestaurantInfo,
    isError: isErrorRestaurantInfo,
  } = useGetRestaurantInfo({ id: restaurantId });
  const [openModalConfirm, setOpenModalConfirm] = useState(false);
  const [isUpdateDisabled, setIsUpdateDisabled] = useState(true);

  if (isLoadingRestaurantInfo) return <FullPageLoader />;
  if (isErrorRestaurantInfo) return <ErrorPage />;
  return (
    <>
      <ModalConfirm
        open={openModalConfirm}
        setOpen={setOpenModalConfirm}
        confirmText={"editRestaurant.confirmMessage"}
        confirmFunction={() => {
          setSelectedTab(targetTab);
          return setOpenModalConfirm(false);
        }}
      />
      <div className="px-4 pt-4">
        <div className="mx-auto my-2 flex max-w-4xl items-center text-vaccent">
          <LinkText className="flex items-center" to="/dashboard">
            <ArrowLeftIcon className="mr-2 h-4 w-4 " />
            {t("general.backDashboard")}
          </LinkText>
        </div>
        <h1 className="m-4 text-center text-4xl sm:m-4 sm:text-6xl">
          {t("editRestaurant.title")}
        </h1>
        <TabHeader
          className="mx-auto mt-4 max-w-3xl"
          tabs={
            restaurantInfo?.data.result.payload.signed_consent === true
              ? tabsNoConsent
              : tabs
          }
          selectedTab={selectedTab}
          setSelectedTab={setSelectedTab}
          isUpdateDisabled={isUpdateDisabled}
          setOpenModalConfirm={setOpenModalConfirm}
          setTargetTab={setTargetTab}
        />
        {selectedTab === "editRestaurant.contactTab" && (
          <EditRestaurantContactInformation
            restaurantInfo={restaurantInfo.data.result.payload}
            isUpdateDisabled={isUpdateDisabled}
            setIsUpdateDisabled={setIsUpdateDisabled}
          />
        )}
        {selectedTab === "editRestaurant.openTab" && (
          <EditRestaurantOpenHours
            restaurantInfo={restaurantInfo.data.result.payload}
            isUpdateDisabled={isUpdateDisabled}
            setIsUpdateDisabled={setIsUpdateDisabled}
          />
        )}
        {selectedTab === "editRestaurant.closedTab" && (
          <EditRestaurantClosedDays
            restaurantInfo={restaurantInfo.data.result.payload}
            isUpdateDisabled={isUpdateDisabled}
            setIsUpdateDisabled={setIsUpdateDisabled}
          />
        )}
        {selectedTab === "editRestaurant.detailsTab" && (
          <EditRestaurantDetails
            restaurantInfo={restaurantInfo.data.result.payload}
            isUpdateDisabled={isUpdateDisabled}
            setIsUpdateDisabled={setIsUpdateDisabled}
          />
        )}
        {selectedTab === "editRestaurant.photosTab" && (
          <EditRestaurantPhotos
            restaurantInfo={restaurantInfo.data.result.payload}
            isUpdateDisabled={isUpdateDisabled}
            setIsUpdateDisabled={setIsUpdateDisabled}
          />
        )}
        {selectedTab === "editRestaurant.badgesTab" && (
          <EditRestaurantBadges
            restaurantInfo={restaurantInfo.data.result.payload}
            isUpdateDisabled={isUpdateDisabled}
            setIsUpdateDisabled={setIsUpdateDisabled}
          />
        )}
        {selectedTab === "editRestaurant.consentTab" && (
          <EditConsent
            restaurantInfo={restaurantInfo.data.result.payload}
            isUpdateDisabled={isUpdateDisabled}
            setIsUpdateDisabled={setIsUpdateDisabled}
          />
        )}
      </div>
    </>
  );
};
