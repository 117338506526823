import { FakeResponseCollection } from "utils/fakeResponseCollection";

export const fake_check_token = new FakeResponseCollection();

fake_check_token.set({
  input: {
    token: "TOKEN",
  },
  response: {
    data: {
      is_valid: true,
    },
  },
});

fake_check_token.set({
  input: {
    token: "BAD-TOKEN",
  },
  response: {
    data: {
      is_valid: false,
    },
  },
});
