import React from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { ReactComponent as Hero } from "./hero.svg";
import Button from "components/Button";
import { ErrorBlock } from "components/ErrorBlock";
import { SuccessBlock } from "components/successBlock";
import { useSendActivation } from "hooks/authenticationHooks";

const LIMIT_SEND = 5; // After this number we don't allow the user to send more

export function ActivateAccount() {
  const { t } = useTranslation();
  const location = useLocation();
  const email = location.state;
  const { mutate: sendActivation, isLoading: isLoadingSendActivation } =
    useSendActivation();
  const [errorList, setErrorList] = React.useState([]);
  const [sendNumber, setSendNumber] = React.useState(0);
  const [successList, setSuccessList] = React.useState([]);

  const send = React.useCallback(
    async (email) => {
      const errors = [];
      setErrorList([]);
      setSuccessList([]);
      setSendNumber((prev) => prev + 1);
      try {
        sendActivation(
          { email },
          {
            onSuccess: (data) => {
              if (data.ok === false) {
                return setErrorList(["sendActivationError"]);
              }
              if (data.ok === true)
                return setSuccessList(["sendActivationSuccess"]);
            },
          }
        );
      } catch (e) {
        errors.push("sendActivationError");
        setErrorList(errors);
      }
    },
    [sendActivation]
  );

  React.useEffect(() => {
    send(email);
  }, [send, email]);

  return (
    <div className="max-w-2xl min-h-full mx-auto sm:mt-10">
      <h1 className="m-8 text-4xl font-extrabold text-center sm:text-6xl">
        {t("activate.title")}
      </h1>
      <Hero className="max-w-xs p-4 mx-auto" />
      <p className="p-4 mx-auto sm:text-lg">{t("activate.text")}</p>
      <div className="flex justify-center">
        <Button
          isLoading={sendNumber === 1 ? null : isLoadingSendActivation} // We avoid loading in first sending
          className="m-2 text-xl max-w-max sm:p-6 sm:text-2xl md:text-3xl "
          onClick={() => {
            if (sendNumber < LIMIT_SEND) send(email);
            if (sendNumber >= LIMIT_SEND) {
              setSuccessList([]);
              setErrorList(["noMoreActivationSendError"]);
            }
          }}
        >
          {t("activate.send")}
        </Button>
      </div>
      {/* if there is an error on the first sending we don't display anything */}
      {sendNumber > 1 && errorList.length > 0 && (
        <ErrorBlock className="mt-3" errorList={errorList} />
      )}
      {sendNumber > 1 && successList.length > 0 && (
        <SuccessBlock className="mt-3" successList={successList} />
      )}
    </div>
  );
}
