import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useUpdateRestaurant } from "hooks/restaurantHooks";
import { MultipleChoices } from "components/FormElements";
import { LinkText } from "components/LinkText";
import { StepSubmitButtons } from "./StepSubmitButtons";

const consentList = ["CONSENT1", "CONSENT2", "CONSENT3"];

export function EditConsent({
  restaurantInfo,
  isUpdateDisabled,
  setIsUpdateDisabled,
}) {
  const { id, signed_consent } = restaurantInfo;
  const { t } = useTranslation();
  const { mutate: updateRestaurant, isLoading: isLoadingUpdateRestaurant } =
    useUpdateRestaurant();
  const [error, setError] = useState([]);
  const [success, setSuccess] = useState([]);
  const [consent, setConsent] = useState(
    signed_consent === true ? consentList : []
  );
  // const [isAccepted, setIsAccepted] = useState(signed_consent);

  React.useEffect(() => {
    if (consent.length === 3) {
      return setIsUpdateDisabled(false);
    } else {
      return setIsUpdateDisabled(true);
    }
  }, [setIsUpdateDisabled, consent]);

  function handleNext() {
    const input = {
      id,
      signed_consent: consent.length === 3 ? true : false,
    };
    updateRestaurant(input, {
      onError: (error) => {
        setError([error.message]);
      },
      onSuccess: (data) => {
        if (!data) return setError(["unknownError"]);
        if (data.data.result.ok === false) return setError(["deniedError"]);
        if (data.data.result.ok === true) {
          window.location.reload();
          // For now we reload the page so we don't handle if clients want to revoke consent
          return setSuccess(["informationUpdated"]);
        }
      },
    });
  }

  return (
    <div className="mx-auto mt-4 max-w-2xl px-4 sm:mt-8">
      <p className="mt-1 mb-6 text-sm text-gray-500 sm:text-base">
        {t("consent.intro")}
      </p>
      <div className="flex flex-col items-center gap-2 sm:gap-4">
        <MultipleChoices
          intro=""
          optionsList={consentList}
          setSelection={setConsent}
          selection={consent}
        />
        <div className="flex flex-col items-center">
          <LinkText to={t("links.webTermsConditions")} target="_blank">
            {t("consent.conditions")}
          </LinkText>
          <LinkText to={t("links.webTermsUse")} target="_blank">
            {t("consent.use")}
          </LinkText>
          <LinkText to={t("links.privacy")} target="_blank">
            {t("consent.privacy")}
          </LinkText>
        </div>
      </div>
      <StepSubmitButtons
        isLoading={isLoadingUpdateRestaurant}
        next={t("editRestaurant.acceptConsent")}
        onNext={handleNext}
        error={error}
        success={success}
        skip={true}
        className="mb-7 sm:mb-14"
        isDisabled={isUpdateDisabled}
      />
    </div>
  );
}
