import { FakeResponseCollection } from "utils/fakeResponseCollection";

export const fake_forgotPassword = new FakeResponseCollection();

fake_forgotPassword.set({
  input: {
    email: "kk@kk.com",
  },
  response: {
    data: {
      result: {
        ok: true,
      },
    },
  },
});

fake_forgotPassword.set({
  input: {
    email: "forgotPassword-fail@verdantips.com",
  },
  response: {
    data: {
      result: {
        ok: false,
        errors: {
          email: { error_code: "OBJECT-NOT-EXISTS" },
        },
      },
    },
  },
});
