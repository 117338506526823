import React from "react";
import { useTranslation } from "react-i18next";
import apple_es from "./img/app_store_es.png";
import apple_en from "./img/app_store_en.png";
import google_es from "./img/google_play_es.png";
import google_en from "./img/google_play_en.png";

export function MarketIcon({ market, className }) {
  const { i18n } = useTranslation();
  const language = i18n.resolvedLanguage;
  if (market === "apple") {
    return (
      <a
        href="https://apps.apple.com/nz/app/verdantips/id1563146322"
        target="_blank"
        rel="noopener noreferrer"
        className={className}
      >
        <img
          src={language === "es" ? apple_es : apple_en}
          alt=""
          className="w-full object-contain sm:h-full"
        />
      </a>
    );
  }
  if (market === "google") {
    return (
      <a
        href="https://play.google.com/store/apps/details?id=com.verdantip.verdantips"
        target="_blank"
        rel="noopener noreferrer"
        className={className}
      >
        <img
          src={language === "es" ? google_es : google_en}
          alt=""
          className="w-full object-contain sm:h-full"
        />
      </a>
    );
  }
}
