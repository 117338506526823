import { FakeResponseCollection } from "utils/fakeResponseCollection";

export const fake_get_issues_by_current_user = new FakeResponseCollection();

fake_get_issues_by_current_user.set({
  input: {
    test: "RESPONSE",
  },
  response: {
    data: {
      result: {
        ok: true,
        payload: [
          {
            id: "issue-1",
            user_id: "user-1",
            restaurant: {
              id: "restaurant-1",
              name: "Garibolo", // TODO by Backend: Can this info come from the query? If not provided with this query we would need to make separate queries for each restaurant Id
            },
            description: "The waiter didn't wear mask all the time",
            keyword: "COVICONS", // TODO by Backend: Better if we have only one string instead of a list
            date: "2021-11-02",
            completed_date: null,
            photos: [
              "https://picsum.photos/seed/picsum/400/300",
              "https://picsum.photos/seed/picsum/400/300",
              "https://picsum.photos/seed/picsum/400/300",
            ],
            restaurant_comment: null,
          },
          {
            id: "issue-2",
            user_id: "user-2",
            restaurant: {
              id: "restaurant-2",
              name: "La Venta de Villarriba de Quintanilla de Rubio Somera",
            },
            description:
              "The waiter said that my dog is too big so they force us to leave it outside",
            keyword: "DOGS_ALLOWED",
            date: "2022-08-02",
            completed_date: null,
            photos: [
              "https://picsum.photos/seed/picsum/400/300",
              "https://picsum.photos/seed/picsum/400/300",
              "https://picsum.photos/seed/picsum/400/300",
            ],
            restaurant_comment: null,
          },
          {
            id: "issue-3",
            user_id: "user-3",
            restaurant: {
              id: "restaurant-1",
              name: "Garibolo",
            },
            description:
              "The wifi disconnects a lot and is practically unusable",
            keyword: "FREE_WIFI",
            date: "2021-03-05",
            completed_date: "2022-01-25",
            photos: [
              "https://picsum.photos/seed/picsum/400/300",
              "https://picsum.photos/seed/picsum/400/300",
              "https://picsum.photos/seed/picsum/400/300",
            ],
            restaurant_comment:
              "We talked to our ISP and they fixed our router so now it works perfectly.",
          },
          {
            id: "issue-4",
            user_id: "user-4",
            restaurant: {
              id: "restaurant-2",
              name: "La Venta de Villarriba de Quintanilla de Rubio Somera",
            },
            description: "The entrance is too steep for a wheelchair",
            keyword: "HANDICAP_ACCESSIBLE",
            date: "2021-02-02",
            completed_date: null,
            photos: [
              "https://picsum.photos/seed/picsum/400/300",
              "https://picsum.photos/seed/picsum/400/300",
              "https://picsum.photos/seed/picsum/400/300",
            ],
            restaurant_comment:
              "We are talking with a subcontractor to analyze how to improve the ramp",
          },
        ],
      },
    },
  },
});

fake_get_issues_by_current_user.set({
  input: {
    test: "EMPTY",
  },
  response: {
    data: {
      result: {
        ok: true,
        payload: [],
      },
    },
  },
});

fake_get_issues_by_current_user.set({
  input: {
    test: "DENIED",
  },
  response: {
    data: {
      result: {
        ok: false,
      },
    },
  },
});
