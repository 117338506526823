import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { BadgeIcon } from "components/BadgeIcon";

export function RestaurantCard({
  name,
  id,
  cover,
  description,
  cuisine,
  sustainability,
  address,
}) {
  const { t } = useTranslation();
  return (
    <Link
      to={`/restaurant/${id}`}
      className="flex w-80 cursor-pointer flex-col overflow-hidden rounded-lg border bg-white shadow"
    >
      <img
        src={cover}
        alt=""
        className="h-40 w-full border-b border-slate-300 object-cover"
      />
      <div className="my-3 flex-1 p-2">
        <h2 className="my-1 truncate text-center text-4xl">{name}</h2>
        <p className="text-sm">{description}</p>
      </div>
      <div className=" bg-slate-100 p-2">
        <p className="text-xs">
          {cuisine.map((item, index) => {
            return (
              <span key={item}>{`${t(`keywords.${item}`)}${
                index === cuisine.length - 1 ? "" : ","
              } `}</span>
            );
          })}
        </p>
        <p className="text-xs">{address}</p>
      </div>
      <div className="flex flex-wrap gap-1 bg-vgreen p-1 py-2">
        {sustainability.map((item) => {
          return <BadgeIcon keyword={item} key={item} />;
        })}
      </div>
    </Link>
  );
}
