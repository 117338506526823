import { FakeResponseCollection } from "utils/fakeResponseCollection";

export const fake_create_restaurant = new FakeResponseCollection();

fake_create_restaurant.set({
  input: {
    id: "restaurant-1", // Must be provided by the frontend
    owner_id: "kk@kk.com", // required
    name: "Restaurant", // required
    address: "Address", // required
    zipcode: 12345, // required
    city: "City", // required
    phone_number: "666555444", // required
    email: "restaurant-1@kk.com", // required
    urls: {
      website: "www.restaurant.com",
      google_page: "google.com/maps/place/restaurant.com",
      instagram: "",
      facebook: "",
    },
    location: {
      // required: TODO: This can be extracted from the Google Maps URL. Don't know if we have to do it in the frontend, backend or both
      longitude: 1.897,
      latitude: -8.2351,
    },
    status: "UNPUBLISHED", // Possible options: "PUBLISHED", "UNPUBLISHED", "UNDERREVIEW". The last just in case the company wants to check the entries before listing them
    //-- TODO by Backend: Here we have all fields that will be used with restaurants. We can initialize them when we create the account and update them afterwards
    open_hours: {
      monday: [], // Values are objects with "start" hour and "end" hour fields
      tuesday: [],
      wednesday: [],
      thursday: [],
      friday: [],
      saturday: [],
      sunday: [],
    },
    closed_days: [], // Values are date strings
    description: "", // Any string
    cuisine: [], // Values are keyword strings
    dietary: [], // Values are keyword strings
    services: [], // Values are keyword strings
    average_meal_price: null, // Values are numbers
    reservation_required: false,
    reservation_channel: [], // Possible options: "PHONE", "WHATSAPP", "ONLINE"
    photos: {
      cover: "", // Only one cover
      menu: [], // Values are url strings
      food: [], // Values are url strings
      ambience: [], // Values are url strings
      covid: [], // Values are url strings
    },
    sustainability: [], // Values are keyword strings
    certifications: {
      COVICONS: {
        covid_measures: [],
        other_covid_measures: "",
      },
      ECOCERTI: {
        eco_certification: [],
        extra_eco_certification: "",
      },
      ENEGEFFI: {
        energy_practices: [],
        energy_comments: "",
      },
      PLANFRIE: {
        entire_plant_based: false,
        two_main_dishes: false,
        plant_menu_includes: [],
        badge: "PLANTBASED", // Options: "PLANTBASED", "PLANTFRIENDLY"
      },
      PLASCONS: {
        has_plastic_free_certification: false,
        plastic_free_certification_name: "",
        plastic_free_certification_year: null,
        plastic_free_certification_duration: null,
        plastic_use: [],
        badge: "PLASTICFREE", // Options: "PLASTICFREE", "PLASTICCONSCIOUS"
        plastic_comments: "",
      },
      RENWENEG: {
        renewable_has_own: false,
        renewable_installation: [],
        renewable_consumption_percentage: null,
        renewable_start: null,
        renewable_has_electricity: false,
        renewable_electricity_provider: "",
        renewable_has_gas: false,
        renewable_gas_provider: "",
        renewable_comments: "",
      },
      SUSTMEAT: {
        meat_has_certification: false,
        meat_certification_type: [],
        meat_certification_name: "",
        meat_type: "",
        meat_is_local: false,
        meat_menu: [],
        meat_comments: "",
      },
      SUSTPROD: {
        produce_has_grow: false,
        produce_grow_percentage: "",
        produce_grow_type: [],
        produce_grow_other_type: "",
        produce_is_eu_certified: false,
        produce_organic_percentage: "",
        produce_is_local: false,
        produce_local_percentage: "",
        produce_comments: "",
      },
      SUSTSEAF: {
        has_seafood_certification: false,
        seafood_certification_type: [],
        seafood_certification_name: "",
        seafood_type: "",
        seafood_is_local: false,
        seafood_comments: "",
      },
      VEGEFRIE: {
        entirely_vegetarian: false,
        two_minimum_vegetarian_dishes: false,
        badge: "VEGETARIAN", // Options: "VEGETARIAN", "VEGETARIANFRIENDLY"
      },
      WASTCONS: {
        waste_sell_surplus: false,
        waste_surplus_description: "",
        waste_compost: false,
        waste_compost_explanation: [],
        waste_inventory: false,
        waste_recycle: [],
        waste_train: false,
        waste_customer_containers: false,
        waste_comments: "",
      },
      WATREFFI: {
        water_practices: [],
        water_comments: "",
      },
    },
    signed_consent: false,
  },
  response: {
    data: {
      result: {
        ok: true,
        // mutations do not return payload
        // payload: {
        //   id: "restaurant-1", // It's provided by the API
        // },
      },
    },
  },
});

fake_create_restaurant.set({
  input: {
    owner_id: "kk@kk.com", // required
    name: "Restaurant 2", // required
    address: "Address 1", // required
    zipcode: 12345, // required
    city: "City 1", // required
    phone_number: "666555444", // required
    email: "restaurant-1@kk.com", // required
    urls: {
      website: "www.restaurant-1.com",
      google_page: "google.com/maps/place/restaurant-1.com",
      instagram: null,
      facebook: null,
    },
    location: {
      // required: TODO: This can be extracted from the Google Maps URL. Don't know if we have to do it in the frontend, backend or both
      longitude: 1.897,
      latitude: -8.2351,
    },
  },
  response: {
    data: {
      result: {
        ok: false,
        // TODO by Backend: What specific errors can we have here?
        // 1. The restaurant already exists (by name, by google maps page, restaurant email...)
        // 2. Denied access
        // ??
      },
    },
  },
});
