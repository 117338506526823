import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import Button from "components/Button";
import { ModalAddTimeSlot } from "components/ModalAddTimeSlot";
import { StepSubmitButtons } from "./StepSubmitButtons";

const weekDays = [
  "monday",
  "tuesday",
  "wednesday",
  "thursday",
  "friday",
  "saturday",
  "sunday",
];

export function RestaurantOpenHours({
  setStep,
  restaurantInformation,
  setRestaurantInformation,
}) {
  const { open_hours } = restaurantInformation;
  const { t } = useTranslation();
  const [isNextDisabled, setIsNextDisabled] = useState(true);
  const [open, setOpen] = useState(false);
  const [schedule, setSchedule] = useState(open_hours);

  useEffect(() => {
    let isEmpty = true;
    for (const day in schedule) {
      if (schedule[day].length > 0) {
        isEmpty = false;
      }
    }
    if (isEmpty) return setIsNextDisabled(true);
    else return setIsNextDisabled(false);
  }, [schedule, open]);

  function handleNext() {
    setRestaurantInformation({ open_hours: schedule });
    setStep((prev) => prev + 1);
  }

  return (
    <div className="mx-auto max-w-3xl">
      <ModalAddTimeSlot
        open={open}
        setOpen={setOpen}
        schedule={schedule}
        setSchedule={setSchedule}
      />
      <h2 className="m-4 text-center text-3xl sm:m-4 sm:text-5xl">
        {t("restaurantOpen.title")}
      </h2>
      <p className="mt-4 max-w-2xl px-4 ">{t("restaurantOpen.intro")}</p>
      <div className="m-4">
        <Button onClick={() => setOpen(true)}>
          {t("restaurantOpen.addSlot")}
        </Button>
      </div>
      <div className="flex flex-col px-4 sm:px-16">
        {weekDays.map((day) => {
          return (
            <DayBlock
              key={day}
              day={day}
              slots={schedule[day]}
              setSchedule={setSchedule}
            />
          );
        })}
      </div>
      <StepSubmitButtons
        isDisabled={isNextDisabled}
        next={t("general.next")}
        back={t("general.back")}
        onNext={handleNext}
        onBack={() => setStep((prev) => prev - 1)}
      />
    </div>
  );
}

export function DayBlock({ day, slots, setSchedule }) {
  const { t } = useTranslation();
  return (
    <div className="m-2 max-w-xl rounded-md bg-gray-100 p-3 ">
      <div className="flex justify-between">
        <p className="mb-1 text-base font-semibold sm:text-lg">
          {t(`restaurantOpen.${day}`)}
        </p>
        <p className="mb-1 pr-2 text-base font-semibold sm:text-lg">
          {slots.length > 0 && t(`restaurantOpen.schedule`)}
        </p>
      </div>
      {slots.length > 0 && (
        <div className="relative mb-2">
          <div
            className="absolute inset-0 flex items-center"
            aria-hidden="true"
          >
            <div className="w-full border-[1px] border-gray-500" />
          </div>
        </div>
      )}
      <div className="mr-4 flex flex-col items-end gap-3">
        {slots.map(({ start, end }, index) => {
          return (
            <div key={index} className="flex items-center">
              <p className="mr-2 text-base sm:text-lg">{`${start[0]}:${start[1]} to ${end[0]}:${end[1]}`}</p>
              <RemoveButton
                day={day}
                index={index}
                setSchedule={setSchedule}
                className="h-8 w-8"
              />
            </div>
          );
        })}
      </div>
    </div>
  );
}

export function RemoveButton({ className, day, index, setSchedule }) {
  function handleClick() {
    setSchedule((prevSchedule) => {
      prevSchedule[day].splice(index, 1);
      return { ...prevSchedule };
    });
  }
  return (
    <button onClick={handleClick}>
      <svg
        className={className}
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 20 21"
      >
        <path fill="#D9EF59" d="M19 10.5a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
        <path
          stroke="#354D5A"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="m8 12.5 2-2m0 0 2-2m-2 2-2-2m2 2 2 2m7-2a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
        />
      </svg>
    </button>
  );
}
