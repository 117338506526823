import { FakeResponseCollection } from "utils/fakeResponseCollection";

export const fake_signIn = new FakeResponseCollection();

fake_signIn.set({
  input: {
    email: "kk@kk.com",
    password: "Password123",
  },
  response: {
    status: 200,
    data: {
      access_token: "TOKEN",
      email: "kk@kk.com",
      // Maybe we shouldn't provide more user info than this. If the component needs more we can use the specific api for user attributes
    },
  },
});

fake_signIn.set({
  input: {
    email: "kk@kk.com",
    password: "Password000",
  },
  response: {
    status: 401,
    data: {
      code: "BAD-USER-PASSWORD",
      msg: "Bad username or password",
    },
  },
});

fake_signIn.set({
  input: {
    email: "kk@kk.com",
    password: "Password666",
  },
  response: {
    status: 401,
    data: {
      code: "EMAIL-NOT-VALIDATED",
      msg: "Email not validated",
    },
  },
});

fake_signIn.set({
  input: {
    email: "fail@kk.com", // The fail will come when sending the activation mail
    password: "Password666",
  },
  response: {
    status: 401,
    data: {
      code: "EMAIL-NOT-VALIDATED",
      msg: "Email not validated",
    },
  },
});
