import React from "react";
import Button from "components/Button";
import { ErrorBlock } from "components/ErrorBlock";
import { SuccessBlock } from "components/successBlock";

export function StepSubmitButtons({
  error = [],
  isLoading,
  next,
  back,
  onNext,
  onBack,
  isDisabled = false,
  className,
  success = [],
}) {
  return (
    <div className={`mt-4 sm:mt-8 ${className}`}>
      <div className="mx-auto flex max-w-xl flex-col gap-2 sm:gap-4">
        {back !== undefined ? (
          <div className="flex justify-around ">
            <Button onClick={onBack} className="text-lg sm:text-xl md:text-3xl">
              {back}
            </Button>
            <Button
              onClick={onNext}
              isLoading={isLoading}
              isDisabled={isDisabled}
              className="text-lg sm:text-xl md:text-3xl"
            >
              {next}
            </Button>
          </div>
        ) : (
          <div className="flex justify-center ">
            <Button
              isLoading={isLoading}
              isDisabled={isDisabled}
              className="text-lg sm:text-xl md:text-3xl"
              onClick={onNext}
            >
              {next}
            </Button>
          </div>
        )}
        {error.length > 0 && <ErrorBlock errorList={error} />}
        {success.length > 0 && <SuccessBlock successList={success} />}
      </div>
    </div>
  );
}
