export const en = {
  title: "Title",
  _: "", // We use it to initialize states and avoid "missing key errors"

  links: {
    webTermsConditions: "/website-terms-and-conditions",
    webTermsUse: "/website-terms-of-use",
    appTerms: "/app-terms-and-conditions",
    privacy: "/privacy-policy",
    restaurants: "/sustainable-businesses",
  },

  errors: {
    undefined: "Unexpected error.",
    announceError: "An error has occurred.",
    accessError: "Couldn't access with the provided email and password.",
    registerError: "Couldn't register your information in the server.",
    forgotPasswordError:
      "Could't execute the reset password process in the server.",
    resetPasswordError:
      "Couldn't execute the change password process in the server.",
    connectionError: "Didn't receive response from the server.",
    unknownError: "Couldn't connect with the server.",
    repeatPasswordError: "The passwords don't match.",
    invalidEmailError: "The email format is not valid.",
    shortPasswordError: "The password should be at least 8 characters long.",
    sendActivationError: "Couldn't send the activation mail. Please try later.",
    deniedError: "The operation was denied by the server.",
    noMoreActivationSendError:
      "The maximum number of code sendings has been reached, please try again later.",
    rangeConflictError:
      "The selected hour range is inside of at least one of the current ones",
    selectMinimumDaysError: "Select at least one day",
    allFieldsError: "Enter all the inputs",
    bigImageError: "You can only upload images up to 10mb",
    tooManyImagesError:
      "Some of the images were rejected because exceeded the maximum limit",
    websiteError: "The website URL is not a valid URL",
    googlemapsError: "The Google Maps URL is not a valid URL",
    instagramError: "The Instagram URL is not a valid URL",
    facebookError: "The Facebook URL is not a valid URL",
    requiredError: "Please fill all the fields marked as required with a (*)",
    loadIssuesError: "Couldn't get your issues information from the server",
    loadRestaurantsError:
      "Couldn't get your restaurants information from the server",
    plantAnimalIncompatible:
      "The badges for Full Vegetarian and Full Plant Based are not compatible with Sustainable Meat or Seafood ones",
    notShortenedLinksError:
      "Google Maps shortened links are not valid. In order to get the right link you can visit the one you provided and copy the url that appears in the url bar with the domain https://www.google....",
    notGoogleLink:
      "You need to provide a valid Google Maps link from https://www.google....",
    emptyIntervalError: "The start hour can't be the same as the end one",
  },

  success: {
    announceSuccess: "Operation executed successfully",
    accountConfirmedTitle: "Account Created",
    accountConfirmedText: "You account has been created successfully.",
    passwordUpdatedTitle: "Password updated",
    passwordUpdatedText: "You password has been updated successfully.",
    signInLink: "Sign In",
    sendActivationSuccess:
      "An email with instructions has been sent to the provided email address",
    dashboardLink: "Go to the Dashboard",
    restaurantCreatedTitle: "Your restaurant has been published successfully",
    restaurantCreatedText:
      "Your restaurant is now published in our Verdantips platform. You can update your information using the dashboard.",
    informationUpdated: "The information has been updated successfully",
    contactEmailSent: "You contact message was sent successfully",
    waitlistSent: "You've been added to our waitlist successfully :)",
  },

  validateEmail: {
    titleSuccess: "Account Activated",
    titleFail: "Error activating your account",
    titleExpired: "The activation link has expired",
    textSuccess:
      "Your account was created successfully. Now you can access to our reserved zone for registered users.",
    textFail:
      "It seems that we had a problem activating your account. Please try again later.",
    textExpired:
      "The link that we sent you to activate your account is no longer useful. Please use the new one that we’ve sent you just now. If you don’t receive it you can use the button to ask for a new one.",
    linkSuccess: "Login",
  },

  keywords: {
    COVICONS: "Safety Conscious",
    ECOCERTI: "Eco-certified",
    ENEGEFFI: "Energy Effient",
    PLANFRIE: "Plant-based friendly",
    PLANTFRIENDLY: "Plant-based friendly",
    PLANTBASED: "Plant-based",
    PLANFULL: "Plant-based",
    PLASCONS: "Plastic conscious",
    PLASTICCONSCIOUS: "Plastic Conscious",
    PLASTICFREE: "Plastic Free",
    RENWENEG: "Renewable energy",
    SUSTMEAT: "Sustainable meat",
    SUSTPROD: "Sustainable produce",
    SUSTSEAF: "Sustainable seafood",
    VEGEFRIE: "Vegetarian friendly",
    VEGEFULL: "Vegetarian",
    VEGETARIAN: "Vegetarian",
    VEGETARIANFRIENDLY: "Vegetarian friendly",
    WASTCONS: "Waste conscious",
    WATREFFI: "Water efficient",
    AIR_CON: "Air conditioning",
    BOTTOMLESS: "Bottomless",
    BRUNCH: "Brunch",
    BUFFET: "Buffet",
    CASUAL_DINING: "Casual dining",
    COCKTAILS: "Cocktails",
    DAILY_SPECIAL: "Daily special",
    DOGS_ALLOWED: "Dogs allowed",
    FINE_DINING: "Fine dining",
    FREE_WIFI: "Free wifi",
    HANDICAP_ACCESSIBLE: "Handicap accessible",
    KIDS_MENU: "Kids menu",
    LIVE_MUSIC: "Live music",
    NO_RESERVATION_REQUIRED: "No reservation required",
    OUTDOOR_DINING: "Outdoor dining",
    OWN_DELIVERY: "Own delivery",
    PARTY_VIBE: "Party vibe",
    QUIET: "Quiet",
    RESERVATION_REQUIRED: "Reservation required",
    ROMANTIC: "Romantic",
    SCENIC_VIEW: "Scenic view",
    SHOW: "Show/Performance",
    STAY: "Stay and work",
    AFGHANI: "Afghani",
    ALGERIAN: "Algerian",
    AMERICAN: "American",
    ARABIC: "Arabic",
    ARGENTINEAN: "Argentinean",
    ASIAN: "Asian",
    ASSYRIAN: "Assyrian",
    AUSTRALIAN: "Australian",
    BAR: "Bar",
    BARBECUE: "Barbecue",
    BASQUE: "Basque",
    BEER_PUB: "Beer Pub",
    BELGIAN: "Belgian",
    BRAZILIAN: "Brazilian",
    BRITISH: "British",
    CAFE: "Cafe",
    CAJUN: "Cajun & Creole",
    CARIBBEAN: "Caribbean",
    CATALAN: "Catalonian",
    CENTRAL_AMERICAN: "Central American",
    CENTRAL_ASIAN: "Central Asian",
    CENTRAL_EUROPEAN: "Central European",
    CHINESE: "Chinese",
    COFFEE_SHOP: "Coffe Shop",
    COLOMBIAN: "Colombian",
    CONTEMPORARY: "Contemporary / Modern",
    COSTA_RICAN: "Costa Rican",
    CROATIAN: "Croatian",
    CUBAN: "Cuban",
    DANISH: "Danish",
    DELI: "Deli",
    DINER: "Diner",
    DUTCH: "Dutch",
    EASTER_EUROPEAN: "Easter European",
    ECUADOREAN: "Ecuadorean",
    EGYPTIAN: "Egyptian",
    ETHIOPIAN: "Ethiopian",
    EUROPEAN: "European",
    FAST_FOOD: "Fast Food",
    FILIPINO: "Filipino",
    FRENCH: "French",
    FUSION: "Fusion",
    GASTROPUB: "Gastropub",
    GEORGIAN: "Georgian",
    GERMAN: "German",
    GREEK: "Greek",
    GRILL: "Grill",
    HAWAIIAN: "Hawaiian",
    HEALTHY: "Healthy",
    HUNGARIAN: "Hungarian",
    INDIAN: "Indian",
    INDONESIAN: "Indonesian",
    INTERNATIONAL: "International",
    IRISH: "Irish",
    ISRAELI: "Israeli",
    ITALIAN: "Italian",
    JAMAICAN: "Jamaican",
    JAPANESE: "Japanese",
    KOREAN: "Korean",
    LATIN: "Latin",
    LEBANESE: "Lebanese",
    MALAYSIAN: "Malasia",
    MEDITERRANEAN: "Mediterranean",
    MEXICAN: "Mexican",
    MIDDLE_EASTERN: "Middle Eastern",
    MOROCCAN: "Moroccan",
    MODERN: "Modern",
    NEPALI: "Nepali",
    NEW_ZEALAND: "New Zealand",
    NORWEGIAN: "Norwegian",
    PAKISTANI: "Pakistani",
    PERSIAN: "Persian",
    PERUVIAN: "Peruvian",
    PIZZA: "Pizza",
    POLISH: "Polish",
    POLYNESIAN: "Polynesian",
    PORTUGUESE: "Portuguese",
    PUERTO_RICAN: "Puerto Rican",
    RUSSIAN: "Russian",
    SCANDINAVIAN: "Scandinavian",
    SCOTTISH: "Scottish",
    SEAFOOD: "Seafood",
    SINGAPOREAN: "Singaporean",
    SOUPS: "Soups",
    SOUTH_AMERICAN: "South American",
    SPANISH: "Spanish",
    STEAKHOUSE: "Steak House",
    STREET_FOOD: "Street Food",
    SUSHI: "Sushi",
    SWEDISH: "Swedish",
    SWISS: "Swiss",
    SZECHUAN: "Szechuan",
    TAIWANESE: "Taiwanese",
    TAPAS: "Tapas",
    THAI: "Thai",
    TIBETAN: "Tibetan",
    TUNISIAN: "Tunisian",
    TURKISH: "Turkish",
    VENEZUELAN: "Venezuelan",
    VIETNAMESE: "Vietnamese",
    WINE_BAR: "Wine Bar",
    WOK: "Wok",
    GLUTEN_FREE: "Gluten free",
    HALAL: "Halal",
    KOSHER: "Kosher",
    PHONE: "Phone",
    WHATSAPP: "Whatsapp",
    ONLINE: "Online",
    PLANTAPPETIZERS: "Plant-based appetizers",
    PLANTDESSERTS: "Plant-based desserts",
    VEGANBEER: "Vegan Beer",
    VEGANWINE: "Vegan Wine",
    VEGANLIQUOR: "Vegan Liquor",
    MICHELIN: "MICHELIN Green Star",
    SLOWFOOD: "Slow Food",
    INTERECO: "InterEco",
    BIOSPHERE: "Biosphere",
    ECOCOOK: "EcoCook",
    QUALITAT: "Garantía de Qualitat Ambiental",
    BREEAM: "BREEAM",
    LEED: "LEED",
    OTHERECOCERTIFICATION: "Other",
    COVIDLOCAL:
      "Follows all safety measures required by your local administration",
    OPENAIR: "Open air dining options",
    EMPLOYEETEMPERATURES:
      "Employee temperatures measured daily before work to ensure no one has a fever",
    HIGHERMASKS:
      "Staff required to wear surgical grade or higher masks at all times",
    SANITIZER:
      "Hand sanitizer is provided in a visible location for customers to use",
    TOUCHLESS: "Touchless bathrooms and doors",
    SIGNS:
      "Signs and indicators are placed around the restaurant to remind customers to socially distance",
    COMPOSTOWN: "Yes, we have our own composting system onsite",
    COMPOSTCITY: "Yes, we dispose of organic waste in the city's organic bins",
    COMPOSTTRASH: "No, the scraps are placed in the regular trash",
    RECYCLEGLASS: "Glass",
    RECYCLEPAPER: "Paper",
    RECYCLEMETAL: "Metal",
    PLASTICTABLEWARE: "Cutlery and tableware",
    PLASTICCUPS: "Cups, lids and stirrers",
    PLASTICSTRAWS: "Straws",
    PLASTICONTAINERS: "Take out containers and bags",
    PLASTICBOTTLES: "Water and beverage bottles",
    WILDGAME: "Wild game",
    GRASSFED: "Grass-fed",
    HORMONEFREE: "Hormone-free",
    FREERANGECHICKEN: "Free range chicken",
    MEATCERTIFICATIONEU: "EU certified organic meat",
    MEATCERTIFICATIONOTHER: "Other",
    SEAFOODCERTIFICATIONMSC: "MSC certified seafood",
    SEAFOODCERTIFICATIONOTHER: "Other",
    "0-25": "Less than 25%",
    "25-50": "25% - 50%",
    "50-75": "50% - 75%",
    "75-100": "75% - 100%",
    GROWONSITE: "Onsite",
    GROWBACKYARD: "Backyard garden",
    GROWROOFTOP: "Rooftop garden",
    GROWOFFSITE: "Offsite farm",
    GROWVERTICAL: "Vertical farming",
    GROWAQUAPONICS: "Aquaponics",
    GROWHYDROPONICS: "Hydroponics",
    GROWOTHER: "Other",
    GEOTHERMAL: "Geothermal",
    WIND: "Wind",
    SOLARPV: "Solar PV",
    SOLARTHERMAL: "Solar Thermal",
    HYDRO: "Hydro",
    HYDROFUEL: "Hydrogen Fuel Cells",
    NOOVEN:
      "No oven OR combi/convection oven or pressure cooker instead of traditional oven",
    DISHWASHER:
      "Dishwasher with energy grade of A++ or A+++ (old scale) or A (new scale)",
    REFRIGERATOR:
      "Refrigerator with energy grade of A++ or A+++ (old scale) or A (new scale)",
    LED: "All LED lighting (no incandescent lights)",
    CONTROLLED: "Lights controlled by occupancy/motion sensors",
    PRERINSE:
      "Efficient pre-rinse spray valves (less than 6L water per minute)",
    EFFICIENTDISHWASHER: "Water efficient dishwasher (under 2L water per rack)",
    TABLECLOTHFREE: "Tablecloth free",
    DUALURINALS: "Dual, low-flush toilets/urinals",
    SINKSENSOR: "Button or sensor activated bathroom sinks",
    ECOPRODUCTS: "Use of eco-labelled cleaning products",
    CONSENT1:
      "I hereby confirm that the information provided herein is accurate, correct and complete. I understand that if my statements are found to be false, my restaurant risks losing the corresponding sustainability icon and potentially being removed from the Verdantips app. *",
    CONSENT2:
      "I undertake to inform Verdantips in writing of any changes to the information already provided and to provide updated information when requested to do so. *",
    CONSENT3:
      "I agree to Verdantips’ terms and conditions, terms of use, and privacy policy for using the data submitted in this form. *",
  },

  general: {
    accept: "Accept",
    cancel: "Cancel",
    upload: "Upload a file",
    imageFormat: "PNG, JPG, GIF up to 10MB",
    add: "Add",
    update: "Update",
    start: "Create",
    next: "Next",
    back: "Back",
    filter: "Filter",
    backDashboard: "Go back to the dashboard",
    cookieMessage: "Cookies message",
    cookieConsent: "I Accept",
  },

  nav: {
    forRestaurants: "/for-restaurants",
    forUsers: "/for-users",
    about: "/about",
    contact: "/contact",
    sustainability: "/sustainability",
    restaurants: "/sustainable-businesses",
    barcelonaPilot: "/join-our-barcelona-pilot",
  },

  header: {
    findRestaurants: "Find restaurants",
    dashboard: "Dashboard",
    login: "Login",
    signOut: "Sign out",
  },

  footer: {
    siteMap: "Site Map",
    legal: "Legal",
    followUs: "Follow us",
  },

  waitlistForm: {
    title: "Join us",
    intro:
      "Verdantips is coming soon to cities across Europe! Subscribe now to our waitlist to learn when Verdantips will be launching in your city and how to become a Verdant restaurant.",
    restaurant: "Restaurant name",
    restaurantPlaceholder: "My restaurant",
    city: "City",
    cityPlaceholder: "Your city",
    email: "Email",
    emailPlaceholder: "Your email",
    name: "Name",
    namePlaceholder: "Your name",
    restaurantName: "Restaurant name",
    restaurantNamePlaceholder: "Your restaurant",
    position: "Your Position",
    positionPlaceholder: "Owner",
    submit: "Submit",
  },

  modal: {
    title: "Choose your location",
    barcelona: "Barcelona Region",
    other: "Other",
    link: "/sustainable-businesses",
    cancel: "Cancel",
    confirm: "Confirm",
    confirmTitle: "Confirmation Required",
  },

  home: {
    heroTitle: "Sustainable Restaurants",
    heroSubTitle: "at your reach",
    headerRestaurants:
      "We help restaurants reduce their enviromental impact while gaining new customers",
    moreAboutRestaurants: "More about Verdant Restaurants",
    headerDiners:
      "We help diners find nearby restaurants that align with their values",
    moreAboutDiners: "More about Verdant users",
    headerSustainability: "Sustainability",
    textSustainability01:
      "We take a holistic approach to sustainability, which means we look at all the ways dining out can have an impact on the environment.",
    textSustainability02:
      "When a restaurant meets our standards for a certain sustainability criteria, they receive a badge for it.",
    moreAboutSustainability: "More about sustainability",
    friendsAndPartners: "Friends & Partners",
    followUs: "Follow Us!",
  },

  forUsers: {
    title: "Looking for the perfect restaurant?",
    findRestaurants: "Find Restaurants",
    header01:
      "Together we can make a difference, one choice at a time. Join the waitlist to be part of our beta launch!",
    header02: "Taking forever to find a restaurant you like?",
    text02:
      "Let Verdantips help you identify which restaurants in your area work to be sustainable and safe for their customers.",
    header03: "Have picky friends?",
    text03:
      "Verdantips finds the best match for all of your preferences, from vegan friendly dishes, to plastic free take-out or energy efficient appliances.",
    header04: "Worried about security?",
    text04:
      "With Verdantips you can feel safe going out, knowing beforehand all safety measures restaurants are implementing.",
    header05: "Searching for people like you?",
    text05:
      "Join our Verdant community and find new friends in your city that share your same values.",
    socialMediaClaim: "Don’t forget to follow us in Social Media!",
    header06: "But, what do we mean by sustainable?",
    text06:
      "We take a holistic approach to sustainability, which means we look at all the ways dining out can have an impact on the environment.",
    text06_bis:
      "When a restaurant meets our standards for a certain sustainability criteria, they receive a badge for it.",
    button06: "More about Sustainability",
    link: "/sustainability",
  },

  forRestaurants: {
    title: "Join the movement and become a Verdant Restaurant!",
    joinUs: "Join Us",
    header01: "How Verdantips can help your restaurant",
    text01:
      "Market your restaurant's sustainability measures on the Verdantips app and learn how to further reduce your environmental impact on the Verdantips restaurant platform.",
    header02:
      "Showcase your sustainability efforts and safety measures to potential diners",
    header03:
      "See local trends and personalized analytics so you know where to focus to gain more customers",
    header04:
      "Learn how to reduce your carbon footprint and your costs, all in one place",
  },

  sustainability: {
    heroTitle: "What do we mean by sustainable?",
    intro: "Sustainability in the Restaurant Sector",
    introText:
      "Users can choose between 11 different sustainability preferences on our app. Read below to learn what measures a restaurant must implement in order to receive each badge.",
    ecoCertifiedTitle: "Eco-Certified",
    ecoCertifiedText:
      "A restaurant receives the Eco-Certified badge if it received a valid certification, designation, or award for its sustainability efforts",
    covidConsciousTitle: "Safety Conscious",
    covidConsciousText:
      "A restaurant receives the Safety Conscious badge if it follows stricts protocols to ensure the safety of its diners and staff.",
    plantBasedTitle: "Plant-Based",
    plantBasedFriendly: "Plant-Based Friendly",
    plantBased: "Plant-Based",
    plantBasedText:
      "A restaurant receives the Plant-Based badge if its entire menu is plant-based or Plant-Based Friendly if it offers at least two main dishes on its menu that do not contain any animal products.",
    vegetarianTitle: "Vegetarian",
    vegetarianFriendly: "Vegetarian Friendly",
    vegetarian: "Vegetarian",
    vegetarianText:
      "A restaurant receives the Vegetarian badge if its entire menu excludes meat, poultry, and seafood products. It receives the Vegetarian Friendly badge if at least two main dishes on the menu are vegetarian.",
    sustainableMeatTitle: "Sustainable Meat",
    sustainableMeatText:
      "A restaurant receives the Sustainable Meat badge if it continuously provides meat or poultry menu items that are certified organic or another ecological certification, or local to the region and at least one of the following: grass-fed, hormone-free, and/or free-range.",
    sustainableSeafoodTitle: "Sustainable Seafood",
    sustainableSeafoodText:
      "A restaurant receives the Sustainable Seafood badge if it continuously provides fish or other seafood menu items that are from suppliers with a certification recognized by the Global Sustainable Seafood Initiative and/or that are caught or produced locally and adjusted to the daily catch.",
    sustainableProduceTitle: "Sustainable Produce",
    sustainableProduceText:
      "A restaurant receives the Sustainable Produce badge if it grows its own food and/or continuously serves produce that is certified organic or grown locally in the region.",
    wasteConsciousTitle: "Waste Conscious",
    wasteConsciousText:
      "A Waste Conscious restaurant effectively reduces, reuses, and recycles its food and material waste, such as through composting organic matter, properly managing inventory, and always recycling paper, glass, and aluminum products.",
    plasticConsciousTitle: "Plastic Conscious",
    plasticConscious: "Plastic Conscious",
    plasticFree: "Plastic Free",
    plasticConsciousText:
      "A restaurant receives the Plastic-Free badge if it has received a plastic-free certification or does not use single-use plastics or any plastic packaging or storage. A restaurant is considered Plastic Conscious if it minimizes its use of plastic.",
    renewableEnergyTitle: "Renewable Energy",
    renewableEnergyText:
      "A restaurant receives the Renewable Energy badge if it produces its own renewable energy or if it purchases renewable electricity and/or gas through its supplier.",
    energyEfficientTitle: "Energy Efficient",
    energyEfficientText:
      "An Energy Efficient restaurant implements a number of measures to reduce its energy consumption, such as using energy efficient appliances, heating, cooling, and lighting and training staff to minimize consumption.",
    waterEfficientTitle: "Water Efficient",
    waterEfficientText:
      "A Water Efficient restaurant implements a number of measures to reduce its water usage, such as using water efficient dishwashers, low flow sinks, and low flush toilets.",
  },

  about: {
    title: "About Us",
    ourVisionTitle: "Our Vision",
    ourVisionSubtitle: "To empower a sustainable society",
    ourVisionText:
      "We envision Verdantips to be the go-to platform for people and businesses to make sustainable choices in everything they do.",
    ourValuesTitle: "Our Values",
    ourValuesText:
      "At Verdantips, we strive to live by our values and make every decision with them in mind.",
    empowering: "Empowering",
    empoweringText:
      "We provide people with tools and knowledge in an accessible platform to help them make choices that align with the future they want for the planet.",
    compassionate: "Compassionate",
    compassionateText:
      "We love and respect our peers and our ecosystem, always caring for our employees, customers, users, people, and the planet.",
    courageous: "Courageous",
    corageousText:
      "We dare to envision an audacious future, tell the hard truths needed to get there, and strive towards a sustainable future no matter how hard it may be.",
    resilient: "Resilient",
    resilientText:
      "We overcome the challenges of building a sustainable future, face them as learnings, and emerge as better versions of ourselves.",
    truthful: "Truthfull",
    truthfulText:
      "We pledge to provide transparency through our platform, treat information respectfully, and live by our values in everything we do.",
    ourLogo: "Our Logo",
    ourLogoText: "Our logo emblems our mission, vision, and values.",
    location: "Location",
    symbolOfTruth: "Symbol of Truth",
    sustainability: "Sustainability",
    wholeness: "Totality & Wholeness",
    ourStory: "Our Story",
    ourStoryText1:
      "Verdantips’s vision came into existence when we shared our struggle on how to live sustainably. Mackenzie is vegan and avoids buying plastic, Natalia is strictly vegetarian and take steps to reduce waste, and Mahima is flexitarian but cares about her electricity and water use. Given the diverse values each of us had, we realized it was challenging to agree on sustainability as a whole. That became even more conflicting when we would dine out as we struggled to find restaurants that followed green practices beyond serving vegan and vegetarian dishes. And those who did, would not cater to all of our needs altogether. ",
    ourStoryText2:
      "We soon realized this issue is present across many lifestyle sectors such as food, clothing, skincare and make-up, and more. One spends hours scanning through websites and articles only to feeling overwhelmed and losing motivation.",
    ourStoryText3:
      "With this problem at hand, we participated in the Entrepreneurship Journey of EIT Innoenergy in September 2019 where we validated our concept, developed a business model, and go-to-market strategy. Later in 2020, we got accepted into the ALTS Accelerator program and presented at Demo Days. We also took part in the MALL New Normal challenge and won under the category “Sustainable Practices”. With that, we secured a collaboration with AMS Smart City and bringing our BETA product to launch soon.",
    ourStoryText4:
      "Our goal is to bring transparency and ease for everyone to adapt to a sustainable lifestyle, and we believe in taking one step after the other. Hence, we are starting with the food industry to help people make better food choices and later tap into other sectors.",
    ourTeam: "Our Team",
    ourTeamText:
      "We are three engineers from three continents with a passion for helping people and the planet.",
  },

  contact: {
    title: "Contact Us",
    intro:
      "If you would like to learn more Verdantips or are interested in partnering with us, provide your contact information in the form below and we will reach out to you!",
    sendMail: "You may also send us an email at business@verdantips.com",
    nameLabel: "Name",
    namePlaceholder: "Your name",
    emailLabel: "Email",
    emailPlaceholder: "Your email",
    message: "Message",
    submit: "Submit",
  },

  barcelonaPilot: {
    heroTitle: "Join our Barcelona pilot today",
    heroText:
      "You are a pioneer by making an effort to make your restaurant more sustainable, and we want to let everyone know who is doing things right.",
    gettingStartedTitle: "Getting started",
    gettingStartedText:
      "We are ready to launch our pilot in the Barcelona region soon! Complete your profile with us now so you can become one of the first Verdant restaurants in our app, completely free.",
    howToJoinTitle: "How to join",
    howToJoinText:
      "Simply complete our 20 minute (or less) questionnaire asking about practices at your restaurant. We will let you know the badges you received and when your profile is live in the app!",
    whatToHavePrepared: "What to have prepared",
    basicInfoBulletpoint:
      "Basic information about your restaurant (address, website, opening hours, cuisine type, etc.)",
    photosBulletpoint:
      "Photos to display in the app of the menu, food items, interior/ambiance, and implementation of safety measures in png or jpeg format",
    detailsBulletpoint: "Details about practices pertaining to our 12 badges ",
    learn: "learn more about our badges",
    link: "/sustainability",
    minutesBulletpoint:
      "10 to 20 minutes of time, preferably on a desktop computer",
    startNow: "Start Now",
  },

  login: {
    title: "Sign In for Restaurants",
    emailLabel: "Email address",
    passwordLabel: "Password",
    forgot: "Forgot password",
    signIn: "Sign In",
    noAccount: "Don't have an account?",
    create: "Create account",
    errorTitle: "Errors encountered accessing your account:",
  },

  createRestaurantAccount: {
    title: "Create Restaurant Owner Account",
    intro:
      "Create an account to start working with us. You will be able to change this information later on except the email, that will be linked to your account.",
    emailLabel: "Email address",
    nameLabel: "First name",
    lastNameLabel: "Last name",
    countryLabel: "Country",
    defaultCountry: "United States of America",
    phoneLabel: "Phone",
    phonePlaceholder: "(555) 987-6543",
    roleLabel: "Role",
    rolePlaceholder: "Owner",
    passwordLabel: "Password",
    repeatLabel: "Repeat password",
    create: "Create account",
    already: "Already have an account?",
    signIn: "Sign in",
    errorMessage: "The operation couldn't be executed:",
  },

  mail: {
    accountCreatedTitle: "Check your Email",
    accountCreatedText:
      "Please check your email for further instructions on how to confirm your account.",
    forgotPasswordTitle: "Check your Email",
    forgotPasswordText:
      "Please check your email for further instructions on how to reset your password",
  },

  forgotPassword: {
    title: "Forgot your Password?",
    text: "Please enter your registered email address. We will send you instructions to reset your password",
    emailLabel: "Email address",
    reset: "Send reset instructions",
  },

  resetPassword: {
    title: "Reset your password",
    text: "Please enter the new password that you want to use:",
    passwordLabel: "New password",
    confirmLabel: "Confirm new password",
    button: "Reset password",
    errorTitle: "Couldn't reset your password",
  },

  activate: {
    title: "Your account is not activated yet",
    text: "Your account was created but not activated. We’ve sent you a mail with instructions about how to do it to your email address.",
    send: "Resend Activation Mail",
  },

  dashboard: {
    title: "Dashboard",
    pendingIssues: "Pending issues",
    date: "Date",
    restaurant: "Restaurant",
    category: "Category",
    description: "Description",
    seeAll: "See all",
    tableExplanation:
      "Click on any row to display more information. Click the headers to sort the table based on the chosen field.",
    noPending: "There are no pending issues.",
    registeredRestaurants: "Registered Restaurants",
    published: "Published",
    notPublished: "Not Published",
    contactInfo: "Contact Information",
    openHours: "Open Hours",
    restaurantDetails: "Restaurant Details",
    photos: "Cover Photo",
    sustainabilityBadges: "Sustainability Badges",
    signedConsent: "Signed Consent",
    delete: "Delete",
    edit: "Edit",
    unpublish: "Unpublish",
    publish: "Publish",
    deleteRestaurantTitle: "Confirm Delete Restaurant",
    deleteRestaurantText:
      "If you proceed the restaurant will be deleted from our database.",
    deleteRestaurantAction: "Delete",
    unpublishRestaurantTitle: "Confirm to Unpublish Restaurant",
    unpublishRestaurantText:
      "If you proceed the restaurant will no longer be available to our users.",
    unpublishRestaurantAction: "Unpublish",
    publishRestaurantTitle: "Confirm to Publish Restaurant",
    publishRestaurantText:
      "If you proceed the restaurant will be available to our users.",
    publishRestaurantAction: "Publish",
    createRestaurant: "Create new restaurant",
  },

  issues: {
    title: "Issues Manager",
    status: "Status",
    pending: "Pending",
    solved: "Solved",
    noIssues: "Congratulations!! You don't have any issue :)",
  },

  issue: {
    title: "Issue Report",
    subTitle: "Issue details and information",
    status: "Status",
    titleId: "Issue ID",
    titleComment: "Solved message",
    titleUser: "Created by user",
    titleRestaurant: "Restaurant Name",
    titleDescription: "Description",
    titleKeyword: "Category",
    titleDate: "Date",
    titlePhotos: "Photos",
    markSolved: "Mark issue as solved",
    solve: "Solve",
    cancel: "Cancel",
    solveMessage:
      "In order to mark the issue as solved please comment the reason or actions executed to consider it as such.",
    reopen: "Reopen issue",
  },

  restaurantContact: {
    title: "Contact Information",
    intro: "Enter your restaurant contact information:",
    nameLabel: "Restaurant name",
    namePlaceholder: "Add restaurant name",
    addressLabel: "Address",
    addressPlaceholder: "Shop no, Street, Area",
    zipcodeLabel: "Zipcode",
    cityLabel: "City",
    cityPlaceholder: "Add city name",
    phoneLabel: "Phone",
    phonePlaceholder: "12-34-567-890",
    emailLabel: "Email",
    emailPlaceholder: "name@mymail.com",
    websiteLabel: "Website URL",
    websitePlaceholder: "www.myrestaurant.com",
    googlemapsLabel: "Google Maps page URL",
    instagramLabel: "Instagram URL",
    facebookLabel: "Facebook URL",
  },

  restaurantOpen: {
    title: "Restaurant Regular Open Hours",
    intro:
      "Enter the hours that your restaurant is usually open during the week:",
    monday: "Monday",
    tuesday: "Tuesday",
    wednesday: "Wednesday",
    thursday: "Thursday",
    friday: "Friday",
    saturday: "Saturday",
    sunday: "Sunday",
    addTime: "Add Time Range",
    addSlot: "Add opening hours",
    addTimeIntro:
      "Enter a time range and choose the days you want to be assigned:",
    cancel: "Cancel",
    add: "Add",
    from: "From",
    to: "to",
    following: "On the following days:",
    schedule: "Open hours",
  },

  restaurantDetails: {
    title: "Restaurant Details",
    intro: "Enter special information about your restaurant:",
    descriptionTitle: "Define your restaurant in one sentence:",
    services: "Services and Ambiance:",
    cuisine: "Cuisine:",
    dietary: "Does your restaurant have the following options?",
    price: "What is the average meal price per person in $?",
    reservation: "Do you require reservation?",
    channel: "Please include the reservation channel:",
  },

  restaurantPhotos: {
    title: "Restaurant Photos",
    intro:
      "Upload photos of different categories. You must provide at least the cover photo to continue.",
    coverTitle: "Cover",
    menuTitle: "Menu",
    foodTitle: "Food",
    ambienceTitle: "Ambience",
    covidTitle: "Safety Measures",
    maximum: "Maximum",
    photo: "photo",
    photos: "photos",
  },

  restaurantBadges: {
    title: "Sustainability Badges",
    intro: "Apply for our different sustainability badges",
    apply: "Apply",
    edit: "Edit information",
    remove: "Remove",
    formIntro:
      "Please answer the following questions. If you meet our criteria you will we able to add the badge:",
    plant: {
      entirePlantBased: "Is your menu entirely plant based?",
      twoMainDishes:
        "Does your menu offer at least two main dishes that are entirely plant-based?",
      menuIncludes: "Does your menu include:",
    },
    eco: {
      certificationIntro:
        "Select the certification, designation, or award that your restaurant has (check all that apply)",
      extraEco: `Enter the "other" certification `,
    },
    vegetarian: {
      entirely: "Is your menu entirely vegetarian?",
      twoMainDishes:
        "Does your menu offer at least two main dishes that are entirely vegetarian?",
    },
    covid: {
      measures:
        "Select each of the safety measures below that apply to your restaurant:",
      otherMeasures:
        "Is there anything else you would like diners to know about measures you are taking to protect your staff and customers?",
    },
    waste: {
      surplus: "Do you sell or donate surplus food from your restaurant?",
      surplusDescription:
        "Describe how you sell or donate the surplus food (such as through a platform like Too Good to Go, or through an organization)",
      compost: "Do you compost the organic waste generated by your restaurant?",
      compostExplanation: "Please explain further:",
      inventory:
        "Do you use an inventory management system to minimize food losses, such as first in, first out?",
      recycle: "Select the types of waste that you continuously recycle:",
      train:
        "Do you train your staff on sustainable waste management practices?",
      containers: "Do you allow diners to bring their own take out containers?",
      comments:
        "Is there anything else you would like diners to know about waste management at your restaurant?",
    },
    plastic: {
      hasCertification:
        "Does your restaurant currently have a certification for being plastic-free?",
      certificationName: "What is the name of the certification?",
      certificationYear: "What year did you receive it?",
      certificationDuration: "How long is it valid for?",
      plasticUse: "Do you use plastic...? (check all that apply)",
      comments:
        "Is there anything else you would like diners to know about plastic use at your restaurant?",
    },
    meat: {
      hasCertification:
        "Do you continuously serve meat on your menu that has received an ecological certification?",
      certificationType: "What is the name of the certification?",
      certificationName: "Enter the name of the certification:",
      meatType:
        "Please tell us more about your certified meat, such as the type of meat that is certified:",
      local:
        "Do you continuously serve meat on your menu from animals that were raised or hunted less than 100 Km away from your location?",
      menu: "Do you continuously serve meat that is...?",
      comments:
        "Is there anything else you would like diners to know about the meat you serve at your restaurant?",
    },
    seafood: {
      hasCertification:
        "Do you continuously serve seafood on your menu that has received an ecological certification?",
      certificationType: "What is the name of the certification?",
      certificationName: "Enter the name of the certification:",
      seafoodType:
        "Please tell us more about your certified seafood, such as the type of seafood that is certified..",
      local:
        "Do you continuously serve seafood that was caught or produced in waters less than 100 Km away and adjusted to the daily catch?",
      comments:
        "Is there anything else you would like diners to know about the meat you serve at your restaurant?",
    },
    produce: {
      grow: "Does your restaurant grow its own food?",
      growPercentage:
        "Approximately what percentage of the produce that you serve do you grow yourself?",
      growType: "How do you grow the produce? (check all that apply)",
      otherGrowType: "Please specify your “other” option:",
      certified:
        "Do you continuously serve produce on your menu that is EU certified organic",
      organicPercentage:
        "Approximately what percentage of the produce that you serve is EU certified organic?",
      local:
        "Do you continuously serve produce on your menu that was grown in an area less than 100 Km away?",
      localPercentage:
        "Approximately what percentage of the produce that you serve is grown in an area less than 100 Km away?",
      comments:
        "Is there anything else you would like diners to know about the produce you serve at your restaurant?",
    },
    renewable: {
      hasOwn:
        "Does your restaurant produce and consume its own renewable energy, such as through rooftop solar panels?",
      installation:
        "What type of installation do you use to produce the energy?",
      consumptionPercentage:
        "Approximately what percentage of your energy consumption does the installation cover",
      start: "In what year did your installation become operational?",
      hasElectricity:
        "Do you purchase renewable electricity from your electricity supplier?",
      electricityProvider: "What is the name of your electricity supplier?",
      hasGas: "Do you purchase renewable gas from your gas supplier?",
      gasProvider: "What is the name of your gas supplier?",
      comments:
        "Is there anything else you would like diners to know about the energy consumed at your restaurant?",
    },
    energy: {
      practices:
        "Check all the energy efficiency practices that your restaurant follows:",
      comments:
        "Is there anything else you would like diners to know about the energy efficiency practices at your restaurant?",
    },
    water: {
      practices:
        "Check all the efficient water management practices that your restaurant follows: ",
      comments:
        "Is there anything else you would like diners to know about the water management practices at your restaurant?",
    },
  },

  consent: {
    title: "Consent and Authorization",
    intro:
      "In order to create your restaurant account please accept the following terms:",
    conditions: "Terms & Conditions",
    use: "Terms of Use",
    privacy: "Privacy Policy",
    accept: "I Accept",
  },

  closed: {
    title: "Restaurant Closed Days",
    intro:
      "Select the days your restaurant will be closed during the year on top of regular days.",
    explanation:
      "Click on the days you want to mark as closed. Clicking on marked days will unmark them.",
    explanation2:
      "The days that don't have any open hours defined will appear automatically as regular closed days.",
  },

  calendar: {
    monday: "M",
    tuesday: "T",
    wednesday: "W",
    thursday: "T",
    friday: "F",
    saturday: "S",
    sunday: "S",
    0: "January",
    1: "February",
    2: "March",
    3: "April",
    4: "May",
    5: "June",
    6: "July",
    7: "August",
    8: "September",
    9: "October",
    10: "November",
    11: "December",
    regular: "Regular closed days",
    extra: "Extra closed days",
    today: "Today",
  },

  register: {
    title: "Register Restaurant:",
    skipMessage: "Want to do it later?",
    skip: "Skip",
    skipTitle: "Skip Restaurant Creation",
    skipWithCreation:
      "The restaurant will be created with the information you provided so far, but you will need to complete the registration to publish your restaurant in our platform.",
    skipWithoutCreation:
      "You will be able to register your restaurant later on in your dashboard.",
  },

  editRestaurant: {
    title: "Restaurant Details",
    updateContact: "Update Contact Information",
    updateOpenHours: "Update Open Hours",
    updateClosed: "Update Closed Days",
    updateDetails: "Update Details",
    updatePhotos: "Update Photos",
    updateBadges: "Update Badges",
    acceptConsent: "Accept Consent",
    rejectConsent: "Reject Consent",
    contactTab: "Contact",
    openTab: "Open Hours",
    closedTab: "Closed Days",
    detailsTab: "Details",
    photosTab: "Photos",
    badgesTab: "Badges",
    consentTab: "Consent",
    confirmMessage:
      "All changes will be lost if you don't save them. Do you want to continue?",
  },
  careers: {
    heroTitle: "Careers",
    whyVerdantips: "Why Verdantips?",
    reason:
      "Work directly alongside our founding team to help empower a sustainable society.",
    impact: "Careers with an impact",
    jobAvailable:
      "We currently have the following positions open. Don’t see one thay fits your skills but want to contribute? Send us your CV and cover letter outlining the role you’d like to",
    jobNotAvailable:
      "We currently don’t have any position open. Do you think that we would be interested in your skills? Send us your CV and cover letter outlining the role you’d like to",
    learnMore: "Learn more",
  },
  restaurants: {
    heroTitle: "Sustainable restaurants in Barcelona",
    intro:
      "Here you have a list of all our sustainable restaurants in Barcelona. For more information and functionalities like mapping and custom filters download our app:",
    filter: "Filter by sustainability badge:",
    empty:
      "Right now there is no restaurant that has achieved the selected badges",
  },
  restaurantInfo: {
    toRestaurants: "Sustainable restaurants list",
    restaurantDetails: "Restaurant Details",
    cuisine: "Cuisine",
    services: "Services",
    dietary: "Dietary",
    badges: "Sustainability badges",
    more: "Do you want more information?",
    download:
      "Download our Verdantips app and access to much more information about this restaurant like:",
    map: "Map location.",
    price: "Average price per person.",
    reservation: "Reservation requirements.",
    opening: "Opening hours.",
    photos: "Photos of the menu, food, ambience and security measures.",
    contact: "Full contact information.",
    future: "And much more in the future!!",
  },
};
