export const badgeOptions = [
  "ECOCERTI",
  "COVICONS",
  "PLANTBASED",
  "PLANTFRIENDLY",
  "PLASTICFREE",
  "PLASTICCONSCIOUS",
  "VEGETARIAN",
  "VEGETARIANFRIENDLY",
  "WASTCONS",
  "SUSTMEAT",
  "SUSTPROD",
  "SUSTSEAF",
  "ENEGEFFI",
  "RENWENEG",
  "WATREFFI",
];
