import axios from "axios";

const images = function (api) {
  return {
    async getUploadUrl(folder, fileName) {
      const query = `query images ($folder: String, $file_name: String) { result: images__get_upload_url (folder: $folder, file_name: $file_name) {ok payload errors}}`;
      const variables = { folder, file_name: fileName };
      const data = await api.gql(query, variables);
      return data.data.result;
    },
    async uploadFile(formData, url) {
      try {
        const res = await axios.post(url, formData, {
          headers: {
            "Content-type": "multipart/formData",
          },
        });
        return res;
      } catch (e) {
        throw new Error(e);
      }
    },
  };
};

export default images;
