import axios from "axios";
import restaurants from "./restaurants";
import auth from "./authentication";
import images from "./images";
import contact from "./contact";
import issues from "./issues";
import { apiConfig } from "./apiConfig";
const api = {};

api.gql = async (query, variables, options = { withToken: true }) => {
  let headers = {};
  if (localStorage.getItem("VerdantipsToken")) {
    headers = {
      Authorization: `Bearer ${localStorage.getItem("VerdantipsToken")}`,
    };
  }

  try {
    const response = await axios.post(
      `${apiConfig.hostUrl}/api`,
      { query, variables },
      { headers }
    );
    return response.data;
  } catch (error) {
    if (error.response) {
      if (
        error.response.status === 401 &&
        error.response.data.msg === "Token has expired"
      ) {
        return { ok: false, code: "TOKEN-EXPIRED", error };
      } else {
        console.error(error);
        return { ok: false, code: error.response.data.msg, error };
      }
    } else if (error.request) {
      // The request was made but no response was received
      console.log("response not received:", error.request);
      return { ok: false, code: "NO-RESPONSE", error };
    } else {
      // Something happened in setting up the request that triggered an Error
      console.log("error:", error.message);
      return { ok: false, code: "REQUEST-ERROR", error };
    }
  }
};

api.contact = contact(api);
api.images = images(api);
api.restaurants = restaurants(api);
api.auth = auth(api);
api.issues = issues(api);

export default api;
