import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { diff } from "deep-object-diff";
import { useUpdateRestaurant } from "hooks/restaurantHooks";
import Button from "components/Button";
import { ModalAddTimeSlot } from "components/ModalAddTimeSlot";
import { StepSubmitButtons } from "./StepSubmitButtons";
import { DayBlock } from "./RestaurantOpenHours";

const weekDays = [
  "monday",
  "tuesday",
  "wednesday",
  "thursday",
  "friday",
  "saturday",
  "sunday",
];

export function EditRestaurantOpenHours({
  restaurantInfo,
  isUpdateDisabled,
  setIsUpdateDisabled,
}) {
  const { id, open_hours } = restaurantInfo;
  const { t } = useTranslation();
  const { mutate: updateRestaurant, isLoading: isLoadingUpdateRestaurant } =
    useUpdateRestaurant();
  const [error, setError] = useState([]);
  const [success, setSuccess] = useState([]);
  const [open, setOpen] = useState(false);
  const [schedule, setSchedule] = useState(
    JSON.parse(JSON.stringify(open_hours))
  );

  useEffect(() => {
    const difference = diff(open_hours, schedule);
    if (Object.keys(difference).length === 0) {
      return setIsUpdateDisabled(true);
    } else return setIsUpdateDisabled(false);
  }, [open_hours, schedule, setIsUpdateDisabled]);

  useEffect(() => {
    let isEmpty = true;
    for (const day in schedule) {
      if (schedule[day].length > 0) {
        isEmpty = false;
      }
    }
    if (isEmpty) return setIsUpdateDisabled(true);
  }, [schedule, open, setIsUpdateDisabled]);

  function handleNext() {
    setSuccess([]);
    const input = {
      id,
      open_hours: schedule,
    };
    updateRestaurant(input, {
      onError: (e) => {
        setError([e.message]);
      },
      onSuccess: (data) => {
        if (!data) return setError(["unknownError"]);
        if (data?.data.result.ok === false) return setError(["deniedError"]);
        if (data.data.result.ok === true) {
          setSuccess(["informationUpdated"]);
          return setIsUpdateDisabled(true);
        }
      },
    });
  }

  return (
    <div className="mx-auto max-w-3xl">
      <ModalAddTimeSlot
        open={open}
        setOpen={setOpen}
        schedule={schedule}
        setSchedule={setSchedule}
      />
      <div className="m-4 mt-8">
        <Button onClick={() => setOpen(true)}>
          {t("restaurantOpen.addSlot")}
        </Button>
      </div>
      <div className="flex flex-col px-4 sm:px-16">
        {weekDays.map((day) => {
          return (
            <DayBlock
              key={day}
              day={day}
              slots={schedule[day]}
              setSchedule={setSchedule}
            />
          );
        })}
      </div>
      <StepSubmitButtons
        isLoading={isLoadingUpdateRestaurant}
        success={success}
        next={t("editRestaurant.updateOpenHours")}
        onNext={handleNext}
        error={error}
        skip={false}
        className="mb-7 sm:mb-14"
        isDisabled={isUpdateDisabled}
      />
    </div>
  );
}
