import React, { Fragment, useState } from "react";
import { useTranslation } from "react-i18next";
import { Dialog, Transition } from "@headlessui/react";
import Button from "components/Button";
import { XIcon } from "@heroicons/react/solid";
import { ErrorBlock } from "components/ErrorBlock";
import { areEqual } from "utils/areEqual";

const daysList = [
  "monday",
  "tuesday",
  "wednesday",
  "thursday",
  "friday",
  "saturday",
  "sunday",
];

function convertToDecimal({ start, end }) {
  return {
    start: parseFloat(start[0]) + parseFloat(start[1]) / 60,
    end: parseFloat(end[0]) + parseFloat(end[1]) / 60,
  };
}

function checkSlot(currentSlots, newSlot) {
  let isValid = true;
  const currentSlotsToDecimal = [];
  currentSlots.map(({ start, end }) => {
    return currentSlotsToDecimal.push(convertToDecimal({ start, end }));
  });
  const newSlotToDecimal = convertToDecimal(newSlot);
  for (const currentSlot of currentSlotsToDecimal) {
    const newStart = newSlotToDecimal.start;
    let newEnd = newSlotToDecimal.end;
    const currentStart = currentSlot.start;
    let currentEnd = currentSlot.end;

    // Convert the end hours after midnight to bigger than 24
    if (newEnd < newStart) newEnd = newEnd + 24;
    if (currentEnd < currentStart) currentEnd = currentEnd + 24;

    // If the starting hour is inside a current slot
    if (newStart > currentStart && newStart < currentEnd)
      return (isValid = false);

    // If the end hour is inside a current slot
    if (newEnd > currentStart && newEnd < currentEnd) return (isValid = false);

    // If the start hour is before the current slot but the end hour is after it, so the new slot contains the current
    if (newStart < currentStart && newEnd > currentEnd)
      return (isValid = false);

    // If the current slot ends after midnight
    if (currentEnd > 24 && newStart < currentEnd - 24) return (isValid = false);
  }
  return isValid;
}

function updateSchedule(previousSchedule, newRange) {
  let updatedSchedule = { ...previousSchedule };
  for (const dayEntry of newRange) {
    const { day, slot } = dayEntry;
    const dayCurrentSlots = previousSchedule[day];
    // if there is no slot, we directly include the new one
    if (dayCurrentSlots.length === 0) {
      updatedSchedule[day].push(slot);
    } else {
      const updatedSlots = updateSlots(dayCurrentSlots, slot);
      updatedSchedule[day] = updatedSlots;
    }
  }
  return updatedSchedule;
}

function updateSlots(currentSlots, newSlot) {
  // We have slots in the day, so we have to insert it in the right order
  // It's easier if we have the hours in decimal notation. i.e. "10:30" is "10.5"
  const currentSlotsToDecimal = [];
  const newSlotToDecimal = convertToDecimal(newSlot);
  currentSlots.map((slot) => {
    const { start, end } = slot;
    return currentSlotsToDecimal.push(convertToDecimal({ start, end }));
  });
  const insertionIndexes = [];
  for (const range of currentSlotsToDecimal) {
    // For new slots that don't cross midnight
    if (newSlotToDecimal.start < range.start)
      insertionIndexes.push(currentSlotsToDecimal.indexOf(range));
  }
  if (insertionIndexes.length === 0) {
    // We couldn't find a range that started later
    currentSlots.push(newSlot);
  } else {
    // We choose the first slot that was found
    currentSlots.splice(insertionIndexes[0], 0, newSlot);
  }
  return currentSlots;
}

export function ModalAddTimeSlot({ open, setOpen, schedule, setSchedule }) {
  const { t } = useTranslation();
  const [error, setError] = useState([]);
  const [start, setStart] = useState("00:00");
  const [end, setEnd] = useState("00:00");
  const [days, setDays] = useState([]);

  function handleAdd() {
    setError([]);
    if (!start || !end) {
      return setError(["allFieldsError"]);
    }
    if (areEqual(start, end)) {
      return setError(["emptyIntervalError"]);
    }
    let isRangeValid = true;
    const startHour = start.split(":")[0];
    const startMinutes = start.split(":")[1];
    const endHour = end.split(":")[0];
    const endMinutes = end.split(":")[1];
    if (days.length === 0) {
      return setError(["selectMinimumDaysError"]);
    }

    const newRange = [];
    days.map((day) => {
      return newRange.push({
        day,
        slot: { start: [startHour, startMinutes], end: [endHour, endMinutes] },
      });
    });

    newRange.map(({ day, slot }) => {
      if (checkSlot(schedule[day], slot) === false) {
        isRangeValid = false;
      }
      return undefined;
    });

    if (isRangeValid === false) {
      return setError(["rangeConflictError"]);
    } else {
      setSchedule((previousSchedule) => {
        const updatedSchedule = updateSchedule(previousSchedule, newRange);
        return updatedSchedule;
      });
      setOpen(false);
    }

    setStart("00:00");
    setEnd("00:00");
  }

  function handleClose() {
    setStart("00:00");
    setEnd("00:00");
    setError([]);
    setOpen(false);
  }

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 z-30 overflow-y-auto"
        onClose={setOpen}
      >
        <div className="flex min-h-screen items-center justify-center px-4 pt-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden sm:inline-block sm:h-screen sm:align-middle"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="relative inline-block transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left align-bottom shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6 sm:align-middle">
              <div className="absolute top-0 right-0 hidden pt-4 pr-4 sm:block">
                <button
                  type="button"
                  className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2"
                  onClick={handleClose}
                >
                  <span className="sr-only">Close</span>
                  <XIcon className="h-6 w-6" aria-hidden="true" />
                </button>
              </div>
              <div className="sm:flex sm:items-start">
                <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                  <Dialog.Title as="h2" className="">
                    {t("restaurantOpen.addTime")}
                  </Dialog.Title>
                  <div className="mt-4">
                    <p>{t("restaurantOpen.addTimeIntro")}</p>
                    <TimeSlot
                      start={start}
                      setStart={setStart}
                      end={end}
                      setEnd={setEnd}
                    />
                    <DaySelection setDays={setDays} />
                    {error.length > 0 && <ErrorBlock errorList={error} />}
                  </div>
                </div>
              </div>
              <div className="mx-auto mt-5 flex flex-row justify-center gap-4 sm:mt-4 sm:gap-8">
                <Button variant="secondary" onClick={() => setOpen(false)}>
                  {t("restaurantOpen.cancel")}
                </Button>
                <Button onClick={handleAdd}>{t("restaurantOpen.add")}</Button>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

function TimeSlot({ start, setStart, end, setEnd }) {
  const { t } = useTranslation();
  return (
    <div className="m-2 flex items-center justify-center gap-2 rounded-md bg-gray-200 p-3 ">
      <p className="text-xs sm:text-base">{t("restaurantOpen.from")}</p>
      <input
        type="time"
        className="block rounded-md border-gray-300 text-xs shadow-sm focus:border-green-500 focus:ring-green-500 sm:text-base"
        value={start}
        onChange={(e) => setStart(e.target.value)}
      />
      <p className="text-xs sm:text-base">{t("restaurantOpen.to")}</p>
      <input
        type="time"
        className="block rounded-md border-gray-300 text-xs shadow-sm focus:border-green-500 focus:ring-green-500 sm:text-base"
        value={end}
        onChange={(e) => setEnd(e.target.value)}
      />
    </div>
  );
}

function DaySelection({ setDays }) {
  const { t } = useTranslation();
  const [checkedState, setCheckedState] = useState(
    new Array(daysList.length).fill(false)
  );

  function handleOnChange(position) {
    const updatedCheckedState = checkedState.map((item, index) =>
      index === position ? !item : item
    );
    setCheckedState(updatedCheckedState);
  }

  React.useEffect(() => {
    const checkedDays = [];
    for (const [index, check] of checkedState.entries()) {
      if (check === true) checkedDays.push(daysList[index]);
    }
    setDays(checkedDays);
  }, [checkedState, setDays]);

  return (
    <div className="flex flex-col rounded-md p-2 ">
      <p className="mb-2 text-left text-xs sm:text-base">
        {t("restaurantOpen.following")}
      </p>
      <div className="rounded-md bg-gray-100 p-2">
        {daysList.map((day, index) => {
          return (
            <div key={day} className="flex items-center ">
              <div className="flex h-5 items-center">
                <input
                  id={day}
                  aria-describedby="Days"
                  name={day}
                  type="checkbox"
                  checked={checkedState[index]}
                  onChange={() => handleOnChange(index)}
                  className="h-4 w-4 rounded border-gray-300 text-green-600 focus:ring-green-500"
                />
              </div>
              <div className="ml-3 text-sm">
                <label htmlFor={day} className="text-xs sm:text-base">
                  {t(`restaurantOpen.${day}`)}
                </label>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}
