import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useUpdateRestaurant } from "hooks/restaurantHooks";
import Calendar from "components/Calendar";
import { getMonthCalendar } from "utils/dates";
import { StepSubmitButtons } from "./StepSubmitButtons";
import { arraysAreEqual } from "utils/areEqual";

export function EditRestaurantClosedDays({
  restaurantInfo,
  isUpdateDisabled,
  setIsUpdateDisabled,
}) {
  const {
    id,
    open_hours: savedOpenHours,
    closed_days: savedClosedDays,
  } = restaurantInfo;
  const { t } = useTranslation();
  const { mutate: updateRestaurant, isLoading: isLoadingUpdateRestaurant } =
    useUpdateRestaurant();
  const [error, setError] = useState([]);
  const [success, setSuccess] = useState([]);
  const [referenceDay, setReferenceDay] = useState(new Date());
  const [regularClosedDays, setRegularClosedDays] = useState([]);
  const [extraClosedDays, setExtraClosedDays] = useState(savedClosedDays);
  const month = getMonthCalendar(referenceDay);

  useEffect(() => {
    setIsUpdateDisabled(arraysAreEqual(savedClosedDays, extraClosedDays));
  }, [savedClosedDays, extraClosedDays, setIsUpdateDisabled]);

  useEffect(() => {
    let closedDaysList = [];
    for (const day in savedOpenHours) {
      if (savedOpenHours[day].length === 0) {
        if (day === "sunday") closedDaysList.push(0);
        if (day === "monday") closedDaysList.push(1);
        if (day === "tuesday") closedDaysList.push(2);
        if (day === "wednesday") closedDaysList.push(3);
        if (day === "thursday") closedDaysList.push(4);
        if (day === "friday") closedDaysList.push(5);
        if (day === "saturday") closedDaysList.push(6);
      }
    }
    setRegularClosedDays(closedDaysList);
  }, [savedOpenHours]);

  function handleNext() {
    setError([]);
    const input = {
      id,
      closed_days: extraClosedDays,
    };
    updateRestaurant(input, {
      onError: (error) => {
        setError([error.message]);
      },
      onSuccess: (data) => {
        if (!data) return setError(["unknownError"]);
        if (data?.data.result.ok === false) return setError(["deniedError"]);
        if (data?.data.result.ok === true)
          return setSuccess(["informationUpdated"]);
      },
    });
  }

  return (
    <>
      <div className="mx-auto mt-4 max-w-3xl px-4 sm:mt-8">
        <div className="mb-4  sm:mb-4">
          <p>{t("closed.intro")}</p>
          <p>{t("closed.explanation")}</p>
          <p>{t("closed.explanation2")}</p>
        </div>
        <div className="flex flex-col items-center ">
          <Calendar
            month={month}
            setReferenceDay={setReferenceDay}
            regularClosedDays={regularClosedDays}
            extraClosedDays={extraClosedDays}
            setExtraClosedDays={setExtraClosedDays}
          />
        </div>
        <StepSubmitButtons
          isDisabled={isUpdateDisabled}
          isLoading={isLoadingUpdateRestaurant}
          next={t("editRestaurant.updateClosed")}
          onNext={handleNext}
          error={error}
          success={success}
          skip={false}
          className="mb-7 sm:mb-14"
        />
      </div>
    </>
  );
}
