import React from "react";
import { useTranslation } from "react-i18next";
import { useGetUserIssues } from "hooks/issuesHooks";
import { FullPageLoader } from "components/Loading";
import { ErrorPage } from "components/ErrorPage";
import { Table } from "components/Table";

export function MyIssues() {
  const { t } = useTranslation();
  const {
    data: userIssues,
    isLoading: isLoadingUserIssues,
    isError: isErrorUserIssues,
    error: errorIssues,
  } = useGetUserIssues();

  const columns = React.useMemo(
    () => [
      {
        Header: t("dashboard.date"),
        accessor: "date", // accessor is the "key" in the data
      },
      {
        Header: t("issues.status"),
        accessor: "completed_date",
        Cell: ({ row }) => {
          if (row.values.completed_date !== null)
            return (
              <span className="inline-flex px-2 text-xs font-semibold leading-5 text-green-800 bg-green-100 rounded-full">
                {t("issues.solved")}
              </span>
            );
          else
            return (
              <span className="inline-flex px-2 text-xs font-semibold leading-5 text-orange-800 bg-orange-100 rounded-full">
                {t("issues.pending")}
              </span>
            );
        },
      },
      {
        Header: t("dashboard.restaurant"),
        accessor: "restaurant.name",
      },
      {
        Header: t("dashboard.category"),
        accessor: "keyword",
        Cell: ({ row }) => {
          return <p>{t(`keywords.${row.values.keyword}`)}</p>;
        },
      },
      {
        Header: t("dashboard.description"),
        accessor: "description",
        disableSortBy: true,
      },
    ],
    [t]
  );

  if (isLoadingUserIssues) return <FullPageLoader />;
  if (isErrorUserIssues) return <ErrorPage message={errorIssues} />;

  return (
    <div className="min-h-full mx-auto mt-4 max-w-8xl sm:mt-8">
      <h1 className="mx-4 mb-4 text-4xl font-extrabold text-center sm:mx-8 sm:mb-8 sm:text-6xl">
        {t("issues.title").toUpperCase()}
      </h1>

      {(userIssues.pending.length > 0 || userIssues.solved.length > 0) && (
        <>
          <p className="mx-4 mb-4 text-sm sm:mx-8 sm:mb-6 sm:text-base">
            {t("dashboard.tableExplanation")}
          </p>
          <Table
            data={[...userIssues.pending, ...userIssues.solved]}
            columns={columns}
          />
        </>
      )}
      {userIssues.pending.length === 0 && userIssues.solved.length === 0 && (
        <p className="mx-4 mb-4 text-lg text-center sm:mx-8 sm:mb-6 sm:text-xl">
          {t("issues.noIssues")}
        </p>
      )}
    </div>
  );
}
