import React from "react";
import { useTranslation } from "react-i18next";
import { ReactComponent as BadgesEn } from "./img/badges_en.svg";
import { ReactComponent as BadgesEs } from "./img/badges_es.svg";

export function BadgesImage({ className }) {
  const { i18n } = useTranslation();
  const language = i18n.resolvedLanguage;
  if (language === "es") return <BadgesEs className={className} />;
  return <BadgesEn className={className} />;
}
