import { FakeResponseCollection } from "utils/fakeResponseCollection";

export const fake_update_restaurant = new FakeResponseCollection();

fake_update_restaurant.set({
  input: {
    id: "restaurant-1",
    status: "PUBLISHED",
  },
  response: {
    data: {
      result: {
        ok: true,
      },
    },
  },
});

fake_update_restaurant.set({
  input: {
    id: "restaurant-2",
    status: "UNPUBLISHED",
  },
  response: {
    data: {
      result: {
        ok: true,
      },
    },
  },
});

// Update restaurant open hours
fake_update_restaurant.set({
  input: {
    id: "restaurant-1",
    open_hours: {
      // TODO by Backend: Changed the format so it's easier to handle with the frontend inpunts
      monday: [],
      tuesday: [],
      wednesday: [{ start: ["10", "00"], end: ["16", "00"] }],
      thursday: [
        { start: ["10", "00"], end: ["16", "00"] },
        { start: ["20", "00"], end: ["23", "00"] },
      ],
      friday: [
        { start: ["10", "00"], end: ["16", "00"] },
        { start: ["20", "00"], end: ["23", "00"] },
      ],
      saturday: [
        { start: ["10", "00"], end: ["16", "00"] },
        { start: ["20", "00"], end: ["23", "00"] },
      ],
      sunday: [{ start: ["10", "00"], end: ["16", "00"] }],
    },
  },
  response: {
    data: {
      result: {
        ok: true,
      },
    },
  },
});

// Update restaurant closed days
fake_update_restaurant.set({
  input: {
    id: "restaurant-1",
    closed_days: ["2022-04-01"],
  },
  response: {
    data: {
      result: {
        ok: true,
      },
    },
  },
});

// Update restaurant details
fake_update_restaurant.set({
  input: {
    id: "restaurant-1",
    description: "description",
    cuisine: ["AMERICAN", "ASIAN"],
    dietary: ["GLUTEN_FREE", "KOSHER"],
    services: ["AIR_CON", "BOTTOMLESS"],
    average_meal_price: 20,
    reservation_required: true,
    reservation_channel: ["PHONE"],
  },
  response: {
    data: {
      result: {
        ok: true,
      },
    },
  },
});

// Update photos
fake_update_restaurant.set({
  input: {
    id: "restaurant-1",
    photos: {
      cover: "restaurant-1-photo",
      //-- TODO by Backend: Add photo categories
      menu: ["menu-1-url", "menu-2-url"],
      food: ["food-1-url", "food-2-url"],
      ambience: ["ambience-1-url", "ambience-2-url"],
      covid: null,
    },
  },
  response: {
    data: {
      result: {
        ok: true,
      },
    },
  },
});

//-- TODO by Backend: Here we have all possible options checked for each badge
// Check https://docs.google.com/document/d/1rpVszsyZ7umwdaIw2KhJ6ECVud-fPdvB6ES1D48Rn2Q/ for badge validations
// All string fields would be null if they are not filled (not empty strings)
// All array fields would be empty arrays if they are not filled

// Eco-Certified
fake_update_restaurant.set({
  input: {
    id: "restaurant-1",
    sustainability: ["ECOCERTI"],
    ECOCERTI: {
      eco_certification: [
        "MICHELIN",
        "SLOWFOOD",
        "INTERECO",
        "BIOSPHERE",
        "ECOCOOK",
        "QUALITAT",
        "BREEAM",
        "LEED",
        "OTHERECOCERTIFICATION",
      ],
      extra_eco_certification: "certification", // string or null
    },
  },
  response: {
    data: {
      result: {
        ok: true,
      },
    },
  },
});

// Plant-based friendly
fake_update_restaurant.set({
  input: {
    id: "restaurant-1",
    sustainability: ["PLANFRIE"],
    PLANFRIE: {
      entire_plant_based: true,
      two_main_dishes: true,
      plant_menu_includes: [
        "PLANTAPPETIZERS",
        "PLANTDESSERTS",
        "VEGANBEER",
        "VEGANWINE",
        "VEGANLIQUOR",
      ],
    },
  },
  response: {
    data: {
      result: {
        ok: true,
      },
    },
  },
});

// Vegetarian friendly
fake_update_restaurant.set({
  input: {
    id: "restaurant-1",
    sustainability: ["VEGEFRIE"],
    VEGEFRIE: {
      entirely_vegetarian: true,
      two_minimum_vegetarian_dishes: true,
    },
  },
  response: {
    data: {
      result: {
        ok: true,
      },
    },
  },
});

// Covid conscious
fake_update_restaurant.set({
  input: {
    id: "restaurant-1",
    sustainability: ["COVICONS"],
    COVICONS: {
      covid_measures: [
        "COVIDLOCAL",
        "OPENAIR",
        "EMPLOYEETEMPERATURES",
        "HIGHERMASKS",
        "SANITIZER",
        "TOUCHLESS",
        "SIGNS",
      ],
      other_covid_measures: "measures",
    },
  },
  response: {
    data: {
      result: {
        ok: true,
      },
    },
  },
});

// Waste conscious
fake_update_restaurant.set({
  input: {
    id: "restaurant-1",
    sustainability: ["WASTCONS"],
    WASTCONS: {
      waste_sell_surplus: true,
      waste_surplus_description: "description",
      waste_compost: true,
      waste_compost_explanation: ["COMPOSTOWN", "COMPOSTCITY"], // There is another option "COMPOSTTRASH", but you can have a badge with it selected
      waste_inventory: true,
      waste_recycle: ["RECYCLEGLASS", "RECYCLEPAPER", "RECYCLEMETAL"],
      waste_train: true,
      waste_customer_containers: true,
      waste_comments: "comments",
    },
  },
  response: {
    data: {
      result: {
        ok: true,
      },
    },
  },
});

// Plastic conscious
fake_update_restaurant.set({
  input: {
    id: "restaurant-1",
    sustainability: ["PLASCONS"],
    PLASCONS: {
      has_plastic_free_certification: true,
      plastic_free_certification_name: "certification",
      plastic_free_certification_year: 2020, // Must be the current year or earlier
      plastic_free_certification_duration: 2024, // Must be the current year or later
      plastic_use: [
        "PLASTICTABLEWARE",
        "PLASTICCUPS",
        "PLASTICSTRAWS",
        "PLASTICONTAINERS",
        "PLASTICBOTTLES",
      ], // These are all possible options, but you have 1 at most to earn "Plastic Conscious" status. If not you can still have "Plastic Free" status to earn the badge
      plastic_free_badge: true, // IMPORTANT!! There is two options to achieve the badge
      plastic_conscious_badge: false, // This is false because plasti_use has more than 1 item
      plastic_comments: "comments",
    },
  },
  response: {
    data: {
      result: {
        ok: true,
      },
    },
  },
});

// Sustainable meat
fake_update_restaurant.set({
  input: {
    id: "restaurant-1",
    sustainability: ["SUSTMEAT"],
    SUSTMEAT: {
      meat_has_certification: true,
      meat_certification_type: [
        "MEATCERTIFICATIONEU",
        "MEATCERTIFICATIONOTHER",
      ],
      meat_certification_name: "certification",
      meat_type: "type",
      meat_is_local: true,
      meat_menu: ["WILDGAME", "GRASSFED", "HORMONEFREE", "FREERANGECHICKEN"],
      meat_comments: "comments",
    },
  },
  response: {
    data: {
      result: {
        ok: true,
      },
    },
  },
});

// Sustainable Seafood
fake_update_restaurant.set({
  input: {
    id: "restaurant-1",
    sustainability: ["SUSTSEAF"],
    SUSTSEAF: {
      has_seafood_certification: true,
      seafood_certification_type: [
        "SEAFOODCERTIFICATIONMSC",
        "SEAFOODCERTIFICATIONOTHER",
      ],
      seafood_certification_name: "certification",
      seafood_type: "type",
      seafood_is_local: true,
      seafood_comments: "comments",
    },
  },
  response: {
    data: {
      result: {
        ok: true,
      },
    },
  },
});

// Sustainable produce
fake_update_restaurant.set({
  input: {
    id: "restaurant-1",
    sustainability: ["SUSTPROD"],
    SUSTPROD: {
      produce_has_grow: true,
      produce_grow_percentage: "25-50",
      produce_grow_type: [
        "GROWONSITE",
        "GROWBACKYARD",
        "GROWROOFTOP",
        "GROWOFFSITE",
        "GROWVERTICAL",
        "GROWAQUAPONICS",
        "GROWHYDROPONICS",
        "GROWOTHER",
      ],
      produce_grow_other_type: "other",
      produce_is_eu_certified: true,
      produce_organic_percentage: "50-75",
      produce_is_local: true,
      produce_local_percentage: "75-100",
      produce_comments: "comments",
    },
  },
  response: {
    data: {
      result: {
        ok: true,
      },
    },
  },
});

// Renewable energy
fake_update_restaurant.set({
  input: {
    id: "restaurant-1",
    sustainability: ["RENWENEG"],
    RENWENEG: {
      renewable_has_own: true,
      renewable_installation: [
        "GEOTHERMAL",
        "WIND",
        "SOLARPV",
        "SOLARTHERMAL",
        "HYDRO",
        "HYDROFUEL",
      ],
      renewable_consumption_percentage: 50, // number between 1 and 99
      renewable_start: 2020,
      renewable_has_electricity: true,
      renewable_electricity_provider: "supplier",
      renewable_has_gas: true,
      renewable_gas_provider: "supplier",
      renewable_comments: "comments",
    },
  },
  response: {
    data: {
      result: {
        ok: true,
      },
    },
  },
});

// Energy Efficient
fake_update_restaurant.set({
  input: {
    id: "restaurant-1",
    sustainability: ["ENEGEFFI"],
    ENEGEFFI: {
      energy_practices: [
        "NOOVEN",
        "DISHWASHER",
        "REFRIGERATOR",
        "LED",
        "CONTROLLED",
      ],
      energy_comments: "comments",
    },
  },
  response: {
    data: {
      result: {
        ok: true,
      },
    },
  },
});

// Water efficient
fake_update_restaurant.set({
  input: {
    id: "restaurant-1",
    sustainability: ["WATREFFI"],
    WATREFFI: {
      water_practices: [
        "PRERINSE",
        "EFFICIENTDISHWASHER",
        "TABLECLOTHFREE",
        "DUALURINALS",
        "SINKSENSOR",
        "ECOPRODUCTS",
      ],
      water_comments: "comments",
    },
  },
  response: {
    data: {
      result: {
        ok: true,
      },
    },
  },
});

// Consent
fake_update_restaurant.set({
  input: {
    id: "restaurant-1",
    signed_consent: true,
  },
  response: {
    data: {
      result: {
        ok: true,
      },
    },
  },
});

// update contact information
fake_update_restaurant.set({
  input: {
    id: "restaurant-1",
    name: "La Viña 2",
    address: "Lehendakari Aguirre, 4",
    zipcode: 12345,
    city: "Bilbao",
    phone_number: "666555444",
    email: "restaurant-1@kk.com",
    urls: {
      website: "www.restaurant.com",
      google_page: "google.com/maps/place/restaurant.com",
      instagram: "",
      facebook: "",
    },
  },
  response: {
    data: {
      result: {
        ok: true,
      },
    },
  },
});
