import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useUpdateRestaurant } from "hooks/restaurantHooks";
import { TextInput } from "components/FormElements";
import { AddPills } from "components/FormElements";
import { useGetKeywords } from "hooks/restaurantHooks";
import { MultipleChoices } from "components/FormElements";
import { InputToggle } from "components/FormElements";
import { StepSubmitButtons } from "./StepSubmitButtons";
import { Conditional } from "components/FormElements";
import { FullPageLoader } from "components/Loading";
import { ErrorPage } from "components/ErrorPage";
import { areEqual } from "utils/areEqual";

export function EditRestaurantDetails({
  restaurantInfo,
  isUpdateDisabled,
  setIsUpdateDisabled,
}) {
  const {
    id,
    services: savedServices,
    cuisine: savedCuisine,
    description: savedDescription,
    dietary: savedDietary,
    average_meal_price: savedPrice,
    reservation_required: savedReservation,
    reservation_channel: savedReservationChannel,
  } = JSON.parse(JSON.stringify(restaurantInfo));
  const { t } = useTranslation();
  const {
    data: keywords,
    isLoading: isLoadingKeywords,
    isError: isErrorKeywords,
  } = useGetKeywords();
  const { mutate: updateRestaurant, isLoading: isLoadingUpdateRestaurant } =
    useUpdateRestaurant();
  const [error, setError] = useState([]);
  const [success, setSuccess] = useState([]);
  const [openServices, setOpenServices] = useState(false);
  const [openCuisine, setOpenCuisine] = useState(false);
  const [servicesList, setServicesList] = useState(savedServices || "");
  const [cuisineList, setCuisineList] = useState(savedCuisine || "");
  const [description, setDescription] = useState(savedDescription || "");
  const [dietaryList, setDietaryList] = useState(savedDietary || "");
  const [price, setPrice] = useState(savedPrice || "");
  const [reservation, setReservation] = useState(savedReservation);
  const [reservationChannel, setReservationChannel] = useState(
    savedReservationChannel
  );

  useEffect(() => {
    if (parseInt(price) < 0) setPrice("0");
    if (
      servicesList.length === 0 ||
      cuisineList.length === 0 ||
      price.length === 0 ||
      (reservation === true && reservationChannel.length === 0)
    ) {
      return setIsUpdateDisabled(true);
    }
    if (
      areEqual(servicesList, savedServices) &&
      areEqual(cuisineList, savedCuisine) &&
      description === savedDescription &&
      areEqual(dietaryList, savedDietary) &&
      (price?.toString() === savedPrice?.toString() ||
        (savedPrice === null && price === "")) &&
      reservation === savedReservation &&
      areEqual(reservationChannel, savedReservationChannel)
    ) {
      return setIsUpdateDisabled(true);
    } else return setIsUpdateDisabled(false);
  }, [
    servicesList,
    cuisineList,
    reservation,
    price,
    reservationChannel,
    setIsUpdateDisabled,
    description,
    dietaryList,
    savedCuisine,
    savedDescription,
    savedPrice,
    savedDietary,
    savedReservation,
    savedReservationChannel,
    savedServices,
  ]);

  function handleReservation() {
    setReservation(!reservation);
    setReservationChannel([]);
  }

  function handleNext() {
    setError([]);
    setSuccess([]);

    const input = {
      id,
      description,
      cuisine: cuisineList,
      dietary: dietaryList,
      services: servicesList,
      average_meal_price: parseInt(price),
      reservation_required: reservation,
      reservation_channel: reservationChannel,
    };
    updateRestaurant(input, {
      onError: (error) => {
        return setError([error.message]);
      },
      onSuccess: (data) => {
        if (!data) return setError(["unknownError"]);
        if (data?.data.result.ok === false) return setError(["deniedError"]);
        if (data?.data.result.ok === true)
          return setSuccess(["informationUpdated"]);
      },
    });
  }

  if (isLoadingKeywords) return <FullPageLoader />;
  if (isErrorKeywords) return <ErrorPage />;
  return (
    <div className="mx-auto mt-4 max-w-3xl px-4 sm:mt-8">
      <ul className="flex list-disc flex-col gap-4 p-2 pl-4">
        <li>
          <TextInput
            attributeName="description"
            label={t("restaurantDetails.descriptionTitle")}
            value={description}
            setValue={setDescription}
            borderTop={false}
            autoCorrect="on"
          />
        </li>
        <li>
          <AddPills
            title={t("restaurantDetails.services")}
            open={openServices}
            setOpen={setOpenServices}
            optionsList={keywords.data.result.payload.services}
            setList={setServicesList}
            list={servicesList}
            required={true}
          />
        </li>
        <li>
          <AddPills
            title={t("restaurantDetails.cuisine")}
            open={openCuisine}
            setOpen={setOpenCuisine}
            optionsList={keywords.data.result.payload.cuisine}
            setList={setCuisineList}
            list={cuisineList}
            required={true}
          />
        </li>
        <li>
          <MultipleChoices
            optionsList={keywords.data.result.payload.dietary}
            setSelection={setDietaryList}
            selection={dietaryList}
            intro={t("restaurantDetails.dietary")}
            blockStyle="max-w-fit"
          />
        </li>
        <li>
          <TextInput
            attributeName="price"
            type="number"
            label={t("restaurantDetails.price")}
            value={price}
            setValue={setPrice}
            borderTop={false}
            autoCorrect="on"
            className="w-20"
            required={true}
          />
        </li>
        <li>
          <InputToggle
            enabled={reservation}
            setEnabled={handleReservation}
            text={t("restaurantDetails.reservation")}
          />
          {reservation === true && (
            <Conditional className="rounded-md bg-gray-100 ">
              <MultipleChoices
                optionsList={["PHONE", "WHATSAPP", "ONLINE"]}
                setSelection={setReservationChannel}
                selection={reservationChannel}
                intro={t("restaurantDetails.channel")}
                required={true}
                blockStyle="max-w-fit"
              />
            </Conditional>
          )}
        </li>
      </ul>
      <StepSubmitButtons
        isDisabled={isUpdateDisabled}
        isLoading={isLoadingUpdateRestaurant}
        next={t("editRestaurant.updateDetails")}
        onNext={handleNext}
        error={error}
        success={success}
        skip={false}
        className="mb-t sm:mb-14"
      />
    </div>
  );
}
