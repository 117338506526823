import React, { useState, useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Modal } from "./Modal";
import Button from "./Button";
import {
  MailIcon,
  PhoneIcon,
  LockClosedIcon,
  UserIcon,
  BriefcaseIcon,
} from "@heroicons/react/solid";
import { Switch } from "@headlessui/react";
import { PhotoModal } from "./ModalPhoto";
import { useDropzone } from "react-dropzone";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export function TextInput({
  attributeName,
  label,
  placeholder,
  value,
  setValue,
  autoComplete = "off",
  required = false,
  type = "text",
  icon,
  borderTop = true,
  disabled = false,
  minLength,
  maxLength,
  pattern,
  readonly,
  autoCorrect = "off",
  className,
  min,
  max,
  onChange = (e) => setValue(e.target.value),
  labelClassName,
}) {
  return (
    <div
      className={`${
        borderTop === true ? "sm:border-t sm:pt-4" : null
      }  sm:flex sm:items-center sm:gap-4 sm:border-gray-200`}
    >
      <label
        htmlFor={attributeName}
        className={`block font-medium sm:mt-px ${labelClassName}`}
      >
        {`${label} ${required === true ? "*" : ""}`}
      </label>
      <div className="relative mt-1 flex-1 sm:mt-0">
        {icon === "mail" && (
          <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
            <MailIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
          </div>
        )}
        {icon === "lock" && (
          <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
            <LockClosedIcon
              className="h-5 w-5 text-gray-400"
              aria-hidden="true"
            />
          </div>
        )}
        {icon === "phone" && (
          <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
            <PhoneIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
          </div>
        )}
        {icon === "user" && (
          <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
            <UserIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
          </div>
        )}
        {icon === "business" && (
          <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
            <BriefcaseIcon
              className="h-5 w-5 text-gray-400"
              aria-hidden="true"
            />
          </div>
        )}

        <input
          type={type}
          value={value}
          onChange={onChange}
          name={attributeName}
          id={attributeName}
          autoComplete={autoComplete}
          className={`${
            icon !== undefined ? "pl-10" : ""
          } block w-full  rounded-md border-gray-300 text-sm text-vdark placeholder-gray-400 shadow-sm focus:border-green-500 focus:ring-green-500 ${className}`}
          placeholder={placeholder}
          required={required}
          disabled={disabled}
          minLength={minLength}
          maxLength={maxLength}
          pattern={pattern}
          readOnly={readonly}
          autoCorrect={autoCorrect}
          min={min}
          max={max}
        />
      </div>
    </div>
  );
}

export function MultipleChoices({
  intro = "",
  optionsList = [],
  selection,
  setSelection,
  required,
  blockStyle,
  filter = false,
}) {
  const { t } = useTranslation();
  const [filterText, setFilterText] = useState("");

  function selectItem(e) {
    const value = e.target.value;
    if (selection.includes(value) === true) {
      const newSelection = selection.filter((item) => item !== value);
      return setSelection([...newSelection]);
    }
    if (selection.includes(value) === false) {
      return setSelection([...selection, value]);
    }
  }

  return (
    <div className="flex flex-col gap-2">
      <p className="font-medium ">{`${intro} ${required ? "*" : ""}`}</p>
      {filter && (
        <TextInput
          label="Filter"
          value={filterText}
          setValue={setFilterText}
          borderTop={false}
          className="my-2"
        />
      )}
      <div
        className={`mx-2 flex flex-col flex-wrap gap-2 rounded-md bg-gray-100 p-3 sm:mx-4 ${
          blockStyle ? blockStyle : ""
        }`}
      >
        {optionsList.map((item) => {
          if (filterText.length > 0) {
            if (
              !t(`keywords.${item}`)
                .toUpperCase()
                .includes(filterText.toUpperCase())
            )
              return undefined;
          }
          return (
            <div key={item} className="flex items-start">
              <div className="flex h-5 items-center">
                <input
                  id={item}
                  name={item}
                  type="checkbox"
                  value={item}
                  onChange={selectItem}
                  checked={selection.includes(item) ? true : false}
                  className="h-4 w-4 rounded border-gray-300 text-green-600 focus:ring-green-500"
                />
              </div>
              <div className="ml-3 text-sm">
                <label htmlFor={item} className="text-xs sm:text-sm">
                  {t(`keywords.${item}`)}
                </label>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export function AddPills({
  title,
  open,
  setOpen,
  list,
  setList,
  optionsList,
  required,
  filter = true,
  blockStyle = "sm:max-h-[50vh]", // To adjust the height of the columns so they are similar
}) {
  const { t } = useTranslation();

  function handleRemove(item) {
    const index = list.indexOf(item);
    if (index > -1) {
      list.splice(index, 1);
      setList([...list]);
    }
  }

  return (
    <>
      <Modal open={open} setOpen={setOpen} title={title}>
        <MultipleChoices
          setSelection={setList}
          selection={list}
          optionsList={optionsList}
          blockStyle={blockStyle}
          filter={filter}
        />
        <div className="mx-auto mt-5 flex flex-row justify-center gap-4 sm:mt-4 sm:gap-8">
          <Button
            variant="secondary"
            onClick={() => {
              setList([]);
              setOpen(false);
            }}
          >
            {t("general.cancel")}
          </Button>
          <Button onClick={() => setOpen(false)}>{t("general.accept")}</Button>
        </div>
      </Modal>
      <div className="w-full text-sm ">
        <div className="flex items-baseline ">
          <p className="font-medium">
            {title}
            <span>{required === true && " *"}</span>
          </p>
          <button
            onClick={() => setOpen(true)}
            className="ml-4 text-sm font-bold text-vaccent sm:text-base"
          >
            {t("general.add")}
          </button>
        </div>
        <div
          className={`mt-2 rounded-md bg-gray-100 ${
            list.length > 0 ? "mx-2 p-4 sm:mx-4" : ""
          }`}
        >
          {list.map((text) => {
            return <Badge key={text} text={text} handleRemove={handleRemove} />;
          })}
        </div>
      </div>
    </>
  );
}

function Badge({ text, handleRemove }) {
  const { t } = useTranslation();
  return (
    <span className="mr-2 mb-2 inline-flex items-center rounded-full bg-vgreen py-1 pl-2.5 pr-1 text-sm font-medium text-vdark">
      {t(`keywords.${text}`)}
      <button
        type="button"
        onClick={() => handleRemove(text)}
        className="ml-0.5 inline-flex h-4 w-4 flex-shrink-0 items-center justify-center rounded-full text-vdark hover:bg-green-500 hover:text-white focus:bg-green-500 focus:text-white focus:outline-none"
      >
        <span className="sr-only">Remove</span>
        <svg
          className="h-2 w-2"
          stroke="currentColor"
          fill="none"
          viewBox="0 0 8 8"
        >
          <path strokeLinecap="round" strokeWidth="1.5" d="M1 1l6 6m0-6L1 7" />
        </svg>
      </button>
    </span>
  );
}

export default function Toggle({ enabled, setEnabled }) {
  return (
    <Switch
      checked={enabled}
      onChange={setEnabled}
      className={classNames(
        enabled ? "bg-vgreen" : "bg-gray-200",
        "relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2"
      )}
    >
      <span className="sr-only">Use setting</span>
      <span
        className={classNames(
          enabled ? "translate-x-5" : "translate-x-0",
          "pointer-events-none relative inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
        )}
      >
        <span
          className={classNames(
            enabled
              ? "opacity-0 duration-100 ease-out"
              : "opacity-100 duration-200 ease-in",
            "absolute inset-0 flex h-full w-full items-center justify-center transition-opacity"
          )}
          aria-hidden="true"
        >
          <svg
            className="h-3 w-3 text-gray-400"
            fill="none"
            viewBox="0 0 12 12"
          >
            <path
              d="M4 8l2-2m0 0l2-2M6 6L4 4m2 2l2 2"
              stroke="currentColor"
              strokeWidth={2}
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </span>
        <span
          className={classNames(
            enabled
              ? "opacity-100 duration-200 ease-in"
              : "opacity-0 duration-100 ease-out",
            "absolute inset-0 flex h-full w-full items-center justify-center transition-opacity"
          )}
          aria-hidden="true"
        >
          <svg
            className="h-3 w-3 text-green-600"
            fill="currentColor"
            viewBox="0 0 12 12"
          >
            <path d="M3.707 5.293a1 1 0 00-1.414 1.414l1.414-1.414zM5 8l-.707.707a1 1 0 001.414 0L5 8zm4.707-3.293a1 1 0 00-1.414-1.414l1.414 1.414zm-7.414 2l2 2 1.414-1.414-2-2-1.414 1.414zm3.414 2l4-4-1.414-1.414-4 4 1.414 1.414z" />
          </svg>
        </span>
      </span>
    </Switch>
  );
}

export function InputToggle({ enabled, setEnabled, text }) {
  return (
    <div className="flex flex-col gap-2 sm:flex-row sm:items-center sm:gap-4">
      <p className="font-medium">{text}</p>
      <Toggle setEnabled={setEnabled} enabled={enabled} />
    </div>
  );
}

export function UploadPhoto({ title, max, imageList, setImageList, required }) {
  const { t } = useTranslation();
  const onDrop = useCallback(
    (acceptedFiles) => {
      setError(null);
      const imagesDataList = [];
      let availableEntries = max - imageList.length;
      for (const file of acceptedFiles) {
        const imageData = {
          preview: URL.createObjectURL(file),
          raw: file,
        };
        if (availableEntries > 0) {
          imagesDataList.push(imageData);
          availableEntries = availableEntries - 1;
        } else {
          setError(["tooManyImagesError"]);
        }
      }
      setImageList((prev) => {
        return [...prev, ...imagesDataList];
      });
    },
    [setImageList, imageList, max]
  );
  const { getRootProps, getInputProps, isDragAccept, isDragReject } =
    useDropzone({
      onDrop,
      accept: "image/jpg,image/png,image/jpeg",
      multiple: max > 1 ? true : false,
      // maxFiles: max,
      validator: fileValidator,
    });
  const [zoomPhoto, setZoomPhoto] = React.useState();
  const [open, setOpen] = React.useState(false);
  const [error, setError] = React.useState(null);

  const className = useMemo(() => {
    return classNames(
      isDragAccept ? "bg-green-100" : "bg-white",
      isDragReject ? "bg-red-100" : "",
      "flex items-center justify-center border-2 border-gray-300 border-dashed rounded-md h-44 w-44"
    );
  }, [isDragAccept, isDragReject]);

  function fileValidator(file) {
    if (file.size > 1024 * 1024 * 10) {
      return setError("bigImageError");
    }
  }

  function handleRemove(preview) {
    setError(null);
    setImageList((prev) => {
      const updatedList = prev.filter((image) => image.preview !== preview);
      return [...updatedList];
    });
  }

  function handleZoomPhoto(preview) {
    setOpen(true);
    setZoomPhoto(preview);
  }

  // function handleDrop(e) {
  //   e.preventDefault();
  //   if (e.dataTransfer.items) {
  //     // Use DataTransferItemList interface to access the file(s)
  //     for (let i = 0; i < e.dataTransfer.items.length; i++) {
  //       // If dropped items aren't files, reject them
  //       if (e.dataTransfer.items[i].kind === "file") {
  //         var file = e.dataTransfer.items[i].getAsFile();
  //         console.log("test 1... file[" + i + "].name = " + file.name);
  //       }
  //     }
  //   } else {
  //     // Use DataTransfer interface to access the file(s)
  //     for (let i = 0; i < e.dataTransfer.files.length; i++) {
  //       console.log(
  //         "test 2... file[" + i + "].name = " + e.dataTransfer.files[i].name
  //       );
  //     }
  //   }
  // }

  return (
    <>
      <PhotoModal setOpen={setOpen} open={open} photo={zoomPhoto} />
      <div className="flex flex-col gap-2 rounded-md bg-gray-100 p-2 sm:p-4 ">
        <div className="mb-2 flex items-baseline">
          <label
            htmlFor={title}
            className="block text-sm font-medium text-gray-700"
          >
            {`${title}:`}
            {required === true && <span> * </span>}
            <span className="ml-2 text-xs font-normal text-gray-500">{`(${t(
              "restaurantPhotos.maximum"
            )} ${max} ${
              max > 1
                ? t("restaurantPhotos.photos")
                : t("restaurantPhotos.photo")
            })`}</span>
          </label>
        </div>
        <div className="flex flex-wrap items-center gap-4">
          {imageList.length < max && (
            <div {...getRootProps({ className })}>
              <div className="space-y-1 text-center">
                <svg
                  className="mx-auto h-12 w-12 text-gray-400"
                  stroke="currentColor"
                  fill="none"
                  viewBox="0 0 48 48"
                  aria-hidden="true"
                >
                  <path
                    d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                    strokeWidth={2}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
                <div className="flex text-sm text-gray-600">
                  <label
                    htmlFor={title}
                    className="relative w-full cursor-pointer font-medium text-vaccent focus-within:outline-none focus-within:ring-2 focus-within:ring-green-500 focus-within:ring-offset-2 hover:text-green-500"
                  >
                    <span>{t("general.upload")}</span>
                    <input {...getInputProps()} />
                  </label>
                </div>
                <p className="text-xs text-gray-500">
                  {t("general.imageFormat")}
                </p>
              </div>
            </div>
          )}
          {imageList.length > 0 &&
            imageList.map((image, index) => {
              return (
                <div className="relative" key={image.preview}>
                  <button
                    onClick={() => handleRemove(image.preview)}
                    className="absolute top-0 right-0 h-8 w-8 translate-x-3 -translate-y-3"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 20 21"
                    >
                      <path
                        fill="#D9EF59"
                        d="M19 10.5a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                      />
                      <path
                        stroke="#354D5A"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="m8 12.5 2-2m0 0 2-2m-2 2-2-2m2 2 2 2m7-2a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                      />
                    </svg>
                  </button>
                  <img
                    src={image.preview}
                    className="h-44 w-44 cursor-pointer rounded-md object-cover"
                    onClick={() => handleZoomPhoto(image.preview)}
                    alt=""
                  />
                </div>
              );
            })}
        </div>
        {error && (
          <span className="mt-4 rounded-md border-2 border-red-300 bg-red-50 p-4 text-sm text-red-800">
            {t(`errors.${error}`)}
          </span>
        )}
      </div>
    </>
  );
}

export function TextInputSimple({
  label,
  placeholder,
  value,
  setValue,
  autoComplete = "off",
  required = false,
  type = "text",
  icon,
  disabled = false,
  minlength,
  pattern,
  readonly,
  autoCorrect = "off",
  className,
  min,
  max,
}) {
  return (
    <div>
      <label htmlFor={value} className="block font-medium sm:mt-px">
        {`${label} ${required === true ? "*" : ""}`}
      </label>
      <div className="relative mt-1 ">
        {icon === "mail" && (
          <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
            <MailIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
          </div>
        )}
        {icon === "lock" && (
          <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
            <LockClosedIcon
              className="h-5 w-5 text-gray-400"
              aria-hidden="true"
            />
          </div>
        )}
        {icon === "phone" && (
          <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
            <PhoneIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
          </div>
        )}
        {icon === "user" && (
          <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
            <UserIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
          </div>
        )}
        {icon === "business" && (
          <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
            <BriefcaseIcon
              className="h-5 w-5 text-gray-400"
              aria-hidden="true"
            />
          </div>
        )}

        <input
          type={type}
          value={value}
          onChange={(e) => {
            if (min !== undefined && parseInt(e.target.value) < min)
              return setValue(value);
            if (max !== undefined && parseInt(e.target.value) > max)
              return setValue(value);
            return setValue(e.target.value);
          }}
          name={value}
          id={value}
          autoComplete={autoComplete}
          className={`${
            icon !== undefined ? "pl-10" : ""
          } block w-full  rounded-md border-gray-300 text-vdark placeholder-gray-400 shadow-sm focus:border-green-500 focus:ring-green-500 sm:text-sm ${className}`}
          placeholder={placeholder}
          required={required}
          disabled={disabled}
          minLength={minlength}
          pattern={pattern}
          readOnly={readonly}
          autoCorrect={autoCorrect}
        />
      </div>
    </div>
  );
}

export function SingleChoice({
  intro,
  optionsList,
  selection,
  setSelection,
  required,
  blockStyle,
}) {
  const { t } = useTranslation();

  function selectItem(e) {
    const value = e.target.value;
    if (selection.includes(value) === true) {
      return setSelection([]);
    }
    if (selection.includes(value) === false) {
      return setSelection([value]);
    }
  }

  return (
    <div className="flex flex-col gap-2 ">
      <p className="font-medium">{`${intro} ${required ? "*" : ""}`}</p>
      <div
        className={`mx-2 flex flex-col flex-wrap gap-2 rounded-md bg-gray-100 p-3 sm:mx-4 ${
          blockStyle ? blockStyle : ""
        }`}
      >
        {optionsList.map((item) => {
          return (
            // We insert the "intro" in the key to avoid conflicts, because the intro should be different on each form
            <div key={`${intro}-${item}`} className="flex items-start">
              <div className="flex h-5 items-center">
                <input
                  id={`${intro}-${item}`}
                  name={item}
                  type="radio"
                  value={item}
                  onChange={selectItem}
                  checked={selection.includes(item) ? true : false}
                  className="h-4 w-4 border-gray-300 text-green-600 focus:ring-green-500"
                />
              </div>
              <div className="ml-3 text-sm">
                <label
                  htmlFor={`${intro}-${item}`}
                  className="text-xs sm:text-sm"
                >
                  {t(`keywords.${item}`)}
                </label>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export function Conditional({ children }) {
  return (
    <div className="m-2 flex flex-col gap-2 rounded-md border-2 border-vgreen p-2 sm:gap-4 sm:p-4">
      {children}
    </div>
  );
}
