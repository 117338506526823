import React from "react";
import Markdown from "markdown-to-jsx";

// Can't make the Tailwind typography plugin work for Markdown, so I will rely on CSS for now

export const MarkdownPage = ({ url }) => {
  const [content, setContent] = React.useState("");
  React.useEffect(() => {
    fetch(url)
      .then((res) => res.text())
      .then((md) => {
        setContent(md);
      });
  }, [url]);
  return (
    <div className=" markdown-page isolate">
      <Markdown children={content} />
    </div>
  );
};
