import React from "react";
import { useTranslation } from "react-i18next";
import careers from "./img/careers.jpg";
import { jobOffers } from "./jobOffers";

export function Careers() {
  const { t, i18n } = useTranslation();
  const language = i18n.resolvedLanguage;
  return (
    <main>
      <HeroImage />
      <div className="mx-auto flex max-w-7xl flex-col gap-8 px-4 sm:gap-12 sm:px-8 md:gap-16">
        <div className="mx-auto flex max-w-4xl flex-col gap-4">
          <h2>{t("careers.whyVerdantips")}</h2>
          <p>{t("careers.reason")}</p>
        </div>
        <div className="mx-auto flex max-w-4xl flex-col gap-4">
          <h2>{t("careers.impact")}</h2>
          <p>
            {jobOffers[language].length > 0
              ? t("careers.jobAvailable")
              : t("careers.jobNotAvailable")}
            <span className="font-semibold text-vdark">
              {" "}
              contact@verdantips.com
            </span>
          </p>
        </div>
        <div className="flex flex-wrap items-center justify-center gap-4 sm:gap-6">
          {jobOffers[language].map(({ title, description, link }) => {
            return (
              <JobPost
                key={title}
                title={title}
                description={description}
                link={link}
              />
            );
          })}
        </div>
      </div>
    </main>
  );
}

function HeroImage() {
  const { t } = useTranslation();
  return (
    <div className="relative isolate mb-8 h-64 sm:mb-12 sm:h-[500px] md:mb-16">
      <img src={careers} alt="" className="absolute h-full object-cover" />
      <div className="absolute h-full w-full bg-vdarkgreen opacity-60 " />
      <div className="absolute flex h-full w-full flex-col items-center justify-center gap-2 p-2 sm:gap-6 lg:gap-8">
        <p
          style={{ fontFamily: "DIN" }}
          className="max-w-sm text-center text-4xl text-white drop-shadow sm:max-w-xl sm:text-6xl md:text-7xl lg:max-w-5xl lg:text-8xl"
        >
          {t("careers.heroTitle")}
        </p>
      </div>
    </div>
  );
}

function JobPost({ title, description, link }) {
  const { t } = useTranslation();
  return (
    <div className="m-2 flex max-w-lg flex-col gap-2 overflow-hidden rounded-lg border bg-white p-4 shadow sm:gap-4">
      <h3>{title}</h3>
      <p>{description}</p>
      <a
        href={link}
        target="_blank"
        rel="noopener noreferrer"
        className="max-w-max self-center rounded-sm bg-vgreen p-2 text-center text-sm font-bold text-vdarkgreen sm:p-3 sm:text-xl"
      >
        {t("careers.learnMore")}
      </a>
    </div>
  );
}
