import React from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import { RequireAuth } from "context/AuthProvider";
import { Dashboard } from "pages/Dashboard";
import { Login } from "pages/Auth/Login/Login";
import { CreateRestaurantAccount } from "pages/Auth/CreateRestaurantAccount";
import { ForgotPassword } from "pages/Auth/ForgotPassword";
import { CheckEmail } from "pages/Auth/CheckEmail";
import { Success } from "pages/Auth/Success";
import { ResetPassword } from "pages/Auth/ResetPassword";
import { RestaurantCreate } from "pages/Dashboard/RestaurantCreate";
import { RestaurantDetail } from "pages/Dashboard/RestaurantDetail";
import { Home } from "pages/Landing/Home";
import { About } from "pages/Landing/About";
import { BarcelonaPilot } from "pages/Landing/BarcelonaPilot";
import { ContactUs } from "pages/Landing/ContactUs";
import { ForRestaurants } from "pages/Landing/ForRestaurants";
import { ForUsers } from "pages/Landing/ForUsers";
import { Sustainability } from "pages/Landing/Sustainability";
import { Restaurants } from "pages/Landing/Restaurants";
import { RestaurantInfo } from "pages/Landing/RestaurantInfo";
import { Careers } from "pages/Landing/Careers";
import { ActivateAccount } from "pages/Auth/Activate";
import { ValidateEmail } from "pages/Auth/ValidateEmail";
import TermAndConditionsApp from "pages/Landing/TermsAndConditionsApp";
import PrivacyPolicy from "pages/Landing/PrivacyPolicy";
import TermsAndConditionsWeb from "pages/Landing/TermsAndConditionsWeb";
import TermsOfUse from "pages/Landing/TermsOfUse";
import { MyIssues } from "pages/Dashboard/MyIssues";
import { IssueDetail } from "pages/Dashboard/IssueDetail";
import { SuccessDashboard } from "pages/Dashboard/SuccessDashboard";

export const navigation = {
  en: [
    { name: "Home", to: "/" },
    { name: "Restaurants", to: "/for-restaurants" },
    { name: "Users", to: "/for-users" },
    {
      name: "About Verdantips",
      to: "/about",
      dropdown: [
        { name: "Sustainability", to: "/sustainability" },
        { name: "Careers", to: "/careers" },
      ],
    },
    { name: "Contact", to: "/contact-us" },
  ],
  es: [
    { name: "Inicio", to: "/" },
    { name: "Restaurantes", to: "/para-restaurantes" },
    { name: "Usuarios", to: "/para-usuarios" },
    {
      name: "Sobre Verdantips",
      to: "/sobre-verdantips",
      dropdown: [
        { name: "Sostenibilidad", to: "/sostenibilidad" },
        { name: "Trabaja con nosotras", to: "/trabaja-con-nosotras" },
      ],
    },
    { name: "Contacto", to: "/contacto" },
  ],
};

export const legalLinks = {
  en: [
    {
      name: "Verdantips Privacy Policy",
      to: "/privacy-policy",
    },
    { name: "Website Terms & Conditions", to: "/website-terms-and-conditions" },
    { name: "Website Terms of Use", to: "/website-terms-of-use" },
    { name: "App Terms & Conditions", to: "/app-terms-and-conditions" },
  ],
  es: [
    { name: "Política de Privacidad", to: "/politica-privacidad" },
    {
      name: "Términos y Condiciones de la Web",
      to: "/web-terminos-y-condiciones",
    },
    { name: "Términos de Uso de la Web", to: "/web-terminos-uso" },
    {
      name: "Términos y Condiciones de la App",
      to: "/app-terminos-condiciones",
    },
  ],
};

export const RoutesContainer = () => (
  <div className="min-h-screen">
    <ScrollToTop />
    <Routes>
      {/* Both languages */}
      <Route path="/" element={<Home />} />
      <Route path="/login" element={<Login />} />
      <Route path="/forgot-password" element={<ForgotPassword />} />
      <Route path="/check-email/:action" element={<CheckEmail />} />
      <Route path="/success/:action" element={<Success />} />
      <Route
        path="/create-restaurant-account"
        element={<CreateRestaurantAccount />}
      />
      <Route path="/reset-password/:token" element={<ResetPassword />} />
      <Route path="/activate-account" element={<ActivateAccount />} />
      <Route path="/validate-email/:token" element={<ValidateEmail />} />

      <Route path="/restaurant/:id" element={<RestaurantInfo />} />

      {/* English */}
      <Route path="/for-restaurants" element={<ForRestaurants />} />
      <Route path="/for-users" element={<ForUsers />} />
      <Route path="/about" element={<About />} />
      <Route path="/contact-us" element={<ContactUs />} />
      <Route path="/join-our-barcelona-pilot" element={<BarcelonaPilot />} />
      <Route path="/sustainability" element={<Sustainability />} />
      <Route path="/careers" element={<Careers />} />
      <Route path="/sustainable-businesses" element={<Restaurants />} />
      <Route path="/privacy-policy" element={<PrivacyPolicy />} />
      <Route
        path="/website-terms-and-conditions"
        element={<TermsAndConditionsWeb />}
      />
      <Route path="/website-terms-of-use" element={<TermsOfUse />} />
      <Route
        path="/app-terms-and-conditions"
        element={<TermAndConditionsApp />}
      />

      {/* Spanish */}
      <Route path="/para-restaurantes" element={<ForRestaurants />} />
      <Route path="/para-usuarios" element={<ForUsers />} />
      <Route path="/sobre-verdantips" element={<About />} />
      <Route path="/contacto" element={<ContactUs />} />
      <Route
        path="/unete-a-nuestro-piloto-en-barcelona"
        element={<BarcelonaPilot />}
      />
      <Route path="/sostenibilidad" element={<Sustainability />} />
      <Route path="/trabaja-con-nosotras" element={<Careers />} />
      <Route path="/negocios-sostenibles" element={<Restaurants />} />
      <Route path="/politica-privacidad" element={<PrivacyPolicy />} />
      <Route
        path="/web-terminos-y-condiciones"
        element={<TermsAndConditionsWeb />}
      />
      <Route path="/web-terminos-uso" element={<TermsOfUse />} />
      <Route
        path="/app-terminos-condiciones"
        element={<TermAndConditionsApp />}
      />

      {/* Protected routes */}
      <Route
        path="/dashboard"
        element={
          <RequireAuth>
            <Dashboard />
          </RequireAuth>
        }
      />
      <Route
        path="/dashboard/create-restaurant"
        element={
          <RequireAuth>
            <RestaurantCreate />
          </RequireAuth>
        }
      />
      <Route
        path="/dashboard/issues"
        element={
          <RequireAuth>
            <MyIssues />
          </RequireAuth>
        }
      />
      <Route
        path="/dashboard/issues/:id"
        element={
          <RequireAuth>
            <IssueDetail />
          </RequireAuth>
        }
      />
      <Route
        path="/dashboard/restaurant/:id"
        element={
          <RequireAuth>
            <RestaurantDetail />
          </RequireAuth>
        }
      />
      <Route
        path="/dashboard/success/:action"
        element={
          <RequireAuth>
            <SuccessDashboard />
          </RequireAuth>
        }
      />
    </Routes>
  </div>
);

function ScrollToTop() {
  const { pathname } = useLocation();

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}
