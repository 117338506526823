import React from "react";
import { useTranslation } from "react-i18next";
import { CheckCircleIcon } from "@heroicons/react/solid";

export function SuccessBlock({
  title = "success.announceSuccess",
  successList = [],
  className,
}) {
  const { t } = useTranslation();
  return (
    <div className={className}>
      <div className="p-4 rounded-md bg-green-50">
        <div className="flex">
          <div className="flex-shrink-0">
            <CheckCircleIcon
              className="w-5 h-5 text-green-400"
              aria-hidden="true"
            />
          </div>
          <div className="ml-3">
            <p className="text-sm font-medium text-green-800">{t(title)}</p>
            <div className="mt-2 text-sm text-green-700">
              <ul className="pl-5 space-y-1 list-disc">
                {successList.map((message) => {
                  return <li key={message}>{t(`success.${message}`)}</li>;
                })}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
