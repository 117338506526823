import { FakeResponseCollection } from "utils/fakeResponseCollection";

export const fake_get_user_attributes = new FakeResponseCollection();

fake_get_user_attributes.set({
  input: {},
  response: {
    data: {
      result: {
        ok: true,
        payload: {
          id: "user-id-1",
          email: "kk@kk.com",
          name: "Ricardo",
          last_name: "Crespo",
          country: "Spain",
          phone_number: "622181450",
          type: "ADMIN",
        },
      },
    },
  },
});

fake_get_user_attributes.set({
  input: {},
  response: {
    data: {
      result: {
        ok: false,
      },
    },
  },
});
