import React from "react";
import { useTranslation } from "react-i18next";
import { XCircleIcon } from "@heroicons/react/solid";

export function ErrorBlock({
  title = "errors.announceError",
  errorList = [],
  className,
}) {
  const { t } = useTranslation();
  return (
    <div className={className}>
      <div className="p-4 rounded-md bg-red-50">
        <div className="flex">
          <div className="flex-shrink-0">
            <XCircleIcon className="w-5 h-5 text-red-400" aria-hidden="true" />
          </div>
          <div className="ml-3">
            <p className="text-sm font-medium text-red-800">{t(title)}</p>
            <div className="mt-2 text-sm text-red-700">
              <ul className="pl-5 space-y-1 list-disc">
                {errorList.map((message) => {
                  return <li key={message}>{t(`errors.${message}`)}</li>;
                })}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
