import React from "react";
import { useTranslation } from "react-i18next";

// TODO: Make it pretty and multilanguage
export function ErrorPage({ message = "unknownError" }) {
  const { t } = useTranslation();
  return (
    <div className="flex items-center content-center min-h-screen">
      <p>{t(`errors.${message}`)}</p>
    </div>
  );
}
