import { FakeResponseCollection } from "utils/fakeResponseCollection";

export const fake_get_issue_by_id = new FakeResponseCollection();

fake_get_issue_by_id.set({
  input: {
    issue_id: "issue-1",
  },
  response: {
    data: {
      result: {
        ok: true,
        payload: {
          id: "issue-1",
          user_id: "user-1",
          restaurant: {
            id: "restaurant-1",
            name: "Garibolo",
          },
          description: "The waiter didn't wear mask all the time",
          keyword: "COVICONS",
          date: "2021-11-02",
          completed_date: "", // TODO by Backend: Before it was null
          photos: [
            "https://source.unsplash.com/1000x500",
            "https://source.unsplash.com/1000x800",
            "https://source.unsplash.com/800x600",
          ],
          restaurant_comment: "", // TODO by Backend: Before it was null
        },
      },
    },
  },
});

fake_get_issue_by_id.set({
  input: {
    issue_id: "issue-3",
  },
  response: {
    data: {
      result: {
        ok: true,
        payload: {
          id: "issue-3",
          user_id: "user-3",
          restaurant_id: "restaurant-1",
          restaurant_name: "Garibolo",
          description: "The wifi disconnects a lot and is practically unusable",
          keyword: "FREE_WIFI",
          date: "2021-03-05",
          completed_date: "2022-01-25",
          photos: [
            "https://source.unsplash.com/800x500",
            "https://source.unsplash.com/900x800",
            "https://source.unsplash.com/800x800",
          ],
          restaurant_comment:
            "We talked to our ISP and they fixed our router so now it works perfectly.",
        },
      },
    },
  },
});

fake_get_issue_by_id.set({
  input: {
    issue_id: "DENIED",
  },
  response: {
    data: {
      result: {
        ok: false, // TODO: Handle different scenarios like: the issue doesn't exist, the user is not authorized...
      },
    },
  },
});
